import React, {useState} from 'react';
import PropTypes from 'prop-types'

import { GridDropdown } from '.';
import { DropdownItem } from 'reactstrap';

export const LobDropdown = (props: any) => {
  const [isLobDropdownOpen, setIsLobDropdownOpen] = useState(false);

  const toggleLobDropdown = () => setIsLobDropdownOpen(prevState => !prevState);

  return (
    <GridDropdown
      gridSize={props.gridSize}
      inputLabel='LOB:'
      isDisabled={props.isDisabled}
      isDropdownOpen={isLobDropdownOpen}
      handleToggle={toggleLobDropdown}
      placeholder='---------'
      value={props.lob}
    >
      {props.lobOptions.map((item: string) =>
        <DropdownItem key={item} value={item} onClick={props.handleLobChange}>
          {item}
        </DropdownItem>
      )}
    </GridDropdown>
  );
};

LobDropdown.propTypes = {
  gridSize: PropTypes.number,
  lob: PropTypes.any,
  lobOptions: PropTypes.array,
  handleLobChange: PropTypes.func,
  isDisabled: PropTypes.bool
};
