import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LobDashboard from './LobDashboard'

import { getUsername } from '../../../State/Authentication/Selectors/AuthenticationSelectors';
import { getLobState } from '../../../State/Lob/Selectors/LobSelectors';
import { getLobs } from '../../../State/Lob/Actions/LobActions';

import { AppPage, PageHeader, WithLoading } from '../../../Common';

const LobDashboardWithLoading = WithLoading(LobDashboard);

const LobDashboardContainer = () => {
    const dispatch = useDispatch();
    const userName = useSelector(getUsername);
    const lobState = useSelector(getLobState);

    useEffect(() => {
        if (!lobState.getLobListLoadable.isReceived) {
            dispatch(getLobs());
        }
    }, [lobState.getLobListLoadable.isReceived, dispatch])

    return (
        <>
            <PageHeader>LOB Dashboard</PageHeader>
            <AppPage>
                <LobDashboardWithLoading
                    loadable={lobState.getLobListLoadable}
                    lobList={lobState.entities}
                    userName={userName}
                />
            </AppPage>
        </>
    )
}

export default LobDashboardContainer;