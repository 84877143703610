import React, { useEffect, useState } from 'react';
import { HeadCell, Row } from '../Models/ForecastingTable';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { ForecastingTableHead } from './ForecastingTableHead';
import { ForecastingTableBody } from './ForecastingTableBody';
import { isNumber } from 'lodash';

const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);
  
    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if(sortConfig.key2 === undefined){
            
            // if (sortConfig.key === 'remaining_budget' 
            //     && a['spends'] === 'Forecasted Spend' 
            //     && b['spends'] != 'Forecasted Spend') {
            //     return -1;
            // }
            // else if (sortConfig.key === 'remaining_budget' && a['spends'] != 'Forecasted Spend' && b['spends'] === 'Forecasted Spend') {
            //     return 1;
            // }
            // else if (sortConfig.key === 'remaining_budget' && a['spends'] != 'Forecasted Spend' && b['spends'] != 'Forecasted Spend') {
            //     return 0;
            // }
           
            if(isNumber(a[sortConfig.key]) || a[sortConfig.key] === null || b[sortConfig.key] === null){
              if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
              }
              if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
              }
            } else {
              if (a[sortConfig.key].toUpperCase() < b[sortConfig.key].toUpperCase()) {
                return sortConfig.direction === 'asc' ? -1 : 1;
              }
              if (a[sortConfig.key].toUpperCase() > b[sortConfig.key].toUpperCase()) {
                return sortConfig.direction === 'asc' ? 1 : -1;
              }
            }
          } else {
            if(a[sortConfig.key] === null || b[sortConfig.key] === null || a[sortConfig.key2] === null || b[sortConfig.key2] === null){
              if (a[sortConfig.key] + a[sortConfig.key2] < b[sortConfig.key] + b[sortConfig.key2]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
              }
              if (a[sortConfig.key] + a[sortConfig.key2] > b[sortConfig.key] + b[sortConfig.key2]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
              }
            } else {
              if ((a[sortConfig.key].toUpperCase() + a[sortConfig.key2]).toUpperCase() < (b[sortConfig.key].toUpperCase() + b[sortConfig.key2]).toUpperCase()) {
                return sortConfig.direction === 'asc' ? -1 : 1;
              }
              if ((a[sortConfig.key].toUpperCase() + a[sortConfig.key2]).toUpperCase() > (b[sortConfig.key].toUpperCase() + b[sortConfig.key2]).toUpperCase()) {
                return sortConfig.direction === 'asc' ? 1 : -1;
              }
            }
          }
          
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);
  
    const requestSort = (key: string, key2: string = undefined) => {
  
      let direction = 'asc';
      if(key2 === undefined){
        if (
          sortConfig &&
          sortConfig.key === key &&
          sortConfig.direction === 'asc'
        ) {
          direction = 'desc';
        }
        setSortConfig({ key, direction });
  
      } else {
        if (
          sortConfig &&
          sortConfig.key === key &&
          sortConfig.key2 === key2 &&
          sortConfig.direction === 'asc'
        ) {
          direction = 'desc';
        }
        setSortConfig({ key, key2, direction });
      }
      
    };
  
    return { items: sortedItems, requestSort, sortConfig };
  };

export const ForecastingTool = (props: any) => {
    const {selectedFiscal, checkedH1, checkedH2, checkedSEM, checkedFB, forecastData, selectedDollar, selectedVariance, lobOptions, searchValue} = props;
    
    const [headCells, setHeadCells] = useState<Array<HeadCell>>([]);
    const [rows, setRows] = useState<Array<Row>>([]);
    const [allData, setAllData] = useState<Array<Row>>([]);  

    useEffect(() => {
        let originHeadCells: HeadCell[] = 
        [
            {id: 'mpa',label: 'MPA', isNum:false, fixed:true, sortColumnName: 'mpa', columnWidth: '12%'},
            {id: 'channel', label: 'Channel', isNum:false, fixed:true, sortColumnName: 'channel', columnWidth: '8%'},
            {id: 'product',label: 'Product', isNum:false, fixed:true, sortColumnName: 'products', columnWidth: '11%'},
            {id: 'map_subtotal',label: 'Subtotal', isNum:true, fixed:true, sortColumnName: 'mpa_subtotal', columnWidth: '8%'},
            // {id: 'mpa_total',label: 'Total', isNum:true, fixed:true},
            {id: 'remaining',label: 'Remaining to be Allocated', isNum:true, fixed:true, sortColumnName: 'remaining_budget'},
            {id: 'spends',label: 'Spends', isNum:false, fixed:true},
            {id: selectedFiscal+1 ,label: selectedFiscal-1+'-11-01', isNum:true, fixed:false},
            {id: selectedFiscal+2 ,label: selectedFiscal-1+'-12-01', isNum:true, fixed:false},
            {id: selectedFiscal+3 ,label: selectedFiscal+'-01-01', isNum:true, fixed:false},
            {id: selectedFiscal+4 ,label: selectedFiscal+'-02-01', isNum:true, fixed:false},
            {id: selectedFiscal+5 ,label: selectedFiscal+'-03-01', isNum:true, fixed:false},
            {id: selectedFiscal+6 ,label: selectedFiscal+'-04-01', isNum:true, fixed:false},
            {id: selectedFiscal+7 ,label: selectedFiscal+'-05-01', isNum:true, fixed:false},
            {id: selectedFiscal+8 ,label: selectedFiscal+'-06-01', isNum:true, fixed:false},
            {id: selectedFiscal+9 ,label: selectedFiscal+'-07-01', isNum:true, fixed:false},
            {id: selectedFiscal+10 ,label: selectedFiscal+'-08-01', isNum:true, fixed:false},
            {id: selectedFiscal+11 ,label: selectedFiscal+'-09-01', isNum:true, fixed:false},
            {id: selectedFiscal+12 ,label: selectedFiscal+'-10-01', isNum:true, fixed:false},
            {id: 'total' ,label: 'Total Forecasted', isNum:true, fixed:true},
        ]

        // originRows.sort((a,b)=> {
        //     let a_mpa = a.mpa.toLowerCase();
        //     let b_mpa = b.mpa.toLowerCase();
        //     if(a_mpa < b_mpa){
        //         return -1;
        //     }
        //     if(a_mpa > b_mpa) {
        //         return 1;
        //     }  
    
        //     let a_spends = a.spends;
        //     let b_spends = b.spends;
        //     if(a_spends === "Forecasted Spend" && b_spends === "Actual Spend")
        //         return -1;
        //     if(a_spends === "Forecasted Spend" && b_spends === "Variance")
        //         return -1;
        //     if(a_spends === "Forecasted Spend" && b_spends === "Variance %")
        //         return -1;
        //     if(a_spends === "Actual Spend" && b_spends === "Forecasted Spend")
        //         return 1;
        //     if(a_spends === "Actual Spend" && b_spends === "Variance")
        //         return -1;
        //     if(a_spends === "Actual Spend" && b_spends === "Variance %")
        //         return -1;
        //     if(a_spends === "Variance" && b_spends === "Forecasted Spend")
        //         return 1;
        //     if(a_spends === "Variance" && b_spends === "Actual Spend")
        //         return 1;
        //     if(a_spends === "Variance" && b_spends === "Variance %")
        //         return -1;
        //     if(a_spends === "Variance %" && b_spends === "Forecasted Spend")
        //         return 1;
        //     if(a_spends === "Variance %" && b_spends === "Actual Spend")
        //         return 1;
        //     if(a_spends === "Variance %" && b_spends === "Variance")
        //         return 1;
        // })

        let tempheadCells = originHeadCells.map(h=>{return {...h}});
        let output: Row[] =[];
        let total_forecasted = 0;
        let total_actual = 0;

        for(let r of forecastData){

            let temp: Row = {
                mpa: "",
                channel: "",
                lob: "",
                products: "",
                scheduled_end_date: "",
                mpa_subtotal: 0,
                // mpa_total: 0,
                remaining_budget:0,
                spends: "",
                m1: 0,
                m2: 0,
                m3: 0,
                m4: 0,
                m5: 0,
                m6: 0,
                m7: 0,
                m8: 0,
                m9: 0,
                m10: 0,
                m11: 0,
                m12: 0,
                total: 0,
            };
            temp.mpa = r.mpa;
            temp.channel = r.channel;
            temp.lob = r.lob;
            temp.products = r.products;
            temp.scheduled_end_date = r.scheduled_end_date;
            temp.spends = r.spends;

            if(selectedDollar === "Gross"){
                temp.mpa_subtotal = r.mpa_subtotal_gross;
                // temp.mpa_total = r.total_gross;
                // temp.remaining_budget = r.remaining_budget_gross;
                if(r.spends === "Variance %"){
                    if(r.jan_gross <= 0)
                        temp.m1 = 0
                    if(r.jan_gross > 0)
                        temp.m1 = Math.round(r.jan_gross)

                    if(r.feb_gross <= 0)
                        temp.m2 = 0
                    if(r.feb_gross > 0)
                        temp.m2 = Math.round(r.feb_gross)

                    if(r.mar_gross <= 0)
                        temp.m3 = 0
                    if(r.mar_gross > 0)
                        temp.m3 = Math.round(r.mar_gross)

                    if(r.apr_gross <= 0)
                        temp.m4 = 0
                    if(r.apr_gross > 0)
                        temp.m4 = Math.round(r.apr_gross)

                    if(r.may_gross <= 0)
                        temp.m5 = 0
                    if(r.may_gross > 0)
                        temp.m5 = Math.round(r.may_gross)

                    if(r.jun_gross <= 0)
                        temp.m6 = 0
                    if(r.jun_gross > 0)
                        temp.m6 = Math.round(r.jun_gross)

                    if(r.july_gross <= 0)
                        temp.m7 = 0
                    if(r.july_gross > 0)
                        temp.m7 = Math.round(r.july_gross)

                    if(r.aug_gross <= 0)
                        temp.m8 = 0
                    if(r.aug_gross > 0)
                        temp.m8 = Math.round(r.aug_gross)

                    if(r.sep_gross <= 0)
                        temp.m9 = 0
                    if(r.sep_gross > 0)
                        temp.m9 = Math.round(r.sep_gross)

                    if(r.oct_gross <= 0)
                        temp.m10 = 0
                    if(r.oct_gross > 0)
                        temp.m10 = Math.round(r.oct_gross)

                    if(r.nov_gross <= 0)
                        temp.m11 = 0
                    if(r.nov_gross > 0)
                        temp.m11 = Math.round(r.nov_gross)

                    if(r.dec_gross <= 0)
                        temp.m12 = 0
                    if(r.dec_gross > 0)
                        temp.m12 = Math.round(r.dec_gross);

                } else {
                    temp.m1 = Math.round(r.jan_gross);
                    temp.m2 = Math.round(r.feb_gross);
                    temp.m3 = Math.round(r.mar_gross);
                    temp.m4 = Math.round(r.apr_gross);
                    temp.m5 = Math.round(r.may_gross);
                    temp.m6 = Math.round(r.jun_gross);
                    temp.m7 = Math.round(r.july_gross);
                    temp.m8 = Math.round(r.aug_gross);
                    temp.m9 = Math.round(r.sep_gross);
                    temp.m10 = Math.round(r.oct_gross);
                    temp.m11 = Math.round(r.nov_gross);
                    temp.m12 = Math.round(r.dec_gross);
                }
                temp.total = temp.m1 + temp.m2 + temp.m3 + temp.m4 + temp.m5 + temp.m6 + temp.m7 + temp.m8 + temp.m9 + temp.m10 + temp.m11 + temp.m12;
                temp.remaining_budget = r.mpa_subtotal_gross - temp.total;
                temp.remaining_budget = Math.round(temp.remaining_budget) === -0? 0: temp.remaining_budget;

                if(r.spends === "Forecasted Spend")
                    total_forecasted = temp.total;
                if(r.spends === "Actual Spend")
                    total_actual = temp.total;
                if(r.spends === "Variance %"){
                    if(total_actual === 0){
                        temp.total = 0;
                    } else {
                        temp.total = Math.round(((total_actual - total_forecasted) / total_actual) * 100);
                    }
                }

            } else if(selectedDollar === "Net"){
                temp.mpa_subtotal = r.mpa_subtotal_net;
                // temp.mpa_total = r.total_net;
                // temp.remaining_budget = r.remaining_budget_net;
                if(r.spends === "Variance %"){
                    if(r.jan_net <= 0)
                        temp.m1 = 0
                    if(r.jan_net > 0)
                        temp.m1 = Math.round(r.jan_net)

                    if(r.feb_net <= 0)
                        temp.m2 = 0
                    if(r.feb_net > 0)
                        temp.m2 = Math.round(r.feb_net)

                    if(r.mar_net <= 0)
                        temp.m3 = 0
                    if(r.mar_net > 0)
                        temp.m3 = Math.round(r.mar_net)

                    if(r.apr_net <= 0)
                        temp.m4 = 0
                    if(r.apr_net > 0)
                        temp.m4 = Math.round(r.apr_net)

                    if(r.may_net <= 0)
                        temp.m5 = 0
                    if(r.may_net > 0)
                        temp.m5 = Math.round(r.may_net)

                    if(r.jun_net <= 0)
                        temp.m6 = 0
                    if(r.jun_net > 0)
                        temp.m6 = Math.round(r.jun_net)

                    if(r.july_net <= 0)
                        temp.m7 = 0
                    if(r.july_net > 0)
                        temp.m7 = Math.round(r.july_net)

                    if(r.aug_net <= 0)
                        temp.m8 = 0
                    if(r.aug_net > 0)
                        temp.m8 = Math.round(r.aug_net)

                    if(r.sep_net <= 0)
                        temp.m9 = 0
                    if(r.sep_net > 0)
                        temp.m9 = Math.round(r.sep_net)

                    if(r.oct_net <= 0)
                        temp.m10 = 0
                    if(r.oct_net > 0)
                        temp.m10 = Math.round(r.oct_net)

                    if(r.nov_net <= 0)
                        temp.m11 = 0
                    if(r.nov_net > 0)
                        temp.m11 = Math.round(r.nov_net)

                    if(r.dec_net <= 0)
                        temp.m12 = 0
                    if(r.dec_net > 0)
                        temp.m12 = Math.round(r.dec_net);
                } else {
                    temp.m1 = Math.round(r.jan_net);
                    temp.m2 = Math.round(r.feb_net);
                    temp.m3 = Math.round(r.mar_net);
                    temp.m4 = Math.round(r.apr_net);
                    temp.m5 = Math.round(r.may_net);
                    temp.m6 = Math.round(r.jun_net);
                    temp.m7 = Math.round(r.july_net);
                    temp.m8 = Math.round(r.aug_net);
                    temp.m9 = Math.round(r.sep_net);
                    temp.m10 = Math.round(r.oct_net);
                    temp.m11 = Math.round(r.nov_net);
                    temp.m12 = Math.round(r.dec_net);
                }
                temp.total = temp.m1 + temp.m2 + temp.m3 + temp.m4 + temp.m5 + temp.m6 + temp.m7 + temp.m8 + temp.m9 + temp.m10 + temp.m11 + temp.m12;
                temp.remaining_budget = r.mpa_subtotal_net - temp.total;
                temp.remaining_budget = Math.round(temp.remaining_budget) === -0? 0: temp.remaining_budget;

                if(r.spends === "Forecasted Spend")
                    total_forecasted = temp.total;
                if(r.spends === "Actual Spend")
                    total_actual = temp.total;
                if(r.spends === "Variance %"){
                    if(total_actual === 0){
                        temp.total = 0;
                    } else {
                        temp.total = Math.round(((total_actual - total_forecasted) / total_actual) * 100);
                    }
                }
            }
            output.push(temp)
        }

        let tempRows = output.map(r=>{return {...r}});

        // filter channels
        if(checkedSEM && !checkedFB){
            tempRows = tempRows.filter(r=> r.channel !=="Facebook");
        }
        if(!checkedSEM && checkedFB){
            tempRows = tempRows.filter(r=> r.channel !=="SEM");
        }

        // filter months
        if(checkedH1 && !checkedH2){
            tempheadCells = tempheadCells.filter(h=> h.fixed || parseInt(h.id) <= selectedFiscal+6)

            let mutateTempRows = tempRows.map(r=>{return {...r}});

            // added code to filter rows based on scheduled_end column when selected H1
            const first_half_year_start_date = selectedFiscal-1+'-11-01';
            const first_half_year_end_date = selectedFiscal+'-04-30';
            mutateTempRows = mutateTempRows.filter(r => r.scheduled_end_date >= first_half_year_start_date && r.scheduled_end_date <= first_half_year_end_date)

            mutateTempRows.map(r=>{
                r.total = r.total - r.m5 - r.m6 - r.m7 - r.m8 - r.m9 - r.m10
                r.remaining_budget = r.mpa_subtotal - r.total
                r.remaining_budget = Math.round(r.remaining_budget) === -0? 0: r.remaining_budget;
                if(r.spends === "Forecasted Spend")
                    total_forecasted = r.total;
                if(r.spends === "Actual Spend")
                    total_actual = r.total;
                if(r.spends === "Variance %"){
                    if(total_actual === 0){
                        r.total = 0;
                    } else {
                        r.total = Math.round(((total_actual - total_forecasted) / total_actual) * 100);
                    }
                }
                // delete (r.m5)
                // delete (r.m6)
                // delete (r.m7)
                // delete (r.m8)
                // delete (r.m9)
                // delete (r.m10)  
            })
            tempRows = mutateTempRows

        }
        if(!checkedH1 && checkedH2){
            tempheadCells = tempheadCells.filter(h=> h.fixed || parseInt(h.id) > selectedFiscal+6)

            let mutateTempRows = tempRows.map(r=>{return {...r}});

            // added code to filter rows based on scheduled_end column when selected H2
            const second_half_year_start_date = selectedFiscal+'-05-01';
            const second_half_year_end_date = selectedFiscal+'-10-31';
            mutateTempRows = mutateTempRows.filter(r => r.scheduled_end_date >= second_half_year_start_date && r.scheduled_end_date <= second_half_year_end_date)

            mutateTempRows.map(r=>{
                r.total = r.total - r.m11 - r.m12 - r.m1 - r.m2 - r.m3 - r.m4
                r.remaining_budget = r.mpa_subtotal - r.total
                r.remaining_budget = Math.round(r.remaining_budget) === -0? 0: r.remaining_budget;
                if(r.spends === "Forecasted Spend")
                    total_forecasted = r.total;
                if(r.spends === "Actual Spend")
                    total_actual = r.total;
                if(r.spends === "Variance %"){
                    if(total_actual === 0){
                        r.total = 0;
                    } else {
                        r.total = Math.round(((total_actual - total_forecasted) / total_actual) * 100);
                    }
                }
                // delete (r.m11)
                // delete (r.m12)
                // delete (r.m1)
                // delete (r.m2)
                // delete (r.m3)
                // delete (r.m4)  
            })
            tempRows = mutateTempRows
        }

        // filter variance
        if(selectedVariance === "Negative"){
            let mpa: string;
            tempRows = tempRows.filter(r=> {  
                if(r.spends === "Forecasted Spend" && r.remaining_budget < 0){
                    mpa = r.mpa;
                    return r;
                }
                if(mpa === r.mpa){
                    return r;
                }
                
            });
        }
        if(selectedVariance === "Non-Negative"){
            let mpa: string;
            tempRows = tempRows.filter(r=> {  
                if(r.spends === "Forecasted Spend" && r.remaining_budget >= 0){
                    mpa = r.mpa;
                    return r;
                }
                if(mpa === r.mpa){
                    return r;
                }
                
            });
        }

        // filter LOBs
        for(let lob of lobOptions){
            if(!lob.checked){   
                tempRows = tempRows.filter(row => row.lob !== lob.name)
            }
        }

        // filter by search
        if(searchValue.trim() !== ""){
            tempRows = tempRows.filter(row => row.mpa.toLowerCase().includes(searchValue.toLowerCase().trim()) || row.products.toLowerCase().includes(searchValue.toLowerCase().trim()))
        }
           
        // Add duplicate values for remaining_budget column to sort the data
        let addDuplicatedRows = tempRows.map(r=>{return {...r}});
      
        let duplicateMpas = new Set();
        for( let r of addDuplicatedRows){
            duplicateMpas.add({"mpa": r.mpa, "rem": r.remaining_budget});
        }

        for(let l of Array.from(duplicateMpas)){            
            let countMPA = 0;
            let mpa = l["mpa"].toString();
            let prev_lob = "";
            let prev_channel = "";
            let prev_products = "";
            let prev_remainingBudget = 0;

            for(let r of addDuplicatedRows){
                if(r.mpa === mpa){
                    if(r.lob !== prev_lob || r.channel !== prev_channel || r.products !== prev_products){
                        prev_lob = r.lob;
                        prev_channel = r.channel;
                        prev_products = r.products;
                        prev_remainingBudget = r.remaining_budget;

                        countMPA = 0;
                    }
                    
                    if(countMPA != 0 ){
                        prev_lob = r.lob;
                        prev_channel = r.channel;
                        prev_products = r.products;

                        r.remaining_budget = prev_remainingBudget; 
                    }
                    countMPA++;
                }
            }
        }

        setAllData(addDuplicatedRows)

        // Remove duplicate row values
        let removeDuplicatedRows = tempRows.map(r=>{return {...r}});
      
        let mpas = new Set();
        for( let r of removeDuplicatedRows){
            mpas.add(r.mpa)
        }
        for(let l of Array.from(mpas)){
            
            let countMPA = 0;
            let mpa = l.toString()
            let prev_lob = "";
            let prev_channel = "";
            let prev_products = "";

            for(let r of removeDuplicatedRows){

                if(r.mpa === mpa){
                    if(r.lob !== prev_lob || r.channel !== prev_channel || r.products !== prev_products){
                        prev_lob = r.lob;
                        prev_channel = r.channel;
                        prev_products = r.products;

                        countMPA = 0;
                    }
                    
                    if(countMPA != 0 ){
                        prev_lob = r.lob;
                        prev_channel = r.channel;
                        prev_products = r.products;

                        r.mpa = ' '; 
                        r.channel = ' '; 
                        r.products = ' '; 
                        r.mpa_subtotal = ' '; 
                        // r.mpa_total = ' '; 
                        r.remaining_budget = ' '; 
                    }
                    countMPA++;
                }
            }
        }

        setHeadCells(tempheadCells)
        if (!sortConfig) {
            setRows(removeDuplicatedRows)
        }
        
       
    }, [selectedFiscal,checkedH1, checkedH2, checkedSEM, checkedFB, selectedDollar, selectedVariance, lobOptions, searchValue]);

    const { items, requestSort, sortConfig } = useSortableData(allData);

    useEffect(() => {
        if (sortConfig) {
            // Remove duplicate row values from sorted data
            let removeDuplicatedRows = items.map(r=>{return {...r}});
        
            let mpas = new Set();
            for( let r of removeDuplicatedRows){
                mpas.add(r.mpa)
            }
            for(let l of Array.from(mpas)){
                
                let countMPA = 0;
                let mpa = l.toString()
                let prev_lob = "";
                let prev_channel = "";
                let prev_products = "";

                for(let r of removeDuplicatedRows){

                    if(r.mpa === mpa){
                        if(r.lob !== prev_lob || r.channel !== prev_channel || r.products !== prev_products){
                            prev_lob = r.lob;
                            prev_channel = r.channel;
                            prev_products = r.products;

                            countMPA = 0;
                        }
                        
                        if(countMPA != 0 ){
                            prev_lob = r.lob;
                            prev_channel = r.channel;
                            prev_products = r.products;

                            r.mpa = ' '; 
                            r.channel = ' '; 
                            r.products = ' '; 
                            r.mpa_subtotal = ' '; 
                            // r.mpa_total = ' '; 
                            r.remaining_budget = ' '; 
                        }
                        countMPA++;
                    }
                }
            }
            setRows(removeDuplicatedRows)
        }
    },[items])

    const getDirection = (name: string) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
        <>
        <Paper sx={{ width: '95%', ml:5, mb:1, border:"1px solid"}}>
            <TableContainer sx={{ maxHeight: 590 }}>
                <Table stickyHeader size='small' >
                    <ForecastingTableHead headCells={headCells} requestSort={(a) => {requestSort(a)}} getDirection={getDirection} />
                    <ForecastingTableBody 
                        rows={rows} 
                        checkedH1={checkedH1} 
                        checkedH2={checkedH2}
                    />
                </Table>
            </TableContainer>
        </Paper>
        </>
    )
}