// initializing Exclude Cids form with default loadable

import { createDefaultLoadable, Loadable } from '../../Common/Loadable';

export interface ExcludeCidsState {
  entities: Array<any>,
  selectedExcludedCidData: any,
  addExcludeIdLoadable: Loadable,
  deleteSelectedExcludeIdLoadable: Loadable,
  getSelectedExcludeIdListLoadable: Loadable,
  getExcludeCidsListLoadable: Loadable,
  updateExcludeIdListLoadable: Loadable
}

export function createDefaultExcludeCidsState(): ExcludeCidsState {
  return {
    entities: [],
    selectedExcludedCidData: null,
    addExcludeIdLoadable: createDefaultLoadable(),
    deleteSelectedExcludeIdLoadable: createDefaultLoadable(),
    getSelectedExcludeIdListLoadable: createDefaultLoadable(),
    getExcludeCidsListLoadable: createDefaultLoadable(),
    updateExcludeIdListLoadable: createDefaultLoadable()
  }
}
