import { createDefaultLoadable, Loadable } from '../../../State/Common/Loadable';

export interface TableConfigurationInterface{
  lineOfBusiness: boolean,
  accountName: boolean,
  category: boolean,
  engine: boolean,
  clientID: boolean,
  firstDayOfLastWeek: boolean,
  firstDayOfLastMonth: boolean,
  weeksLeft: boolean,
  suggestedTransferAmount: boolean,
  newMpaBudgetRequired: boolean,
  totalSpend: boolean,
  budgetOrderId: boolean,
  MPA_budget_all: boolean,
  MPA_budget_category: boolean,
  MPA_category: boolean,
  mpaStatus: boolean,
  pacingStatus: boolean,
  expectedSpent: boolean,
  forecastGap: boolean,
  forecastGapPercent: boolean,
  forecastSpend: boolean,
  progress: boolean,
  progressIndicator: boolean,
  budgetRatio: boolean,
  budgetSpentPercent: boolean,
  darkDate: boolean,
  projectedBudgetPercent: boolean,
  spendLastMonth: boolean,
  spendLastWeek: boolean,
  timeLapsedPercent: boolean,
  totalBudget: boolean,
  totalRemaining: boolean
}

export interface TableConfigurationState {
  config: TableConfigurationInterface,
  getTableConfigLoadable: Loadable,
  updateTableConfigLoadable: Loadable,
}

export const createDefaultTableConfigurationState = (): TableConfigurationState => {
  return {
    config: null,
    getTableConfigLoadable: createDefaultLoadable(),
    updateTableConfigLoadable: createDefaultLoadable()
  }
};
