import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import  history from '../../../Utils/History'
import { getAnnualBudgetsCsv } from '../../../State/AnnualBudget/Actions/AnnualBudgetActions';

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Button, ButtonToolbar } from 'reactstrap';

const AnnualBudgetsToolbar = (props: any) => {
  const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);
  const dispatch = useDispatch();

  const {showAddAnnualBudgetButton} = props;
  
  const toggleExportDropdown = () => setExportDropdownOpen(prevState => !prevState);

  const exportAnnualBudgetData = () => { dispatch(getAnnualBudgetsCsv()); }

  return (
    <ButtonToolbar style={{'padding': '1.5rem 0'}}>
      {showAddAnnualBudgetButton &&
        <Button 
          color="primary"
          onClick={()=>{history.push("/map/annual-budgets/add" ,'/map/mpa')}}
          className="mr-1">
            Add New Annual Budget
        </Button>
      }
      <Dropdown isOpen={isExportDropdownOpen} toggle={toggleExportDropdown} className="mr-1">
        <DropdownToggle caret color="primary">Export</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={exportAnnualBudgetData}>CSV</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </ButtonToolbar>
  );
};

export default AnnualBudgetsToolbar;
