export const months = {
  january: true,
  february: true,
  march: true,
  april: true,
  may: true,
  june: true,
  july: true,
  august: true,
  september: true,
  october: true,
  november: true,
  december: true,
};

export const channelDataToTotal = (channelData: any, dates: { label: string; month: string }[]) =>
  Math.round(
    Object.entries(channelData)?.reduce((total, [key, value]) => total + (months[key] && dates.findIndex((item) => item.month === key) > -1 ? +value : 0), channelData.difference ?? 0) * 100
  ) / 100;

export const channelDataToDifference = (fromAddMPA: boolean, channelData: any, dates: { label: string; month: string }[]) =>
  fromAddMPA
    ? 0
    : Math.round(
        (channelData.working_funds - Object.entries(channelData)?.reduce((total, [key, value]) => total + (months[key] && dates.findIndex((item) => item.month === key) > -1 ? +value : 0), 0)) * 100
      ) / 100;
