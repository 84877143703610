import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, FormGroup, FormLabel} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const EngineSelect = (props: any) => {
    return (
        <>
            <Box sx={{display:'flex', marginLeft:'20px', mb:5}}>
                <div style={{display:'flex', flexDirection:"column", borderRadius:1,border: '1px solid #dee2e6', width:'170px'}}>
                    <FormLabel sx={{color: "white", backgroundColor:"#006AC3", paddingTop:1, paddingBottom: 1, textAlign:"center"}}>Engine</FormLabel>     
                    <FormGroup>
                        <FormControlLabel
                            label="Google_Adwords"
                            control={<Checkbox checked={props.checkedGoogle} onChange={props.handleGoogleChange} />}
                            sx={{ml:1,mr:2,mb:1}}
                        />
                        <FormControlLabel
                            label="Bing"
                            control={<Checkbox checked={props.checkedBing} onChange={props.handleBingChange} />}
                            sx={{ml:1,mr:2,mb:1}}
                        />
                        <FormControlLabel
                            label="Facebook"
                            control={<Checkbox checked={props.checkedFacebook} onChange={props.handleFacebookChange} />}
                            sx={{ml:1,mr:2,mb:1}}
                        />
                    </FormGroup>
                </div>
            </Box>
        </>
    )
}

export default EngineSelect;