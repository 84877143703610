import React, { useMemo } from 'react';
import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';

import { getAuthenticationState } from '../State/Authentication/Selectors/AuthenticationSelectors';

const AuthRoute = ({ component: Component, ...rest }): JSX.Element => {
  const authenticationState = useSelector(getAuthenticationState);

  const currentPath = window.location.pathname;

  const redirectDetail = useMemo(() => {
    if (currentPath === '/privacy')
      return { isRedirect: false, redirectPath: '' };
    if (authenticationState['isAuthenticated'] && currentPath === '/')
      return { isRedirect: true, redirectPath: '/map/mpa' };

    if (!authenticationState['isAuthenticated'] && currentPath !== '/')
      return { isRedirect: true, redirectPath: '/' };

    return { isRedirect: false, redirectPath: '' };
  }, [authenticationState, currentPath]);

  return (
    <Route
      {...rest}
      render={({ history, location, match }: any) =>
        !redirectDetail.isRedirect ? (
          <Component history={history} location={location} match={match} />
        ) : (
          <Redirect to={redirectDetail.redirectPath} from={currentPath} />
        )
      }
    />
  );
};

export default AuthRoute;
