export interface AuthState {
  isAdmin: boolean;
  isAuthenticated: boolean;
  userInfo: any;
  userPermissions: Array<string>;
  token: any;
}

export function createDefaultAuthState(): AuthState {
  return {
    isAdmin: false,
    isAuthenticated: false,
    userInfo: null,
    userPermissions: [],
    token: null,
  };
}
