import React, { useState } from 'react';
import PropTypes, {number} from 'prop-types';

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

export const RowsPerPageDropdown = (props: any) => {
  const [isRowsPerPageDropdownOpen, setRowsPerPageDropdownOpen] = useState(false);
  const toggleRowsPerPageDropdown = () => setRowsPerPageDropdownOpen(prevState => !prevState);

  return (
    <div style={{'alignItems': 'center', 'display': 'flex'}}>
      <span>Show&nbsp;</span>
      <Dropdown isOpen={isRowsPerPageDropdownOpen} toggle={toggleRowsPerPageDropdown} size="sm">
        <DropdownToggle caret color="primary">
          {props.rowsPerPage !== props.count ? props.rowsPerPage : 'All'}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem value={10} onClick={props.handleChangeRowsPerPage}>10</DropdownItem>
          <DropdownItem value={25} onClick={props.handleChangeRowsPerPage}>25</DropdownItem>
          <DropdownItem value={50} onClick={props.handleChangeRowsPerPage}>50</DropdownItem>
          <DropdownItem value={props.count} onClick={props.handleChangeRowsPerPage}>All</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <span>&nbsp;entries</span>
    </div>
  );
};

RowsPerPageDropdown.propTypes = {
  count: PropTypes.number,
  rowsPerPage: number,
  handleChangeRowsPerPage: PropTypes.func
};
