import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import PDFStyles from './PDFStyles';
import moment from 'moment';
import rbcLogo from '../../../Assets/rbc-logo.png';
import { formattedNumber } from 'Components/MediaCalculators/formulation';

const dataFormat = 'MMM DD YYYY';

const PDFDocument = ({ data }: any) => {
  return (
    <Document>
      <Page style={PDFStyles.body} size="A4">
        <View style={PDFStyles.flexView}>
          <View style={{ width: '80%' }}>
            <View>
              <Text style={PDFStyles.title}>RBC Media Purchase Order</Text>
            </View>
            <View style={[PDFStyles.flexView, { alignItems: 'center', marginTop: '5px' }]}>
              <Text>MPO #: </Text>
              <Text
                style={[
                  PDFStyles.boldText,
                  {
                    paddingVertical: '5px',
                    paddingHorizontal: '10px',
                    borderStyle: 'solid',
                    borderWidth: 1,
                  },
                ]}>
                {data.mpaIntake.mpa_number}
              </Text>
            </View>
          </View>
          <View style={{ width: '20%' }}>
            <View style={[PDFStyles.flexView, { flexDirection: 'column', alignItems: 'center' }]}>
              <Image style={PDFStyles.img} src={rbcLogo} />
              <Text style={PDFStyles.caption}>88 Queen's Quay West</Text>
              <Text style={PDFStyles.caption}>Toronto, Ontario, M5J 0B8</Text>
            </View>
          </View>
        </View>
        <View style={[PDFStyles.flexView, { marginTop: 15, paddingLeft: 5 }]}>
          <View style={[PDFStyles.flexView, PDFStyles.flexLeftColumnView]}>
            <View style={[PDFStyles.flexView]}>
              <Text style={[PDFStyles.label]}>Revision #: </Text>
              <Text>{data.mpaIntake.revision_number !== '' ? data.mpaIntake.revision_number.replace('R', '') : 'Original'}</Text>
            </View>
            <View style={[PDFStyles.flexView]}>
              <Text style={[PDFStyles.label]}>Date:</Text>
              <Text>{moment().format(dataFormat)}</Text>
            </View>
            <View style={[PDFStyles.flexView, { marginTop: 20 }]}>
              <Text style={[PDFStyles.label]}>RBC Business Unit:</Text>
              <Text>{data.mpaIntake.client_business_unit}</Text>
            </View>
            <View style={[PDFStyles.flexView, PDFStyles.flexMarginTop]}>
              <Text style={[PDFStyles.label]}>Product:</Text>
              <Text>{data.mpaIntake.products}</Text>
            </View>
          </View>
        </View>
        <View style={[PDFStyles.flexView, { paddingLeft: 5 }]}>
          <View style={[PDFStyles.flexView, PDFStyles.flexLeftColumnView]}>
            <View style={[PDFStyles.flexView, PDFStyles.flexMarginTop]}>
              <Text style={[PDFStyles.label]}>Budget Start:</Text>
              <Text>{moment(data.mpaIntake.scheduled_start).format(dataFormat)}</Text>
            </View>
            <View style={[PDFStyles.flexView, PDFStyles.flexMarginTop]}>
              <Text style={[PDFStyles.label]}>Budget End:</Text>
              <Text>{moment(data.mpaIntake.scheduled_end).format(dataFormat)}</Text>
            </View>
          </View>
          <View style={[PDFStyles.flexMiddleView]} />
          <View style={[PDFStyles.flexView, PDFStyles.flexRightColumnView]}>
            <View style={[PDFStyles.flexView, PDFStyles.flexMarginTop]}>
              <Text style={[PDFStyles.label]}>RBC MO Code:</Text>
            </View>
            <View style={[PDFStyles.flexView, PDFStyles.flexMarginTop]}>
              <Text style={[PDFStyles.label]}>RBC Marketing:</Text>
              <Text>{data.mpaIntake.prepared_for}</Text>
            </View>
          </View>
        </View>

        <View style={[PDFStyles.flexView, PDFStyles.flexMarginTop, { flexDirection: 'column', paddingLeft: 5 }]}>
          <View style={[PDFStyles.flexView]}>
            <Text style={[PDFStyles.label, { width: '22%' }]}>Campaign:</Text>
            <Text wrap={false}>{data.mpaIntake.campaign}</Text>
          </View>
          <View style={[PDFStyles.flexView]}>
            <Text style={[PDFStyles.caption]}>(internal use only)</Text>
          </View>
        </View>

        <View style={[PDFStyles.divider]} />
        <View style={[PDFStyles.flexView]}>
          <Text style={[PDFStyles.subTitle, { width: '55%' }]}>Approved Media</Text>
          <Text style={[PDFStyles.flexMiddleView]} />
          <Text style={[{ fontSize: 13, width: '40%', fontStyle: 'italic' }]}>Notes:</Text>
        </View>
        <View style={[PDFStyles.flexView, { paddingLeft: 5 }]}>
          <View style={[PDFStyles.flexView, PDFStyles.flexLeftColumnView]}>
            <View style={[PDFStyles.flexView, PDFStyles.flexMarginTop]}>
              <View style={[PDFStyles.label]}>
                <Text>Working Funds:</Text>
              </View>
              <View style={[PDFStyles.flexView, PDFStyles.value]}>
                <Text>$</Text>
                <Text style={[PDFStyles.currency]}>{formattedNumber(data.mpaIntake.working_funds_total)}</Text>
              </View>
            </View>
            <View style={[PDFStyles.flexView, PDFStyles.flexMarginTop]}>
              <Text style={[PDFStyles.label]}>Platform Fees:</Text>
              <View style={[PDFStyles.flexView, PDFStyles.value]}>
                <Text>$</Text>
                <Text style={[PDFStyles.currency]}>{formattedNumber(data.mpaIntake.platform_fees)}</Text>
              </View>
            </View>
            <View style={[PDFStyles.flexView, PDFStyles.flexMarginTop]}>
              <Text style={[PDFStyles.label]}>Tax:</Text>
              <View style={[PDFStyles.flexView, PDFStyles.value]}>
                <Text>$</Text>
                <Text style={[PDFStyles.currency]}>{formattedNumber(data.mpaIntake.tax)}</Text>
              </View>
            </View>
            <View style={[PDFStyles.flexView, PDFStyles.flexMarginTop]}>
              <Text style={[PDFStyles.label]}>Grand Total:</Text>
              <View style={[PDFStyles.flexView, PDFStyles.value]}>
                <Text>$</Text>
                <Text style={[PDFStyles.currency]}>{formattedNumber(data.mpaIntake.grand_total)}</Text>
              </View>
            </View>
            <View style={[PDFStyles.flexView, PDFStyles.flexMarginTop]}>
              <Text style={[PDFStyles.caption, { marginLeft: -5 }]}>
                Note: Final price may vary by ± 5%. If variation exceeds 5% or $50,000 (whichever is lower), a revised Media Purchase Order will be issued.
              </Text>
            </View>
          </View>
          <View style={[PDFStyles.flexMiddleView, PDFStyles.flexMarginTop]} />
          <View style={[PDFStyles.flexView, PDFStyles.flexRightColumnView]}>
            <View style={[PDFStyles.flexView, PDFStyles.textArea]}>
              <Text style={{ maxWidth: '100%' }}>{data.mpaIntake.mpo_notes}</Text>
            </View>
          </View>
        </View>

        <View style={[PDFStyles.divider]} />
        <Text style={[PDFStyles.subTitle]}>Authorization</Text>
        <View style={[PDFStyles.flexView, PDFStyles.flexMarginTop]}>
          <View style={[PDFStyles.flexView, PDFStyles.flexLeftColumnView]}>
            <View style={[PDFStyles.flexView, { height: 60 }]}>
              <Text style={[{ width: '40%' }]}>
                RBC Authorized
                {'\n'}
                Signature:
              </Text>
              <View style={[PDFStyles.textArea, PDFStyles.value]} />
            </View>
            <View style={[PDFStyles.flexView, { marginTop: 30 }]}>
              <Text style={[{ width: '40%' }]}>Other Signatures/Initials:</Text>
            </View>
            <View style={[PDFStyles.flexView, { marginTop: 50 }]}>
              <Text style={[{ width: '25%' }]}>Prepared by:</Text>
              <Text>{data.mpaIntake.prepared_by}</Text>
            </View>
            <View style={[PDFStyles.flexView, PDFStyles.flexMarginTop]}>
              <Text style={[{ width: '25%' }]}>Date:</Text>
              <Text>{moment().format(dataFormat)}</Text>
            </View>
          </View>
          <View style={[PDFStyles.flexMiddleView]} />
          <View style={[PDFStyles.flexView, PDFStyles.flexRightColumnView]}>
            <Text>By signing this document, I warrant that I have the authority to release budget on behalf of RBC</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
