// initializing Lob form with default loabable

import { createDefaultLoadable, Loadable } from '../../Common/Loadable';

export interface LobState {
  entities: Array<any>,
  selectedLob: any,
  addLobLoadable: Loadable,
  deleteSelectedLobLoadable: Loadable,
  getSelectedLobListLoadable: Loadable,
  getLobListLoadable: Loadable,
  getLobsCsvLoadable: Loadable,
  updateLobListLoadable: Loadable
}

export function createDefaultLobState(): LobState {
  return {
    entities: [],
    selectedLob: null,
    addLobLoadable: createDefaultLoadable(),
    deleteSelectedLobLoadable: createDefaultLoadable(),
    getSelectedLobListLoadable: createDefaultLoadable(),
    getLobListLoadable: createDefaultLoadable(),
    getLobsCsvLoadable: createDefaultLoadable(),
    updateLobListLoadable: createDefaultLoadable()
  }
}
