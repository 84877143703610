
export interface BudgetOrdersViewState {
  selectedSearchEngines: Array<string>,
  selectedCategory: string,
  selectedLineOfBusiness: string,
  selectedWeeksLeft: string,
  searchTerm: string,
  sortAttribute: string,
  sortDirection: string,
}

export const createDefaultBudgetOrdersViewState = (): BudgetOrdersViewState => {
  return {
    selectedSearchEngines: [],
    selectedCategory: '',
    selectedLineOfBusiness: '',
    selectedWeeksLeft: '',
    searchTerm: '',
    sortAttribute: '',
    sortDirection: ''
  };
};

export const SEARCH_ENGINE_CHECKBOXES = [
  { label: 'Google Adwords', name: 'googleAdwords', checked: false },
  { label: 'Bing Ads', name: 'bing', checked: false },
];

export const SORT_CONFIG_DIRECTION = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
  NORMAL: 'normal'
};

export const SORT_DIRECTION_ICON = {
  'ascending': 'sort-up',
  'descending': 'sort-down',
  'normal': 'sort'
};

export const OPPOSITE_SORT_DIRECTION = {
  'normal': SORT_CONFIG_DIRECTION.ASCENDING,
  'ascending': SORT_CONFIG_DIRECTION.DESCENDING,
  'descending': SORT_CONFIG_DIRECTION.NORMAL
};

export const MAX_SEARCH_RESULTS = 6;
