import React, {useState} from 'react';
import PropTypes from 'prop-types'

import { GridDropdown } from '../../../Common';
import { DropdownItem } from 'reactstrap';

const MpaStatusDropdown = (props: any) => {
  let mpaStatusItems = [
    {mapStatus: "Request Pending"},
    {mapStatus: "Issued"},
    {mapStatus: "Signature Pending"},
    {mapStatus: "Approved"}
  ];

  const [isMpaStatusDropdownOpen, setIsMpaStatusDropdownOpen] = useState(false);

  const toggleMpaStatusDropdown = () => setIsMpaStatusDropdownOpen(prevState => !prevState);

  return (
    <GridDropdown
      inputLabel="MPA Status:"
      isDisabled={props.isDisabled}
      isDropdownOpen={isMpaStatusDropdownOpen}
      handleToggle={toggleMpaStatusDropdown}
      placeholder="---------"
      value={props.status}
    >
      {mpaStatusItems.map((item: any, index: number) =>
        <DropdownItem value={item.mapStatus} onClick={props.handleMpaStatusChange} key={index}>
          {item.mapStatus}
        </DropdownItem>
      )}
    </GridDropdown>
  );
};

MpaStatusDropdown.propTypes = {
  status: PropTypes.any,
  handleMpaStatusChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default MpaStatusDropdown;
