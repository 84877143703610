import { createDefaultMpaFormContextState } from '../Models/MpaFormContextState';

import { SET_SELECTED_MPA_ID, SET_SELECTED_LOB } from '../ActionTypes/MpaFormContextActionTypes';

export default function (state = createDefaultMpaFormContextState(), action: any) {
  switch(action.type) {
    case SET_SELECTED_MPA_ID:
        return {
          ...state,
          selectedMpaId: action.mpaId
        };

    case SET_SELECTED_LOB:
      return {
        ...state,
        selectedLob: action.lob
      }

    default:
        return state;
  };
};
