import React from 'react';
import PropTypes from 'prop-types';

import {Col, FormGroup, Input, Label} from 'reactstrap';

export const GridTextField = (props: any) => {
  return (
    <Col xs={props.gridSize}>
      <FormGroup>
        <Label>{props.inputLabel}</Label>
        <Input
          id={props.inputId}
          label={props.inputLabel}
          type="textarea"
          onChange={props.handleChange}
          value={props.value}
          margin="normal"
        />
      </FormGroup>
    </Col>
  );
};

GridTextField.propTypes = {
  gridSize: PropTypes.number,
  handleChange: PropTypes.func,
  inputId: PropTypes.string,
  inputLabel: PropTypes.string,
  value: PropTypes.string
};
