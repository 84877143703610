import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const RequiredFieldModal = (props) => {
    const toggle = () => props.setModal(!props.modal);
    const requiredFields = props.requiredFields;

    var requiredMsg = 'The following fields are required:\n';
    let counterVal = 1

    for (let index in requiredFields) {
        requiredMsg += counterVal + ". " + requiredFields[index] +'\n'
        counterVal++;
    }

    return (
        <div>
            <Modal isOpen={props.modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Form Error</ModalHeader>
            <ModalBody >
                <span style={{whiteSpace:'pre-line'}}> {requiredMsg} </span>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
            </Modal>
        </div>
    );
}