import React, { useEffect } from "react";
import { AppPage, PageHeader, WithMultipleLoading } from "../../../Common";
import EditApiConfig from "./EditApiConfig";
import { useDispatch, useSelector } from "react-redux";
import { getDagApiConfigsState } from "State/DagApiConfig/Selectors/DagApiConfigsSelectors";
import { getDagApiConfigById, getDagApiConfigDropdown } from "State/DagApiConfig/Actions/DagApiConfigsAction";

const EditApiConfigWithLoading = WithMultipleLoading(EditApiConfig);

const EditApiConfigContainer = (props: any) => {
  const { params } = props.match;
  const dispatch = useDispatch();
  const dagApiConfigsState = useSelector(getDagApiConfigsState);

  useEffect(() => {
    if (!params?.id) return;
    dispatch(getDagApiConfigById(params.id));
    dispatch(getDagApiConfigDropdown(params.id));
  }, [params.id, getDagApiConfigById, getDagApiConfigDropdown, dispatch]);

  return (
    <>
      <PageHeader>Edit {dagApiConfigsState.dagApiConfig?.dag_name ?? ""}</PageHeader>
      <AppPage>
        <EditApiConfigWithLoading
          dagApiConfigId={+params.id}
          loadables={[dagApiConfigsState.getApiConfigByIdLoadable, dagApiConfigsState.getApiConfigDropdownLoadable]}
          record={dagApiConfigsState.dagApiConfig}
          apiConfigDropdown={dagApiConfigsState.dropdown}
        />
      </AppPage>
    </>
  );
};

export default EditApiConfigContainer;
