import { DagApiConfigsState, createDefaultDagApiConfigsState } from "../Models/DagApiConfigsState";
import { updateLoadableOnError, updateLoadableOnStart, updateLoadableOnSuccess } from "State/Common/Loadable";
import {
  GET_DAG_API_CONFIGS,
  GET_DAG_API_CONFIGS_ERROR,
  GET_DAG_API_CONFIGS_SUCCESS,
  GET_DAG_API_CONFIG_BY_ID,
  GET_DAG_API_CONFIG_BY_ID_ERROR,
  GET_DAG_API_CONFIG_BY_ID_SUCCESS,
  UPDATE_DAG_API_CONFIG,
  UPDATE_DAG_API_CONFIG_ERROR,
  UPDATE_DAG_API_CONFIG_SUCCESS,
  GET_DAG_API_CONFIG_DROPDOWN,
  GET_DAG_API_CONFIG_DROPDOWN_SUCCESS,
  GET_DAG_API_CONFIG_DROPDOWN_ERROR,
} from "../ActionTypes/DagApiConfigsActionsTypes";

export default function (state = createDefaultDagApiConfigsState(), action: any): DagApiConfigsState {
  switch (action.type) {
    case GET_DAG_API_CONFIGS:
      return {
        ...state,
        getApiConfigListLoadable: updateLoadableOnStart(),
      };
    case GET_DAG_API_CONFIGS_SUCCESS:
      return {
        ...state,
        entities: action.result,
        getApiConfigListLoadable: updateLoadableOnSuccess(),
      };

    case GET_DAG_API_CONFIGS_ERROR:
      return {
        ...state,
        getApiConfigListLoadable: updateLoadableOnError(action.error),
      };

    case GET_DAG_API_CONFIG_BY_ID:
      return {
        ...state,
        getApiConfigByIdLoadable: updateLoadableOnStart(),
      };
    case GET_DAG_API_CONFIG_BY_ID_SUCCESS:
      return {
        ...state,
        dagApiConfig: action.result[0] ?? null,
        getApiConfigByIdLoadable: updateLoadableOnSuccess(),
      };

    case GET_DAG_API_CONFIG_BY_ID_ERROR:
      return {
        ...state,
        getApiConfigByIdLoadable: updateLoadableOnError(action.error),
      };

    case UPDATE_DAG_API_CONFIG:
      return {
        ...state,
        updateApiConfigLoadable: updateLoadableOnStart(),
      };
    case UPDATE_DAG_API_CONFIG_SUCCESS:
      return {
        ...state,
        updateApiConfigLoadable: updateLoadableOnSuccess(),
      };

    case UPDATE_DAG_API_CONFIG_ERROR:
      return {
        ...state,
        updateApiConfigLoadable: updateLoadableOnError(action.error),
      };
    case GET_DAG_API_CONFIG_DROPDOWN:
      return {
        ...state,
        getApiConfigDropdownLoadable: updateLoadableOnStart(),
      };
    case GET_DAG_API_CONFIG_DROPDOWN_SUCCESS:
      return {
        ...state,
        dropdown: action.result,
        getApiConfigDropdownLoadable: updateLoadableOnSuccess(),
      };

    case GET_DAG_API_CONFIG_DROPDOWN_ERROR:
      return {
        ...state,
        getApiConfigDropdownLoadable: updateLoadableOnError(action.error),
      };
    default:
      return state;
  }
}
