import { createDefaultFormInfoState, FormInfoState } from '../Models/FormInfoState';
import {
  updateLoadableOnStart,
  updateLoadableOnSuccess,
  updateLoadableOnError
} from '../../Common/Loadable';

import {
  GET_ANCHOR_CIDS, GET_ANCHOR_CIDS_SUCCESS, GET_ANCHOR_CIDS_ERROR,
  GET_DROPDOWN_OPTIONS, GET_DROPDOWN_OPTIONS_SUCCESS, GET_DROPDOWN_OPTIONS_ERROR,
  GET_NEW_MPA_NUMBER, GET_NEW_MPA_NUMBER_SUCCESS, GET_NEW_MPA_NUMBER_ERROR,
  GET_PRODUCT_OPTIONS, GET_PRODUCT_OPTIONS_SUCCESS, GET_PRODUCT_OPTIONS_ERROR,
  GET_CHANNELS_FOR_SELECTED_PRODUCT, GET_CHANNELS_FOR_SELECTED_PRODUCT_SUCCESS, GET_CHANNELS_FOR_SELECTED_PRODUCT_ERROR,
  GET_CHANNEL_OPTIONS, GET_CHANNEL_OPTIONS_SUCCESS, GET_CHANNEL_OPTIONS_ERROR
} from '../ActionTypes/FormInfoActionTypes';

export default function (state = createDefaultFormInfoState(), action: any): FormInfoState {
  switch (action.type) {
    case GET_ANCHOR_CIDS:
      return {
        ...state,
        getAnchorCidsLoadable: updateLoadableOnStart()
      };

    case GET_ANCHOR_CIDS_SUCCESS:
      return {
        ...state,
        anchorCids: action.result,
        getAnchorCidsLoadable: updateLoadableOnSuccess()
      };

    case GET_ANCHOR_CIDS_ERROR:
      return {
        ...state,
        getAnchorCidsLoadable: updateLoadableOnError(action.error)
      };

    case GET_DROPDOWN_OPTIONS:
      return {
        ...state,
        getDropdownOptionsLoadable: updateLoadableOnStart()
      };

    case GET_DROPDOWN_OPTIONS_SUCCESS:
      return {
        ...state,
        dropdownOptions: action.result,
        getDropdownOptionsLoadable: updateLoadableOnSuccess()
      };

    case GET_DROPDOWN_OPTIONS_ERROR:
      return {
        ...state,
        getDropdownOptionsLoadable: updateLoadableOnError(action.error)
      };

    case GET_NEW_MPA_NUMBER:
      return {
        ...state,
        getNewMpaNumberLoadable: updateLoadableOnStart()
      };

    case GET_NEW_MPA_NUMBER_SUCCESS:
      return {
        ...state,
        newMpaNumber: action.result,
        getNewMpaNumberLoadable: updateLoadableOnSuccess()
      };

    case GET_NEW_MPA_NUMBER_ERROR:
      return {
        ...state,
        getNewMpaNumberLoadable: updateLoadableOnError(action.error)
      };

    case GET_PRODUCT_OPTIONS:
      return {
        ...state,
        getProductOptionsLoadable: updateLoadableOnStart()
      };

    case GET_PRODUCT_OPTIONS_SUCCESS:
      return {
        ...state,
        productOptionsWithoutChannel: action.result,
        getProductOptionsLoadable: updateLoadableOnSuccess()
      };

    case GET_PRODUCT_OPTIONS_ERROR:
      return {
        ...state,
        getProductOptionsLoadable: updateLoadableOnError(action.error)
      };

    case GET_CHANNELS_FOR_SELECTED_PRODUCT:
      return {
        ...state,
        getChannelsListLoadable: updateLoadableOnStart()
      };

    case GET_CHANNELS_FOR_SELECTED_PRODUCT_SUCCESS:
      return {
        ...state,
        selectedProductChannelList: action.result,
        getChannelsListLoadable: updateLoadableOnSuccess()
      };

    case GET_CHANNELS_FOR_SELECTED_PRODUCT_ERROR:
      return {
        ...state,
        getChannelsListLoadable: updateLoadableOnError(action.error)
      };

    case GET_CHANNEL_OPTIONS:
      return {
        ...state,
        getChannelOptionsLoadable: updateLoadableOnStart()
      };

    case GET_CHANNEL_OPTIONS_SUCCESS:
      return {
        ...state,
        channelOptions: action.result,
        getChannelOptionsLoadable: updateLoadableOnSuccess()
      };

    case GET_CHANNEL_OPTIONS_ERROR:
      return {
        ...state,
        getChannelOptionsLoadable: updateLoadableOnError(action.error)
      };

    default:
      return state;
  }
}
