import { formatNumberToCanadianDollars } from 'Utils/FormatNumberToCurrency';

export interface IMediaCalculatorObj {
  value: number | null;
  formattedString: string;
}

export const initialMediaCalculatorObj = {
  value: null,
  formattedString: '',
};

export const formattedDivision = (
  dividend: number | null,
  divisor: number | null
): IMediaCalculatorObj =>
  numberToMediaCalculatorObj(
    dividend && divisor ? RoundTo2Decimal(+dividend / +divisor) : null
  );

export const formattedDifference = (
  firstNumber: number | null,
  secondNumber: number | null,
  isWholeNumber = false,
  isCurrency = true
): IMediaCalculatorObj =>
  numberToMediaCalculatorObj(
    firstNumber && secondNumber
      ? (Math.floor(+firstNumber * 100) - Math.floor(+secondNumber * 100)) / 100
      : null,
    isWholeNumber,
    isCurrency
  );

export const formattedMultiplication = (
  ...args: (number | null)[]
): IMediaCalculatorObj =>
  numberToMediaCalculatorObj(
    !args.includes(null)
      ? RoundTo2Decimal(args.reduce((total, current) => total * current, 1))
      : null
  );

export const formattedSum = (...args: (number | null)[]): IMediaCalculatorObj =>
  numberToMediaCalculatorObj(
    !args.includes(null)
      ? Math.floor(args.reduce((total, current) => total + current * 100, 0)) /
          100
      : null
  );

export const RoundTo2Decimal = (number: number | null) =>
  number ? Math.round(number * 100) / 100 : null;

export const numberToMediaCalculatorObj = (
  number?: number | null,
  isWholeNumber = false,
  isCurrency = true
): IMediaCalculatorObj => {
  return {
    value: number,
    formattedString:
      number !== null
        ? isCurrency
          ? formatNumberToCanadianDollars(number)
          : formattedNumber(isWholeNumber ? Math.round(number) : number)
        : '',
  };
};

export const formattedNumber = (number: number | null): string =>
  number?.toLocaleString('en-CA') ?? '';
