import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { ReportTableHead } from '../Common/ReportTableHead';
import { ReportTableBody } from '../Common/ReportTableBody';
import { HeadCell, Row } from '../Models/ReportTable';
import BudgetReportsService from '../Services/BudgetReportsService';
import { toast } from 'react-toastify';
import { isNumber, cloneDeep } from 'lodash';

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];

    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if(sortConfig.key2 === undefined){  
          if(isNumber(a[sortConfig.key]) || a[sortConfig.key] === null || b[sortConfig.key] === null){
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === 'asc' ? 1 : -1;
            }
          } else {
            if (a[sortConfig.key].toUpperCase() < b[sortConfig.key].toUpperCase()) {
              return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key].toUpperCase() > b[sortConfig.key].toUpperCase()) {
              return sortConfig.direction === 'asc' ? 1 : -1;
            }
          }
        } else {
          if(a[sortConfig.key] === null || b[sortConfig.key] === null || a[sortConfig.key2] === null || b[sortConfig.key2] === null){
            if (a[sortConfig.key] + a[sortConfig.key2] < b[sortConfig.key] + b[sortConfig.key2]) {
              return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] + a[sortConfig.key2] > b[sortConfig.key] + b[sortConfig.key2]) {
              return sortConfig.direction === 'asc' ? 1 : -1;
            }
          } else {
            if ((a[sortConfig.key].toUpperCase() + a[sortConfig.key2]).toUpperCase() < (b[sortConfig.key].toUpperCase() + b[sortConfig.key2]).toUpperCase()) {
              return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if ((a[sortConfig.key].toUpperCase() + a[sortConfig.key2]).toUpperCase() > (b[sortConfig.key].toUpperCase() + b[sortConfig.key2]).toUpperCase()) {
              return sortConfig.direction === 'asc' ? 1 : -1;
            }
          }
        }          
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: string, checkboxFilter?: Boolean, key2: string = undefined) => {  
    let direction = 'asc';
      if(key2 === undefined){
        if (
          sortConfig &&
          sortConfig.key === key &&
          sortConfig.direction === 'asc'
        ) {
          direction = 'desc';
        }
        setSortConfig({ key, direction });  
      } else {
        if (
          sortConfig &&
          sortConfig.key === key &&
          sortConfig.key2 === key2 &&
          sortConfig.direction === 'asc'
        ) {
          direction = 'desc';
        }
        setSortConfig({ key, key2, direction });
      }  
  };    

  return { items: sortedItems, requestSort, sortConfig };
};

export const LobRollup = (props: any) => {

  function createData(
    lob: string,
    channel: string,
    // budget_amount: number,
    mpa_amount: number,
    // remaining_to_mpa: number,
    loaded: number,
    remaining_to_load: number,
    spend: number,
    remaining_on_mpa: number,
  ) {
    return {
      lob,
      channel,
      // budget_amount,
      mpa_amount,
      // remaining_to_mpa,
      loaded,
      remaining_to_load,
      spend,
      remaining_on_mpa
    };
  }

  const [lobRollup, setLobRollup] = useState<Row[]>([]);

  //remove grand_total row from sorting
  const [lobRollupData, setLobRollupData] = useState<Array<Row>>([]);
  const [grandTotalData, setGrandTotalData] = useState<Array<Row>>([]);
  
  const grandTotal = (response:any) =>{
    let grand_total = createData('Grand Total', '',0,0,0,0,0);
    let output: Row[] =[];

    //separate all_data and grand_total data for sorting
    let lobRollupOutput: Row[] = [];
    let grandTotalOutput: Row[] = [];

    for( let r of response){
      let temp = createData('','',0,0,0,0,0);
      temp.lob = r.lob;
      temp.channel = r.channel;
      if(props.selectedDollar === "Gross"){
        // temp.budget_amount = Math.round(r.gross_budget_amount);
        temp.mpa_amount = Math.round(r.gross_mpa_amount);
        // temp.remaining_to_mpa = Math.round(r.gross_remaining_to_mpa) ===-0? 0: Math.round(r.gross_remaining_to_mpa);
        if(r.channel === "Facebook"){
          temp.loaded = Math.round(r.gross_mpa_amount);
          temp.remaining_to_load = 0
        } else {
          temp.loaded = Math.round(r.gross_loaded_budget);
          var gross_remaining_to_load = Math.round(r.gross_mpa_amount) - Math.round(r.gross_loaded_budget);
          temp.remaining_to_load = Math.round(gross_remaining_to_load) === -0 ? 0 : Math.round(gross_remaining_to_load);
          // temp.remaining_to_load = Math.round(r.gross_remaining_to_load) ===-0 ?0: Math.round(r.gross_remaining_to_load);
        }
        // temp.loaded = Math.round(r.gross_loaded);
        // temp.remaining_to_load = Math.round(r.gross_remaining_to_load) ===-0 ?0: Math.round(r.gross_remaining_to_load);
        temp.spend = Math.round(r.gross_spend);
        var gross_remaining_on_mpa = Math.round(r.gross_mpa_amount) - Math.round(r.gross_spend);
        temp.remaining_on_mpa = Math.round(gross_remaining_on_mpa) === -0 ? 0 : Math.round(gross_remaining_on_mpa);
        // temp.remaining_on_mpa = Math.round(r.gross_remaining_on_mpa) ===-0? 0: Math.round(r.gross_remaining_on_mpa);
        
      } else if(props.selectedDollar === "Net"){
        // temp.budget_amount = Math.round(r.net_budget_amount);
        temp.mpa_amount = Math.round(r.net_mpa_amount);
        // temp.remaining_to_mpa = Math.round(r.net_remaining_to_mpa) ===-0? 0: Math.round(r.net_remaining_to_mpa);
        if(r.channel === "Facebook"){
          temp.loaded = Math.round(r.net_mpa_amount);
          temp.remaining_to_load = 0
        } else {
          temp.loaded = Math.round(r.net_loaded_budget);
          var net_remaining_to_load = Math.round(r.net_mpa_amount) - Math.round(r.net_loaded_budget);
          temp.remaining_to_load = Math.round(net_remaining_to_load) === -0 ? 0: Math.round(net_remaining_to_load);
          // temp.remaining_to_load = Math.round(r.net_remaining_to_load) ===-0? 0: Math.round(r.net_remaining_to_load);
        }
        // temp.loaded = Math.round(r.net_loaded);
        // temp.remaining_to_load = Math.round(r.net_remaining_to_load) ===-0? 0: Math.round(r.net_remaining_to_load);
        temp.spend = Math.round(r.net_spend);
        var net_remaining_on_mpa = Math.round(r.net_mpa_amount) - Math.round(r.net_spend);
        temp.remaining_on_mpa = Math.round(net_remaining_on_mpa) === -0 ? 0 : Math.round(net_remaining_on_mpa);
        // temp.remaining_on_mpa = Math.round(r.net_remaining_on_mpa) ===-0? 0: Math.round(r.net_remaining_on_mpa);
      }
      
      // grand_total.budget_amount = grand_total.budget_amount + temp.budget_amount;
      grand_total.mpa_amount = grand_total.mpa_amount + temp.mpa_amount;
      // grand_total.remaining_to_mpa = grand_total.remaining_to_mpa + temp.remaining_to_mpa;
      grand_total.loaded = grand_total.loaded + temp.loaded;
      grand_total.remaining_to_load = grand_total.remaining_to_load + temp.remaining_to_load;
      grand_total.spend = grand_total.spend + temp.spend;
      grand_total.remaining_on_mpa = grand_total.remaining_on_mpa + temp.remaining_on_mpa;
      
      output.push(temp)

      //push all_data without grand_total to separate array for sorting
      // lobRollupOutput.push(temp)
      // setLobRollupData(lobRollupOutput);
    }

    let tempRows = output.map(r=>{return {...r}});
    let rowsWithoutGrandTotal = output.map(r=>{return {...r}});

    // filter channels
    for(let channel of props.channelOptions){
      if(!channel.checked){               
        rowsWithoutGrandTotal = tempRows.filter(row => row.channel !== channel.name && row.lob !== 'Grand Total');
        tempRows = tempRows.filter(row => row.channel !== channel.name);
      }
    }
    setLobRollupData(rowsWithoutGrandTotal);

    console.log('Temp rows');
    console.log(tempRows);

    // Remove duplicate budget amount values for each LOB
    let removeDuplicateValues = tempRows.map(r=>{return {...r}});

    let lobs = new Set();
    for( let r of removeDuplicateValues){
        lobs.add(r.lob)
    }

    for(let l of Array.from(lobs)){            
      let countLOB = 0;
      let lob = l.toString();
      let prev_amount = 0;
      let amount_difference = 0;
      let amountRowsCnt = 0;
      let prev_channel = '';

      for(let r of removeDuplicateValues){             
        if(r.lob === lob){ 
          // remove duplicate channels
          // if(prev_channel != r.channel) {
          //   prev_channel = r.channel;
          // } else {
          //   r.channel = ' ';
          // } 
          
          // if(r.budget_amount !== prev_amount){
          //   prev_amount = r.budget_amount;
          //   amountRowsCnt = 0;
          // }

          // if(amountRowsCnt != 0) {
          //   amount_difference += prev_amount;
          //   prev_amount = r.budget_amount;
          //   r.budget_amount = null;
          // }
          // amountRowsCnt++;

          if(countLOB != 0 ){
            r.lob = ' ';
          }
          countLOB++;
        } 
        // else if(r.lob === 'Grand Total') {
        //   r.budget_amount -= amount_difference;
        // }
      }
    }

    output = removeDuplicateValues;

    let grand_total_row: Row = {
      lob: 'Grand Total',
      channel: ' ',
      // budget_amount: 0,
      mpa_amount: 0,          
      // remaining_to_mpa: 0,
      loaded: 0,
      remaining_to_load: 0,
      spend: 0,
      remaining_on_mpa: 0
    };
    for(let row of removeDuplicateValues) {
      // grand_total_row.budget_amount += row.budget_amount;
      grand_total_row.mpa_amount += row.mpa_amount;
      // grand_total_row.remaining_to_mpa += row.remaining_to_mpa;
      grand_total_row.loaded += row.loaded;
      grand_total_row.remaining_to_load += row.remaining_to_load;
      grand_total_row.spend += row.spend;
      grand_total_row.remaining_on_mpa += row.remaining_on_mpa;
    }

    output.push(grand_total_row);
    props.setLobRollupisloaded(true);
    setLobRollup(output);

    //push only grand_total data to separate array for sorting
    grandTotalOutput.push(grand_total_row);
    setGrandTotalData(grandTotalOutput);
  }

  //get LOB Rollup
  useEffect(() => {
    async function getLobRollup() {
      props.setLobRollupisloaded(false);

      try{
        const response = await BudgetReportsService.retrieveLobRollup(props.selectedFiscal);
        // const currentYear = "FY-"+new Date().getFullYear().toString();
        // if(props.selectedFiscal !== currentYear){
        //   for( let r of response){
        //    r.loaded = 0;
        //    r.remaining_to_load = 0;
        //    r.spend = 0;
        //    r.remaining_on_mpa = 0;
        //   }
        // }
        grandTotal(response);        
      } catch(ex){
        props.setLobRollupisloaded(true);
        if(ex === "SQL Query Failed"){
          toast.error("Couldn't retrieve data in LOB(Rollup), please try again");
        } else {
          sessionStorage.removeItem("authenticationState");
          sessionStorage.removeItem("tableConfig");
          window.location.reload(); 
        }
        
      } 
    }
    getLobRollup();
  }, [props.selectedFiscal, props.selectedDollar, props.channelOptions]);

  //call sorting function to sort data without grand_total
  const { items, requestSort, sortConfig } = useSortableData(lobRollupData);

  //load table after sorting
  useEffect(() => {    
    if (sortConfig) {
      // Remove duplicate budget amount values for each LOB
      let removeDuplicateValues = items.map(r=>{return {...r}});

      let lobs = new Set();
      for( let r of removeDuplicateValues){
          lobs.add(r.lob)
      }

      for(let l of Array.from(lobs)){            
        let countLOB = 0;
        let lob = l.toString();
        let prev_amount = 0;
        let amount_difference = 0;

        for(let r of removeDuplicateValues){             
          if(r.lob === lob){  
            // if(r.budget_amount !== prev_amount){
            //   prev_amount = r.budget_amount;
            // }

            if(countLOB != 0 ){              
                // amount_difference += prev_amount;
                // prev_amount = r.budget_amount;
                r.lob = ' ';
                // r.budget_amount = null;
            }
            countLOB++;
          } 
          // else if(r.lob === 'Grand Total') {
          //   r.budget_amount -= amount_difference;
          // }
        }
      }
      setLobRollup([...removeDuplicateValues, ...grandTotalData]);
    }
  },[items])

  const getDirection = (name: string) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  let headCells: HeadCell[];
  headCells = [
    {id: 'lob',label: 'LOB', isNum:false, applySort:true},
    {id: 'channel',label: 'Channel', isNum:false, applySort:true},
    // {id: 'budget_amount', label: 'Budget Amount', isNum:true, applySort:true},
    {id: 'mpa_amount',label: 'MPA Amount', isNum:true, applySort:true},
    // {id: 'remaining_to_mpa',label: 'Remaining Budget', isNum:true, applySort:true},
    {id: 'loaded',label: 'Loaded', isNum:true, applySort:true},
    {id: 'remaining_to_load',label: 'Remaining To Load', isNum:true, applySort:true},
    {id: 'spend',label: 'Spend', isNum:true, applySort:true},
    {id: 'remaining_on_mpa',label: 'Remaining On MPA', isNum:true, applySort:true}
  ];

  return (
    <>
      {props.lobRollupisloaded?
        <Paper sx={{ width: '95%', ml:5,mb:5, border:"1px solid"}}>
          <TableContainer>
            <h4 style={{textAlign: "left", padding:"5px"}}>BUDGET BREAKDOWN - LOB (Rollup)</h4>
            <Table stickyHeader size='small' >
              <ReportTableHead headCells={headCells} requestSort={(colId) => {requestSort(colId)}} getDirection={getDirection} />
              <ReportTableBody rows={lobRollup} />
            </Table>
          </TableContainer>
        </Paper>
      : null}
    </>
  )
}