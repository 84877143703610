// initializing annualBudget form with default loabable

import { createDefaultLoadable, Loadable } from '../../Common/Loadable';

export interface AnnualBudgetState {
  entities: Array<any>,
  selectedAnnualBudget: any,
  addAnnualBudgetLoadable: Loadable,
  deleteSelectedAnnualBudgetLoadable: Loadable,
  getSelectedAnnualBudgetListLoadable: Loadable,
  getAnnualBudgetListLoadable: Loadable,
  getAnnualBudgetsCsvLoadable: Loadable,
  updateAnnualBudgetListLoadable: Loadable
}

export function createDefaultAnnualBudgetState(): AnnualBudgetState {
  return {
    entities: [],
    selectedAnnualBudget: null,
    addAnnualBudgetLoadable: createDefaultLoadable(),
    deleteSelectedAnnualBudgetLoadable: createDefaultLoadable(),
    getSelectedAnnualBudgetListLoadable: createDefaultLoadable(),
    getAnnualBudgetListLoadable: createDefaultLoadable(),
    getAnnualBudgetsCsvLoadable: createDefaultLoadable(),
    updateAnnualBudgetListLoadable: createDefaultLoadable()
  }
}
