import React, { useMemo } from "react";
import { Col, Row } from "reactstrap";
import { IFormValues } from "./FormValues";
import { IDagApiConfigDropdownDto } from "State/DagApiConfig/Models/DagApiConfigsState";
import ExtraSettingDropdown from "./ExtraSettingDropdown";

interface IExtraSettingsForm {
  formValues: IFormValues;
  apiConfigDropdown: IDagApiConfigDropdownDto[];
  onConvNameSelect: (value: string, index: number) => void;
  onConvAcctSelect: (value: string) => void;
  OnAcctLobSelect: (value: string) => void;
}

const ExtraSettingsForm = ({
  apiConfigDropdown,
  formValues,
  onConvNameSelect,
  onConvAcctSelect,
  OnAcctLobSelect,
}: IExtraSettingsForm): JSX.Element => {
  const options = useMemo(
    () => ({
      conv_names: ["-", ...apiConfigDropdown?.map((item) => item.conv_name).filter((value) => value)],
      conv_accts: ["-", ...apiConfigDropdown?.map((item) => item.conv_acct).filter((value) => value)],
      acct_lobs: ["-", ...apiConfigDropdown?.map((item) => item.acct_lob).filter((value) => value)],
    }),
    [apiConfigDropdown]
  );

  return (
    <Row>
      <Col xs={12}>
        <h3>Extra Settings</h3>
      </Col>
      <ExtraSettingDropdown
        inputLabel="Conversion Name"
        itemKeyPrefix="conv_name"
        options={options.conv_names}
        onSelect={onConvNameSelect}
        value={formValues.conv_name}
      />
      <ExtraSettingDropdown
        inputLabel="Conversion Account"
        itemKeyPrefix="conv_acct"
        options={options.conv_accts}
        onSelect={onConvAcctSelect}
        value={formValues.conv_acct}
      />
      <ExtraSettingDropdown
        inputLabel="Account LOB"
        itemKeyPrefix="acct_lob"
        options={options.acct_lobs}
        onSelect={OnAcctLobSelect}
        value={formValues.acct_lob}
      />
    </Row>
  );
};

export default ExtraSettingsForm;
