import React from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';

export const ConfirmationModal = (props: any) => {
  const handleConfirmationAction = () => {
    props.handleToggleModal();
    props.executeConfirmationAction();
  };

  return (
    <>
      <Modal isOpen={props.isModalOpen} centered>
        <ModalHeader>{props.confirmationHeader}</ModalHeader>
        <ModalBody>
          <p>{props.confirmationText}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirmationAction}>
            Confirm
          </Button>
          <Button color="primary" outline onClick={props.handleToggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
};
