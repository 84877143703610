import React from 'react';
import PropTypes from 'prop-types';

import { TableActionsCell } from './TableActionsCell';
import { ReadOnlyTableCell } from './ReadOnlyTableCell';
import { BudgetOrdersInterface } from '../Models/BudgetOrders';

const createTableRowElement = (budgetOrder: BudgetOrdersInterface) => {
  return function (attribute: string) {
    return (
        <ReadOnlyTableCell attribute={attribute} budgetOrder={budgetOrder} key={attribute} />
    );
  }
};

interface Props{
  areActionsDisabled: boolean, 
  budgetOrder: BudgetOrdersInterface, 
  tableConfiguration: Array<string>, 
  handleSelectRow: (clientId : string, actionIcon : string) => void
}

export const TableRow = (props: Props) => {
  const { areActionsDisabled, budgetOrder, tableConfiguration, handleSelectRow } = props;
  return (
    <tr key={budgetOrder['clientID']}>
      {!areActionsDisabled && <TableActionsCell budgetOrder={budgetOrder} handleSelect={handleSelectRow} />}
      {tableConfiguration.map(createTableRowElement(budgetOrder), this)}
    </tr>
  );
};

TableRow.defaultProps = {
  areActionsDisabled: false,
  handleSelectRow: () => {},
};

TableRow.propTypes = {
  areActionsDisabled: PropTypes.bool.isRequired,
  budgetOrder: PropTypes.object.isRequired,
  handleSelectRow: PropTypes.func.isRequired,
  tableConfiguration: PropTypes.array.isRequired,
};
