import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEqual, pick, keys, values, filter, union, cloneDeep } from 'lodash';

import MpaForm from '../Common/MpaForm';

import { updateSelectedMpa } from '../../../State/Mpa/Actions/MpaActions';

import { MpaIntakeInterface } from '../../../Models/MpaIntakeInterface';
import { CidFieldInterface } from '../../../Models/CidFieldInterface';
import { toast } from 'react-toastify';
import { MandatoryFieldMpaModal } from '../../../Common/MandatoryFieldMpa';
import { Container } from 'reactstrap';
import MpaService from 'Services/MpaService';
import { getFormInfoState } from '../../../State/FormInfo/Selectors/FormInfoSelectors';
import { getAnchorCids } from '../../../State/FormInfo/Actions/FormInfoActions';
import FormInfoService from 'Services/FormInfoService';
import { ConfirmationModal } from '../../../Common';
import { ModalsConsumer } from '../../../Contexts/ModalsContext';
import { deleteSelectedMpa } from '../../../State/Mpa/Actions/MpaActions';
import { MpaWarningModal } from '../../../Common/MpaWarningModal';
import history from '../../../Utils/History';

const EditMpa = (props: any) => {
  const formInfoState = useSelector(getFormInfoState);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [mandatoryModal, setMandatoryModal] = useState(false);
  const [selectedMpaId, setSelectedMpaId] = useState(0);
  const [mpaWarningModal, setMpaWarningModal] = useState(false);
  // MPA Intake Form Data
  const [mpaIntake, setMpaIntake] = useState<MpaIntakeInterface>(
    props.mpaIntakeList.mpa
  );

  // CID Summary Data
  const [cidList, setCidList] = useState<Array<CidFieldInterface>>([
    {
      channel:"",
      anchor_cid:'',
      working_funds:0,
      campaign_name:'',

      november_disabled: false,
      december_disabled: false,
      january_disabled: false,
      february_disabled: false,
      march_disabled: false,
      april_disabled: false,
      may_disabled: false,
      june_disabled: false,
      july_disabled: false,
      august_disabled: false,
      september_disabled: false,
      october_disabled: false,

      november:0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      april: 0,
      may: 0,	
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      id:0,
      mpa_id: 0,
      anchor_cid_platform_fee:0,
      difference:0
      // legacy_mpa:"",
      // google_cid:'',
      // google_budget:0,
      // bing_cid:'',
      // bing_budget:0,
      // apple_cid:'',
      // apple_budget:0,
      // facebook_cid: "",
      // pinterest_cid: "",
      // bell_cid: "",
      // tiktok_cid: ""
    }
  ]);

  useEffect(() => {
    populateChannelData();
  },[props.mpaIntakeList.mpa.products]);

  // populate multiple channel fields on edit mpa form
  const populateChannelData = () => {
    let cidData = props.mpaIntakeList.cid;
    let retrievedChannelList: CidFieldInterface[] = [];

    for (let cid of cidData) {
      let temp: CidFieldInterface = {
        channel:        cid.channel,
        anchor_cid:     cid.anchor_cid,
        working_funds:  cid.working_funds,
        campaign_name:  cid.campaign_name,

        november_disabled:       cid.november_disabled,
        december_disabled:       cid.december_disabled,
        january_disabled:        cid.january_disabled,
        february_disabled:       cid.february_disabled,
        march_disabled:          cid.march_disabled,
        april_disabled:          cid.april_disabled,
        may_disabled:            cid.may_disabled,	
        june_disabled:           cid.june_disabled,
        july_disabled:           cid.july_disabled,
        august_disabled:         cid.august_disabled,
        september_disabled:      cid.september_disabled,
        october_disabled:        cid.october_disabled,

        november:       cid.november,
        december:       cid.december,
        january:        cid.january,
        february:       cid.february,
        march:          cid.march,
        april:          cid.april,
        may:            cid.may,	
        june:           cid.june,
        july:           cid.july,
        august:         cid.august,
        september:      cid.september,
        october:        cid.october,

        
        id:             cid.id,
        mpa_id:         props.mpaIntakeList.mpa.id,
        anchor_cid_platform_fee:cid.anchor_cid_platform_fee,
        difference:cid.difference
        // legacy_mpa:     props.mpaIntakeList.mpa.legacy_mpa,
        // google_cid:     cid.google_cid,
        // google_budget:  cid.google_budget,
        // bing_cid:       cid.bing_cid,
        // bing_budget:    cid.bing_budget,
        // apple_cid:      cid.apple_cid,
        // apple_budget:   cid.apple_budget,
        // facebook_cid:   '',
        // pinterest_cid:  '',
        // bell_cid:       '',
        // tiktok_cid:     ''        
      }

      if(cid.channel === '' || cid.channel === null) {
        temp.channel = cid.anchor_cid.includes("-")?'Google':'Facebook';
      }

      retrievedChannelList.push(temp);
    }
    setCidList(retrievedChannelList);
  };

  const [cidsToRemove, setCidsToRemove] = useState([]);
  const [startDateChange, setStartDateChange] = useState(false);
  const [endDateChange, setEndDateChange] = useState(false);

  const handleGetChangeLog = (finalCidList) => {
    //create MPA change logs
    const changedMpaKeysMethod = (o1: any, o2: any) => {
      return filter(union(keys(o1), keys(o2)), (key) => {
        return o1[key] !== o2[key];
      });
    };

    const mpaChangedKeys = changedMpaKeysMethod(mpaIntake, props.mpaIntakeList.mpa);
    const mpaChangeLogs = mpaChangedKeys.map((key) => {
      const newValue = values(pick(mpaIntake, key))
      const prevValue = values(pick(props.mpaIntakeList.mpa, key))
      return `${key} :  ${prevValue} ->  ${newValue}`;
    })

    //create CID change logs
    const changedCidKeyMethod = (o1:any, o2:any) =>{
      return o1.map((cidObj:any, index:any) => {
        if(o2[index] !== undefined){
          return filter(union(keys(cidObj), keys(o2[index])), (key) => {
            return cidObj[key] !== o2[index][key];
          });
        } else {
          return keys(cidObj);
        }
      });
    };

    const cidChangedKeys = changedCidKeyMethod(finalCidList, props.mpaIntakeList.cid);
    const cidChangeLog = cidChangedKeys.map((item:any, index:any) => {
      return item.map((key:any) => {
        const newValue = values(pick(finalCidList[index], key));
        const prevValue = values(pick(props.mpaIntakeList.cid[index], key));
        return `${key} : ${prevValue} -> ${newValue}`
      })
    })
    const cidChangeLogs = cidChangeLog?.map((items:any) => {return `${items}`});

    return `${mpaChangeLogs}, ${cidChangeLogs}`
  };

  useEffect(() => {
    // increment revision number field if grand total is changed.
    if(mpaIntake.grand_total !== props.mpaIntakeList.mpa.grand_total){
      if(props.mpaIntakeList.mpa.revision_number === "" || props.mpaIntakeList.mpa.revision_number === null || isNaN(props.mpaIntakeList.mpa.revision_number.split("")[1])){
        setMpaIntake({...mpaIntake, revision_number: "R1"});
      }else{
        const currentRevNumber = parseInt(props.mpaIntakeList.mpa.revision_number.split("")[1]);
        const newRevNumber = "".concat('R', (currentRevNumber + 1).toString());
        setMpaIntake({...mpaIntake, revision_number: newRevNumber});
      }
     }else {
       setMpaIntake(mpaIntake)
     }
  }, [mpaIntake.grand_total]);

  // remove other cid fields from final array to save only anchor_cid value in database
  // const removeExtraFields = () => {
  //   let originalCidList = cloneDeep(cidList);
  //   let finalData = originalCidList.map((row) => {
  //       delete row.facebook_cid;
  //       delete row.pinterest_cid;
  //       delete row.tiktok_cid;
  //       delete row.bell_cid;
  //       return row;
  //   });
  //   return finalData;
  // };

  // remove channel value of old data from form_mpa table
  const removeDataFromMpaList = () => {
    let originalMpaList = cloneDeep(mpaIntake);
    originalMpaList.channel = '';
    return originalMpaList;
  };

  // Confirmation popup text if all the CID rows are deleted and the MPA itself will be deleted
  const buildDeleteMpaConfirmationText = () => {
    const finalMpaList = removeDataFromMpaList();
    const selectedMpaNumber = finalMpaList.mpa_number
    const selectedMpaLob = finalMpaList.client_business_unit;
    const selectedMpaProduct = finalMpaList.products;
    const selectedMpaName = `MPA: ${selectedMpaNumber}, LOB: ${selectedMpaLob}, Product: ${selectedMpaProduct}`;

    return `The following ${selectedMpaName} will be deleted. Do you want to proceed?`;
  };

  // Delete selected MPA if all the CID rows are deleted
  const deleteMpaAction = () => {
    const payload = {
      deletedBy : props.userName
    };
    dispatch(deleteSelectedMpa(selectedMpaId, payload));
  };

  // Display warning popup if the entered MPA budget exceeds the annual budget
  const buildBudgetWarningText = () => {
    return `The total MPA budget will be more than the annual budget. Do you want to proceed?`;
  }

  // Redirect to annual-budgets page
  const BudgetWarningAction = () => {
    history.push(`/map/annual-budgets`,'/map/mpa');
  }

  // Update selected MPA after budget warning popup is displayed
  useEffect(() => {
    if(mpaWarningModal === true) {
      // const finalCidList = removeExtraFields();
      const finalCidList = cloneDeep(cidList);
      const finalMpaList = removeDataFromMpaList();
      const changeLog = handleGetChangeLog(finalCidList);

      const mpaPayload = { 
        mpaIntake: finalMpaList, 
        cidList: finalCidList, 
        changeLog: changeLog, 
        cidsToRemove: cidsToRemove,
        editedBy: props.userName
      };
      dispatch(updateSelectedMpa(selectedMpaId, mpaPayload));
    }
  }, [mpaWarningModal]);

  const handleUpdateMpa = async (event: any, rowId: any, openConfirmationModal: any, openMpaWarningModal: any) => {
    // const finalCidList = removeExtraFields();
    const finalCidList = cloneDeep(cidList);
    const finalMpaList = removeDataFromMpaList();
    setMpaWarningModal(false); 

    event.preventDefault(finalCidList, props.mpaIntakeList.cid);
    setSelectedMpaId(rowId);

    if(finalCidList.length === 0) {
      openConfirmationModal();
    } else if(finalCidList.length > 0) {
      //handle submit unchanged form
      if(isEqual(finalMpaList, props.mpaIntakeList.mpa) && isEqual(finalCidList, props.mpaIntakeList.cid)){
        toast.error("Alert!!! Can't submit unchanged form");
      } else {
        let startDate = !startDateChange? new Date(finalMpaList.scheduled_start.slice(0,-1)): new Date(finalMpaList.scheduled_start)
        let endDate = !endDateChange? new Date(finalMpaList.scheduled_end.slice(0,-1)): new Date(finalMpaList.scheduled_end)

        if(finalMpaList.scheduled_start <= finalMpaList.scheduled_end && (
          (endDate.getMonth()+1 < 11 && startDate.getFullYear() + 1 >= endDate.getFullYear() && startDate.getMonth() + 1 >= 11) ||
          (startDate.getMonth()+1 < 11 && endDate.getMonth()+1 < 11 && startDate.getFullYear() === endDate.getFullYear()) ||
          (startDate.getMonth()+1 >= 11 && startDate.getFullYear() === endDate.getFullYear())
          )
        ){
          if(mandatoryModal == true){
            let year = finalMpaList.campaign.slice(0,7);
            let lob = finalMpaList.client_business_unit;
            // let channel = mpaIntake.channel;
            let product = finalMpaList.products;
            let schedule_start = finalMpaList.scheduled_start.slice(0,10)
            let anchor_cid_set = new Set();

            for(let i=0; i<finalCidList.length; i++){
              anchor_cid_set.add(finalCidList[i].anchor_cid)
            }

            let anchor_cid = "";
            let anchor_cid_list = Array.from(anchor_cid_set)

            let already_existed = true;
            for(let originalCid of props.mpaIntakeList.cid){
              if(!anchor_cid_list.includes(originalCid.anchor_cid)){
                already_existed = false;
                break;
              } 
            }

            for(let i=0; i<anchor_cid_list.length; i++){
              if(i < anchor_cid_list.length - 1){
                anchor_cid = anchor_cid+"\'"+anchor_cid_list[i]+"\',";
              } else {
                anchor_cid = anchor_cid+"\'"+anchor_cid_list[i]+"\'";
              }
            }
            let params = {
              year: year,
              lob : lob,
              // channel: channel,
              product: product,
              anchor_cid: anchor_cid,
              schedule_start: schedule_start,
            }

            try {            
              // Allow Caribbean to create multiple MPAs for same CIDs
              const response = lob !== "Caribbean" ? await MpaService.retrieveAnchorIdProductCount(params) : {counts:0};          
              if(response.counts > finalCidList.length && !already_existed){
                toast.error("Alert!!! Specified Anchor CIDs already exist based on the given scheduled date range and product for the given fiscal year!");
              } else {
                // Display warning popup if the entered MPA budget exceeds the total annual budget
                try {
                  const response = await FormInfoService.retrieveAnnualBudgetValidationCount("F"+finalMpaList.campaign.slice(5,7), finalMpaList.client_business_unit, finalMpaList.products);
                  if(finalMpaList.grand_total > response.annual_budget) {
                    openMpaWarningModal();                  
                  } else {
                    setMpaWarningModal(true);
                  }
                } catch(ex) {
                  toast.error("Couldn't retrieve annual budget info, please try again");
                } 
              }
            } catch(ex){
              toast.error("Couldn't retrieve count data, please try again");
            }             
          } else {
            setModal(true);
          }
        } else {
          toast.error("Alert!!! Schedule dates are not correct!");
        }
      };
    }
  };

  return (
    <>
      <ModalsConsumer>
        {
          modalsState => {
            return modalsState && (
              <>
                <Container fluid={true}>
                  <MandatoryFieldMpaModal 
                    modal={modal}
                    mpaIntake={mpaIntake}
                    cidList={cidList}
                    setModal={setModal}
                    mandatoryModal = {mandatoryModal}
                    setMandatoryModal = {setMandatoryModal}
                    fromAddMPA={false}
                  />
                  <MpaForm
                    mpaIntake={mpaIntake}
                    anchorCidOptions={formInfoState.anchorCids}
                    dropdownOptions={props.dropdownOptions}
                    cidList={cidList}
                    isDisabled={true}
                    setMpaIntake={setMpaIntake}
                    setCidList={setCidList}
                    cidsToRemove={cidsToRemove}
                    setCidsToRemove={setCidsToRemove}
                    auditLogs={props.mpaIntakeList.auditLog}
                    isFormDisabled={props.isFormDisabled}
                    handleMpaFormSubmit={handleUpdateMpa}
                    fromAddMPA={false}
                    setStartDateChange={setStartDateChange}
                    setEndDateChange={setEndDateChange}
                  />
                  <ConfirmationModal
                    confirmationHeader="Delete MPA"
                    confirmationText={buildDeleteMpaConfirmationText()}
                    executeConfirmationAction={deleteMpaAction}
                    handleToggleModal={modalsState.handleToggleConfirmationModal}
                    isModalOpen={modalsState.isConfirmationModalOpen}
                  />
                  <MpaWarningModal
                    confirmationHeader="Warning"
                    confirmationText={buildBudgetWarningText()}
                    executeConfirmationAction={BudgetWarningAction}
                    handleToggleModal={modalsState.handleToggleMpaWarningModal}
                    isModalOpen={modalsState.isMpaWarningModalOpen}
                    mpaWarningModal={mpaWarningModal}
                    setMpaWarningModal={setMpaWarningModal}
                  />
                </Container>
              </>
            )
          }
        }
      </ModalsConsumer>
    </>
  );
};

export default EditMpa;
