export const getMonthName = (date: string) => {
  let datestring = new Date(date);
  return datestring.toLocaleString('default', { month: 'long' }).toLowerCase();
};

export const formatDate = (selectedDatetime) => {
  let selectedDate = selectedDatetime.split('T')[0];
  let datestring = new Date(selectedDate.replace(/-/g, '/'));
  let month = datestring.getMonth();
  let year = datestring.getFullYear();
  let formattedDate = new Date(year, month, 1);
  return formattedDate;
};

export const addMonthsToDate = (selectedDate, months) => {
  let newDatestring = new Date(selectedDate);
  newDatestring.setMonth(newDatestring.getMonth() + months);
  return newDatestring;
};
