import React from 'react';

import { Loadable } from '../State/Common/Loadable';
import { Loader } from '.';

export const WithMultipleLoading = (Component: any) => {
  return function WithMultipleLoadingComponent({ loadables, ...props }: any) {
    if (loadables.every((loadable: Loadable) => loadable.isReceived)) {
      return (<Component {...props} />);
    }

    if (loadables.some((loadable: Loadable) => !!loadable.error)) {
      sessionStorage.removeItem("authenticationState");
      sessionStorage.removeItem("tableConfig");
      window.location.reload(); 
    }

    return (<Loader />);
  }
};
