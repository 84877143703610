import { toast } from 'react-toastify';

export const loadState = () => {
  try {

    const serializedState = sessionStorage.getItem('authenticationState');

    if (serializedState === null) { return undefined; }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('authenticationState', serializedState);
  } catch (err) {
    toast.error("Something wrong with the authenticationState, please try again!");
  }
};
