import {
  LobFormContextState,
  createDefaultLobFormContextState
} from '../Models/LobFormContextState';

import { SET_SELECTED_LOB_ID } from '../ActionTypes/LobFormContextTypes';

export default function (
  state = createDefaultLobFormContextState(),
  action: any
): LobFormContextState {
  switch(action.type) {
    case SET_SELECTED_LOB_ID:
      return {
         ...state,
         selectedLobId: action.lobId
      };

    default:
      return state;
  };
};
