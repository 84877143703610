import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { downloadFile } from '../../../Utils/DownloadFile';
import history from '../../../Utils/History';

import {
  addCidToExcludeListSuccess, addCidToExcludeListError,
  deleteSelectedExcludeIdSuccess, deleteSelectedExcludeIdError,
  getExcludeCidsSuccess, getExcludeCidsError,
  getSelectedExcludeIdSuccess, getSelectedExcludeIdError,
  updateSelectedExcludeIdSuccess, updateSelectedExcludeIdError
} from '../Actions/ExcludeCidsActions';

import ExcludeCidsService from '../../../Services/ExcludeCidsService';

export function* onAddCidToExcludeList(action: any) {
  try {
    let response = yield call(ExcludeCidsService.addCidToExclude, action.excludedCidData);
    yield put(addCidToExcludeListSuccess(response));
    toast.success('Successfully Added CID to Exclude List');
  } catch (err) {
    yield put(addCidToExcludeListError(err));
    toast.error('Could Not Add CID to Exclude List');
  } finally {
    yield history.push(`/map/exclude-cids`, `/map/mpa`);
  }
}

export function* onDeleteSelectedExcludeId(action: any) {
  try {
    yield call(ExcludeCidsService.deleteSelectedExcludeId, action.excludeId, action.payload);
    yield put(deleteSelectedExcludeIdSuccess(action.excludeId));
    toast.success('Successfully Deleted Exclude CID');
  } catch (err) {
    yield put(deleteSelectedExcludeIdError(err));
    toast.error('Could Not Delete Exclude CID');
  }
}

export function* onGetExcludeCids(action: any) {
    try {
      let response = yield call(ExcludeCidsService.retrieveExcludeCids);
      yield put(getExcludeCidsSuccess(response));
    } catch (err) {
      yield put(getExcludeCidsError(err));
    }
}

export function* onGetSelectedExcludeId(action: any) {
  try {
    let response = yield call(ExcludeCidsService.retrieveSelectedExcludeId, action.excludeId);
    yield put(getSelectedExcludeIdSuccess(response));
  } catch (err) {
    yield put(getSelectedExcludeIdError(err));
  }
}

export function* onUpdateSelectedExcludeId(action: any) {
  try {
    let response = yield call(
      ExcludeCidsService.updateSelectedExcludeId,
      action.excludeId,
      action.excludedCidData
    );
    yield put(updateSelectedExcludeIdSuccess(response));
    toast.success('Successfully Updated Exclude CID');
  } catch (err) {
    yield put(updateSelectedExcludeIdError(err));
    toast.error('Could Not Update Exclude CID');
  } finally {
    yield history.push( `/map/exclude-cids`, `/map/mpa`);
  }
}