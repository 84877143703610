export const ADD_NEW_LOB = 'ADD_NEW_LOB';
export const ADD_NEW_LOB_SUCCESS = 'ADD_NEW_LOB_SUCCESS';
export const ADD_NEW_LOB_ERROR = 'ADD_NEW_LOB_ERROR';

export const DELETE_SELECTED_LOB = 'DELETE_SELECTED_LOB';
export const DELETE_SELECTED_LOB_SUCCESS = 'DELETE_SELECTED_LOB_SUCCESS';
export const DELETE_SELECTED_LOB_ERROR = 'DELETE_SELECTED_LOB_ERROR';

export const GET_LOB = 'GET_LOB';
export const GET_LOB_SUCCESS = 'GET_LOB_SUCCESS';
export const GET_LOB_ERROR = 'GET_LOB_ERROR';

export const GET_LOBS_CSV = 'GET_LOBS_CSV';
export const GET_LOBS_CSV_SUCCESS = 'GET_LOBS_CSV_SUCCESS';
export const GET_LOBS_CSV_ERROR = 'GET_LOBS_CSV_ERROR';

export const GET_SELECTED_LOB = 'GET_SELECTED_LOB';
export const GET_SELECTED_LOB_SUCCESS = 'GET_SELECTED_LOB_SUCCESS';
export const GET_SELECTED_LOB_ERROR = 'GET_SELECTED_LOB_ERROR';

export const UPDATE_SELECTED_LOB = 'UPDATE_SELECTED_LOB';
export const UPDATE_SELECTED_LOB_SUCCESS = 'UPDATE_SELECTED_LOB_SUCCESS';
export const UPDATE_SELECTED_LOB_ERROR = 'UPDATE_SELECTED_LOB_ERROR';