import React, {useState} from 'react';
import PropTypes from 'prop-types'

import { GridDropdown, GridInput } from '../../../Common';
import { DropdownItem } from 'reactstrap';

export const DropdownWithInput = (props: any) => {
  const [isLobDropdownOpen, setIsLobDropdownOpen] = useState(false);

  const toggleLobDropdown = () => setIsLobDropdownOpen(prevState => !prevState);

  return (
      <GridDropdown
        gridSize={props.gridSize}
        inputLabel={props.inputLabel}
        isDisabled={props.isDisabled}
        isDropdownOpen={isLobDropdownOpen}
        handleToggle={toggleLobDropdown}
        placeholder='---------'
        value={props.value}
      >
        {props.dropdownOptions.map((item: string) =>
          <DropdownItem key={item} value={item} onClick={props.handleChange}>
            {item}
          </DropdownItem>
        )}
        <GridInput
            // gridSize={3}
            inputAria={props.inputAria}
            inputId={props.inputId}
            inputLabel="Other:"
            inputType="string"
            value={props.value}
            handleChange={props.handleChange}
        />
      </GridDropdown>
    
  );
};

DropdownWithInput.propTypes = {
  gridSize: PropTypes.number,
  inputAria: PropTypes.string,
  inputId: PropTypes.string,
  inputLabel: PropTypes.string,
  inputType: PropTypes.string,
  value: PropTypes.any,
  dropdownOptions: PropTypes.array,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool
};
