import React, { useEffect, useMemo, useState } from 'react'
import {  useSelector } from 'react-redux';
import history from '../../../Utils/History'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


import MpaToolbar from '../Common/MpaToolbar';
import {  DashboardTable, PaginationToolbar, RowsPerPageDropdown, SearchBar, TablePagination }
  from '../../../Common';

import { formatNumberToCanadianDollars } from '../../../Utils/FormatNumberToCurrency';  

import { ModalsConsumer } from '../../../Contexts/ModalsContext';

import { getUserPermissions } from '../../../State/Authentication/Selectors/AuthenticationSelectors';

import { isNumber, cloneDeep } from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if(sortConfig.key2 === undefined){
         
          if(isNumber(a[sortConfig.key]) || a[sortConfig.key] === null || b[sortConfig.key] === null){
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === 'asc' ? 1 : -1;
            }
          } else {
            if (a[sortConfig.key].toUpperCase() < b[sortConfig.key].toUpperCase()) {
              return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key].toUpperCase() > b[sortConfig.key].toUpperCase()) {
              return sortConfig.direction === 'asc' ? 1 : -1;
            }
          }
        } else {
          if(a[sortConfig.key] === null || b[sortConfig.key] === null || a[sortConfig.key2] === null || b[sortConfig.key2] === null){
            if (a[sortConfig.key] + a[sortConfig.key2] < b[sortConfig.key] + b[sortConfig.key2]) {
              return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] + a[sortConfig.key2] > b[sortConfig.key] + b[sortConfig.key2]) {
              return sortConfig.direction === 'asc' ? 1 : -1;
            }
          } else {
            if ((a[sortConfig.key].toUpperCase() + a[sortConfig.key2]).toUpperCase() < (b[sortConfig.key].toUpperCase() + b[sortConfig.key2]).toUpperCase()) {
              return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if ((a[sortConfig.key].toUpperCase() + a[sortConfig.key2]).toUpperCase() > (b[sortConfig.key].toUpperCase() + b[sortConfig.key2]).toUpperCase()) {
              return sortConfig.direction === 'asc' ? 1 : -1;
            }
          }
        }
        
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: string, key2: string = undefined) => {

    let direction = 'asc';
    if(key2 === undefined){
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'asc'
      ) {
        direction = 'desc';
      }
      setSortConfig({ key, direction });

    } else {
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.key2 === key2 &&
        sortConfig.direction === 'asc'
      ) {
        direction = 'desc';
      }
      setSortConfig({ key, key2, direction });
    }
    
  };

  return { items: sortedItems, requestSort, sortConfig };
}; 

const MpaDashboard = (props: any) => {
  const [exportErrorAlert, setExportErrorAlert] = useState(false);
  const [exportSuccessAlert, setExportSuccessAlert] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [filteredData, setFilteredData] = useState<Array<any>>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fiscalData, setFiscalData] = useState<Array<any>>([]);
  const [fiscalFilter, setFiscalFilter] = useState(true);
  // const [selectedFiscal, setSelectedFiscal] = useState('');
  // load current fiscal year's data on page load
  const currentFiscalYear = new Date().getFullYear().toString();
  const nextFiscalYear = (new Date().getFullYear()+1).toString();
  const [selectedFiscal, setSelectedFiscal] = useState<String>(new Date().getMonth()+1 >= 11?
                                                              "FY-" + nextFiscalYear
                                                              :"FY-"+ currentFiscalYear);

  const userPermissions = useSelector(getUserPermissions);
  const { items, requestSort, sortConfig } = useSortableData(filteredData);

  const getDirection = (name: string) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  // const isShowAlert = useMemo(() => 
  //   {
  //     const today = new Date();
  //     return [6,7,8].includes(today.getDate())
  //   },[])

  // const alertDate = useMemo(()=>{
  //   const year = new Date().getFullYear();
  //   const month = new Date().getMonth();
  //   return `${year}-${month<9?"0":""}${month+1}-10`
  // },[])

  // change date format from iso format to m/d/Y to display on manage MPA page
  const format_date = (selectedDate) => {
    let datestring = new Date(selectedDate);
    let isodatestring = datestring.toISOString();
    let fullDate = isodatestring.split("T")[0];
    let dateparts = fullDate.split("-");
    let month = dateparts[1];
    let day = dateparts[2];
    let year = dateparts[0];
    let formattedDate = month + "/" + day + "/" + year;
    return formattedDate;
  };

  useEffect(() => {
    let originalMpaList = cloneDeep(props.mpaList);
    setTableData([...originalMpaList].map((row) => {
      row.scheduled_start = format_date(row.scheduled_start);
      row.scheduled_end = format_date(row.scheduled_end);
      return row;
    }));
  }, [props.mpaList]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);

  // filter current year's data to display it on page load
  useEffect(() => {
    if(fiscalData.length === 0) {
      setFiscalData((tableData).filter((data:any) => data.fiscal && data.fiscal.includes(selectedFiscal)));
    } 
  }, [tableData]);

  /*const buildDeleteMpaConfirmationText = () => {
    const selectedMpa = filteredData.length > 0 ?
      filteredData.find((mpa: any) => mpa['id'] === selectedMpaId) :
      tableData.find((mpa: any) => mpa['id'] === selectedMpaId);
    const selectedMpaNumber = selectedMpa && selectedMpa['mpa_number'];
    const selectedMpaLob = selectedMpa && selectedMpa['client_business_unit'];
    const selectedMpaChannel = selectedMpa && selectedMpa['channel'];
    const selectedMpaPhase = selectedMpa && `Phase ${selectedMpa['phase_number']}`;
    const selectedMpaName = `${selectedMpaNumber} ${selectedMpaLob} ${selectedMpaChannel} - ${selectedMpaPhase}`;

    return `The following MPA ${selectedMpaName} will be deleted. Do you want to proceed?`;
  };

  const deleteMpaAction = () => {
    const payload = {
      deletedBy : props.userName
    };
    dispatch(deleteSelectedMpa(selectedMpaId, payload));
  };*/

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchValue = (e: any) => {
    setSearchValue(e)
  };

  const toggle = () => setDropdownOpen(prevState => !prevState);

  /*const selectMpaToDelete = (event: any, id: number, openConfirmationModal: any) => {
    setSelectedMpaId(id);
    openConfirmationModal();
  };*/

  useEffect(() => {
    if(props.exportDataToBqStatus === 'Success'){
      setExportSuccessAlert(true);
      window.setTimeout(() => {setExportSuccessAlert(false)},2000);
    } else if(props.exportDataToBqStatus === 'Error') {
      setExportErrorAlert(true);
      window.setTimeout(() => {setExportErrorAlert(false)},2000)
    }
  }, [props.exportDataToBqStatus]);
  
  useEffect(() => {
    setPage(0);
    const filterFuncton = (data: any, keyword: string) => {
      setFilteredData(
        data.filter((data: any) =>
          data.legacy_mpa.toLowerCase().includes(keyword.toLocaleLowerCase()) ||
          data.mpa_number.toLowerCase().includes(keyword.toLocaleLowerCase()) ||
          data.client_business_unit.toLowerCase().includes(keyword.toLocaleLowerCase()) ||
          data.products.toLowerCase().includes(keyword.toLocaleLowerCase()) ||
          (data.anchor_cid && data.anchor_cid.toLowerCase().includes(keyword.toLocaleLowerCase()))
      ))
    };

    const keywords = searchValue.split(" ");

    keywords.forEach((element, index) => {
      // call filter function
      if(index === 0 && !fiscalFilter){
        // first word and no fiscal is selected
        filterFuncton(tableData, element);
      }
      else if(index === 0 && fiscalFilter){
        // first word and a fiscal is selected
        filterFuncton(fiscalData, element);
      }
      else if(index > 0 && fiscalFilter) {
        // not a first word but a fiscal is selected
        filterFuncton(filteredData, element);
      }
      else {
        filterFuncton(filteredData, element);
      }
    });

  }, [searchValue, selectedFiscal, tableData, fiscalData, fiscalFilter]);

  const setDropDownAction = (e:any) => {
    setPage(0);
    // filter has been selected
    setFiscalFilter(true);
    setSelectedFiscal(e.innerHTML);

    // reset search bar if a filter is selected
    setSearchValue('')

    // update table data on base of filter selection
    setFilteredData((tableData).filter((data:any) => data.fiscal && data.fiscal.includes(e.innerHTML)));
    setFiscalData((tableData).filter((data:any) => data.fiscal && data.fiscal.includes(e.innerHTML)));
  }

  const fiscalList = tableData.map(item => {
    return item.fiscal
  })
  
  const uniqueFiscal = fiscalList.filter((value, index, self) => self.indexOf(value) === index);

  const cancelDropDownAction = () => {
    setFiscalFilter(false)
    setPage(0);
    // reset search bar if filter is removed
    setSearchValue('')
  }

  return (
    <>
      <ModalsConsumer>
        {
          modalsState => {
            return modalsState && (
              <>
                <div style={{display: "flex", marginTop: "2rem"}}>
                  <Dropdown isOpen={dropdownOpen} toggle={toggle} className='mr-2'>
                    <DropdownToggle caret color="primary">
                      {fiscalFilter ? `${selectedFiscal}`: 'Select a Fiscal'}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={()=>cancelDropDownAction()}>---</DropdownItem>
                      {uniqueFiscal.map((item,index) => 
                        <DropdownItem key={index} onClick={(e)=>setDropDownAction(e.target)}>{item}</DropdownItem>)
                      }         
                    </DropdownMenu>
                  </Dropdown>
                  <Alert color="primary" style={{padding: "0.375rem 1.25rem", marginBottom: "0"}}>
                      The balances in the channel are updated every month on the <strong>10<sup>th</sup></strong> automatically. Please verify and adjust the MPA budgets as needed.
                  </Alert>
                </div>          
                <br/>
                <SearchBar
                  placeholder={"Search for LOB, Product, MPA# or CID"}
                  searchInput={searchValue}
                  handleSearchValue={handleSearchValue}
                />
                <MpaToolbar
                  handleExportDataToBQ={props.exportDataToBQ}
                  showAddMpaButton={userPermissions.includes('add_mpa')}
                />
                <Alert color="success" isOpen={exportSuccessAlert}> Started exporting data to Bigquery...</Alert>
                <Alert color="danger" isOpen={exportErrorAlert}> Issues exporting data to Bigquery...</Alert>
                <DashboardTable>
                  <thead>
                    <tr>
                      <th align="left" onClick={() => {setPage(0);requestSort('legacy_mpa', 'revision_number')}} style={{cursor:"pointer", width:"7%"}}>
                        <b>MPA</b> {getDirection('legacy_mpa')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('legacy_mpa')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      <th align="left" onClick={() => {setPage(0);requestSort('fiscal')}} style={{cursor:"pointer", width:"10%"}}>
                        <b>Fiscal</b> {getDirection('fiscal')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('fiscal')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      <th align="left" onClick={() => {setPage(0);requestSort('client_business_unit')}} style={{cursor:"pointer", width:"10%"}}>
                        <b>LOB</b> {getDirection('client_business_unit')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('client_business_unit')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      <th align="left" onClick={() => {setPage(0);requestSort('products')}} style={{cursor:"pointer",width:"15%"}}>
                        <b>Product</b> {getDirection('products')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('products')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>                      
                      <th align="left" onClick={() => {setPage(0);requestSort('scheduled_start')}} style={{cursor:"pointer",width:"12%"}}>
                        <b>Scheduled Start</b> {getDirection('scheduled_start')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('scheduled_start')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      <th align="left" onClick={() => {setPage(0);requestSort('scheduled_end')}} style={{cursor:"pointer",width:"12%"}}>
                        <b>Scheduled End</b> {getDirection('scheduled_end')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('scheduled_end')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      {/* <th align="left" onClick={() => {setPage(0);requestSort('channel')}} style={{cursor:"pointer", width:"10%"}}>
                        <b>Channel</b> {getDirection('channel')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('channel')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th> */}
                      {/* <th align="left" onClick={() => {setPage(0);requestSort('phase_number')}} style={{cursor:"pointer",width:"10%"}}>
                        <b>Phase</b> {getDirection('phase_number')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('phase_number')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th> */}
                      <th onClick={() => {setPage(0);requestSort('delta')}} style={{cursor:"pointer", textAlign:"right"}}>
                        <b>Delta</b> {getDirection('delta')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('delta')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      <th onClick={() => {setPage(0);requestSort('grand_total')}} style={{cursor:"pointer", textAlign:"right"}}>
                        <b>Total</b> {getDirection('grand_total')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('grand_total')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      <th align="left" onClick={() => {setPage(0);requestSort('mpa_status')}} style={{cursor:"pointer", width:"10%", textAlign:"center"}}>
                        <b>Status</b> {getDirection('mpa_status')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('mpa_status')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      <th onClick={() => {setPage(0);requestSort('notes')}} style={{cursor:"pointer", width:"17%"}}>
                        <b>Notes</b> {getDirection('notes')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('notes')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>

                      <th align="left" style={{width:"10%"}}><b>Edit</b></th>
                      {/* {userPermissions.includes('delete_mpa') && <th align="left" style={{width:"10%"}}><b>Delete</b></th>} */}
                    </tr>
                  </thead>
                  <tbody>
                    {/* {(searchValue || fiscalFilter ?  */}
                    {(rowsPerPage > 0 ?
                        // filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) :
                        // tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) :
                        items
                      ).map((row: any) => (
                        <tr key={row.id}>
                          <td align="left">{row.legacy_mpa} {row.revision_number}</td>
                          <td align="left">{row.fiscal}</td>
                          <td align="left">{row.client_business_unit}</td>
                          <td align="left">{row.products}</td>
                          <td align="left">{row.scheduled_start}</td>
                          <td align="left">{row.scheduled_end}</td>
                          {/* <td align="left">{row.channel}</td> */}
                          {/* <td align="left">{row.phase_number}</td> */}
                          <td align="right">{formatNumberToCanadianDollars(row.delta)}</td>
                          <td align="right">{formatNumberToCanadianDollars(row.grand_total)}</td>
                          <td align="center">{row.mpa_status}</td>
                          <td align="left">{row.notes}</td>
                          <td>
                              <FontAwesomeIcon 
                                style={{"cursor": "pointer"}}
                                onClick={()=>{history.push(`/map/mpa/edit/${row.id}`,'/map/mpa',)}}
                              icon="edit" size="sm" />
                          </td>
                          {/* {userPermissions.includes('delete_mpa') &&
                          
                            <td>
                              <FontAwesomeIcon
                                icon="trash"
                                size="sm"
                                style={{"color": "#006AC3", "cursor": "pointer"}}
                                onClick={
                                  event => selectMpaToDelete(event, row['id'], modalsState.handleToggleConfirmationModal)
                                }
                              />
                            </td>
                          } */}
                        </tr>
                    ))}
                    {emptyRows > 0 && (
                      <tr style={{ height: 53 * emptyRows }}>
                        <td colSpan={9}><span></span></td>
                      </tr>
                    )}
                  </tbody>
                </DashboardTable>
                <PaginationToolbar>
                  <RowsPerPageDropdown
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TablePagination
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                  />
                </PaginationToolbar>
                {/* <ConfirmationModal
                  confirmationHeader="Delete MPA"
                  confirmationText={buildDeleteMpaConfirmationText()}
                  executeConfirmationAction={deleteMpaAction}
                  handleToggleModal={modalsState.handleToggleConfirmationModal}
                  isModalOpen={modalsState.isConfirmationModalOpen}
                /> */}
              </>
            )
          }
        }
      </ModalsConsumer>
    </>
  );
};

export default MpaDashboard;