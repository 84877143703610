import { StyleSheet, Font } from '@react-pdf/renderer';

import font from '../../../Assets/Roboto-Bold.ttf';

// Register font
Font.register({
  family: 'Roboto',
  src: font,
});

const PDFStyles = StyleSheet.create({
  body: {
    fontSize: 10,
    paddingVertical: 35,
    paddingHorizontal: 25,
  },
  flexView: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexLeftColumnView: {
    flexDirection: 'column',
    width: '55%',
  },
  flexMiddleView: {
    width: '3%',
  },
  flexRightColumnView: {
    flexDirection: 'column',
    width: '42%',
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: 15,
  },
  subTitle: {
    fontFamily: 'Roboto',
    fontSize: 13,
  },
  caption: {
    fontSize: 9,
    fontStyle: 'italic',
  },
  divider: {
    width: '100%',
    borderBottom: 1,
    marginVertical: 15,
    borderColor: 'lightgrey',
  },
  boldText: {
    fontFamily: 'Roboto',
  },
  flexMarginTop: {
    marginTop: 10,
  },
  label: {
    fontFamily: 'Roboto',
    width: '40%',
  },
  value: {
    width: '60%',
  },
  currency: { textAlign: 'right', width: '85%' },

  textArea: {
    borderStyle: 'solid',
    borderWidth: 1,
    minHeight: '100%',
    padding: 5,
  },
  img: {
    width: 40,
    height: 40,
    objectFit: 'cover',
    textAlign: 'right',
  },

  BlueButton: {
    padding: '0.375rem 0.75rem',
    marginBottom: '16px',
    fontSize: '1rem',
    lineHeight: '1.5',
    borderRadius: '0.3rem',
    color: '#fff',
    backgroundColor: '#007bff',
    borderColor: '#007bff',
    // display: 'inline-block',
    fontWeight: 400,
    textAlign: 'center',
    verticalAlign: 'middle',
    border: '1px solid transparent',
    textDecoration: 'none',
    transition: 'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
  },
});

export default PDFStyles;
