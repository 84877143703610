import DagApiConfigsService from "Services/DagApiConfigsService";
import { call, put } from "redux-saga/effects";
import {
  getDagApiConfigByIdError,
  getDagApiConfigByIdSuccess,
  getDagApiConfigDropdownError,
  getDagApiConfigDropdownSuccess,
  getDagApiConfigError,
  getDagApiConfigSuccess,
  updateDagApiConfigError,
  updateDagApiConfigSuccess,
} from "../Actions/DagApiConfigsAction";
import { toast } from "react-toastify";
import history from "../../../Utils/History";

export function* onGetDagApiConfigs() {
  try {
    let response = yield call(DagApiConfigsService.retrieveDagApiConfigs);
    yield put(getDagApiConfigSuccess(response));
  } catch (err) {
    yield put(getDagApiConfigError(err));
  }
}

export function* onGetDagApiConfigById(action: any) {
  try {
    let response = yield call(DagApiConfigsService.retrieveDagApiConfigById, action.dagApiConfigId);
    yield put(getDagApiConfigByIdSuccess(response));
  } catch (err) {
    yield put(getDagApiConfigByIdError(err));
  }
}

export function* onUpdateDagApiConfig(action: any) {
  try {
    let response = yield call(DagApiConfigsService.updateDagApiConfig, action.dagApiConfigId, action.payload);
    yield put(updateDagApiConfigSuccess(response));
    toast.success("Successfully Updated Dag Api Config");
  } catch (err) {
    yield put(updateDagApiConfigError(err));
    toast.error("Could Not Update Dag Api Config");
  } finally {
    yield history.push("/dag/api-config");
  }
}

export function* onGetDagApiConfigDropdown(action: any) {
  try {
    let response = yield call(DagApiConfigsService.retrieveDagApiConfigDropdown, action.dagApiConfigId);
    yield put(getDagApiConfigDropdownSuccess(response));
  } catch (err) {
    yield put(getDagApiConfigDropdownError(err));
  }
}
