import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../../Common/PageHeader';
import { ChannelRollup } from './ChannelRollup';
import { LobRollup } from './LobRollup';
import { LobProduct } from './LobProduct';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MpaService from 'Services/MpaService';
import { Box, FormGroup, FormLabel, Button, Stack} from '@mui/material';
import {  Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { BomPipeline } from '../Common/BomPipeline';
import InformationPopup from '../Common/InformationPopup';
import FormInfoService from '../../../Services/FormInfoService';

export const BudgetValidationContainer = (props: any) => {

  const [lobRollupisloaded, setLobRollupisloaded] = useState<Boolean>(false);
  const [lobProductisloaded, setLobProductisloaded] = useState<Boolean>(false);
  const [fiscalList, setFiscalList] = useState<Array<String>>([]);
  const [selectedFiscal, setSelectedFiscal] = useState<String>(new Date().getMonth()+1>=11?
                                                              "FY-" + (new Date().getFullYear()+1).toString()
                                                              :"FY-"+ new Date().getFullYear().toString());
  const [selectedDollar, setSelectedDollar] = useState<String>("Gross");
  const [fiscalYearisloaded, setFiscalYearisloaded] = useState<Boolean>(false);
  const [dropdownOpenYear, setDropdownOpenYear] = useState(false);
  const [dropdownOpenDollar, setDropdownOpenDollar] = useState(false);
  const [channelOptions, setChannelOptions] = useState<Array<any>>([{}]);
  const [channelOptionsisloaded, setChannelOptionsisloaded] = useState<Boolean>(false);

  const toggleYear = () => setDropdownOpenYear(prevState => !prevState);
  const toggleDollar = () => setDropdownOpenDollar(prevState => !prevState);
  const dollarOptions = ["Gross", "Net"];

  //get fiscal years
  useEffect(() => {    
    async function getFiscalYear() {
      try{
        const response = await MpaService.retrieveMpas();
        const fiscalList = response.map(item => {
          return item.fiscal
        })
        const uniqueFiscal = fiscalList.filter((value, index, self) => self.indexOf(value) === index);
        setFiscalList(uniqueFiscal);
        setFiscalYearisloaded(true)

      } catch(ex){  
        sessionStorage.removeItem("authenticationState");
        sessionStorage.removeItem("tableConfig");
        window.location.reload(); 
      } 
    }
    setFiscalYearisloaded(false)
    getFiscalYear();

    //get all channels list from form_lob table
    async function getChannelOptions() {
      try{
        const response = await FormInfoService.retrieveAllChannelOptions();
        let tempChannel = [];
        response.map((channel) => tempChannel.push({checked: true, name: channel}));

        setChannelOptions(tempChannel);
        setChannelOptionsisloaded(true);
      } catch(ex){  
        sessionStorage.removeItem("authenticationState");
        sessionStorage.removeItem("tableConfig");
        window.location.reload(); 
      } 
    }
    setChannelOptionsisloaded(false);
    getChannelOptions();
  }, []);

  const selectAll = () =>{
    let tempChannelOptions = channelOptions.map(channel => {return {...channel}});
    tempChannelOptions.map((channel) => {
      channel['checked'] = true
    })
    setChannelOptions(tempChannelOptions);    
  }

  const clearAll = () =>{
    let tempChannelOptions = channelOptions.map(channel => {return {...channel}});
    tempChannelOptions.map((channel) => {
      channel['checked'] = false
    })
    setChannelOptions(tempChannelOptions);
  }

  const handleChannelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempChannelOptions = channelOptions.map(channel => {return {...channel}});
    tempChannelOptions.map((channel) => {
      if(channel['name'] === event.target.name){
        channel['checked'] = event.target.checked
      }
    })
    setChannelOptions(tempChannelOptions);
  };

  const handleYearChange = (e:any) => {
    setSelectedFiscal(e.innerHTML);
  }

  const handleDollarChange = (e:any) => {
    setSelectedDollar(e.innerHTML);
  }

  return (
    <>
      <PageHeader>Budget Validation</PageHeader>
      
      {fiscalYearisloaded && channelOptionsisloaded ?
        <Box sx={{ display:'flex', justifyContent:"space-between", marginRight:"60px"}}>
          <div style={{display:"flex", justifyContent:"flex-start"}}>
            <Dropdown isOpen={dropdownOpenYear} toggle={toggleYear} style={{ marginLeft: "40px", marginBottom:"16px"}}>
              <DropdownToggle caret color="primary">
                {selectedFiscal}
              </DropdownToggle>
              <DropdownMenu>
                {fiscalList.map((item,index) => 
                  parseInt(item.slice(3))< 2021?
                  <DropdownItem key={index} onClick={(e)=>handleYearChange(e.target)}>{item} -- BOM data may not be accurate</DropdownItem>
                  :
                  <DropdownItem key={index} onClick={(e)=>handleYearChange(e.target)}>{item}</DropdownItem>)
                }         
              </DropdownMenu>
            </Dropdown>

            <Dropdown isOpen={dropdownOpenDollar} toggle={toggleDollar} style={{ marginLeft: "20px", marginBottom:"16px"}}>
              <DropdownToggle caret color="primary">
                {selectedDollar}
              </DropdownToggle>
              <DropdownMenu>
                {dollarOptions.map((item,index) => 
                  <DropdownItem key={index} onClick={(e)=>handleDollarChange(e.target)}>{item}</DropdownItem>)
                }         
              </DropdownMenu>
            </Dropdown>
            
            <Box sx={{display:'flex', marginLeft:'20px', mb:5, height:150}}>
              <div style={{display:'flex', flexDirection:"column", borderRadius:1,border: '1px solid black',}}>
              <FormLabel sx={{color: "white", backgroundColor:"#006AC3", paddingTop:1,paddingBottom: 1, marginBottom:0,textAlign:"center"}}>Channel</FormLabel>     
                  <div style={{overflowY:"scroll"}}>
                    <FormGroup>
                        <Stack spacing={2} justifyContent="center" direction="row" style={{position:"sticky", top:0, backgroundColor:"White", zIndex:1}}>    
                            <Button size="small" onClick={selectAll}>Select All</Button>
                            <Button size="small" onClick={clearAll}>Clear</Button>
                        </Stack>
                        {channelOptions.map((channel,index) => 
                          <FormControlLabel
                              key={index}
                              label={channel.name}
                              control={<Checkbox checked={channel.checked} name={channel.name} onChange={handleChannelChange} />}
                              sx={{mt:0.5,ml:1,mr:2,height:15}}
                          />  
                        )}
                    </FormGroup>
                  </div>
              </div>
            </Box>

            <InformationPopup />
          </div>
          {/* <BomPipeline /> */}
        </Box>
      :null}

      <ChannelRollup 
        selectedFiscal={selectedFiscal.slice(0,7)} 
        selectedDollar={selectedDollar} 
        channelOptions={channelOptions}
      />

      {/* <Box sx={{display:'flex', alignItems:'center',ml:5, mb:2}}>
        <div style={{display:'flex', alignItems:'center',borderRadius:1,border: '1px solid #006AC3'}}>
          <FormLabel sx={{ fontWeight:'bold', ml:2,mr:2}}>Channel</FormLabel>
            <FormGroup row={true}>
                <FormControlLabel
                  label="Facebook"
                  control={<Checkbox checked={checkedFB} onChange={handleFBChange} />}
                />            
                <FormControlLabel
                  label="SEM"
                  control={<Checkbox checked={checkedSEM} onChange={handleSEMChange} />}
                />
            </FormGroup>
        </div>
      </Box>
      : <div style={{textAlign:"center"}}><CircularProgress /></div>} */}
      <LobRollup 
        lobRollupisloaded={lobRollupisloaded} 
        setLobRollupisloaded={setLobRollupisloaded}
        selectedFiscal={selectedFiscal.slice(0,7)}
        selectedDollar={selectedDollar}
        channelOptions={channelOptions}
      />
      <LobProduct 
        lobProductisloaded={lobProductisloaded} 
        setLobProductisloaded={setLobProductisloaded}
        selectedFiscal={selectedFiscal.slice(0,7)}
        selectedDollar={selectedDollar}
        channelOptions={channelOptions}
      />      
    </>
  )
}
