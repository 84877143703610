import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonToolbar } from 'reactstrap';
import { toast } from 'react-toastify';

import { BudgetOrdersState } from '../Models/BudgetOrders';
import { createDefaultPublishLogsState, PublishLogsState } from '../Models/PublishLogs';
import { createDefaultPipelineState, PipelineState } from '../Models/PipelineStatus';

import BudgetOrdersService from '../Services/BudgetOrdersService';
import PublishLogsService from '../Services/PublishLogsService';
import PipelineStatusService from '../Services/PipelineStatusService';

import { downloadFile } from '../../../Utils/DownloadFile';
import { updateLoadableOnError, updateLoadableOnStart, updateLoadableOnSuccess } from '../../../State/Common/Loadable';

interface Props{
  history: any
  budgetOrdersState: BudgetOrdersState,
}

export const AuxiliaryToolbar = (props: Props) => {
  const {budgetOrdersState, history} = props;

  const [budgetOrders, setBudgetOrders] = useState<BudgetOrdersState>(budgetOrdersState);

  const [publishLogs, setPublishLogs] = useState<PublishLogsState>(createDefaultPublishLogsState());
  const [pipeline, setPipeline] = useState<PipelineState>(createDefaultPipelineState());

  const PERIOD = 10000;
  let getPipelineStatusTimeout: any;

  const prevRef: any = useRef();

  // useEffect(() => {
  //   let isCancelled = false;

  //   if (!prevRef.current) {
  //     // Component did mount
  //     getPipelineStatusPeriodically(isCancelled);
  //     prevRef.current = {
  //       hasPipelineStatusLoaded: pipeline.getPipelineStatusLoadable,
  //       isPipelineRunnable: pipeline.isRunnable,
  //       pipelineStatusText: pipeline.status
  //     }
  //   } else {
  //     // Component did update
  //     const prevHasPipelineLoaded = prevRef.current.hasPipelineStatusLoaded;
  //     const prevIsPipelineRunnable = prevRef.current.isPipelineRunnable;
  //     const prevPipelineStatusText = prevRef.current.pipelineStatusText;
      
  //     if ((!prevIsPipelineRunnable && pipeline.isRunnable)
  //       && (!prevHasPipelineLoaded
  //       && pipeline.getPipelineStatusLoadable
  //       && prevPipelineStatusText === 'running'
  //       && pipeline.status === 'success'
  //     )) {
  //       history.push('/bom/budget-orders');
  //     }
  //   }

  //   // Component will unmount
  //   return () => {
  //     clearTimeout(getPipelineStatusTimeout);
  //     getPipelineStatusPeriodically = () => {return Promise.resolve()};
  //     isCancelled = true;
  //   }
  // }, []);

  const exportCsv = async () => { 

    setBudgetOrders({...budgetOrders, getBudgetOrdersCsvLoadable:updateLoadableOnStart()});
    try{
      let budgetOrdersCsvBlob = await BudgetOrdersService.retrieveBudgetOrdersCsv();
      let budgetOrdersCsvFilename = `budget-orders_${new Date().getTime()}.csv`;
      downloadFile(budgetOrdersCsvBlob, budgetOrdersCsvFilename);

      setBudgetOrders({...budgetOrders, getBudgetOrdersCsvLoadable:updateLoadableOnSuccess()});

    } catch(ex){
      setBudgetOrders({...budgetOrders, getBudgetOrdersCsvLoadable:updateLoadableOnError(ex.message)});
    }
  
  
  }

  const exportPublishLogs = async() => { 

    setPublishLogs({...publishLogs, getPublishLogsLoadable:updateLoadableOnStart()});
    try{
      let publishLogsCsvBlob = await PublishLogsService.retrievePublishLogs();
      let publishLogsCsvFilename = `publish-logs_${new Date().getTime()}.csv`;
      downloadFile(publishLogsCsvBlob, publishLogsCsvFilename);

      setPublishLogs({...publishLogs, getPublishLogsLoadable:updateLoadableOnSuccess()});

    } catch(ex){
      toast.error('Could not download publish logs');
      setPublishLogs({...publishLogs, getPublishLogsLoadable:updateLoadableOnError(ex.message)});
    }
  };

  const runReport = async () => {

    setPipeline({...pipeline, isRunnable: false, lastActionTime: +new Date(), updatePipelineStatusLoadable: updateLoadableOnStart()});
    try{
      await PipelineStatusService.updatePipelineStatus();
      setPipeline({...pipeline, isRunnable: false, updatePipelineStatusLoadable: updateLoadableOnSuccess()});
    } catch(ex){
      setPipeline({...pipeline, isRunnable: true, updatePipelineStatusLoadable:updateLoadableOnError(ex.message)});
    }
  }

  let getPipelineStatusPeriodically = async (isCancelled = false) => {

    const isRunnable = (+new Date() - pipeline.lastActionTime) > PERIOD * 3;
    try{
      const response = await PipelineStatusService.retrievePipelineStatus();
      // setPipeline({...pipeline, status: response.status, startDate:response.start_date, isRunnable: isRunnable ? response.status !== 'running' : false, getPipelineStatusLoadable: updateLoadableOnSuccess()});
      if(!isCancelled){
        setPipeline((prevState:PipelineState)=>{
          if(prevState.status !== response.status){
            return {...pipeline, status: response.status, startDate:response.start_date, isRunnable: isRunnable ? response.status !== 'running' : false, getPipelineStatusLoadable: updateLoadableOnSuccess()}
          }
          return {...prevState}
        })
      }
    } catch(ex){
      setPipeline({...pipeline, status: 'Failed to update', isRunnable: false, getPipelineStatusLoadable:updateLoadableOnError(ex.message)});
    }
    getPipelineStatusTimeout = setTimeout(getPipelineStatusPeriodically, PERIOD);
  }
  
  return (
    <ButtonToolbar style={{'justifyContent': 'flex-end', 'padding': '1rem 0'}}>
      <Button color="primary" tag={Link} to="/bom/table-configuration">
      {/* <Button color="primary" onClick={()=>{history.push({pathname:"/bom/table-configuration", state:"/bom/budget-orders"})}}> */}
        Columns
      </Button>
      <Button
        className="ml-2"
        color="primary"
        disabled={budgetOrders.getBudgetOrdersCsvLoadable.isLoading}
        onClick={exportCsv}
      >
        Export CSV
      </Button>
      <Button
        className="ml-2"
        color="primary"
        disabled={publishLogs.getPublishLogsLoadable.isLoading}
        onClick={exportPublishLogs}
      >
        Export Publish Log
      </Button>
      {/* <Button
        className="ml-2"
        color="primary"
        disabled={!pipeline.isRunnable}
        // disabled={!isPipelineRunnable}
        onClick={runReport}
      >
        Run Report
      </Button>
      <span className='text text-primary ml-2 mt-auto mb-auto'>
        Status: {`${pipeline.status} ${pipeline.startDate}`}
      </span> */}
    </ButtonToolbar>
  );
}
