import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ApiConfigDashboard from "./ApiConfigDashboard";
import { AppPage, PageHeader, WithLoading } from "../../../Common";
import { getDagApiConfigsState } from "State/DagApiConfig/Selectors/DagApiConfigsSelectors";
import { getDagApiConfigs } from "State/DagApiConfig/Actions/DagApiConfigsAction";

// const ApiConfigDashboardWithLoading = WithLoading(ApiConfigDashboard);
const ApiConfigDashboardWithLoading = WithLoading(ApiConfigDashboard);

const ApiConfigDashboardContainer = () => {
  const dispatch = useDispatch();
  const dagApiConfigsState = useSelector(getDagApiConfigsState);

  useEffect(() => {
    if (!dagApiConfigsState.getApiConfigListLoadable.isReceived) {
      dispatch(getDagApiConfigs());
    }
  }, [dagApiConfigsState.getApiConfigListLoadable.isReceived, dispatch]);

  return (
    <>
      <PageHeader>API Config Dashboard</PageHeader>
      <AppPage>
        <ApiConfigDashboardWithLoading dagConfigList={dagApiConfigsState.entities} loadable={dagApiConfigsState.getApiConfigListLoadable} />
      </AppPage>
    </>
  );
};

export default ApiConfigDashboardContainer;
