import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { GridDropdown } from '../../../Common';
import { DropdownItem } from 'reactstrap';

const FiscalYearDropdown = (props: any) => {
  const [isFiscalYearDropdownOpen, setIsFiscalYearDropdownOpen] = useState(false);

  const toggleFiscalYearDropdown = () => setIsFiscalYearDropdownOpen(prevState => !prevState);

  return (
    <GridDropdown
      gridSize={props.gridSize}
      inputLabel="Fiscal Year:"
      isDisabled={props.isDisabled}
      isDropdownOpen={isFiscalYearDropdownOpen}
      handleToggle={toggleFiscalYearDropdown}
      value={props.fiscalYear}
    >
    <DropdownItem value="F24" onClick={props.handleFiscalYearChange}>F24</DropdownItem>
    <DropdownItem value="F23" onClick={props.handleFiscalYearChange}>F23</DropdownItem>
    <DropdownItem value="F22" onClick={props.handleFiscalYearChange}>F22</DropdownItem>
    <DropdownItem value="F21" onClick={props.handleFiscalYearChange}>F21</DropdownItem>
    <DropdownItem value="F20" onClick={props.handleFiscalYearChange}>F20</DropdownItem>
    </GridDropdown>
  );
};

FiscalYearDropdown.propTypes = {
  gridSize: PropTypes.number,
  fiscalYear: PropTypes.string,
  handleFiscalYearChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default FiscalYearDropdown;
