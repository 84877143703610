import { BudgetOrdersInterface } from "./BudgetOrders";

export interface BudgetOrderEditingContextState {
  budgetOrderEditingMode: string,
  budgetOrderToEditId: string,
  budgetOrdersToReview: Array<BudgetOrdersInterface>,
};

export const createDefaultBudgetOrderEditingContextState = (): BudgetOrderEditingContextState => {
  return {
    budgetOrderEditingMode: null,
    budgetOrderToEditId: null,
    budgetOrdersToReview: []
  }
};

export const BUDGET_ORDER_EDITING_MODE = {
  NO_SUGGESTIONS: 'NO_SUGGESTIONS',
  WITH_SUGGESTIONS: 'WITH_SUGGESTIONS',
  REBALANCING: 'REBALANCING'
};

export const BUDGET_ORDER_ACTION_MAP = {
  'check': BUDGET_ORDER_EDITING_MODE.REBALANCING,
  'plus': BUDGET_ORDER_EDITING_MODE.WITH_SUGGESTIONS,
  'edit': BUDGET_ORDER_EDITING_MODE.NO_SUGGESTIONS
};
