import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { Redirect } from "react-router-dom";

import { EditBudgetOrders } from './EditBudgetOrders';
import { PageHeader } from '../../../Common/PageHeader';
import { WithLoading } from '../../../Common/WithLoading';

import { BudgetOrdersInterface, BudgetOrdersState, buildBudgetOrders, getEditableColumns } from '../Models/BudgetOrders';
import {  createDefaultBudgetOrdersViewState } from '../Models/BudgetOrdersView';

import BudgetOrdersService from '../Services/BudgetOrdersService';
import { updateLoadableOnError, updateLoadableOnStart, updateLoadableOnSuccess } from '../../../State/Common/Loadable';


const EditBudgetOrdersWithLoadable = WithLoading(EditBudgetOrders);

export const EditBudgetOrdersContainer = (props: any) => {
  const { history, location } = props;
  const prevLocationRef: any = useRef();

  let budgetOrderClientIdToEdit = ""
  let tempBudgetOrdersState = null;
  let budgetOrderEditingMode = ""

  if(location.state){
    budgetOrderClientIdToEdit = location.state.clientId;
    tempBudgetOrdersState = location.state.budgetOrdersState;
    budgetOrderEditingMode = location.state.editeMode;
  }

  const [budgetOrdersState, setBudgetOrdersState] = useState<BudgetOrdersState>(tempBudgetOrdersState);
  const [budgetOrdersList,setBudgetOrdersList] = useState<Array<BudgetOrdersInterface>>(budgetOrdersState?budgetOrdersState.entities:[]);
  

  const selectedBudgetOrderCategory = budgetOrdersList.length > 0 ?
                                      budgetOrdersList.find((budgetOrder: BudgetOrdersInterface) => budgetOrder['clientID'] === budgetOrderClientIdToEdit)['category']
                                      : "";
  

  const getBudgetOrdersViewFromQueryString = (urlQueryString: string): any => {
    return urlQueryString ?
      queryString.parse(urlQueryString) :
      {...createDefaultBudgetOrdersViewState(), selectedCategory: selectedBudgetOrderCategory };
  }

  //get Budget orders
  useEffect(() => {
    async function getBudgetOrder() {

      setBudgetOrdersState({...budgetOrdersState, getBudgetOrdersLoadable:updateLoadableOnStart()});
      try{
         
          const response = await BudgetOrdersService.retrieveBudgetOrders(getBudgetOrdersViewFromQueryString(location.search));
          setBudgetOrdersState({...budgetOrdersState, entities: buildBudgetOrders(response['rowData']), entitiesColumns: response['headers'], getBudgetOrdersLoadable:updateLoadableOnSuccess()});
          setBudgetOrdersList(buildBudgetOrders(response['rowData']));
          
      } catch(ex){
        setBudgetOrdersState({...budgetOrdersState, getBudgetOrdersLoadable:updateLoadableOnError(ex.message)});
      }
      
    }
    if(location.state){
      if (!prevLocationRef.current) {
        // Component did mount
        getBudgetOrder();
        prevLocationRef.current = location;
      } else {
        // Component did update
        const prevLocation = prevLocationRef.current;
        
        if (prevLocation.search !== location.search) {
          getBudgetOrder();
        }
      }
    }
  }, [location,prevLocationRef.current,budgetOrdersList]);

  if(location.state){
  return (
    <>
      <PageHeader>Edit Budget Orders</PageHeader>
      <EditBudgetOrdersWithLoadable
        areBudgetOrdersBeingUpdated={
          budgetOrdersState.updateBudgetOrdersLoadable.isLoading ||
          budgetOrdersState.clearBudgetOrdersLoadable.isLoading
        }
        budgetOrderEditingMode={budgetOrderEditingMode}
        budgetOrderClientIdToEdit={budgetOrderClientIdToEdit}
        budgetOrders={budgetOrdersList}
        history={history}
        loadable={budgetOrdersState.getBudgetOrdersLoadable}
        tableConfiguration={getEditableColumns(budgetOrdersState.entitiesColumns)}
        selectedBudgetOrderCategory={selectedBudgetOrderCategory}
        budgetOrdersState={budgetOrdersState}
      />
    </>
  )} else {
    return <Redirect to='/bom/budget-orders' />
  }
};
