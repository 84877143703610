import React, { createContext, Dispatch, useState } from 'react';

interface ModalsContextInterface {
  isConfirmationModalOpen: boolean;
  isLogoutModalOpen: boolean;
  isMpaWarningModalOpen: boolean;
  isDeleteChannelConfirmationModalOpen: boolean;
  handleToggleConfirmationModal: Dispatch<any>;
  handleToggleLogoutModal: Dispatch<any>;
  handleToggleMpaWarningModal: Dispatch<any>;
  handleToggleDeleteChannelConfirmationModal: Dispatch<any>;
}

export const ModalsContext = createContext<ModalsContextInterface | null>(null);

const { Provider, Consumer } = ModalsContext;

interface ModalsProviderProps {
  children: any
}

export const ModalsProvider = (props: ModalsProviderProps) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isMpaWarningModalOpen, setIsMpaWarningModalOpen] = useState(false);
  const [isDeleteChannelConfirmationModalOpen, setIsDeleteChannelConfirmationModalOpen] = useState(false);

  const handleToggleConfirmationModal = () => setIsConfirmationModalOpen(prevState => !prevState);
  const handleToggleLogoutModal = () => setIsLogoutModalOpen(prevState => !prevState);
  const handleToggleMpaWarningModal = () => setIsMpaWarningModalOpen(prevState => !prevState);
  const handleToggleDeleteChannelConfirmationModal = () => setIsDeleteChannelConfirmationModalOpen(prevState => !prevState);

  const modalsState: ModalsContextInterface = {
    isConfirmationModalOpen,
    isLogoutModalOpen,
    isMpaWarningModalOpen,
    isDeleteChannelConfirmationModalOpen,
    handleToggleConfirmationModal,
    handleToggleLogoutModal,
    handleToggleMpaWarningModal,
    handleToggleDeleteChannelConfirmationModal
  };

  return (
    <Provider value={modalsState}>
      {props.children}
    </Provider>
  );
};

export const ModalsConsumer = Consumer;
