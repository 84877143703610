import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { login } from '../State/Authentication/Actions/AuthenticationActions';
import GoogleIcon from 'Assets/GoogleIcon';
const LoginButton = () => {
  const dispatch = useDispatch();

  const handleOnClick = useGoogleLogin({
    onSuccess: (response) => dispatch(login(response['code'])),
    flow: 'auth-code',
  });

  return (
    <button
      id="googleLoginBtn"
      onClick={() => handleOnClick()}
      style={{
        background: 'rgb(66, 133, 244)',
        display: 'inline-flex',
        alignItems: 'center',
        color: 'rgb(255, 255, 255)',
        boxShadow:
          'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px',
        padding: '0px',
        borderRadius: '2px',
        border: '1px solid transparent',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'Roboto, sans-serif',
      }}>
      <div
        style={{
          marginRight: '10px',
          background: 'rgb(255, 255, 255)',
          padding: '10px',
          borderRadius: '2px',
        }}>
        <GoogleIcon />
      </div>
      <span style={{ padding: '10px 10px 10px 0px', fontWeight: 500 }}>
        Sign in with Google
      </span>
    </button>
  );
};
export default LoginButton;
