import { createDefaultLoadable, Loadable } from '../../Common/Loadable';

export interface BudgetPacingState {
  budgetPacingEntities: Array<any>,
  budgetPacingMetricsEntities: Array<any>,
  getBudgetPacingListLoadable: Loadable,
  getBudgetPacingMetricsListLoadable: Loadable
}

export function createDefaultBudgetPacingState(): BudgetPacingState {
  return {
    budgetPacingEntities: [],
    budgetPacingMetricsEntities: [],
    getBudgetPacingListLoadable: createDefaultLoadable(),
    getBudgetPacingMetricsListLoadable: createDefaultLoadable()
  }
}