import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExcludeCidForm from '../Common/ExcludeCidForm';

import { isEqual, pick, keys, values, filter, union } from 'lodash';
import { ExcludeCidFormInterface } from '../../../Models/ExcludeCidFormInterface';
import { updateSelectedExcludeId } from '../../../State/ExcludeCids/Actions/ExcludeCidsActions';
import { getUserPermissions } from '../../../State/Authentication/Selectors/AuthenticationSelectors';

import { ConfirmationModal } from '../../../Common';

import { ModalsConsumer } from '../../../Contexts/ModalsContext';
import { toast } from 'react-toastify';

const EditExcludeCid = (props: any) => {

    const dispatch = useDispatch();

    const [excludedCidData, setExcludedCidData] = useState<ExcludeCidFormInterface>(props.selectedExcludedCidData);
    const [selectedExcludeId, setSelectedExcludeId] = useState(0);

    const userPermissions = useSelector(getUserPermissions);

    useEffect(() => {
        setExcludedCidData(props.selectedExcludedCidData);
    }, [props.selectedExcludedCidData]);

    const buildUpdateExcludeCidConfirmationText = () => {
        const customerId = "'" + excludedCidData.customerId + "'";
        const accountName = "'" + excludedCidData.account_name + "'";

        return `CID ${customerId} with the account name ${accountName} will be updated in the exclude list. Do you want to proceed?`;
    };

    const handleUpdateExcludeCid = (event: any, openUpdateConfirmationModal: any, excludeId: any) => {
        event.preventDefault();
        setSelectedExcludeId(excludeId);
        
        // remove hyphens from Customer Id before inserting it to cids_to_exclude table
        let regex = new RegExp('-', 'g');
        excludedCidData.customerId = excludedCidData.customerId.replace(regex, '');

        if(isEqual(excludedCidData, props.selectedExcludedCidData)) {
            toast.error("No changes found to update");
        } else {
            openUpdateConfirmationModal();
        }
    };

    const updateExcludeCidAction = () => {    
        if(!isEqual(excludedCidData, props.selectedExcludedCidData)) {
            const excludeCidPayload = {
                excludedCidData: excludedCidData,
                editedBy: props.userName
            };
            dispatch(updateSelectedExcludeId(selectedExcludeId.toString(10), excludeCidPayload));
        }
    };

  return (
    <ModalsConsumer>
      {
        modalsState => {
          return modalsState && (
            <>
              <ExcludeCidForm
                excludedCidData={excludedCidData}
                onSave={handleUpdateExcludeCid}
                openUpdateConfirmationModal={modalsState.handleToggleConfirmationModal}
                setExcludedCidData={setExcludedCidData}
                isSubmitDisabled={
                  (excludedCidData.customerId && excludedCidData.account_name) === "" || props.isSubmitDisabled || !userPermissions.includes('change_mpa')? true : false
                }
                isDisabled={true}
              />
              <ConfirmationModal
                confirmationHeader="Update Exclude CID"
                confirmationText={buildUpdateExcludeCidConfirmationText()}
                executeConfirmationAction={updateExcludeCidAction}
                handleToggleModal={modalsState.handleToggleConfirmationModal}
                isModalOpen={modalsState.isConfirmationModalOpen}
              />
            </>
          )
        }
      }
    </ModalsConsumer>
  )
}

export default EditExcludeCid;

function isNotEqual(excludedCidData: ExcludeCidFormInterface, selectedExcludedCidData: any) {
    throw new Error('Function not implemented.');
}
