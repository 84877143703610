import { GridDropdown } from "Common";
import React, { useCallback, useState } from "react";
import { DropdownItem } from "reactstrap";

interface IExtraSettingDropdown {
  options: string[];
  value: string;
  onSelect: (value: string, index: number) => void;
  itemKeyPrefix: string;
  inputLabel: string;
  gridSize?: number;
}

export default ({ options, value, onSelect, itemKeyPrefix, inputLabel, gridSize = 3 }: IExtraSettingDropdown): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = useCallback(() => {
    setIsOpen((oldValue) => !oldValue);
  }, []);

  return (
    <GridDropdown
      gridSize={gridSize + ""}
      inputLabel={inputLabel}
      isDisabled={false}
      isDropdownOpen={isOpen}
      handleToggle={toggleDropdown}
      value={value}
    >
      {options.map((value, index) => (
        <DropdownItem onClick={() => onSelect(value, index)} key={`${itemKeyPrefix}-${value}-${index}`}>
          {value}
        </DropdownItem>
      ))}
    </GridDropdown>
  );
};
