export const ADD_NEW_ANNUAL_BUDGET = 'ADD_NEW_ANNUAL_BUDGET';
export const ADD_NEW_ANNUAL_BUDGET_SUCCESS = 'ADD_NEW_ANNUAL_BUDGET_SUCCESS';
export const ADD_NEW_ANNUAL_BUDGET_ERROR = 'ADD_NEW_ANNUAL_BUDGET_ERROR';

export const DELETE_SELECTED_ANNUAL_BUDGET = 'DELETE_SELECTED_ANNUAL_BUDGET';
export const DELETE_SELECTED_ANNUAL_BUDGET_SUCCESS = 'DELETE_SELECTED_ANNUAL_BUDGET_SUCCESS';
export const DELETE_SELECTED_ANNUAL_BUDGET_ERROR = 'DELETE_SELECTED_ANNUAL_BUDGET_ERROR';

export const GET_ANNUAL_BUDGET = 'GET_ANNUAL_BUDGET';
export const GET_ANNUAL_BUDGET_SUCCESS = 'GET_ANNUAL_BUDGET_SUCCESS';
export const GET_ANNUAL_BUDGET_ERROR = 'GET_ANNUAL_BUDGET_ERROR';

export const GET_ANNUAL_BUDGETS_CSV = 'GET_ANNUAL_BUDGETS_CSV';
export const GET_ANNUAL_BUDGETS_CSV_SUCCESS = 'GET_ANNUAL_BUDGETS_CSV_SUCCESS';
export const GET_ANNUAL_BUDGETS_CSV_ERROR = 'GET_ANNUAL_BUDGETS_CSV_ERROR';

export const GET_SELECTED_ANNUAL_BUDGET = 'GET_SELECTED_ANNUAL_BUDGET';
export const GET_SELECTED_ANNUAL_BUDGET_SUCCESS = 'GET_SELECTED_ANNUAL_BUDGET_SUCCESS';
export const GET_SELECTED_ANNUAL_BUDGET_ERROR = 'GET_SELECTED_ANNUAL_BUDGET_ERROR';

export const UPDATE_SELECTED_ANNUAL_BUDGET = 'UPDATE_SELECTED_ANNUAL_BUDGET';
export const UPDATE_SELECTED_ANNUAL_BUDGET_SUCCESS = 'UPDATE_SELECTED_ANNUAL_BUDGET_SUCCESS';
export const UPDATE_SELECTED_ANNUAL_BUDGET_ERROR = 'UPDATE_SELECTED_ANNUAL_BUDGET_ERROR';
