import React from 'react';
import PropTypes from 'prop-types';
import { BudgetOrdersInterface } from '../Models/BudgetOrders';

interface Props {
  attribute: string,
  budgetOrder: BudgetOrdersInterface,
  handleContentEditableBlur: (event: any) => void,
  handleContentEditableChange: (event: any) => void,
  handleContentEditableFocus: () => void,
  handleContentEditableKeyPress: (event: any) => void,
}

export const EditableTableCell = (props: Props) => {
  const {
    attribute,
    budgetOrder,
    handleContentEditableBlur,
    handleContentEditableChange,
    handleContentEditableFocus,
    handleContentEditableKeyPress
  } = props;

  return (
    <td>
      <input
        type="text"
        autoComplete="off"
        name={budgetOrder['clientID']}
        onBlur={handleContentEditableBlur}
        onChange={handleContentEditableChange}
        onKeyPress={handleContentEditableKeyPress}
        onFocus={handleContentEditableFocus}
        style={{border: '1px solid #cecece', borderRadius: '5px', textAlign: 'center', width: '100%'}}
        value={budgetOrder[attribute] + ""}
      />
    </td>
  );
};

EditableTableCell.defaultProps = {
  handleContentEditableBlur: () => {},
  handleContentEditableChange: () => {},
  handleContentEditableFocus: () => {},
  handleContentEditableKeyPress: () => {},
  onEdit: () => {},
};

EditableTableCell.propTypes = {
  attribute: PropTypes.string.isRequired,
  budgetOrder: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  handleContentEditableBlur: PropTypes.func.isRequired,
  handleContentEditableChange: PropTypes.func.isRequired,
  handleContentEditableFocus: PropTypes.func.isRequired,
  handleContentEditableKeyPress: PropTypes.func.isRequired,
};
