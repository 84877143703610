import {
  ADD_NEW_LOB, ADD_NEW_LOB_SUCCESS, ADD_NEW_LOB_ERROR,
  DELETE_SELECTED_LOB, DELETE_SELECTED_LOB_SUCCESS, DELETE_SELECTED_LOB_ERROR,
  GET_LOB, GET_LOB_SUCCESS, GET_LOB_ERROR,
  GET_LOBS_CSV, GET_LOBS_CSV_SUCCESS, GET_LOBS_CSV_ERROR,
  GET_SELECTED_LOB, GET_SELECTED_LOB_SUCCESS, GET_SELECTED_LOB_ERROR,
  UPDATE_SELECTED_LOB, UPDATE_SELECTED_LOB_SUCCESS, UPDATE_SELECTED_LOB_ERROR
} from '../ActionTypes/LobActionTypes';

export const addNewLob = (lobData: any) => {
  return {
    type: ADD_NEW_LOB,
    lobData
  };
};

export const addNewLobSuccess = (result: any) => {
  return {
    type: ADD_NEW_LOB_SUCCESS,
    result
  };
};

export const addNewLobError = (error: any) => {
  return {
    type: ADD_NEW_LOB_ERROR,
    error
  };
};

export const deleteSelectedLob = (lobId: any, payload: any) => {
  return {
    type: DELETE_SELECTED_LOB,
    lobId,
    payload
  };
};

export const deleteSelectedLobSuccess = (lobId: any, payload: any) => {
  return {
    type: DELETE_SELECTED_LOB_SUCCESS,
    lobId,
    payload
  };
};

export const deleteSelectedLobError = (error: any) => {
  return {
    type: DELETE_SELECTED_LOB_ERROR,
    error
  };
};

export const getLobs = () => {
  return {
    type: GET_LOB
  };
};

export const getLobSuccess = (result: any) => {
  return {
    type: GET_LOB_SUCCESS,
    result
  };
};

export const getLobError = (error: any) => {
  return {
    type: GET_LOB_ERROR,
    error
  };
};

export const getLobsCsv = () => {
  return {
    type: GET_LOBS_CSV
  };
};

export const getLobSuccessCsv = () => {
  return {
    type: GET_LOBS_CSV_SUCCESS
  };
};

export const getLobErrorCsv = (error: any) => {
  return {
    type: GET_LOBS_CSV_ERROR,
    error
  };
};

export const getSelectedLob = (lobId: string) => {
  return {
    type: GET_SELECTED_LOB,
    lobId
  };
};

export const getSelectedLobSuccess = (result: any) => {
  return {
    type: GET_SELECTED_LOB_SUCCESS,
    result
  };
};

export const getSelectedLobError = (error: any) => {
  return {
    type: GET_SELECTED_LOB_ERROR,
    error
  };
};

export const updateSelectedLob = (lobId: string, lobData: any) => {
  return {
    type: UPDATE_SELECTED_LOB,
    lobId,
    lobData
  };
};

export const updateSelectedLobSuccess = (result: any) => {
  return {
    type: UPDATE_SELECTED_LOB_SUCCESS,
    result
  };
};

export const updateSelectedLobError = (error: any) => {
  return {
    type: UPDATE_SELECTED_LOB_ERROR,
    error
  };
};
