import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { GridDropdown } from '../../../Common';
import { DropdownItem } from 'reactstrap';

const AnnualBudgetStatusDropdown = (props: any) => {
  const [isAnnualBudgetStatusDropdown, setIsAnnualBudgetStatusDropdownOpen] = useState(false);

  const toggleAnnualBudgetStatusDropdown = () => setIsAnnualBudgetStatusDropdownOpen(prevState => !prevState);

  return (
    <GridDropdown
      gridSize={props.gridSize}
      inputLabel="Status:"
      isDisabled={props.isDisabled}
      isDropdownOpen={isAnnualBudgetStatusDropdown}
      handleToggle={toggleAnnualBudgetStatusDropdown}
      placeholder='---------'
      value={props.annualBudgetStatus}
    >
      <DropdownItem value="Calculated" onClick={props.handleAnnualBudgetStatusChange}>
        Calculated
      </DropdownItem>
      <DropdownItem value="Proposed" onClick={props.handleAnnualBudgetStatusChange}>
        Proposed
      </DropdownItem>
      <DropdownItem value="Approved" onClick={props.handleAnnualBudgetStatusChange}>
        Approved
      </DropdownItem>
    </GridDropdown>
  );
};

AnnualBudgetStatusDropdown.propTypes = {
  gridSize: PropTypes.number,
  annualBudgetStatus: PropTypes.string,
  handleAnnualBudgetStatusChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default AnnualBudgetStatusDropdown;
