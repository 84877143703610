import { SET_SELECTED_MPA_ID, SET_SELECTED_LOB } from '../ActionTypes/MpaFormContextActionTypes';

export const setSelectedMpaId = (mpaId: string) => {
  return {
    type: SET_SELECTED_MPA_ID,
    mpaId
  };
};

export const setSelectedLob = (lob: string) => {
  return {
    type: SET_SELECTED_LOB,
    lob
  }
}
