import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import invariant from 'redux-immutable-state-invariant';
import { composeWithDevTools } from 'redux-devtools-extension';
import { throttle } from 'lodash';

import rootSaga from './RootSaga';
import { createRootReducer } from './RootReducer';

import { AppState, buildInitialAppState } from './Common/AppState';
import { loadState, saveState } from '../Utils/SessionStorage';


const invariantMiddleware = invariant();
const sagaMiddleware = createSagaMiddleware();

const middlewareFunctions = [
  sagaMiddleware,
  invariantMiddleware
];

export default function (initialState: AppState) {
  const enhancers = process.env.NODE_ENV === 'production'
    ? applyMiddleware(...middlewareFunctions)
    : composeWithDevTools(applyMiddleware(...middlewareFunctions));

  const preloadedAuthState = loadState() && loadState()['authenticationState'];
  const preloadedState = buildInitialAppState(initialState, preloadedAuthState);

  const store = createStore(createRootReducer(), preloadedState, enhancers);

  store.subscribe(throttle(() => {
    saveState({
      authenticationState: store.getState()['authenticationState']
    })
  }, 100));

  sagaMiddleware.run(rootSaga);

  return store;
};
