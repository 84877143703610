import styled from 'styled-components';
import { PDFDownloadLink } from '@react-pdf/renderer';

const PDFDownloadButton = styled(PDFDownloadLink)`
  background-color: #006AC3 !important;
  border: 1px solid transparent;
  border-radius: 0.3rem;
  color: #fff;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  vertical-align: middle;
`;

export default PDFDownloadButton;
