import React from 'react'

import { GridInput } from '../../../Common';

import {Col, Row} from 'reactstrap';

const MpaSummary = (props: any) => {
  return (
    <>
      <Row>
        <Col xs={12}><h3>Summary</h3></Col>
      </Row>
      <Row>
        <GridInput
          gridSize={3}
          inputType="string"
          inputAria="mpa-number-text"
          inputId="mpa-number-input"
          inputLabel="MPA #:"
          value={props.mpaSummary.mpa_number}
          isReadOnly={true}
          isDisabled={props.isDisabled}
        />
        <GridInput
          gridSize={3}
          inputType="string"
          inputAria="rev-number-text"
          inputId="rev-number-input"
          inputLabel="Rev. #:"
          handleChange={props.handleRevMpaChange}
          value={props.mpaSummary.revision_number}
          isReadOnly={false}
          isDisabled={false}
        />
        <GridInput
          gridSize={3}
          inputType="string"
          inputAria="ini-mpa-number-text"
          handleChange={props.handleInitMapChange}
          inputId="ini-mpa-number-input"
          inputLabel="INI MPA #:"
          value={props.mpaSummary.legacy_mpa}
          isReadOnly={false}
          isDisabled={false}
          required={true}
        />
        <GridInput
          gridSize={3}
          inputType="string"
          inputAria="prepared-by-text"
          inputId="prepared-by-input"
          inputLabel="Prepared By:"
          value={props.mpaSummary.prepared_by}
          isReadOnly={true}
          isDisabled={props.isDisabled}
        />
      </Row>
      <Row>
        <GridInput
          gridSize={12}
          inputType="string"
          inputAria="campaign-text"
          inputId="campaign-input"
          inputLabel="Campaign:"
          value={props.mpaSummary.campaign}
          isReadOnly={true}
          isDisabled={props.isDisabled}
        />
      </Row>
      <Row>
        <GridInput
          gridSize={4}
          inputType="string"
          inputAria="working-funds-text"
          inputId="working-funds-input"
          inputLabel="Working Funds ($):"
          // value={props.mpaSummary.sub_total.toLocaleString('en')}
          value={props.mpaSummary.working_funds_total.toLocaleString('en')}          
          isReadOnly={true}
          isDisabled={props.isDisabled}
        />
        <GridInput
          gridSize={4}
          inputType="string"
          inputAria="commission-text"
          inputId="commission-input"
          inputLabel="Commission ($):"
          value={props.mpaSummary.commission.toLocaleString('en')}
          isReadOnly={true}
          isDisabled={props.isDisabled}
        />
        <GridInput
          gridSize={4}
          inputType="string"
          inputAria="platform-fees-result-text"
          inputId="platform-fees-result-input"
          inputLabel="Platform Fees ($):"
          value={props.mpaSummary.platform_fees.toLocaleString('en')}
          isReadOnly={true}
          isDisabled={props.isDisabled}
        />
      </Row>
      <Row>
        <GridInput
          gridSize={4}
          inputType="string"
          inputAria="net-total-text"
          inputId="net-total-input"
          inputLabel="Net Total ($):"
          value={props.mpaSummary.net_total.toLocaleString('en')}
          isReadOnly={true}
          isDisabled={props.isDisabled}
        />
        <GridInput
          gridSize={4}
          inputType="string"
          inputAria="tax-text"
          inputId="tax-input"
          inputLabel="Tax ($):"
          value={props.mpaSummary.tax.toLocaleString('en')}
          isReadOnly={true}
          isDisabled={props.isDisabled}
        />
        <GridInput
          gridSize={4}
          inputType="string"
          inputAria="grand-total-text"
          inputId="grand-total-input"
          inputLabel="Grand Total ($):"
          value={props.mpaSummary.grand_total.toLocaleString('en')}
          isReadOnly={true}
          isDisabled={props.isDisabled}
        />
      </Row>
    </>
  );
};
export default MpaSummary;
