import ApiService from './ApiService';

export default class ExcludeCidsService {
    static addCidToExclude(excludedCidData: any): Promise<any> {
        return ApiService.post({ relativeUrl: `/exclude-cids/api/exclude-cids`, body: excludedCidData });
    };

    static deleteSelectedExcludeId(excludeId: string, payload: string): Promise<any> {
        return ApiService.delete({ relativeUrl: `/exclude-cids/api/exclude-cids/${excludeId}`, body: payload });
    }

    static retrieveExcludeCids(): Promise<Array<any>> {
        return ApiService.get({ relativeUrl: '/exclude-cids/api/exclude-cids' });
    };

    static retrieveSelectedExcludeId(excludeId: string): Promise<Array<any>> {
        return ApiService.get({ relativeUrl: `/exclude-cids/api/exclude-cids/${excludeId}` });
    };

    static updateSelectedExcludeId(excludeId: string, excludedCidData: any): Promise<any> {
        return ApiService.put({
            relativeUrl: `/exclude-cids/api/exclude-cids/${excludeId}`,
            body: excludedCidData
        });
    };
};