import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const InformationPopup = (props: any) => {
    const [modal, setModal] = useState(false);
    const toggle = () => { setModal(!modal)}

    const single_day = 1000 * 60 * 60 * 24;
    const curr_year_start_dt = new Date(props.selectedFiscal-1, 10, 1); //1st Nov 2021, if current fiscal year is 2022
    const curr_year_end_dt = new Date(props.selectedFiscal, 9, 31);  //31st Oct 2022, if current fiscal year is 2022
    const h1_end_dt = new Date(props.selectedFiscal, 3, 30);  //30 Apr 2022, if current fiscal year is 2022
    const h2_start_dt = new Date(props.selectedFiscal, 4, 1);  //1st May 2022, if current fiscal year is 2022
    const yesterday_dt = new Date(props.selectedFiscal, new Date().getMonth(), new Date().getDate()-1);  //yesterday's date
    let remaining_days_in_h1 = 0;
    let remaining_days_in_h2 = 0;
    let remaining_days_in_fiscal_year = Math.round(curr_year_end_dt.getTime() - yesterday_dt.getTime()) / (single_day); 

    //if the current date is in H1
    if(yesterday_dt >= curr_year_start_dt && yesterday_dt < h1_end_dt) {
        remaining_days_in_h1 = Math.round(h1_end_dt.getTime() - yesterday_dt.getTime()) / (single_day); 
    }

    //if the current date is in H2
    if(yesterday_dt >= h2_start_dt && yesterday_dt < curr_year_end_dt) {
        remaining_days_in_h2 = Math.round(curr_year_end_dt.getTime() - yesterday_dt.getTime()) / (single_day); 
    }

    return (        
        <div>
            {props.currentPage == 'forecast_overview' ? 
            <div>
                <InfoOutlinedIcon onClick={toggle} color='primary' sx={{ marginLeft: "20px", marginBottom:"16px", cursor:"pointer"}}/>
                <Modal size="xl" isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}></ModalHeader>
                    <ModalBody>
                        <h3><b>Known Limitations</b></h3>
                        <ul>
                            <li>MPA Amount: MPA amount for SEM includes amount for Apple Search Ads</li>
                            <li>Tax for US Accounts is 0</li>
                            <li>LOB: For LOB Caribbean, all the MPAs have scheduled start date as 1st November and scheduled end date as 31st October, the calculation for the remaining number of days is considered between the current date and the end date of the current fiscal year.</li>
                        </ul>
                        <h3><b>Calculation Info</b></h3>
                        <ul>                            
                            <li>For other LOBs, the calculation for the remaining number of days is:
                                <ul>
                                    <li>For H1: {remaining_days_in_h1}</li>
                                    <li>For H2: {remaining_days_in_h2}</li>
                                    <li>For current fiscal year: {remaining_days_in_fiscal_year}</li>
                                </ul>
                            </li>                                                        
                        </ul>
                    </ModalBody>
                    <ModalFooter>
                    <Button color="danger" onClick={toggle}>Close Window</Button>
                    </ModalFooter>
                </Modal>
            </div>
            :
            <div>
                <HelpOutlineIcon onClick={toggle} color='primary' sx={{ marginLeft: "20px", marginBottom:"16px", cursor:"pointer"}}/>
                <Modal size="xl" isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}><h3><b>Known Limitations</b></h3></ModalHeader>
                    <ModalBody>                     
                        <ul>                                                    
                            <li>Spend Amount: Spend amount unavailable for Apple Search Ads. For Facebook's spend amount, the amount is the sum of all the amounts from November to the current month</li>
                            <li>LOB: For LOB Caribbean and channel Facebook, all the MPAs are merged into one row with Subtotal, Forecasted Spend and Total Forecasted as the sum of all the values and Actual Spend as the maximum value among the MPAs.</li>
                        </ul>
                    </ModalBody>
                    <ModalFooter>
                    <Button color="danger" onClick={toggle}>Close Window</Button>
                    </ModalFooter>
                </Modal>
            </div>
            }
        </div>
    )
}

export default InformationPopup;