import React from "react";
import { DashboardTable } from "../../../Common";
import { ModalsConsumer } from "../../../Contexts/ModalsContext";

import history from "../../../Utils/History";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IDagApiConfigListDto } from "State/DagApiConfig/Models/DagApiConfigsState";

interface IApiConfigDashboard {
  dagConfigList: IDagApiConfigListDto[];
}

const ApiConfigDashboard = ({ dagConfigList }: IApiConfigDashboard): JSX.Element => {
  return (
    <>
      <ModalsConsumer>
        {(modalsState) => {
          return (
            modalsState && (
              <>
                <DashboardTable style={{width: "50%"}}>
                  <thead>
                    <tr>
                      <th style={{ cursor: "pointer", width: "15%" }}>
                        <b>DAG</b>
                      </th>
                      <th align="left" style={{ width: "10%" }}>
                        <b>Edit</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dagConfigList.map((row) => (
                      <tr key={row.custom_id}>
                        <td align="left">{row.dag_name}</td>
                        <td>
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              history.push(`/dag/api-config/edit/${row.custom_id}`, "/dag/api-config");
                            }}
                            icon="edit"
                            size="sm"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </DashboardTable>
              </>
            )
          );
        }}
      </ModalsConsumer>
    </>
  );
};

export default ApiConfigDashboard;
