import React, {useState} from 'react';
import PropTypes from 'prop-types'

import { GridDropdown, GridInput } from './';
import { DropdownItem } from 'reactstrap';

export const ChannelDropdown = (props: any) => {
  const [isChannelDropdownOpen, setIsChannelDropdownOpen] = useState(false);

  const toggleChannelDropdown = () => setIsChannelDropdownOpen(prevState => !prevState);

  return (
    <GridDropdown
      elemClassname='channel_dropdown'
      gridSize={props.gridSize}
      inputLabel='Channel:'
      isDisabled={props.isDisabled}
      isDropdownOpen={isChannelDropdownOpen}
      handleToggle={toggleChannelDropdown}
      placeholder='---------'
      value={props.channel}
    >
      {props.channelOptions.map((item: string) =>
        <DropdownItem key={item} value={item} onClick={props.handleChannelChange}>
          {item}
        </DropdownItem>
      )}
      {props.currentForm === 'LOB' ? 
      <GridInput
        // gridSize={3}
        inputAria='new-channel-text'
        inputId={"new-channel-input-" + props.channelDropdownIndex}
        inputLabel="Other:"
        inputType="string"
        value={props.channel}
        handleChange={props.handleChannelChange}
      />
      : ''
      }      
    </GridDropdown>
  );
};

ChannelDropdown.propTypes = {
  gridSize: PropTypes.number,
  channel: PropTypes.any,
  channelOptions: PropTypes.array,
  handleChannelChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  channelDropdownIndex: PropTypes.number,
  currentForm: PropTypes.any,
};
