import React from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';

export const MpaWarningModal = (props: any) => {
  const handleConfirmationAction = () => {
    props.handleToggleModal();
    props.executeConfirmationAction();
  };

  const continueToggle = () => {props.setMpaWarningModal(!props.mpaWarningModal); props.handleToggleModal(); }

  return (
    <>
      <Modal isOpen={props.isModalOpen} centered>
        <ModalHeader toggle={props.handleToggleModal}>{props.confirmationHeader}</ModalHeader>
        <ModalBody>
          <p>{props.confirmationText}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirmationAction}>
            Return to Annual Budgets
          </Button>
          <Button color="primary" outline onClick={continueToggle}>
            Continue
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
};
