import React, {useState} from 'react';
import PropTypes from 'prop-types'

import { GridDropdown } from '../../../Common';
import { DropdownItem } from 'reactstrap';

const PhaseNumberDropdown = (props: any) => {
  let phaseNumberItems = [
    {phaseNumber: "1"},
    {phaseNumber: "2"},
    {phaseNumber: "3"},
    {phaseNumber: "4"},
    {phaseNumber: "5"},
    {phaseNumber: "6"},
    {phaseNumber: "7"},
    {phaseNumber: "8"},
    {phaseNumber: "9"},
    {phaseNumber: "10"},
  ];

  const [isPhaseNumberDropdownOpen, setIsPhaseNumberDropdownOpen] = useState(false);

  const togglePhaseNumberDropdown = () => setIsPhaseNumberDropdownOpen(prevState => !prevState);

  return (
    <GridDropdown
      inputLabel="Phase Number:"
      isDisabled={props.isDisabled}
      isDropdownOpen={isPhaseNumberDropdownOpen}
      handleToggle={togglePhaseNumberDropdown}
      placeholder="---------"
      value={props.phaseNumber}
    >
      {phaseNumberItems.map((item: any) =>
        <DropdownItem key={item.phaseNumber} value={item.phaseNumber} onClick={props.handlePhaseNumberChange}>
          {item.phaseNumber}
        </DropdownItem>
      )}
    </GridDropdown>
  );
};

PhaseNumberDropdown.propTypes = {
  phaseNumber: PropTypes.any,
  handlePhaseNumberChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default PhaseNumberDropdown;
