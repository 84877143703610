import React from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, ButtonToolbar, Table} from 'reactstrap';

import { CheckboxInput } from '../Common/CheckboxInput'

import { BUDGET_ORDER_ATTRIBUTE_MAP } from '../Models/BudgetOrderAttributeMap';
import { TableConfigurationInterface } from '../Models/TableConfiguration';

const createTableConfigRow = (tableConfig: TableConfigurationInterface, handleUpdateSingleField: (attribute: string) => void, isDisabled: boolean) => {
  return function (attribute: string) {
    return (
      <tr key={attribute}>
        <td>
          <CheckboxInput
            name={attribute}
            checked={tableConfig[attribute]}
            disabled={isDisabled}
            handleCheckboxToggle={handleUpdateSingleField}
          />
        </td>
        <td style={{textAlign: 'left'}}>{BUDGET_ORDER_ATTRIBUTE_MAP[attribute].name}</td>
      </tr>
    );
  }
};

interface Props{
  disableForm: boolean,
  handleUpdateTableConfig: () => void,
  handleUpdateSingleField: (attribute: string) => void,
  handleUpdateAllFields: (displayAllFields: boolean) => void,
  handleCancel: () => void,
  tableConfig: TableConfigurationInterface
}

export const TableConfigurationForm = (props: Props) => {
  const {
    disableForm,
    handleUpdateTableConfig,
    handleUpdateSingleField,
    handleUpdateAllFields,
    handleCancel,
    tableConfig
  } = props;

  return (
    <>
      <ButtonToolbar
        className="justify-content-center"
        style={{'padding': '1rem', 'textAlign': 'left'}}
      >
        <Button
          className="mr-2"
          color="secondary"
          disabled={disableForm}
          onClick={() => { handleUpdateAllFields(true); }}
        >
          All
        </Button>
        <Button
          className="mr-2"
          color="secondary"
          disabled={disableForm}
          onClick={() => { handleUpdateAllFields(false); }}
        >
          None
        </Button>
        <ButtonGroup>
          <Button
            color="primary"
            disabled={disableForm}
            onClick={handleUpdateTableConfig}
          >
            Apply
          </Button>
          <Button
            color="secondary"
            disabled={disableForm}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
      <Table style={{width: '300px', margin: '1rem auto'}}>
        <thead>
          <tr>
            <th>Show</th>
            <th style={{textAlign: 'left'}}>Column Name</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(tableConfig).map(createTableConfigRow(tableConfig, handleUpdateSingleField, disableForm), this)}
        </tbody>
      </Table>
    </>
  );
};

TableConfigurationForm.propTypes = {
  disableForm: PropTypes.bool.isRequired,
  handleUpdateTableConfig: PropTypes.func.isRequired,
  handleUpdateSingleField: PropTypes.func.isRequired,
  handleUpdateAllFields: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  tableConfig: PropTypes.object.isRequired
};
