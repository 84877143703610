export const BUDGET_ORDER_ATTRIBUTE_TYPE = {
  currency: "currency",
  number: "number",
  progress: "progress",
  string: "string"
};

const currency = BUDGET_ORDER_ATTRIBUTE_TYPE.currency;
const number = BUDGET_ORDER_ATTRIBUTE_TYPE.number;
const progress = BUDGET_ORDER_ATTRIBUTE_TYPE.progress;
const string = BUDGET_ORDER_ATTRIBUTE_TYPE.string;


// use alphabetical order
export const BUDGET_ORDER_ATTRIBUTE_MAP = {
  "accountName": { name: "Account Name", type: string, isEditable: false, width: "9rem" },
  "activeBudgetOrderAmount": { name: "Active Budget", type: currency, isEditable: false, width: "7rem" },
  "budgetOrderId": { name: "Budget Order ID", type: string, isEditable: false, width: "7rem" },
  "budgetRatio": { name: "Budget Ratio", type: string, isEditable: false, width: "7rem" },
  "budgetSpentPercent": { name: "% of Budget Spent", type: string, isEditable: false, width: "7rem" },
  "category": { name: "Category", type: string, isEditable: false, width: "7rem" },
  "clientID": { name: "Client ID", type: string, isEditable: false, width: "7rem" },
  "commitBudget": { name: "New Budget", type: currency, isEditable: false, width: "7rem" },
  "commitDarkDate": { name: "New Dark Date", type: number, isEditable: false, width: "7rem" },
  "commitWeeksLeft": { name: "New Weeks Left", type: number, isEditable: false, width: "5rem" },
  "darkDate": { name: "Dark Date", type: string, isEditable: false, width: "7rem" },
  "engine": { name: "Search Engine", type: string, isEditable: false, width: "6rem" },
  "expectedSpent": { name: "Expected Spent", type: currency, isEditable: false, width: "7rem"},
  "firstDayOfLastMonth": { name: "First Day of Last Month", type: string, isEditable: false, width: "7rem" },
  "firstDayOfLastWeek": { name: "First Day of Last Week", type: string, isEditable: false, width: "7rem" },
  "forecastGapPercent": { name: "Forecast Gap %", type: number, isEditable: false, width: "7rem" },
  "forecastGap": { name: "Forecast Gap", type: currency, isEditable: false, width: "7rem" },
  "forecastSpend": { name: "Forecast Spend", type: currency, isEditable: false, width: "7rem" },
  "lineOfBusiness": { name: "Line of Business", type: string, isEditable: false, width: "6rem" },
  "MPA_budget_all":{ name: "MPA Budget (All)", type: currency, isEditable: false, width: "7rem"},
  "MPA_budget_category": { name: "MPA Budget (Category)", type: currency, isEditable: false, width: "7rem"},
  "MPA_category": { name: "MPA Category", type: string, isEditable: false, width: "7rem"},
  "mpaStatus": { name: "MPA Status", type: string, isEditable: false, width: "10rem" },
  "newMpaBudgetRequired": { name: "New MPA Required", type: currency, isEditable: false, width: "7rem" },
  "pacingStatus": { name: "Pacing Status", type: string, isEditable: false, width: "7rem" },
  "progress": { name: "Progress Column %", type: number, isEditable: false, width: "7rem" },
  "progressIndicator": { name: "Progress Indicator", type: progress, isEditable: false, width: "7rem" },
  "projectedBudgetPercent": { name: "Projected Budget %", type: number, isEditable: false, width: "7rem" },
  "requiredWeeksRunToEnd": { name: "Weeks Required", type: number, isEditable: false, width: "5rem" },
  "spendLastMonth": { name: "Spend Last Month", type: currency, isEditable: false, width: "7rem" },
  "spendLastWeek": { name: "Spend Last Week", type: currency, isEditable: false, width: "7rem" },
  "suggestedTransferAmount": { name: "Suggested Transfer Amount", type: currency, isEditable: false, width: "7rem" },
  "timeLapsedPercent": { name: "% of Time Lapsed", type: number, isEditable: false, width: "7rem" },
  "totalBudget": { name: "Loaded Budget", type: currency, isEditable: false, width: "7rem" },
  "totalRemaining": { name: "Remaining Budget", type: currency, isEditable: false, width: "7rem" },
  "totalSpend": { name: "Total Spend", type: currency, isEditable: false, width: "7rem" },
  "transferAmount": { name: "Transfer Amount", type: currency, isEditable: true, width: "8rem" },
  "weeksLeft": { name: "Weeks Left", type: number, isEditable: false, width: "5rem" },
};
