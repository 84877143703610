import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ExcludeCidsDashboard from './ExcludeCidsDashboard'

import { getUsername } from '../../../State/Authentication/Selectors/AuthenticationSelectors';
import { getExcludeCidsState } from '../../../State/ExcludeCids/Selectors/ExcludeCidsSelectors';
import { getExcludeCids } from '../../../State/ExcludeCids/Actions/ExcludeCidsActions';

import { AppPage, PageHeader, WithLoading } from '../../../Common';

const ExcludeCidsDashboardWithLoading = WithLoading(ExcludeCidsDashboard);

const ExcludeCidsDashboardContainer = () => {
    const dispatch = useDispatch();
    const userName = useSelector(getUsername);
    const excludeCidsState= useSelector(getExcludeCidsState);

    useEffect(() => {
        if (!excludeCidsState.getExcludeCidsListLoadable.isReceived) {
            dispatch(getExcludeCids());
        }
    }, [excludeCidsState.getExcludeCidsListLoadable.isReceived, dispatch])

    return (
        <>
            <PageHeader>Exclude CIDs</PageHeader>
            <AppPage>
                <ExcludeCidsDashboardWithLoading
                    loadable={excludeCidsState.getExcludeCidsListLoadable}
                    excludeCidsList={excludeCidsState.entities}
                    userName={userName}
                />
            </AppPage>
        </>
    )
}

export default ExcludeCidsDashboardContainer;