import {
  GET_ANCHOR_CIDS, GET_ANCHOR_CIDS_SUCCESS, GET_ANCHOR_CIDS_ERROR,
  GET_DROPDOWN_OPTIONS, GET_DROPDOWN_OPTIONS_SUCCESS, GET_DROPDOWN_OPTIONS_ERROR,
  GET_NEW_MPA_NUMBER, GET_NEW_MPA_NUMBER_SUCCESS, GET_NEW_MPA_NUMBER_ERROR,
  GET_PRODUCT_OPTIONS, GET_PRODUCT_OPTIONS_SUCCESS, GET_PRODUCT_OPTIONS_ERROR,
  GET_CHANNELS_FOR_SELECTED_PRODUCT, GET_CHANNELS_FOR_SELECTED_PRODUCT_SUCCESS, GET_CHANNELS_FOR_SELECTED_PRODUCT_ERROR,
  GET_CHANNEL_OPTIONS, GET_CHANNEL_OPTIONS_SUCCESS, GET_CHANNEL_OPTIONS_ERROR
} from '../ActionTypes/FormInfoActionTypes';

export const getAnchorCids = (lob: string, channel: string, product?: string) => {
  return {
    type: GET_ANCHOR_CIDS,
    lob,
    channel,
    product
  };
};

export const getAnchorCidsSuccess = (result: any) => {
  return {
    type: GET_ANCHOR_CIDS_SUCCESS,
    result
  };
};

export const getAnchorCidsError = (error: any) => {
  return {
    type: GET_ANCHOR_CIDS_ERROR,
    error
  };
};

export const getDropdownOptions = () => {
  return {
    type: GET_DROPDOWN_OPTIONS
  };
};

export const getDropdownOptionsSuccess = (result: any) => {
  return {
    type: GET_DROPDOWN_OPTIONS_SUCCESS,
    result
  };
};

export const getDropdownOptionsError = (error: any) => {
  return {
    type: GET_DROPDOWN_OPTIONS_ERROR,
    error
  };
};

export const getNewMpaNumber = (lob: string) => {
  return {
    type: GET_NEW_MPA_NUMBER,
    lob
  };
};

export const getNewMpaNumberSuccess = (result: any) => {
  return {
    type: GET_NEW_MPA_NUMBER_SUCCESS,
    result
  };
};

export const getNewMpaNumberError = (error: any) => {
  return {
    type: GET_NEW_MPA_NUMBER_ERROR,
    error
  };
};

export const getProductOptions = () => {
  return {
    type: GET_PRODUCT_OPTIONS
  };
};

export const getProductOptionsSuccess = (result: any) => {
  return {
    type: GET_PRODUCT_OPTIONS_SUCCESS,
    result
  };
};

export const getProductOptionsError = (error: any) => {
  return {
    type: GET_PRODUCT_OPTIONS_ERROR,
    error
  };
};

export const getChannelsForSelectedProduct = (product: string) => {
  return {
    type: GET_CHANNELS_FOR_SELECTED_PRODUCT,
    product
  };
};

export const getChannelsForSelectedProductSuccess = (result: any) => {
  return {
    type: GET_CHANNELS_FOR_SELECTED_PRODUCT_SUCCESS,
    result
  };
};

export const getChannelsForSelectedProductError = (error: any) => {
  return {
    type: GET_CHANNELS_FOR_SELECTED_PRODUCT_ERROR,
    error
  };
};

export const getChannelOptions = () => {
  return {
    type: GET_CHANNEL_OPTIONS
  };
};

export const getChannelOptionsSuccess = (result: any) => {
  return {
    type: GET_CHANNEL_OPTIONS_SUCCESS,
    result
  };
};

export const getChannelOptionsError = (error: any) => {
  return {
    type: GET_CHANNEL_OPTIONS_ERROR,
    error
  };
};


