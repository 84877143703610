export const ADD_CID_TO_EXCLUDE_LIST = 'ADD_CID_TO_EXCLUDE_LIST';
export const ADD_CID_TO_EXCLUDE_LIST_SUCCESS = 'ADD_CID_TO_EXCLUDE_LIST_SUCCESS';
export const ADD_CID_TO_EXCLUDE_LIST_ERROR = 'ADD_CID_TO_EXCLUDE_LIST_ERROR';

export const DELETE_SELECTED_EXCLUDE_ID = 'DELETE_SELECTED_EXCLUDE_ID';
export const DELETE_SELECTED_EXCLUDE_ID_SUCCESS = 'DELETE_SELECTED_EXCLUDE_ID_SUCCESS';
export const DELETE_SELECTED_EXCLUDE_ID_ERROR = 'DELETE_SELECTED_EXCLUDE_ID_ERROR';

export const GET_EXCLUDE_CIDS = 'GET_EXCLUDE_CIDS';
export const GET_EXCLUDE_CIDS_SUCCESS = 'GET_EXCLUDE_CIDS_SUCCESS';
export const GET_EXCLUDE_CIDS_ERROR = 'GET_EXCLUDE_CIDS_ERROR';

export const GET_SELECTED_EXCLUDE_ID = 'GET_SELECTED_EXCLUDE_ID';
export const GET_SELECTED_EXCLUDE_ID_SUCCESS = 'GET_SELECTED_EXCLUDE_ID_SUCCESS';
export const GET_SELECTED_EXCLUDE_ID_ERROR = 'GET_SELECTED_EXCLUDE_ID_ERROR';

export const UPDATE_SELECTED_EXCLUDE_ID = 'UPDATE_SELECTED_EXCLUDE_ID';
export const UPDATE_SELECTED_EXCLUDE_ID_SUCCESS = 'UPDATE_SELECTED_EXCLUDE_ID_SUCCESS';
export const UPDATE_SELECTED_EXCLUDE_ID_ERROR = 'UPDATE_SELECTED_EXCLUDE_ID_ERROR';