import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, ButtonToolbar, Table } from 'reactstrap';

import { TableHeader } from '../Common/TableHeader';
import { TableRow } from '../Common/TableRow';

import { BudgetOrdersInterface, BudgetOrdersState } from '../Models/BudgetOrders';
import BudgetOrdersService from '../Services/BudgetOrdersService';
import { updateLoadableOnError, updateLoadableOnStart, updateLoadableOnSuccess } from '../../../State/Common/Loadable';

import { SubmissionBar } from '../Styled/SubmissionBar';

interface Props{
  areBudgetOrdersBeingUpdated: boolean,
  budgetOrdersToReviewList: Array<BudgetOrdersInterface>,
  tableConfig: Array<string>,
  history: any,
  budgetOrdersState: BudgetOrdersState,
  budgetOrderEditingMode: string,
  budgetOrderClientIdToEdit: string
}

export const ReviewBudgetOrders = (props: Props) => {
  const { areBudgetOrdersBeingUpdated, 
          budgetOrdersToReviewList, 
          tableConfig, 
          history, 
          budgetOrdersState,
          budgetOrderEditingMode, 
          budgetOrderClientIdToEdit} = props;

  const [budgetOrders, setBudgetOrders] = useState<BudgetOrdersState>(budgetOrdersState);

  const handleCancel = () => {
    history.push('/bom/budget-orders');
  }

  const handleRebalance = () => {
    history.push({
      pathname:'/bom/edit-budget-orders',
      state:{clientId:budgetOrderClientIdToEdit,editeMode:budgetOrderEditingMode,budgetOrdersState}
    });
  }

  const handleSubmit = async() => {
    setBudgetOrders({...budgetOrders, updateBudgetOrdersLoadable:updateLoadableOnStart()});
    try{
      await BudgetOrdersService.updateBudgetOrders(budgetOrdersToReviewList);
      setBudgetOrders({...budgetOrders, updateBudgetOrdersLoadable:updateLoadableOnSuccess()});
      toast.success('Successfully updated budget orders');
    } catch(ex){
      setBudgetOrders({...budgetOrders, updateBudgetOrdersLoadable:updateLoadableOnError(ex.message)});
      toast.error('Failed to update budget orders');
    } finally{
      history.push('/bom/budget-orders');
    }
  }

  return (
    <div className="App-page">
      <SubmissionBar>
        <Button
          color="primary"
          disabled={areBudgetOrdersBeingUpdated}
          onClick={handleRebalance}
        >
          Rebalance Budget Orders
        </Button>
        <ButtonToolbar>
          <ButtonGroup>
            <Button
              color="primary"
              disabled={areBudgetOrdersBeingUpdated}
              onClick={handleSubmit}
            >
              Publish
            </Button>
            <Button
              color="secondary"
              disabled={areBudgetOrdersBeingUpdated}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </SubmissionBar>
      <div style={{'maxHeight': '480px', 'overflowY': 'scroll'}}>
        <Table bordered style={{'tableLayout': 'fixed'}}>
          <TableHeader tableConfig={tableConfig} />
          <tbody>
            {budgetOrdersToReviewList.map((budgetOrder: BudgetOrdersInterface, index: any) => (
              <TableRow
                areActionsDisabled={true}
                budgetOrder={budgetOrder}
                key={index}
                tableConfiguration={tableConfig}
              />
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}