import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddMpa from './AddMpa';
import { AppPage, PageHeader, WithLoading } from '../../../Common';

import { getMpaState } from '../../../State/Mpa/Selectors/MpaSelectors';
import { getUsername, getUserPermissions } from '../../../State/Authentication/Selectors/AuthenticationSelectors';
import { getFormInfoState } from '../../../State/FormInfo/Selectors/FormInfoSelectors';
import { getProductOptions } from '../../../State/FormInfo/Actions/FormInfoActions';
import { Redirect } from 'react-router-dom';

const AddMpaWithLoading = WithLoading(AddMpa);

const AddMpaContainer = (props: any) => {
  // -- implementing redux

  const username = useSelector(getUsername);

  // addMpaState carries all the addForm data
  const formInfoState = useSelector(getFormInfoState);
  const addMpaState = useSelector(getMpaState);
  const dispatch = useDispatch();

  const userPermissions = useSelector(getUserPermissions);

  //send a dispatch to get cid, channel and product options
  // useEffect(() => {
  //   if(!formInfoState.getDropdownOptionsLoadable.isReceived) {
  //     dispatch(getDropdownOptions());
  //   }
  // }, [formInfoState.getDropdownOptionsLoadable.isReceived, dispatch]);

  //send a dispatch to get cid and product options
  useEffect(() => {
    if (!formInfoState.getProductOptionsLoadable.isReceived) {
        dispatch(getProductOptions());
    }
  }, [formInfoState.getProductOptionsLoadable.isReceived, dispatch]);

  if(userPermissions.includes('add_mpa')){
    return (
      <>
        <PageHeader>Add MPA</PageHeader>
        <AppPage>
          <AddMpaWithLoading
            loadable={formInfoState.getProductOptionsLoadable}
            dropdownOptions={formInfoState.productOptionsWithoutChannel}
            // loadable={formInfoState.getDropdownOptionsLoadable}
            // dropdownOptions={formInfoState.dropdownOptions}
            isFormDisabled={addMpaState.addMpaLoadable.isLoading}
            userName={username}
          />
        </AppPage>
      </>
    )
  } else {
    return <Redirect to={{pathname:'/map/mpa', state: '/map/mpa'}} />
  }
};

export default AddMpaContainer;
