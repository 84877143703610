import { combineReducers } from "redux";

import annualBudgetReducer from "./AnnualBudget/Reducers/AnnualBudgetReducer";
import annualBudgetFormContextStateReducer from "./AnnualBudgetFormContext/Reducers/AnnualBudgetFormContextReducer";
import authenticationReducer from "./Authentication/Reducers/AuthenticationReducer";
import formInfoReducer from "./FormInfo/Reducers/FormInfoReducer";
import lobReducer from "./Lob/Reducers/LobReducer";
import lobFormContextReducer from "./LobFormContext/Reducers/LobFormContextReducer";
import mpaReducer from "./Mpa/Reducers/MpaReducer";
import mpaFormContextStateReducer from "./MpaFormContext/Reducers/MpaFormContextReducer";
import excludeCidsReducer from "./ExcludeCids/Reducers/ExcludeCidsReducer";
import excludeCidsFormContextReducer from "./ExcludeCidsFormContext/Reducers/ExcludeCidsFormContextReducer";
import dagApiConfigsReducer from "./DagApiConfig/Reducers/DagApiConfigsReducer";
import budgetPacingReducer from "./BudgetPacing/Reducers/BudgetPacingReducer";

export const createRootReducer = () =>
  combineReducers({
    annualBudgetState: annualBudgetReducer,
    annualBudgetFormContextState: annualBudgetFormContextStateReducer,
    authenticationState: authenticationReducer,
    formInfoState: formInfoReducer,
    lobState: lobReducer,
    lobFormContextState: lobFormContextReducer,
    mpaFormContextState: mpaFormContextStateReducer,
    mpaState: mpaReducer,
    excludeCidsState: excludeCidsReducer,
    excludeCidsFormContextState: excludeCidsFormContextReducer,
    dagApiConfigsState: dagApiConfigsReducer,
    budgetPacingState: budgetPacingReducer
  });
