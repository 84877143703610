import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { PageHeader } from '../../../Common';
import { SearchBar } from 'Common/SearchBar';
import ChannelSelect from './ChannelSelect';
import LobSelect from './LobSelect';
import YearSelect from './YearSelect';
import DollarSelect from './DollarSelect';
import QuarterSelect from './QuarterSelect';
import { ForecastingTool } from './ForecastingTool';
import SpendForecastService from '../Services/SpendForecastService';
import VarianceSelect from './VarianceSelect';
import InformationPopup from '../Common/InformationPopup';
import { BomPipeline } from 'Components/BudgetValidation/Common/BomPipeline';
import { Box, FormGroup, FormLabel} from '@mui/material';

export const ForecastingToolContainer = (props: any) => {
  
  const [checkedFB, setCheckedFB] = useState(true);
  const [checkedSEM, setCheckedSEM] = useState(true);
  const [checkedH1, setCheckedH1] = useState([11,12,1,2,3,4].includes(new Date().getMonth()+1)?true:false);
  const [checkedH2, setCheckedH2] = useState([5,6,7,8,9,10].includes(new Date().getMonth()+1)?true:false);
  const [fiscalList, setFiscalList] = useState<Array<String>>([]);
  const [lobOptions, setLobOptions] = useState<Array<String>>([]);
  const [selectedFiscal, setSelectedFiscal] = useState<String>(new Date().getMonth()+1>=11?
                                                              "FY-" + (new Date().getFullYear()+1).toString()
                                                              :"FY-"+ new Date().getFullYear().toString());
  const [selectedDollar, setSelectedDollar] = useState<string>("Gross");
  const [selectedVariance, setSelectedVariance] = useState<string>("All");
  const [fiscalYearisloaded, setFiscalYearisloaded] = useState<Boolean>(false);
  const [lobOptionsisloaded, setLobOptionsisloaded] = useState<Boolean>(false);
  const [forecastDataisloaded, setForecastDataisloaded] = useState<Boolean>(false);
  const [forecastData, setForecastData] = useState<Array<any>>([]);
  const dollarOptions = ["Gross", "Net"];
  const varianceOptions = ["All", "Negative", "Non-Negative"];
  const [searchValue, onChangeSearch] = useState('');

  //get fiscal years
  useEffect(() => {
    let baseYear = 2022;
    let currentYear = new Date().getFullYear();
    let intervalYears = currentYear - 2022;
    let fiscalList = ["FY-" + baseYear];

    for(let i = 1; i <= intervalYears; i++){
      fiscalList.unshift("FY-"+ (baseYear + i).toString())
    }

    setFiscalList(fiscalList);
    setFiscalYearisloaded(true);
  }, []);

  useEffect(() => {
    async function getLobOptions() {
      try{
        const response = await SpendForecastService.retrieveForecastLOB(selectedFiscal.slice(3,7));
        let tempLob = [];
        response.map((lob)=>tempLob.push({checked:true, name:lob.lob}))
      
        tempLob.sort((a,b)=> a.name > b.name? 1: -1)
        setLobOptions(tempLob);
        setLobOptionsisloaded(true);
      } catch(ex){  
        sessionStorage.removeItem("authenticationState");
        sessionStorage.removeItem("tableConfig");
        window.location.reload(); 
      } 
    }
    setLobOptionsisloaded(false);
    getLobOptions();
  }, []);

  useEffect(() => {
    async function getForecastData() {
      try{
        const response = await SpendForecastService.retrieveForecastData(selectedFiscal.slice(3,7));
        let tempRows = response.map(r=>{return {...r}})
      //   tempRows.sort((a,b)=> a.mpa > b.mpa? 1: -1)
        tempRows.map(d=> d.total=0);

        setForecastData(tempRows);
        setForecastDataisloaded(true);
        
      } catch(ex){  
        sessionStorage.removeItem("authenticationState");
        sessionStorage.removeItem("tableConfig");
        window.location.reload(); 
      } 
    }
    setForecastDataisloaded(false);
    getForecastData()
  }, [selectedFiscal]);

  const handleFBChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(checkedSEM)
      setCheckedFB(event.target.checked);
  };

  const handleSEMChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(checkedFB)
      setCheckedSEM(event.target.checked);
  };

  const handleH1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(checkedH2)
      setCheckedH1(event.target.checked);
  };

  const handleH2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(checkedH1)
      setCheckedH2(event.target.checked);
  };

  const handleYearChange = (e:any) => {
    setSelectedFiscal(e.innerHTML);
    
  }

  const selectAll = () =>{
    let tempLobOptions = lobOptions.map(lob=>{return {...lob}});
    tempLobOptions.map((lob) => {
      lob['checked'] = true
    })
    setLobOptions(tempLobOptions)

  }

  const clearAll = () =>{
    let tempLobOptions = lobOptions.map(lob=>{return {...lob}});
    tempLobOptions.map((lob) => {
      lob['checked'] = false
    })
    setLobOptions(tempLobOptions)
  }

  const handleLobChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    let tempLobOptions = lobOptions.map(lob=>{return {...lob}});
    tempLobOptions.map((lob) => {
      if(lob['name'] === event.target.name){
        lob['checked'] = event.target.checked
      }
    })
    setLobOptions(tempLobOptions)

    // let countChecked = tempLobOptions.filter(lob=>lob['checked']).length;
    
    // if(!event.target.checked){
    //   if(countChecked > 1){
    //     tempLobOptions.map((lob) => {
    //       if(lob['name'] === event.target.name){
    //         lob['checked'] = event.target.checked
    //       }
    //     })
    //     setLobOptions(tempLobOptions)
      
    //   }
    // } else {
    //   tempLobOptions.map((lob) => {
    //     if(lob['name'] === event.target.name){
    //       lob['checked'] = event.target.checked
    //     }
    //   })
    //   setLobOptions(tempLobOptions)
    // }
  };

  const handleDollarChange = (e:any) => {
    setSelectedDollar(e.innerHTML);
  }

  const handleVarianceChange = (e:any) => {
    setSelectedVariance(e.innerHTML);
  }

  const handleSearchValue = (e: any) => {
    onChangeSearch(e)
  };

  return (
    <>
        <PageHeader>Spend Forecast Breakdown</PageHeader>
        
        {fiscalYearisloaded && lobOptionsisloaded && forecastDataisloaded?
        <React.Fragment>
          <div style={{padding: "0px 50px 20px 40px"}}>
            <SearchBar
              placeholder={"Search for MPA or Product"}
              handleSearchValue={handleSearchValue}
            />
          </div>
          <Box sx={{ display:'flex', justifyContent:"space-between", marginRight:"50px"}}>
            <div style={{display:'flex'}}>
              <ChannelSelect
                checkedFB={checkedFB}
                checkedSEM={checkedSEM}
                handleFBChange={handleFBChange}
                handleSEMChange={handleSEMChange}
              />
              <LobSelect
                lobOptions={lobOptions}
                handleLobChange={handleLobChange}   
                selectAll={selectAll}
                clearAll={clearAll}      
              />
              <YearSelect
                fiscalList={fiscalList}
                selectedFiscal={selectedFiscal}
                handleYearChange={handleYearChange}
              />
              <QuarterSelect
                checkedH1={checkedH1}
                checkedH2={checkedH2}
                handleH1Change={handleH1Change}
                handleH2Change={handleH2Change}
              />
              <DollarSelect
                dollarOptions={dollarOptions}
                selectedDollar={selectedDollar}
                handleDollarChange={handleDollarChange}
              />
              <VarianceSelect
                varianceOptions={varianceOptions}
                selectedVariance={selectedVariance}
                handleVarianceChange={handleVarianceChange}
              />
              <InformationPopup 
                currentPage = {"forecast_breakdown"}
              />
            </div>
            {/* <BomPipeline /> */}
          </Box>
          <ForecastingTool 
            selectedFiscal ={selectedFiscal.slice(-4)}
            forecastData = {forecastData}
            checkedH1={checkedH1}
            checkedH2={checkedH2}
            checkedSEM={checkedSEM}
            checkedFB={checkedFB}
            selectedDollar={selectedDollar}
            selectedVariance={selectedVariance}
            lobOptions={lobOptions}
            searchValue={searchValue}
          />
        </React.Fragment>
        : <div style={{textAlign:"center"}}><CircularProgress /></div>} 

        
    </>
  )
}
