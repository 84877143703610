import { call, put } from 'redux-saga/effects';

import FormInfoService from '../../../Services/FormInfoService';
import {
  getAnchorCidsSuccess, getAnchorCidsError,
  getDropdownOptionsSuccess, getDropdownOptionsError,
  getNewMpaNumberSuccess, getNewMpaNumberError,
  getProductOptionsSuccess, getProductOptionsError,
  getChannelsForSelectedProductSuccess, getChannelsForSelectedProductError,
  getChannelOptionsSuccess, getChannelOptionsError
} from '../Actions/FormInfoActions'

export function* onGetAnchorCids(action: any) {
  try {
    let response = yield call(FormInfoService.retrieveAnchorCids, action.lob, action.channel, action.product);
    yield put(getAnchorCidsSuccess(response));
  } catch (err) {
    yield put(getAnchorCidsError(err));
  }
}

export function* onGetDropdownOptions() {
  try {
    let response = yield call(FormInfoService.retrieveDropdownOptions);
    yield put(getDropdownOptionsSuccess(response));
  } catch (err) {
    yield put(getDropdownOptionsError(err));
  }
}

export function* onGetNewMpaNumber(action: any) {
  try {
    const response = yield call(FormInfoService.retrieveNewMpaNumber, action.lob);
    yield put(getNewMpaNumberSuccess(response));
  } catch (err) {
    yield put(getNewMpaNumberError(err));
  }
}

export function* onGetProductOptions() {
  try {
    let response = yield call(FormInfoService.retrieveProductOptions);
    yield put(getProductOptionsSuccess(response));
  } catch (err) {
    yield put(getProductOptionsError(err));
  }
}

export function* onGetChannelsForSelectedProduct(action: any) {
  try {
    let response = yield call(FormInfoService.retrieveSelProductChannelList, action.product);
    yield put(getChannelsForSelectedProductSuccess(response));
  } catch (err) {
    yield put(getChannelsForSelectedProductError(err));
  }
}

export function* onGetChannelOptions() {
  try {
    let response = yield call(FormInfoService.retrieveAllChannelOptions);
    yield put(getChannelOptionsSuccess(response));
  } catch (err) {
    yield put(getChannelOptionsError(err));
  }
}
