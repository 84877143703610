import React from 'react'
import { Redirect } from "react-router-dom";

import { PageHeader } from '../../../Common/PageHeader';
import { ReviewBudgetOrders } from './ReviewBudgetOrders';
import { getEditableColumns } from '../Models/BudgetOrders';

export const ReviewBudgetOrdersContainer = (props: any) => {
  const { history,location } = props;

  
  if(location.state){
    const budgetOrdersState = location.state.budgetOrdersState;
    const budgetOrdersToReview = location.state.budgetOrdersList;
    const budgetOrderEditingMode = location.state.budgetOrderEditingMode;
    const budgetOrderClientIdToEdit = location.state.budgetOrderClientIdToEdit;
    
    return (
      <>
        <PageHeader>Confirm Budget Orders Changes</PageHeader>
        <ReviewBudgetOrders
          areBudgetOrdersBeingUpdated={budgetOrdersState.updateBudgetOrdersLoadable.isLoading}
          budgetOrdersToReviewList={budgetOrdersToReview}
          history={history}
          tableConfig={getEditableColumns(budgetOrdersState.entitiesColumns)}
          budgetOrdersState = {budgetOrdersState}
          budgetOrderEditingMode = {budgetOrderEditingMode}
          budgetOrderClientIdToEdit = {budgetOrderClientIdToEdit}
        />
      </>
    )
  }
  else {
    return <Redirect to='/bom/budget-orders' />
  }
}