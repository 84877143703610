import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, FormGroup, FormLabel, Button, Stack} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const MonthSelect = (props: any) => {
    return (
        <>
            <Box sx={{display:'flex', marginLeft:'20px', mb:5, height:145}}>
              <div style={{display:'flex', flexDirection:"column", borderRadius:1, border:'1px solid #dee2e6', width:'170px'}}>
              <FormLabel sx={{color: "white", backgroundColor:"#006AC3", paddingTop:1,paddingBottom: 1,textAlign:"center"}}>Months</FormLabel>     
                  <div style={{overflowY:"scroll"}}>
                    <FormGroup>
                        {/* <Stack spacing={2} justifyContent="center" direction="row" style={{position:"sticky", top:0, backgroundColor:"White", zIndex:1}}>    
                            <Button size="small" onClick={props.selectAll}>Select All</Button>
                            <Button size="small" onClick={props.clearAll}>Clear</Button>
                        </Stack> */}
                        {props.monthsList.map((month,index) => 
                            <FormControlLabel
                                key={index}
                                label={month.name}
                                control={<Checkbox checked={month.checked} name={month.name} onChange={props.handleMonthChange} />}
                                sx={{ml:1,mr:2,mb:1}}
                            />
                        )}
                    </FormGroup>
                  </div>
              </div>
            </Box>
        </>
    )
}

export default MonthSelect;