import React, { useEffect, useState } from 'react';
import { HeadCell, Row } from '../Models/ForecastingTable';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { OverviewTableHead } from './OverviewTableHead';
import { OverviewTableBody } from './OverviewTableBody';
import { isNumber } from 'lodash';

export const ForecastOverview = (props: any) => {
    const {selectedFiscal, overviewData, checkedH1, checkedH2, checkedSEM, checkedFB, selectedDollar, lobOptions, searchValue} = props;
    
    const [headCells, setHeadCells] = useState<Array<HeadCell>>([]);
    const [rows, setRows] = useState<Array<Row>>([]);
    const [allData, setAllData] = useState<Array<Row>>([]); 

    //groupby rows by lob, channel and product to display only one row for each product
    const aggregateAmounts = (response:any) => {
        var result = [];
        response.reduce(function (res, value) {
            const key = value['lob'] + '_' + value['channel'] + '_' + value['products'];
            if (!res[key]) {
                res[key] = {
                    lob: value['lob'],
                    channel: value['channel'],        
                    products: value['products'],
                    gross_mpa_amount: 0,
                    net_mpa_amount: 0,
                    nov_forecast_net: 0,
                    nov_forecast_gross: 0,
                    dec_forecast_net: 0,
                    dec_forecast_gross: 0,
                    jan_forecast_net: 0,
                    jan_forecast_gross: 0,
                    feb_forecast_net: 0,
                    feb_forecast_gross: 0,
                    mar_forecast_net: 0,
                    mar_forecast_gross: 0,
                    apr_forecast_net: 0,
                    apr_forecast_gross: 0,
                    may_forecast_net: 0,
                    may_forecast_gross: 0,
                    jun_forecast_net: 0,
                    jun_forecast_gross: 0,
                    july_forecast_net: 0,
                    july_forecast_gross: 0,
                    aug_forecast_net: 0,
                    aug_forecast_gross: 0,
                    sep_forecast_net: 0,
                    sep_forecast_gross: 0,
                    oct_forecast_net: 0,
                    oct_forecast_gross: 0,
                    nov_actual_net: 0,
                    nov_actual_gross: 0,
                    dec_actual_net: 0,
                    dec_actual_gross: 0,
                    jan_actual_net: 0,
                    jan_actual_gross: 0,
                    feb_actual_net: 0,
                    feb_actual_gross: 0,
                    mar_actual_net: 0,
                    mar_actual_gross: 0,
                    apr_actual_net: 0,
                    apr_actual_gross: 0,
                    may_actual_net: 0,
                    may_actual_gross: 0,
                    jun_actual_net: 0,
                    jun_actual_gross: 0,
                    july_actual_net: 0,
                    july_actual_gross: 0,
                    aug_actual_net: 0,
                    aug_actual_gross: 0,
                    sep_actual_net: 0,
                    sep_actual_gross: 0,
                    oct_actual_net: 0,
                    oct_actual_gross: 0,
                };
                result.push(res[key]);
            }
            res[key].gross_mpa_amount += value['gross_mpa_amount']
            res[key].net_mpa_amount += value['net_mpa_amount']
            res[key].nov_forecast_net = Math.max(res[key].nov_forecast_net, value['nov_forecast_net']);
            res[key].nov_forecast_gross = Math.max(res[key].nov_forecast_gross, value['nov_forecast_gross']);
            res[key].dec_forecast_net = Math.max(res[key].dec_forecast_net, value['dec_forecast_net']);
            res[key].dec_forecast_gross = Math.max(res[key].dec_forecast_gross, value['dec_forecast_gross']);
            res[key].jan_forecast_net = Math.max(res[key].jan_forecast_net, value['jan_forecast_net']);
            res[key].jan_forecast_gross = Math.max(res[key].jan_forecast_gross, value['jan_forecast_gross']);
            res[key].feb_forecast_net = Math.max(res[key].feb_forecast_net, value['feb_forecast_net']);
            res[key].feb_forecast_gross = Math.max(res[key].feb_forecast_gross, value['feb_forecast_gross']);
            res[key].mar_forecast_net = Math.max(res[key].mar_forecast_net, value['mar_forecast_net']);
            res[key].mar_forecast_gross = Math.max(res[key].mar_forecast_gross, value['mar_forecast_gross']);
            res[key].apr_forecast_net = Math.max(res[key].apr_forecast_net, value['apr_forecast_net']);
            res[key].apr_forecast_gross = Math.max(res[key].apr_forecast_gross, value['apr_forecast_gross']);
            res[key].may_forecast_net = Math.max(res[key].may_forecast_net, value['may_forecast_net']);
            res[key].may_forecast_gross = Math.max(res[key].may_forecast_gross, value['may_forecast_gross']);
            res[key].jun_forecast_net = Math.max(res[key].jun_forecast_net, value['jun_forecast_net']);
            res[key].jun_forecast_gross = Math.max(res[key].jun_forecast_gross, value['jun_forecast_gross']);
            res[key].july_forecast_net = Math.max(res[key].july_forecast_net, value['july_forecast_net']);
            res[key].july_forecast_gross = Math.max(res[key].july_forecast_gross, value['july_forecast_gross']);
            res[key].aug_forecast_net = Math.max(res[key].aug_forecast_net, value['aug_forecast_net']);
            res[key].aug_forecast_gross = Math.max(res[key].aug_forecast_gross, value['aug_forecast_gross']);
            res[key].sep_forecast_net = Math.max(res[key].sep_forecast_net, value['sep_forecast_net']);
            res[key].sep_forecast_gross = Math.max(res[key].sep_forecast_gross, value['sep_forecast_gross']);
            res[key].oct_forecast_net = Math.max(res[key].oct_forecast_net, value['oct_forecast_net']);
            res[key].oct_forecast_gross = Math.max(res[key].oct_forecast_gross, value['oct_forecast_gross']);

            res[key].nov_actual_net = Math.max(res[key].nov_actual_net, value['nov_actual_net']);
            res[key].nov_actual_gross = Math.max(res[key].nov_actual_gross, value['nov_actual_gross']);
            res[key].dec_actual_net = Math.max(res[key].dec_actual_net, value['dec_actual_net']);
            res[key].dec_actual_gross = Math.max(res[key].dec_actual_gross, value['dec_actual_gross']);
            res[key].jan_actual_net = Math.max(res[key].jan_actual_net, value['jan_actual_net']);
            res[key].jan_actual_gross = Math.max(res[key].jan_actual_gross, value['jan_actual_gross']);
            res[key].feb_actual_net = Math.max(res[key].feb_actual_net, value['feb_actual_net']);
            res[key].feb_actual_gross = Math.max(res[key].feb_actual_gross, value['feb_actual_gross']);
            res[key].mar_actual_net = Math.max(res[key].mar_actual_net, value['mar_actual_net']);
            res[key].mar_actual_gross = Math.max(res[key].mar_actual_gross, value['mar_actual_gross']);
            res[key].apr_actual_net = Math.max(res[key].apr_actual_net, value['apr_actual_net']);
            res[key].apr_actual_gross = Math.max(res[key].apr_actual_gross, value['apr_actual_gross']);
            res[key].may_actual_net = Math.max(res[key].may_actual_net, value['may_actual_net']);
            res[key].may_actual_gross = Math.max(res[key].may_actual_gross, value['may_actual_gross']);
            res[key].jun_actual_net = Math.max(res[key].jun_actual_net, value['jun_actual_net']);
            res[key].jun_actual_gross = Math.max(res[key].jun_actual_gross, value['jun_actual_gross']);
            res[key].july_actual_net = Math.max(res[key].july_actual_net, value['july_actual_net']);
            res[key].july_actual_gross = Math.max(res[key].july_actual_gross, value['july_actual_gross']);
            res[key].aug_actual_net = Math.max(res[key].aug_actual_net, value['aug_actual_net']);
            res[key].aug_actual_gross = Math.max(res[key].aug_actual_gross, value['aug_actual_gross']);
            res[key].sep_actual_net = Math.max(res[key].sep_actual_net, value['sep_actual_net']);
            res[key].sep_actual_gross = Math.max(res[key].sep_actual_gross, value['sep_actual_gross']);
            res[key].oct_actual_net = Math.max(res[key].oct_actual_net, value['oct_actual_net']);
            res[key].oct_actual_gross = Math.max(res[key].oct_actual_gross, value['oct_actual_gross']);

            return res;
        }, {});
        applyCalculations(result);
        return result;
    }

    //calculate the columns of overview table
    const applyCalculations = (aggregatedResult:any) => {
        const single_day = 1000 * 60 * 60 * 24;
        const curr_year_end_dt = new Date(selectedFiscal, 9, 31);
        const yesterday_dt = new Date(selectedFiscal, new Date().getMonth(), new Date().getDate()-1);        
        const remaining_days_in_fiscal_year = Math.round(curr_year_end_dt.getTime() - yesterday_dt.getTime()) / (single_day); 

        let originHeadCells: HeadCell[] = [
            {id: 'lob',label: 'LOB', isNum:false},
            {id: 'channel',label: 'Channel', isNum:false},
            {id: 'product',label: 'Category / Product', isNum:false},
            {id: 'mpa_total',label: 'Total MPA Amount', isNum:true},
            {id: 'total_actual_spend',label: 'Total Spend', isNum:true},
            {id: 'total_remaining',label: 'Total Remaining', isNum:true},
            {id: 'total_forecast_spend',label: 'Total Forecasted Spend', isNum:true},
            {id: 'remaining_budget',label: 'Remaining Funds', isNum:true},
            {id: 'total_daily_cost',label: 'Total Daily Cost', isNum:true},
            {id: 'actual_avg_cost',label: 'Actual Avg. Cost', isNum:true},
        ]

        let output: Row[] = [];

        for(let r of aggregatedResult) {
            let temp: Row = {
                lob: "",
                channel: "",
                products: "",
                mpa_total: 0,   
                total_actual_spend: 0,
                total_remaining: 0,
                total_forecast_spend: 0,             
                remaining_budget: 0,
                total_daily_cost: 0,
                actual_avg_cost: 0,
                m1: 0,
                m2: 0,
                m3: 0,
                m4: 0,
                m5: 0,
                m6: 0,
                m7: 0,
                m8: 0,
                m9: 0,
                m10: 0,
                m11: 0,
                m12: 0,
                m1_forecast: 0,
                m2_forecast: 0,
                m3_forecast: 0,
                m4_forecast: 0,
                m5_forecast: 0,
                m6_forecast: 0,
                m7_forecast: 0,
                m8_forecast: 0,
                m9_forecast: 0,
                m10_forecast: 0,
                m11_forecast: 0,
                m12_forecast: 0,
                background_color: ''
            };
            temp.lob = r.lob;
            temp.channel = r.channel;            
            temp.products = r.products;                  

            if(selectedDollar === "Gross") {
                temp.mpa_total = r.gross_mpa_amount;
                temp.m1 = Math.round(r.jan_actual_gross);
                temp.m2 = Math.round(r.feb_actual_gross);
                temp.m3 = Math.round(r.mar_actual_gross);
                temp.m4 = Math.round(r.apr_actual_gross);
                temp.m5 = Math.round(r.may_actual_gross);
                temp.m6 = Math.round(r.jun_actual_gross);
                temp.m7 = Math.round(r.july_actual_gross);
                temp.m8 = Math.round(r.aug_actual_gross);
                temp.m9 = Math.round(r.sep_actual_gross);
                temp.m10 = Math.round(r.oct_actual_gross);
                temp.m11 = Math.round(r.nov_actual_gross);
                temp.m12 = Math.round(r.dec_actual_gross);

                temp.m1_forecast = Math.round(r.jan_forecast_gross);
                temp.m2_forecast = Math.round(r.feb_forecast_gross);
                temp.m3_forecast = Math.round(r.mar_forecast_gross);
                temp.m4_forecast = Math.round(r.apr_forecast_gross);
                temp.m5_forecast = Math.round(r.may_forecast_gross);
                temp.m6_forecast = Math.round(r.jun_forecast_gross);
                temp.m7_forecast = Math.round(r.july_forecast_gross);
                temp.m8_forecast = Math.round(r.aug_forecast_gross);
                temp.m9_forecast = Math.round(r.sep_forecast_gross);
                temp.m10_forecast = Math.round(r.oct_forecast_gross);
                temp.m11_forecast = Math.round(r.nov_forecast_gross);
                temp.m12_forecast = Math.round(r.dec_forecast_gross);
                
                temp.total_actual_spend = temp.m1 + temp.m2 + temp.m3 + temp.m4 + temp.m5 + temp.m6 + temp.m7 + temp.m8 + temp.m9 + temp.m10 + temp.m11 + temp.m12;
                temp.total_forecast_spend = temp.m1_forecast + temp.m2_forecast + temp.m3_forecast + temp.m4_forecast + temp.m5_forecast + temp.m6_forecast + temp.m7_forecast + temp.m8_forecast + temp.m9_forecast + temp.m10_forecast + temp.m11_forecast + temp.m12_forecast;
            } else if(selectedDollar === "Net") {
                temp.mpa_total = r.net_mpa_amount;
                temp.m1 = Math.round(r.jan_actual_net);
                temp.m2 = Math.round(r.feb_actual_net);
                temp.m3 = Math.round(r.mar_actual_net);
                temp.m4 = Math.round(r.apr_actual_net);
                temp.m5 = Math.round(r.may_actual_net);
                temp.m6 = Math.round(r.jun_actual_net);
                temp.m7 = Math.round(r.july_actual_net);
                temp.m8 = Math.round(r.aug_actual_net);
                temp.m9 = Math.round(r.sep_actual_net);
                temp.m10 = Math.round(r.oct_actual_net);
                temp.m11 = Math.round(r.nov_actual_net);
                temp.m12 = Math.round(r.dec_actual_net);

                temp.m1_forecast = Math.round(r.jan_forecast_net);
                temp.m2_forecast = Math.round(r.feb_forecast_net);
                temp.m3_forecast = Math.round(r.mar_forecast_net);
                temp.m4_forecast = Math.round(r.apr_forecast_net);
                temp.m5_forecast = Math.round(r.may_forecast_net);
                temp.m6_forecast = Math.round(r.jun_forecast_net);
                temp.m7_forecast = Math.round(r.july_forecast_net);
                temp.m8_forecast = Math.round(r.aug_forecast_net);
                temp.m9_forecast = Math.round(r.sep_forecast_net);
                temp.m10_forecast = Math.round(r.oct_forecast_net);
                temp.m11_forecast = Math.round(r.nov_forecast_net);
                temp.m12_forecast = Math.round(r.dec_forecast_net);
                
                
                temp.total_actual_spend = temp.m1 + temp.m2 + temp.m3 + temp.m4 + temp.m5 + temp.m6 + temp.m7 + temp.m8 + temp.m9 + temp.m10 + temp.m11 + temp.m12;
                temp.total_forecast_spend = temp.m1_forecast + temp.m2_forecast + temp.m3_forecast + temp.m4_forecast + temp.m5_forecast + temp.m6_forecast + temp.m7_forecast + temp.m8_forecast + temp.m9_forecast + temp.m10_forecast + temp.m11_forecast + temp.m12_forecast;
            }

            temp.total_remaining = temp.mpa_total - temp.total_actual_spend;
            temp.remaining_budget = temp.total_actual_spend - temp.total_forecast_spend;
            temp.total_daily_cost = temp.total_remaining/remaining_days_in_fiscal_year;
            temp.actual_avg_cost = temp.total_actual_spend/remaining_days_in_fiscal_year;
            output.push(temp);
        }

        let tempRows = output.map(r=>{return {...r}});

        // filter channels
        if(checkedSEM && !checkedFB){
            tempRows = tempRows.filter(r=> r.channel !=="Facebook");
        }
        if(!checkedSEM && checkedFB){
            tempRows = tempRows.filter(r=> r.channel !=="SEM");
        }

        // filter LOBs
        for(let lob of lobOptions){
            if(!lob.checked){   
                tempRows = tempRows.filter(row => row.lob !== lob.name)
            }
        }

        if(checkedH1 && !checkedH2){
            const curr_year_start_dt = new Date(selectedFiscal-1, 10, 1);
            const half_year_end_dt = new Date(selectedFiscal, 3, 30);
            let remaining_days_of_half_year = 1;

            if(yesterday_dt >= curr_year_start_dt && yesterday_dt < half_year_end_dt) {
                remaining_days_of_half_year = Math.round(half_year_end_dt.getTime() - yesterday_dt.getTime()) / (single_day); 
            }            

            let mutateTempRows = tempRows.map(r=>{return {...r}});

            mutateTempRows.map(r=>{
                if(r.lob != 'Caribbean') {
                    r.total_actual_spend = r.total_actual_spend - r.m5 - r.m6 - r.m7 - r.m8 - r.m9 - r.m10;
                    r.total_remaining = r.mpa_total - r.total_actual_spend;
                    r.total_forecast_spend = r.total_forecast_spend - r.m5_forecast - r.m6_forecast - r.m7_forecast - r.m8_forecast - r.m9_forecast - r.m10_forecast;

                    r.remaining_budget = r.total_actual_spend - r.total_forecast_spend;
                    r.total_daily_cost = r.total_remaining/remaining_days_of_half_year;     
                }                           
            })
            tempRows = mutateTempRows
        }

        if(!checkedH1 && checkedH2){
            const half_year_start_dt = new Date(selectedFiscal, 4, 1);
            const half_year_end_dt = new Date(selectedFiscal, 9, 31);
            let remaining_days_of_half_year = 1;

            if(yesterday_dt >= half_year_start_dt && yesterday_dt < half_year_end_dt) {
                remaining_days_of_half_year = Math.round(half_year_end_dt.getTime() - yesterday_dt.getTime()) / (single_day); 
            }            
            
            let mutateTempRows = tempRows.map(r=>{return {...r}});

            mutateTempRows.map(r=>{
                if(r.lob != 'Caribbean') {
                    r.total_actual_spend = r.total_actual_spend - r.m11 - r.m12 - r.m1 - r.m2 - r.m3 - r.m4;
                    r.total_remaining = r.mpa_total - r.total_actual_spend;
                    r.total_forecast_spend = r.total_forecast_spend - r.m11_forecast - r.m12_forecast - r.m1_forecast - r.m2_forecast - r.m3_forecast - r.m4_forecast;
                    r.remaining_budget = r.total_actual_spend - r.total_forecast_spend;
                    r.total_daily_cost = r.total_remaining/remaining_days_of_half_year;   
                }
            })
            tempRows = mutateTempRows
        }

        // filter by search
        if(searchValue.trim() !== ""){
            tempRows = tempRows.filter(row => row.lob.toLowerCase().includes(searchValue.toLowerCase().trim()) || row.channel.toLowerCase().includes(searchValue.toLowerCase().trim()) || row.products.toLowerCase().includes(searchValue.toLowerCase().trim()))
        }

        // Remove duplicate row values
        let removeDuplicateValues = tempRows.map(r=>{return {...r}});
      
        let lobs = new Set();
        for( let r of removeDuplicateValues){
            lobs.add(r.lob)
        }

        //add background color to alternate LOB rows to differentiate between LOBs
        let isBackgroundGrey = false; 
        let greyBackgroundColor = '#F5F5F5';
        let whiteBackgroundColor = '#FFFFFF';
        let backgroundColor = greyBackgroundColor;
        
        for(let l of Array.from(lobs)){            
            let countLOB = 0;
            let countFB = 0;
            let countSEM = 0;
            let lob = l.toString();

            //change color when new LOB row starts
            isBackgroundGrey = !isBackgroundGrey;
            if(isBackgroundGrey) {
                backgroundColor = greyBackgroundColor;
            } else {
                backgroundColor = whiteBackgroundColor;
            }

            for(let r of removeDuplicateValues){             
                if(r.lob === lob){        
                    r.background_color = backgroundColor;
                    
                    if(countLOB != 0 ){
                        r.lob = ' ';
                    }                   
                    if(r.channel === 'Facebook'){
                        if(countFB != 0)
                            r.channel = ' ';                        
                        countFB++;
                    }
                    if(r.channel === 'SEM'){
                        if(countSEM != 0)
                          r.channel = ' ';
                        countSEM++;
                    }
                    countLOB++;
                }
            }
        }        

        setHeadCells(originHeadCells)
        setRows(removeDuplicateValues)
    }

    useEffect(() => {
        let forecastTempRows = overviewData.map(r=>{return {...r}});        
        
        //when both H1 and H2 are selected
        if(checkedH1 && checkedH2){ 
            aggregateAmounts(forecastTempRows);
        }

        // filter rows based on scheduled_end column when selected H1
        if(checkedH1 && !checkedH2){          
            const h1_start_date = selectedFiscal-1+'-11-01';
            const h1_end_date = selectedFiscal+'-04-30';
            forecastTempRows = forecastTempRows.filter(r => r.scheduled_end_date >= h1_start_date && r.scheduled_end_date <= h1_end_date);
            aggregateAmounts(forecastTempRows);
        }

        // filter rows based on scheduled_end column when selected H2
        if(!checkedH1 && checkedH2){
            const h2_start_date = selectedFiscal+'-05-01';
            const h2_end_date = selectedFiscal+'-10-31';
            forecastTempRows = forecastTempRows.filter(r => r.scheduled_end_date >= h2_start_date && r.scheduled_end_date <= h2_end_date);
            aggregateAmounts(forecastTempRows);
        }
    },[selectedFiscal, checkedH1, checkedH2, checkedSEM, checkedFB, selectedDollar, lobOptions, searchValue])
    
    return (
        <>
        <Paper sx={{ width: '95%', ml:5, mb:1, border:"1px solid"}}>
            <TableContainer sx={{ maxHeight: 590 }}>
                <Table stickyHeader size='small' >
                    <OverviewTableHead headCells={headCells} />
                    <OverviewTableBody 
                        rows={rows} 
                        checkedH1={checkedH1} 
                        checkedH2={checkedH2}
                    />
                </Table>
            </TableContainer>
        </Paper>
        </>
    )
}