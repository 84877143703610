import { GridInput } from '../../Common';
import React, { useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import CSVButton from './CSVButton';
import {
  IMediaCalculatorObj,
  formattedNumber,
  initialMediaCalculatorObj,
  numberToMediaCalculatorObj,
} from './formulation';
import ValidInput from './ValidInput';

const gridSize = 3;

const ImpressionEstimatorSession = (): JSX.Element => {
  const [spend, setSpend] = useState<IMediaCalculatorObj>(
    initialMediaCalculatorObj
  );

  const [avgCpm, setAvgCpm] = useState<IMediaCalculatorObj>(
    initialMediaCalculatorObj
  );

  const estimatedImpression = useMemo(() => {
    const impression =
      avgCpm.value > 0 && spend.value
        ? formattedNumber(Math.round((spend.value / avgCpm.value) * 1000))
        : '';

    return {
      impression,
      csvContent: [
        ['Spend', spend.formattedString],
        ['Avg. CPM', avgCpm.formattedString],
        ['Estimated Impression', impression],
      ],
    };
  }, [spend, avgCpm]);

  return (
    <Row>
      <Col xs={12}>
        <h3>
          Impression Estimator
          <CSVButton
            filename="Impression_Estimator"
            content={estimatedImpression.csvContent}
          />
        </h3>
      </Col>
      <ValidInput values={spend} onChange={setSpend} label="Spend:" />

      <ValidInput values={avgCpm} onChange={setAvgCpm} label="Avg. CPM:" />

      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="estimated-impression"
        inputId="estimated-impression-input"
        inputLabel="Estimated Impression:"
        value={estimatedImpression.impression}
      />
    </Row>
  );
};

export default ImpressionEstimatorSession;
