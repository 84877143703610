import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { TableConfigurationForm } from './TableConfigurationForm';
import TableConfigurationService from '../Services/TableConfigurationService';
import { TableConfigurationInterface } from '../Models/TableConfiguration';

interface Props{
  history: any,
  isFormLoading: boolean,
  tableConfig: TableConfigurationInterface
}

export const TableConfiguration = (props: Props) => {
  const { history, isFormLoading, tableConfig } = props;

  const [tableConfiguration, setTableConfiguration] = useState<TableConfigurationInterface>({...tableConfig});
 
  const handleUpdateTableConfig = async() => {
    let noneChecked = true;
    for(let i of Object.values(tableConfiguration)){
      if(i === true){
        noneChecked = false;
      }
    }
    if (noneChecked){
      toast.error('Must select at least one Column to show');
    } else {
      try{
        const response = await TableConfigurationService.updateTableConfiguration(tableConfiguration);
        let newTableConfiguration = {...response};
        sessionStorage.setItem('tableConfig', JSON.stringify(newTableConfiguration));
        setTableConfiguration(newTableConfiguration);
        
      } catch(ex){
        toast.error('Failed to update table configuration');
      } finally{
        history.push('/bom/budget-orders');
      }
    }
  }

  const handleUpdateSingleField = (attribute: string) => {
    setTableConfiguration((prevState: TableConfigurationInterface) => {
      return {...prevState, [attribute]: !prevState[attribute] }
    });
  }

  const handleUpdateAllFields = (displayAllFields: boolean) => {
    let newTableConfiguration = {...tableConfiguration};

    Object.keys(newTableConfiguration).forEach(attribute => {
      newTableConfiguration[attribute] = displayAllFields;
    });

    setTableConfiguration(newTableConfiguration);
  }

  const handleCancel = () => {
    sessionStorage.setItem('tableConfig', JSON.stringify(tableConfig));
    history.push('/bom/budget-orders');
  }

  return (
    <div className="App-page">
      <TableConfigurationForm
        disableForm={isFormLoading}
        tableConfig={tableConfiguration}
        handleUpdateTableConfig={handleUpdateTableConfig}
        handleUpdateSingleField={handleUpdateSingleField}
        handleUpdateAllFields={handleUpdateAllFields}
        handleCancel={handleCancel}
      />
    </div>
  );
};

TableConfiguration.propTypes = {
  history: PropTypes.any.isRequired,
  isFormLoading: PropTypes.bool.isRequired,
  tableConfig: PropTypes.object.isRequired
};
