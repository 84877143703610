import ApiService from './ApiService';

export default class AuthenticationService {
  static login(code?: any): Promise<any> {
    return ApiService.post({
      relativeUrl: '/auth/api/login',
      body: { code },
      addToken: false,
    });
  }

  static logout(): Promise<any> {
    return ApiService.post({ relativeUrl: '/auth/api/logout' });
  }
}
