import { createDefaultAnnualBudgetState, AnnualBudgetState } from '../Models/AnnualBudgetState'

import {
  updateLoadableOnStart,
  updateLoadableOnSuccess,
  updateLoadableOnError
} from '../../Common/Loadable';

import {
  ADD_NEW_ANNUAL_BUDGET, ADD_NEW_ANNUAL_BUDGET_SUCCESS, ADD_NEW_ANNUAL_BUDGET_ERROR,
  DELETE_SELECTED_ANNUAL_BUDGET, DELETE_SELECTED_ANNUAL_BUDGET_SUCCESS, DELETE_SELECTED_ANNUAL_BUDGET_ERROR,
  GET_ANNUAL_BUDGET, GET_ANNUAL_BUDGET_SUCCESS, GET_ANNUAL_BUDGET_ERROR,
  GET_ANNUAL_BUDGETS_CSV, GET_ANNUAL_BUDGETS_CSV_SUCCESS, GET_ANNUAL_BUDGETS_CSV_ERROR,
  GET_SELECTED_ANNUAL_BUDGET, GET_SELECTED_ANNUAL_BUDGET_SUCCESS, GET_SELECTED_ANNUAL_BUDGET_ERROR,
  UPDATE_SELECTED_ANNUAL_BUDGET, UPDATE_SELECTED_ANNUAL_BUDGET_SUCCESS, UPDATE_SELECTED_ANNUAL_BUDGET_ERROR
} from '../ActionTypes/AnnualBudgetActionTypes';

export default function (state = createDefaultAnnualBudgetState(), action: any): AnnualBudgetState {
  switch(action.type) {
    case ADD_NEW_ANNUAL_BUDGET:
      return {
        ...state,
        addAnnualBudgetLoadable: updateLoadableOnStart()
      };

    case ADD_NEW_ANNUAL_BUDGET_SUCCESS:
      let entities = [...state.entities, action.result.annual_budget];

      // sort manage page data after new budget is added
      entities.sort((prev, next) => prev.lob.localeCompare(next.lob));

      return {
        ...state,
        entities,
        addAnnualBudgetLoadable: updateLoadableOnSuccess()
      };

    case ADD_NEW_ANNUAL_BUDGET_ERROR:
      return {
        ...state,
        addAnnualBudgetLoadable: updateLoadableOnError(action.error)
      };

    case DELETE_SELECTED_ANNUAL_BUDGET:
      return {
        ...state,
        deleteSelectedAnnualBudgetLoadable: updateLoadableOnStart()
      };

    case DELETE_SELECTED_ANNUAL_BUDGET_SUCCESS:
      return {
        ...state,
        entities: state.entities.filter((annualBudget) => annualBudget['id'] !== action.annualBudgetId),
        deleteSelectedAnnualBudgetLoadable: updateLoadableOnSuccess()
      };

    case DELETE_SELECTED_ANNUAL_BUDGET_ERROR:
      return {
        ...state,
        deleteSelectedAnnualBudgetLoadable: updateLoadableOnError(action.error)
      };

    case GET_ANNUAL_BUDGET:
      return {
        ...state,
        getAnnualBudgetListLoadable: updateLoadableOnStart()
      };

    case GET_ANNUAL_BUDGET_SUCCESS:
      return {
        ...state,
        entities: action.result,
        getAnnualBudgetListLoadable: updateLoadableOnSuccess()
      };

    case GET_ANNUAL_BUDGET_ERROR:
      return {
        ...state,
        getAnnualBudgetListLoadable: updateLoadableOnError(action.error)
      };

    case GET_ANNUAL_BUDGETS_CSV:
      return {
        ...state,
        getAnnualBudgetsCsvLoadable: updateLoadableOnStart()
      };

    case GET_ANNUAL_BUDGETS_CSV_SUCCESS:
      return {
        ...state,
        getAnnualBudgetsCsvLoadable: updateLoadableOnSuccess()
      };

    case GET_ANNUAL_BUDGETS_CSV_ERROR:
      return {
        ...state,
        getAnnualBudgetsCsvLoadable: updateLoadableOnError(action.error)
      };

    case GET_SELECTED_ANNUAL_BUDGET:
      return {
        ...state,
        getSelectedAnnualBudgetListLoadable: updateLoadableOnStart()
      };

    case GET_SELECTED_ANNUAL_BUDGET_SUCCESS:
      return {
        ...state,
        selectedAnnualBudget: action.result,
        getSelectedAnnualBudgetListLoadable: updateLoadableOnSuccess()
      };

    case GET_SELECTED_ANNUAL_BUDGET_ERROR:
      return {
        ...state,
        getSelectedAnnualBudgetListLoadable: updateLoadableOnError(action.error)
      };

    case UPDATE_SELECTED_ANNUAL_BUDGET:
      return {
        ...state,
        updateAnnualBudgetListLoadable: updateLoadableOnStart()
      };

    case UPDATE_SELECTED_ANNUAL_BUDGET_SUCCESS:
      return {
        ...state,
        entities: [...state.entities].map(entity => {
            if (entity['id'] === action.result.annual_budget.id) {
                return action.result.annual_budget;
            }
            return entity;
        }),
        selectedAnnualBudget: action.result,
        updateAnnualBudgetListLoadable: updateLoadableOnSuccess()
      };

    case UPDATE_SELECTED_ANNUAL_BUDGET_ERROR:
      return {
        ...state,
        updateAnnualBudgetListLoadable: updateLoadableOnError(action.error)
      };

    default:
      return state;
  };
};
