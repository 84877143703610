import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_SUCCESS,
} from '../ActionTypes/AuthenticationActionTypes';

export const login = (code?: any) => {
  return { type: LOGIN, code };
};

export const loginSuccess = (result: any) => {
  return { type: LOGIN_SUCCESS, result };
};

export const loginError = (error: any) => {
  return { type: LOGIN_ERROR, error };
};

export const logout = () => {
  return { type: LOGOUT };
};

export const logoutSuccess = () => {
  return { type: LOGOUT_SUCCESS };
};
