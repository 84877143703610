import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pick, keys, values, cloneDeep} from 'lodash';
import MpaForm from '../Common/MpaForm';
import { MpaIntakeInterface } from '../../../Models/MpaIntakeInterface';
import { CidFieldInterface } from '../../../Models/CidFieldInterface';
import { addNewMpa } from '../../../State/Mpa/Actions/MpaActions';
import { MandatoryFieldMpaModal } from '../../../Common/MandatoryFieldMpa';
import { ConfirmationPageMpaModal } from '../../../Common/ConfirmationPageMpa';
import { Container } from 'reactstrap';
import { toast } from 'react-toastify';
import MpaService from 'Services/MpaService';
import moment from 'moment-timezone';
import { ModalsConsumer } from '../../../Contexts/ModalsContext';
import FormInfoService from 'Services/FormInfoService';
import { MpaWarningModal } from '../../../Common/MpaWarningModal';
import history from '../../../Utils/History';

const AddMpa = (props: any) => {

  const dispatch = useDispatch();
  // console.log(new Date("Mon Mar 14 2022 20:00:00 GMT-0500").getHours())
  // console.log(new Date("Mon Mar 14 2022 20:00:00 GMT-0500").toISOString())
  // MPA Intake Form Data
  const [mpaIntake, setMpaIntake] = useState<MpaIntakeInterface>(
    {
      client_business_unit:"",
      channel:"",
      products:"",
      phase_number:0,
      scheduled_start: new Date().toISOString(),
      scheduled_end: new Date().toISOString(),
      prepared_for:"",
      budget_sharing_flag:"",
      mpa_status:"",
      commission_rate:1.5,
      platform_rate:0,
      platform_fees:0,
      tax_rate:13,
      promotion_credit: 0,
      notes:"",
      mpo_notes:"",
      mpa_number:"",
      revision_number:"",
      legacy_mpa:"",
      prepared_by: props.userName,
      campaign:"",
      working_funds_total:0,
      commission:0,
      net_total:0,
      tax:0,
      grand_total:0
    }
  );
 
  // CID Summary Data
  const [cidList, setCidList] = useState<Array<CidFieldInterface>>([
    {
      channel:"",
      anchor_cid:'',
      working_funds:0,
      campaign_name:'',
      november_disabled: false,
      december_disabled: false,
      january_disabled: false,
      february_disabled: false,
      march_disabled: false,
      april_disabled: false,
      may_disabled: false,
      june_disabled: false,
      july_disabled: false,
      august_disabled: false,
      september_disabled: false,
      october_disabled: false,

      november:0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      april: 0,
      may: 0,	
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      id:0,
      mpa_id: 0,
      anchor_cid_platform_fee :0,
      difference:0,
      // legacy_mpa:"",
      // google_cid:'',
      // google_budget:0,
      // bing_cid:'',
      // bing_budget:0,
      // apple_cid:'',
      // apple_budget:0,
      // facebook_cid: "",
      // pinterest_cid: "",
      // bell_cid: "",
      // tiktok_cid: ""  
    },
  ]);

  const [modal, setModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [allCheckerModal, setAllCheckerModal] = useState(false);
  const [mandatoryModal, setMandatoryModal] = useState(false);
  const [mpaWarningModal, setMpaWarningModal] = useState(false);

  const handleGetChangeLog = (finalCidList) => {

    //create MPA change logs
    const changedMpaKeysMethod = (o1: any) => {
      return keys(o1)
    };

    const mpaChangedKeys = changedMpaKeysMethod(mpaIntake);
    const mpaChangeLogs = mpaChangedKeys.map((key) => {
      const newValue = values(pick(mpaIntake, key))
      return `${key} : ->  ${newValue}`;
    })

    //create CID change logs
    const changedCidKeyMethod = (o1:any) =>{
      return o1.map((cidObj:any) => {
        return keys(cidObj)
      });
    };

    const cidChangedKeys = changedCidKeyMethod(finalCidList);
    const cidChangeLog = cidChangedKeys.map((item:any, index:any) => {
      return item.map((key:any) => {
        const newValue = values(pick(finalCidList[index], key));
        return `${key} : -> ${newValue}`
      })
    })
    const cidChangeLogs = cidChangeLog?.map((items:any) => {return `${items}`});

    return `${mpaChangeLogs}, ${cidChangeLogs}`
  };

  // remove other cid fields from final array to save only anchor_cid value in database
  // const removeExtraFields = () => {
  //   let originalCidList = cloneDeep(cidList);
  //   let finalData = originalCidList.map((row) => {
  //       delete row.facebook_cid;
  //       delete row.pinterest_cid;
  //       delete row.tiktok_cid;
  //       delete row.bell_cid;
  //       return row;
  //   });
  //   return finalData;
  // };

  // remove channel value of old data from form_mpa table
  const removeDataFromMpaList = () => {
    let originalMpaList = cloneDeep(mpaIntake);
    originalMpaList.channel = '';
    return originalMpaList;
  };

  // Display warning popup if the entered MPA budget exceeds the annual budget
  const buildBudgetWarningText = () => {
    return `The total MPA budget will be more than the annual budget. Do you want to proceed?`;
  }

  // Redirect to annual-budgets page
  const BudgetWarningAction = () => {
    history.push(`/map/annual-budgets`,'/map/mpa');
  }

  // display confirmation-rules popup after budget warning popup
  useEffect(() => {
    if(mpaWarningModal === true) {
      allCheckerModal ? setConfirmationModal(false) : setConfirmationModal(true);
    }
  }, [mpaWarningModal]);

  useEffect(() => {
    if(allCheckerModal === true){
      // const finalCidList = removeExtraFields();
      const finalCidList = cloneDeep(cidList);
      const finalMpaList = removeDataFromMpaList();
      const changeLog = handleGetChangeLog(finalCidList);
      let newMpa = {...finalMpaList};

      // need to update this hour value when EST/EDT changes, update to 19 or 20 depends
      if(new Date().getHours() < 20){
        newMpa.scheduled_start = moment(new Date(finalMpaList.scheduled_start)).add(0,"days").toISOString();
        newMpa.scheduled_end = moment(new Date(finalMpaList.scheduled_end)).add(0,"days").toISOString();
      } else {
        newMpa.scheduled_start = moment(new Date(finalMpaList.scheduled_start)).add(-1,"days").toISOString();
        newMpa.scheduled_end = moment(new Date(finalMpaList.scheduled_end)).add(-1,"days").toISOString();
      }

      // console.log('Final CID LIST');
      // console.log(finalCidList);
      // console.log('Final MPA LIST');
      // console.log(newMpa);
      // console.log('Change log');
      // console.log(changeLog);
      // return;

      const mpaPayload = { 
        mpaIntake: newMpa, 
        cidList: finalCidList, 
        changeLog: changeLog, 
        addedBy: props.userName
      };    
      dispatch(addNewMpa(mpaPayload));
    }
  }, [allCheckerModal]); 

  const checkNewMpaSubmission = async (event: any, rowId: any, openConfirmationModal: any, openMpaWarningModal: any) => {
    event.preventDefault();
    setMpaWarningModal(false);    
    let startDate = new Date(mpaIntake.scheduled_start)
    let endDate = new Date(mpaIntake.scheduled_end)

    if(mpaIntake.scheduled_start <= mpaIntake.scheduled_end && (
      (endDate.getMonth()+1 < 11 && startDate.getFullYear() + 1 >= endDate.getFullYear() && startDate.getMonth() + 1 >= 11) ||
      (startDate.getMonth()+1 < 11 && endDate.getMonth()+1 < 11 && startDate.getFullYear() === endDate.getFullYear()) ||
      (startDate.getMonth()+1 >= 11 && startDate.getFullYear() === endDate.getFullYear())
      )
    ){
      if(mandatoryModal === true){
        let year = mpaIntake.campaign.slice(0,7);
        let lob = mpaIntake.client_business_unit;
        // let channel = mpaIntake.channel;
        let product = mpaIntake.products;
        let schedule_start = mpaIntake.scheduled_start.slice(0,10)
        let anchor_cid_set = new Set();

        for(let i=0; i<cidList.length; i++){
          anchor_cid_set.add(cidList[i].anchor_cid)
        }

        let anchor_cid = "";
        let anchor_cid_list = Array.from(anchor_cid_set)
        let anchor_cids_without_dash = "";

        for(let i=0; i<anchor_cid_list.length; i++){
          if(i < anchor_cid_list.length - 1){
            anchor_cid = anchor_cid+"\'"+anchor_cid_list[i]+"\',";
          } else {
            anchor_cid = anchor_cid+"\'"+anchor_cid_list[i]+"\'";
          }
        }
        let params = {
          year: year,
          lob : lob,
          // channel: channel,
          product: product,
          anchor_cid: anchor_cid,
          schedule_start: schedule_start,
        }        

        try{
          // Allow Caribbean to create multiple MPAs for same CIDs
          const response = lob !== "Caribbean" ? await MpaService.retrieveAnchorIdProductCount(params) : {counts:0};
          if(response.counts > 0 ){
            toast.error("Alert!!! Specified Anchor CIDs already exist based on the given scheduled date range and product for the given fiscal year!");
          } else {
            // Display warning popup if the entered MPA budget exceeds the total annual budget
            try {
              const finalMpaList = removeDataFromMpaList();
              const response = await FormInfoService.retrieveAnnualBudgetValidationCount("F"+finalMpaList.campaign.slice(5,7), finalMpaList.client_business_unit, finalMpaList.products);

              if(finalMpaList.grand_total > response.annual_budget) {
                openMpaWarningModal();                  
              } else {
                setMpaWarningModal(true);                
              }
            } catch(ex) {
              toast.error("Couldn't retrieve annual budget info, please try again");
            }             
          }
        } catch(ex){
          toast.error("Couldn't retrieve count data, please try again");
        } 

        // show alert if current CID is in excluded CID list        
        let regex = new RegExp('-', 'g');
        anchor_cids_without_dash = anchor_cid.replace(regex, '');

        // anchor_cids_without_dash = "'5029527711','4439629472'";

        let excluded_cids_params = {
          anchor_cids_without_dash: anchor_cids_without_dash
        }

        try {
          const response = await MpaService.retrieveExcludedAnchorIds(excluded_cids_params);
          let result_cids = '';
          if(Object.keys(response['customerId']).length != 0){
            let result = response['customerId'];
            for(let key in result) {
              result_cids += result[key] + ', ';
            }
            result_cids = result_cids.replace(/,\s*$/, "");
            toast.error("Alert!!! Please delete following Anchor CID(s) from the Exclude CIDs list to proceed: "+result_cids, {
              autoClose: 10000
            });
          }
        } catch(ex){
          toast.error("Couldn't retrieve excluded cids count data, please try again");
        } 
      } else {
        setModal(true);
      }
    } else {
      toast.error("Alert!!! Schedule dates are not correct!");
    }
  };

  return (
    <>
      <ModalsConsumer>
        {
          modalsState => {
            return modalsState && (
              <>
                <Container fluid={true}>
                  <MandatoryFieldMpaModal 
                    modal={modal}
                    mpaIntake={mpaIntake}
                    cidList={cidList}
                    setModal={setModal}
                    mandatoryModal = {mandatoryModal}
                    setMandatoryModal = {setMandatoryModal}
                    fromAddMPA={true}
                  />
                  <ConfirmationPageMpaModal 
                    confirmationModal={confirmationModal}
                    setConfirmationModal={setConfirmationModal}
                    checkNewMpaSubmission = {checkNewMpaSubmission}
                    allCheckerModal = {allCheckerModal}
                    setAllCheckerModal = {setAllCheckerModal}
                    mpaIntake={mpaIntake}
                  />
                  <MpaWarningModal
                    confirmationHeader="Warning"
                    confirmationText={buildBudgetWarningText()}
                    executeConfirmationAction={BudgetWarningAction}
                    handleToggleModal={modalsState.handleToggleMpaWarningModal}
                    isModalOpen={modalsState.isMpaWarningModalOpen}
                    mpaWarningModal={mpaWarningModal}
                    setMpaWarningModal={setMpaWarningModal}
                  />
                  <MpaForm
                    mpaIntake={mpaIntake}
                    dropdownOptions={props.dropdownOptions}
                    isDisabled={false}
                    setMpaIntake={setMpaIntake}
                    setCidList={setCidList}
                    cidList={cidList}
                    auditLogs={[]}
                    isFormDisabled={props.isFormDisabled}
                    handleMpaFormSubmit={checkNewMpaSubmission}
                    fromAddMPA={true}
                  />
                </Container>
              </>
            )
          }
        }
      </ModalsConsumer>
    </>    
  );
};

export default AddMpa;
