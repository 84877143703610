import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppPage, PageHeader, WithLoading } from '../../../Common';

import { getUsername } from '../../../State/Authentication/Selectors/AuthenticationSelectors';
import { getBudgetPacingState } from '../../../State/BudgetPacing/Selectors/BudgetPacingSelectors';
import { getBudgetPacing } from '../../../State/BudgetPacing/Actions/BudgetPacingActions';
import BudgetPacingDashboard from './BudgetPacingDashboard';
import { ConfirmationModal, DashboardTable, PaginationToolbar, RowsPerPageDropdown, SearchBar, TablePagination }
  from '../../../Common';
  

const BudgetPacingDashboardWithLoading = WithLoading(BudgetPacingDashboard);

const BudgetPacingDashboardContainer = () => {
    const budgetPacingState = useSelector(getBudgetPacingState);
    const username = useSelector(getUsername);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!budgetPacingState.getBudgetPacingListLoadable.isReceived) {
            dispatch(getBudgetPacing())
        }
    }, [budgetPacingState.getBudgetPacingListLoadable.isReceived, dispatch]);

    return (
        <>
            <PageHeader style={{marginBottom: "2.5rem"}}>Budget Pacing Overview</PageHeader>
            <AppPage>
                <BudgetPacingDashboardWithLoading
                    loadable={budgetPacingState.getBudgetPacingListLoadable}
                    budgetPacingData={budgetPacingState.budgetPacingEntities}
                    userName={username}
                />
            </AppPage>
        </>
      );
}

export default BudgetPacingDashboardContainer;