import React, { useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { AnchorCidTableCellDropdown, ChannelDropdown, GridInput } from '../../../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceInCalendarMonths } from 'date-fns';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { addMonthsToDate, formatDate, getMonthName } from 'Common/actions';
import { channelDataToTotal } from './ChannelCalculationHelper';

interface IEditableChannelRow {
  index: any;
  channelOptions: any;
  anchorCidOptions: any;
  channelList: any;
  handleChannelChange: any;
  handleAnchorCidChange: any;
  handleTotalBudgetChange: any;
  handleCampaignChange: any;
  handleRemoveChannelFromList: any;
  isDisabled: boolean;
  channelData: any;
  modalsState: any;
  mpaIntake: any;
  handleMonthFundsChange: any;
  handleTotalPlatformFeeChange: any;
  fromAddMPA: boolean;
}

const EditableChannelRow = (props: IEditableChannelRow): JSX.Element => {
  const dates: { label: string; month: string }[] = useMemo(() => {
    if (props.channelData.channel === null || props.channelData.channel.trim() === '') return [];
    const result = [];
    const scheduled_start_month = formatDate(props.mpaIntake.scheduled_start);
    const scheduled_end_month = formatDate(props.mpaIntake.scheduled_end);
    const monthsDiff = differenceInCalendarMonths(scheduled_end_month, scheduled_start_month);
    for (let i = 0; i <= monthsDiff; i++) {
      let nextMonth = addMonthsToDate(scheduled_start_month, i);
      let newDate = nextMonth.getMonth() + 1 + '/01/' + nextMonth.getFullYear();
      result.push({ label: newDate, month: getMonthName(newDate) });
    }
    return result;
  }, [props.mpaIntake, differenceInCalendarMonths, props.channelData, formatDate, addMonthsToDate, getMonthName]);

  const total = useMemo(() => channelDataToTotal(props.channelData, dates), [props.channelData, dates, channelDataToTotal]);

  // accordion layout for channel section
  return (
    <div className="channel-row">
      <Accordion>
        <Row key={props.index}>
          <ChannelDropdown
            gridSize={1}
            channelOptions={props.channelOptions}
            channel={props.channelData.channel}
            handleChannelChange={(event: any) => props.handleChannelChange(event, props.index)}
            isDisabled={props.isDisabled}
            currentForm="MPA"
          />
          {props.channelData.channel !== null && props.channelData.channel.trim() !== '' && (
            <>
              <AnchorCidTableCellDropdown
                gridSize={2}
                anchorCidOptions={props.anchorCidOptions}
                handleAnchorCidChange={(event: any) => props.handleAnchorCidChange(event, props.index)}
                isDisabled={props.channelData.id ? true : false}
                anchorCid={props.channelData.anchor_cid}
              />
              <GridInput
                elemStyle={{ paddingRight: '0', fontSize: '15px' }}
                gridSize={1}
                inputAria="working-funds-text"
                inputId={`working-funds-${props.index}`}
                inputLabel="Working Funds ($):"
                inputType="number"
                value={props.channelData.working_funds !== undefined ? props.channelData.working_funds : ''}
                handleChange={(event: any) => props.handleTotalBudgetChange(event, props.index, dates)}
                // required={true}
                // isDisabled={props.channelData.channel === 'SEM' ? true : false}
              />
              <GridInput
                elemStyle={{ paddingRight: '0', fontSize: '15px' }}
                gridSize={1}
                inputAria="platform-fees-text"
                inputId={`platform-fees-${props.index}`}
                inputLabel="Platform Fees ($):"
                inputType="number"
                value={props.channelData.anchor_cid_platform_fee ?? ''}
                handleChange={(event: any) => props.handleTotalPlatformFeeChange(event?.target?.value ?? 0, props.index)}
              />
              <GridInput
                elemStyle={{ paddingRight: '0', fontSize: '15px' }}
                gridSize={1}
                inputAria="campaign-text"
                inputId={`campaign-name-${props.index}`}
                inputLabel="Campaign:"
                inputType="string"
                value={props.channelData.campaign_name ? props.channelData.campaign_name : ''}
                handleChange={(event: any) => props.handleCampaignChange(event, props.index)}
                required={true}
              />
              <GridInput
                elemStyle={{ paddingRight: '0', fontSize: '15px' }}
                gridSize={1}
                inputAria={props.channelData.channel + '-cid-text'}
                inputId={props.channelData.channel + '-cid-input'}
                inputLabel={props.channelData.channel + ' CID:'}
                inputType="string"
                value={props.channelData.anchor_cid ?? ''}
                isDisabled
              />
            </>
          )}
          {props.channelList.length >= 1 ? (
            <Col xs={1}>
              <div className="form-group">
                <label></label>
              </div>
              <div>
                <FontAwesomeIcon
                  style={{ cursor: 'pointer' }}
                  icon="trash"
                  size="lg"
                  title="Delete CID"
                  onClick={(event: any) => props.handleRemoveChannelFromList(event, props.index, props.modalsState)}
                />
              </div>
            </Col>
          ) : (
            ''
          )}
          <div className="col-4">{props.channelData.channel !== '' && <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" />}</div>
        </Row>
        <AccordionDetails>
          <Row>
            {dates.map(({ label, month }, index: number) => (
              <GridInput
                elemStyle={index !== 11 ? { paddingRight: '0', fontSize: '15px' } : { fontSize: '15px' }}
                key={index}
                gridSize={1}
                inputAria={month + '-month'}
                inputId={month + '-input'}
                inputLabel={label}
                inputType="number"
                isDisabled={props.channelData[`${month}_disabled`]}
                value={props.channelData[month] ?? ''}
                handleChange={(event: any) => props.handleMonthFundsChange(month, event, props.index, dates)}
              />
            ))}
            <GridInput
              inputLabel={`Delta ($):`}
              elemStyle={dates.length === 11 ? { fontSize: '15px' } : { paddingRight: '0', fontSize: '15px' }}
              gridSize={1}
              inputType="number"
              isReadOnly
              value={props.channelData.difference ?? 0}
            />
            <GridInput
              inputLabel="Total Amount ($):"
              elemStyle={dates.length === 10 ? { fontSize: '15px' } : { paddingRight: '0', fontSize: '15px' }}
              gridSize={1}
              inputType="number"
              isReadOnly
              value={total}
            />
          </Row>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default EditableChannelRow;
