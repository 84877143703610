import React from 'react';
import { Table, Input } from 'reactstrap';



const TableCell = ({ checked, onUpdateCell, index, header, booleanValue}) => {
    return (
      <td>
         <Input
              style={{position:'inherit',margin:0, width:'100%'}}
              inline={true}
              type="checkbox"
              checked={checked}
              onClick={()=>onUpdateCell(index,header,booleanValue)}
              // className="Table-checkbox-input"
          />
      </td>
    );
  };
  
const LinesOfBusinessTable = ({ linesOfBusiness, onUpdateCell }) => {
  const headers = Object.keys(linesOfBusiness[0])
  const exclusionList = ['Users','Email']
  return (
    <Table bordered striped>
      <thead>
        <tr>
          <th>Users</th>
          {headers.map((header) => {if(!exclusionList.includes(header)){return <th key={header}>{header}</th>}})}
        </tr>
      </thead>
      <tbody>
        {linesOfBusiness.map((row, index) => {
          return (
            <tr key={index}>
              <td>
                {row['Users']}
              </td>
              {headers.map((header) => {
                if(!exclusionList.includes(header)){
                let value = (row[header] === 'TRUE')
                  return (
                    <TableCell
                      index={index}
                      checked={value}
                      header={header}
                      booleanValue={row[header]}
                      onUpdateCell={onUpdateCell}
                    />
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default LinesOfBusinessTable;
