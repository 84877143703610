import { all, takeLatest } from 'redux-saga/effects';

import {
  ADD_NEW_ANNUAL_BUDGET,
  DELETE_SELECTED_ANNUAL_BUDGET,
  GET_ANNUAL_BUDGET,
  GET_ANNUAL_BUDGETS_CSV,
  GET_SELECTED_ANNUAL_BUDGET,
  UPDATE_SELECTED_ANNUAL_BUDGET,
} from './AnnualBudget/ActionTypes/AnnualBudgetActionTypes';

import { SET_SELECTED_ANNUAL_BUDGET_ID } from './AnnualBudgetFormContext/ActionTypes/AnnualBudgetFormContextTypes';

import {
  LOGIN,
  LOGOUT,
} from './Authentication/ActionTypes/AuthenticationActionTypes';

import {
  GET_ANCHOR_CIDS,
  GET_DROPDOWN_OPTIONS,
  GET_NEW_MPA_NUMBER,
  GET_PRODUCT_OPTIONS,
  GET_CHANNELS_FOR_SELECTED_PRODUCT,
  GET_CHANNEL_OPTIONS,
} from './FormInfo/ActionTypes/FormInfoActionTypes';

import {
  ADD_NEW_LOB,
  DELETE_SELECTED_LOB,
  GET_LOB,
  GET_SELECTED_LOB,
  UPDATE_SELECTED_LOB,
} from './Lob/ActionTypes/LobActionTypes';

import { SET_SELECTED_LOB_ID } from './LobFormContext/ActionTypes/LobFormContextTypes';

import {
  ADD_NEW_MPA,
  DELETE_SELECTED_MPA,
  GET_EXPORT_TO_BQ_STATUS,
  GET_MPAS,
  GET_MPAS_CSV,
  GET_SELECTED_MPA,
  UPDATE_SELECTED_MPA,
} from './Mpa/ActionTypes/MpaActionTypes';

import {
  SET_SELECTED_LOB,
  SET_SELECTED_MPA_ID,
} from './MpaFormContext/ActionTypes/MpaFormContextActionTypes';

import {
  ADD_CID_TO_EXCLUDE_LIST,
  DELETE_SELECTED_EXCLUDE_ID,
  GET_EXCLUDE_CIDS,
  GET_SELECTED_EXCLUDE_ID,
  UPDATE_SELECTED_EXCLUDE_ID,
} from './ExcludeCids/ActionTypes/ExcludeCidsActionTypes';

import { SET_SELECTED_EXCLUDE_ID } from './ExcludeCidsFormContext/ActionTypes/ExcludeCidsFormContextTypes';

import { OnLogout, onLogin } from './Authentication/Sagas/AuthenticationSagas';

import {
  onAddNewAnnualBudget,
  onDeleteSelectedAnnualBudget,
  onGetAnnualBudgets,
  onGetAnnualBudgetsCsv,
  onGetSelectedAnnualBudget,
  onUpdateSelectedAnnualBudget,
} from './AnnualBudget/Sagas/AnnualBudgetSagas';

import {
  onAddNewLob,
  onDeleteSelectedLob,
  onGetLobs,
  onGetSelectedLob,
  onUpdateSelectedLob,
} from './Lob/Sagas/LobSagas';

import {
  onAddNewMpa,
  onDeleteSelectedMpa,
  onGetExportToBqStatus,
  onGetMpas,
  onGetMpasCsv,
  onGetSelectedMpa,
  onUpdateSelectedMpa,
} from './Mpa/Sagas/MpaSagas';

import {
  onAddCidToExcludeList,
  onDeleteSelectedExcludeId,
  onGetExcludeCids,
  onGetSelectedExcludeId,
  onUpdateSelectedExcludeId,
} from './ExcludeCids/Sagas/ExcludeCidsSagas';

import { onSeletecteAnnualBudgetId } from './AnnualBudgetFormContext/Sagas/AnnualBudgetFormContextSagas';
import { onSetSeletecteLobId } from './LobFormContext/Sagas/LobFormContextSagas';
import {
  onSetSelectedMpaId,
  onSetSelectedLob,
} from './MpaFormContext/Sagas/MpaFormContextSagas';
import {
  onGetAnchorCids,
  onGetDropdownOptions,
  onGetNewMpaNumber,
  onGetProductOptions,
  onGetChannelsForSelectedProduct,
  onGetChannelOptions,
} from './FormInfo/Sagas/FormInfoSagas';
import { onSetSeletectedExcludeId } from './ExcludeCidsFormContext/Sagas/ExcludeCidsFormContextSagas';
import {
  onGetDagApiConfigById,
  onGetDagApiConfigDropdown,
  onGetDagApiConfigs,
  onUpdateDagApiConfig,
} from './DagApiConfig/Sagas/DagApiConfigsSagas';
import {
  GET_DAG_API_CONFIGS,
  GET_DAG_API_CONFIG_BY_ID,
  GET_DAG_API_CONFIG_DROPDOWN,
  UPDATE_DAG_API_CONFIG,
} from './DagApiConfig/ActionTypes/DagApiConfigsActionsTypes';

import {
  onGetBudgetPacing,
  onGetBudgetPacingMetrics,
} from './BudgetPacing/Sagas/BudgetPacingSagas';
import {
  GET_BUDGET_PACING,
  GET_BUDGET_PACING_METRICS,
} from './BudgetPacing/ActionTypes/BudgetPacingActionTypes';

export default function* () {
  yield all([
    takeLatest(ADD_NEW_ANNUAL_BUDGET, onAddNewAnnualBudget),
    takeLatest(ADD_NEW_MPA, onAddNewMpa),
    takeLatest(ADD_NEW_LOB, onAddNewLob),
    takeLatest(ADD_CID_TO_EXCLUDE_LIST, onAddCidToExcludeList),
    takeLatest(DELETE_SELECTED_ANNUAL_BUDGET, onDeleteSelectedAnnualBudget),
    takeLatest(DELETE_SELECTED_LOB, onDeleteSelectedLob),
    takeLatest(DELETE_SELECTED_EXCLUDE_ID, onDeleteSelectedExcludeId),
    takeLatest(DELETE_SELECTED_MPA, onDeleteSelectedMpa),
    takeLatest(GET_ANCHOR_CIDS, onGetAnchorCids),
    takeLatest(GET_ANNUAL_BUDGET, onGetAnnualBudgets),
    takeLatest(GET_ANNUAL_BUDGETS_CSV, onGetAnnualBudgetsCsv),
    takeLatest(GET_DROPDOWN_OPTIONS, onGetDropdownOptions),
    takeLatest(GET_PRODUCT_OPTIONS, onGetProductOptions),
    takeLatest(GET_EXPORT_TO_BQ_STATUS, onGetExportToBqStatus),
    takeLatest(GET_LOB, onGetLobs),
    takeLatest(GET_EXCLUDE_CIDS, onGetExcludeCids),
    takeLatest(GET_MPAS, onGetMpas),
    takeLatest(GET_MPAS_CSV, onGetMpasCsv),
    takeLatest(GET_NEW_MPA_NUMBER, onGetNewMpaNumber),
    takeLatest(GET_SELECTED_ANNUAL_BUDGET, onGetSelectedAnnualBudget),
    takeLatest(GET_SELECTED_LOB, onGetSelectedLob),
    takeLatest(GET_SELECTED_EXCLUDE_ID, onGetSelectedExcludeId),
    takeLatest(GET_SELECTED_MPA, onGetSelectedMpa),
    takeLatest(LOGIN, onLogin),
    takeLatest(LOGOUT, OnLogout),
    takeLatest(SET_SELECTED_ANNUAL_BUDGET_ID, onSeletecteAnnualBudgetId),
    takeLatest(SET_SELECTED_LOB_ID, onSetSeletecteLobId),
    takeLatest(SET_SELECTED_EXCLUDE_ID, onSetSeletectedExcludeId),
    takeLatest(SET_SELECTED_MPA_ID, onSetSelectedMpaId),
    takeLatest(SET_SELECTED_LOB, onSetSelectedLob),
    takeLatest(UPDATE_SELECTED_ANNUAL_BUDGET, onUpdateSelectedAnnualBudget),
    takeLatest(UPDATE_SELECTED_LOB, onUpdateSelectedLob),
    takeLatest(UPDATE_SELECTED_EXCLUDE_ID, onUpdateSelectedExcludeId),
    takeLatest(UPDATE_SELECTED_MPA, onUpdateSelectedMpa),
    takeLatest(
      GET_CHANNELS_FOR_SELECTED_PRODUCT,
      onGetChannelsForSelectedProduct
    ),
    takeLatest(GET_CHANNEL_OPTIONS, onGetChannelOptions),
    takeLatest(GET_DAG_API_CONFIGS, onGetDagApiConfigs),
    takeLatest(GET_DAG_API_CONFIG_BY_ID, onGetDagApiConfigById),
    takeLatest(UPDATE_DAG_API_CONFIG, onUpdateDagApiConfig),
    takeLatest(GET_DAG_API_CONFIG_DROPDOWN, onGetDagApiConfigDropdown),
    takeLatest(GET_BUDGET_PACING, onGetBudgetPacing),
    takeLatest(GET_BUDGET_PACING_METRICS, onGetBudgetPacingMetrics),
  ]);
}
