import ApiService from './ApiService';

export default class LobService {
  static createLob(lobData: any): Promise<any> {
    return ApiService.post({ relativeUrl: `/lob/api/lobs`, body: lobData });
  };

  static deleteSelectedLob(lobId: string, payload: string): Promise<any> {
    return ApiService.delete({ relativeUrl: `/lob/api/lobs/${lobId}`, body: payload });
  }

  static retrieveLobs(): Promise<Array<any>> {
    return ApiService.get({ relativeUrl: '/lob/api/lobs' });
  };

  static retrieveLobsCsv(): Promise<any> {
    return ApiService.get({ relativeUrl: '/lob/api/lobs-file' });
  };

  static retrieveSelectedLob(lobId: string): Promise<Array<any>> {
    return ApiService.get({ relativeUrl: `/lob/api/lobs/${lobId}` });
  };

  static updateSelectedLob(lobId: string, lobData: any): Promise<any> {
    return ApiService.put({
      relativeUrl: `/lob/api/lobs/${lobId}`,
      body: lobData
    });
  };
};
