import { AppState } from '../../Common/AppState';
import { AuthState } from '../Models/AuthState';

import { createSelector } from 'reselect';

export const getAuthenticationState = (state: AppState) =>
  state.authenticationState;

export const getUsername = createSelector(
  getAuthenticationState,
  (authenticationState: AuthState) => {
    return authenticationState['userInfo']
      ? `${authenticationState['userInfo']['first_name']} ${authenticationState['userInfo']['last_name']}`
      : '';
  }
);

export const getToken = createSelector(
  getAuthenticationState,
  (authenticationState: AuthState) => {
    return authenticationState['userInfo'] ? authenticationState['token'] : '';
  }
);

export const getUserPermissions = createSelector(
  getAuthenticationState,
  (authenticationState: AuthState) => authenticationState['userPermissions']
);
