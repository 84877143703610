import React, {useState} from 'react';
import PropTypes from 'prop-types'

import { TableCellDropdown } from '.';
import { GridDropdown } from './';
import { DropdownItem } from 'reactstrap';

export const AnchorCidTableCellDropdown = (props: any) => {
  const [isCidDropdownOpen, setIsCidDropdownOpen] = useState(false);

  const toggleCidDropdown = () => setIsCidDropdownOpen(prevState => !prevState);

  return (
    <GridDropdown
      elemClassname='anchor_cid_dropdown'
      gridSize={props.gridSize}
      inputLabel='Anchor CID:'
      isDisabled={props.isDisabled || props.anchorCidOptions.length < 1}
      isDropdownOpen={isCidDropdownOpen}
      handleToggle={toggleCidDropdown}
      placeholder='---------'
      value={props.anchorCid}
    >
      {props.anchorCidOptions.map((item: string) =>
        <DropdownItem key={item} value={item} onClick={props.handleAnchorCidChange}>
          {item}
        </DropdownItem>
      )}
    </GridDropdown>

    // <TableCellDropdown
    //   isDisabled={props.isDisabled || props.anchorCidOptions.length < 1}
    //   isDropdownOpen={isCidDropdownOpen}
    //   handleToggle={toggleCidDropdown}
    //   placeholder=""
    //   value={props.anchorCid}
    // >
    //   {props.anchorCidOptions.map((item: string) =>
    //     <DropdownItem key={item} value={item} onClick={props.handleAnchorCidChange}>
    //       {item}
    //     </DropdownItem>
    //   )}
    // </TableCellDropdown>
  );
};

AnchorCidTableCellDropdown.propTypes = {
  gridSize: PropTypes.number,
  anchorCid: PropTypes.any,
  anchorCidOptions: PropTypes.array,
  handleAnchorCidChange: PropTypes.func,
  isDisabled: PropTypes.bool
};
