import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

interface Props{
  budgetOrderCategories: Array<string>, 
  handleBudgetOrderCategoryChange: (value: string) => void, 
  selectedBudgetOrderCategory: string
}

export const BudgetOrderCategoriesDropdown = (props: Props) => {
  const { budgetOrderCategories, handleBudgetOrderCategoryChange, selectedBudgetOrderCategory } = props;

  const [isBudgetOrderCategoriesDropdownOpen, setisBudgetOrderCategoriesDropdownOpen] = useState<boolean>(false);

  const handleSelectOption= (event: any) => {
    handleBudgetOrderCategoryChange(event.target.value);
  }

  const handleToggleBudgetOrderCategoriesDropdown = () => {
    setisBudgetOrderCategoriesDropdownOpen(prevState => !prevState);
  }

  return (
    <Dropdown
      isOpen={isBudgetOrderCategoriesDropdownOpen}
      toggle={handleToggleBudgetOrderCategoriesDropdown}
      className="mr-2"
    >
      <DropdownToggle caret color="primary">
        { selectedBudgetOrderCategory ? selectedBudgetOrderCategory : 'Category' }
      </DropdownToggle>
      <DropdownMenu
        modifiers={{
          setMaxHeight: {
            enabled: true,
            order: 890,
            fn: (data: any) => {
              return {
                ...data,
                styles: {
                  ...data.styles,
                  overflow: 'auto',
                  maxHeight: 200
                }
              }
            }
          }
        }}
      >
        { budgetOrderCategories.map(
          (category: string) => (
            <DropdownItem
              key={category}
              value={category}
              onClick={handleSelectOption}
            >
              {category}
            </DropdownItem>
          )
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

BudgetOrderCategoriesDropdown.propTypes = {
  budgetOrderCategories: PropTypes.array.isRequired,
  handleBudgetOrderCategoryChange: PropTypes.func.isRequired,
  selectedBudgetOrderCategory: PropTypes.string.isRequired,
};
