import React, { useMemo } from "react";
import ApiConfigDropdown from "./ApiConfigDropdown";
import { Alert, Button, Col, Row } from "reactstrap";
import { IconButton } from "@mui/material";
import { RemoveCircleOutline } from "@mui/icons-material";
import { GridInput } from "Common";
import { IFormValues } from "./FormValues";

interface ICustomAccountIDSettingsForm {
  formValues: IFormValues;
  RemoveCustomAccountID: (index: number) => void;
  CustomAccountIDOnChange: (value: string, index: number) => void;
  AddCustomAccount: () => void;
  IsCustomAccountsIDsOnSelect: (value: string) => void;
}

const options = {
  cust_acct: "True",
  prm_mpa: "False",
};

const CustomAccountIDSettingsForm = ({
  formValues,
  IsCustomAccountsIDsOnSelect,
  RemoveCustomAccountID,
  CustomAccountIDOnChange,
  AddCustomAccount,
}: ICustomAccountIDSettingsForm): JSX.Element => {
  const isCustomAccountIds = useMemo(() => formValues.custom_account_cids === "cust_acct", [formValues.custom_account_cids]);

  return (
    <Row>
      <Col xs={12}>
        <h3>Custom Account ID Settings</h3>
      </Col>
      <Col xs={12}>
        <Alert color="primary">
          <strong>Notes:</strong>
          <br />
          If set to TRUE data will be pulled from the custom CID list to the right
          <br />
          If set to FALSE data will be pulled from a BQ table of all MPAs in PRM Online
        </Alert>
      </Col>

      <ApiConfigDropdown
        inputLabel="Custom Account IDs"
        itemKeyPrefix="custom-account-ids"
        options={options}
        onSelect={IsCustomAccountsIDsOnSelect}
        value={formValues.custom_account_cids}
      />
      {isCustomAccountIds &&
        formValues.customAccountIDs.map((customAccountID, index) => (
          <GridInput
            required
            gridSize={3}
            inputLabel={`Custom Account ID #${index + 1}`}
            labelExtra={
              <IconButton
                disabled={formValues.customAccountIDs.length < 2}
                style={{ marginLeft: "5px" }}
                onClick={() => RemoveCustomAccountID(index)}
              >
                <RemoveCircleOutline />
              </IconButton>
            }
            key={`custom-account-Ids-${index}`}
            inputType="string"
            value={customAccountID}
            handleChange={(event) => CustomAccountIDOnChange(event.target.value, index)}
          />
        ))}
      {isCustomAccountIds && (
        <Col xs={12}>
          <Button color="link" disabled={formValues.customAccountIDs.length > 4} onClick={AddCustomAccount}>
            Add Custom Account
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default CustomAccountIDSettingsForm;
