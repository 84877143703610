import {
  AnnualBudgetFormContextState,
  createDefaultAnnualBudgetFormContextState
} from '../Models/AnnualBudgetFormContextState';

import { SET_SELECTED_ANNUAL_BUDGET_ID } from '../ActionTypes/AnnualBudgetFormContextTypes';

export default function (
  state = createDefaultAnnualBudgetFormContextState(),
  action: any
): AnnualBudgetFormContextState {
  switch(action.type) {
    case SET_SELECTED_ANNUAL_BUDGET_ID:
      return {
         ...state,
         selectedAnnualBudgetId: action.annualBudgetId
      };

    default:
      return state;
  };
};
