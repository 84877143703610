import React from 'react';

import './Loader.css';

export const Loader = () => {
  return (
    <div className="Spinner">
      <div className="Bounce1"></div>
      <div className="Bounce2"></div>
      <div className="Bounce3"></div>
    </div>
  );
};
