import React from 'react'
import { Box, FormGroup, FormLabel} from '@mui/material';

const YearSelect = (props: any) => {
    return (
        <>
            <Box sx={{display:'flex',ml:5, mb:2, height:100}}>
                <div style={{display:'flex', flexDirection:"column",width:120, borderRadius:1,border: '1px solid black'}}>
                    <FormLabel sx={{color: "white", backgroundColor:"#006AC3", paddingTop:1,paddingBottom: 1, textAlign:"center"}}>Fiscal Year</FormLabel>     
                    <div style={{overflowY:"scroll"}}>
                        <FormGroup>
                        {props.fiscalList.map((item,index) => 
                            item === props.selectedFiscal?
                            <FormLabel key={index} sx={{paddingLeft:1,paddingRight:2,mb:0,color:"white",backgroundColor:'#006AC3'}} onClick={(e)=>props.handleYearChange(e.target)}>{item}</FormLabel>
                            :
                            <FormLabel key={index} sx={{paddingLeft:1,paddingRight:2,mb:0,color:"black"}} onClick={(e)=>props.handleYearChange(e.target)}>{item}</FormLabel>
                        )}
                        </FormGroup>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default YearSelect;