import {
    GET_BUDGET_PACING, GET_BUDGET_PACING_SUCCESS, GET_BUDGET_PACING_ERROR,
    GET_BUDGET_PACING_METRICS, GET_BUDGET_PACING_METRICS_SUCCESS, GET_BUDGET_PACING_METRICS_ERROR
  } from '../ActionTypes/BudgetPacingActionTypes';

export const getBudgetPacing = () => {
    return {
        type: GET_BUDGET_PACING
    };
};

export const getBudgetPacingSuccess = (result: any) => {
    return {
        type: GET_BUDGET_PACING_SUCCESS,
        result
    };
};

export const getBudgetPacingError = (error: any) => {
    return {
        type: GET_BUDGET_PACING_ERROR,
        error
    };
};

export const getBudgetPacingMetrics = () => {
    return {
        type: GET_BUDGET_PACING_METRICS
    };
};

export const getBudgetPacingMetricsSuccess = (result: any) => {
    return {
        type: GET_BUDGET_PACING_METRICS_SUCCESS,
        result
    };
};

export const getBudgetPacingMetricsError = (error: any) => {
    return {
        type: GET_BUDGET_PACING_METRICS_ERROR,
        error
    };
};