import ApiRequest from '../Utils/ApiRequest';

interface ApiRequestParams {
  relativeUrl: string;
  queryParams?: any;
  body?: any;
  addToken?: boolean;
}

class ApiService {
  static get({ relativeUrl, queryParams = {}, addToken = true }: ApiRequestParams): Promise<any> {
    return ApiService.responseHandler(new ApiRequest()
      .withAuthToken(addToken)
      .withQueryParams(queryParams)
      .get(relativeUrl)
    );
  }

  static post({ relativeUrl, body, addToken = true }: ApiRequestParams): Promise<any> {
    return ApiService.responseHandler(new ApiRequest()
      .withAuthToken(addToken)
      .withBody(body)
      .post(relativeUrl)
    );
  }

  static put({ relativeUrl, body, addToken = true }: ApiRequestParams): Promise<any> {
    return ApiService.responseHandler(new ApiRequest()
      .withAuthToken(addToken)
      .withBody(body)
      .put(relativeUrl)
    );
  }

  static delete({ relativeUrl, body, addToken = true }: ApiRequestParams): Promise<any> {
    return ApiService.responseHandler(new ApiRequest()
      .withAuthToken(addToken)
      .withBody(body)
      .delete(relativeUrl)
    );
  }

  static responseHandler(apiRequest: Promise<Response>): Promise<any> {
    return apiRequest
      .then(response => {
        if (response.ok) {
          const CONTENT_TYPE_HEADER = response.headers.get('Content-Type');

          if (response.status === 204) return {}

          if (CONTENT_TYPE_HEADER && CONTENT_TYPE_HEADER.includes('csv')) return response.blob()

          return response.json();
        } else {
          throw response;
        }
      })
      .then(data => data)
      .catch(ApiService.errorHandler)
  };

  static async errorHandler(errorResponse: Response): Promise<Error> {
    const errorText = await errorResponse.text();
    console.log('ERROR TEXT');
    console.log(errorText);
    const errorObj = JSON.parse(errorText);
    const errorMsg = errorObj['error']['message'];
    const errorStatusCode = errorObj['error']['code'];
    // return Promise.reject(`${errorStatusCode} - ${errorResponse.url} request failed because ${errorMsg}`);
    return Promise.reject(`${errorMsg}`);
  }
}

export default ApiService;
