import { Container } from 'reactstrap';
import { AppPage, GridDivider, PageHeader } from '../../Common';
import React from 'react';
import MarginalCostSession from './MarginalCostSession';
import { BudgetBreakdownSession } from './BudgetBreakdown';
import { BudgetEstimatorSession } from './BudgetEstimators';
import ImpressionEstimatorSession from './ImpressionEstimatorSession';

const MediaCalculators = (): JSX.Element => {
  return (
    <>
      <PageHeader>Media Calculators</PageHeader>
      <AppPage>
        <Container fluid>
          <MarginalCostSession />
          <GridDivider />
          <BudgetBreakdownSession />
          <GridDivider />
          <BudgetEstimatorSession />
          <GridDivider />
          <ImpressionEstimatorSession />
        </Container>
      </AppPage>
    </>
  );
};

export default MediaCalculators;
