import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getUsername } from '../../../State/Authentication/Selectors/AuthenticationSelectors';

import { getAnnualBudgetState } from '../../../State/AnnualBudget/Selectors/AnnualBudgetSelectors';
import { getAnnualBudgets } from '../../../State/AnnualBudget/Actions/AnnualBudgetActions';

import AnnualBudgetsDashboard from './AnnualBudgetsDashboard';

import { AppPage, PageHeader, WithLoading } from '../../../Common';
import { convertToObject } from 'typescript';

const AnnualBudgetsDashboardWithLoading = WithLoading(AnnualBudgetsDashboard);

const AnnualBudgetsDashboardContainer = () => {
  const annualBudgetState = useSelector(getAnnualBudgetState);
  const username = useSelector(getUsername);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!annualBudgetState.getAnnualBudgetListLoadable.isReceived) {
      dispatch(getAnnualBudgets())
    }
  }, [annualBudgetState.getAnnualBudgetListLoadable.isReceived, dispatch]);

  let isBudgetDeleted;
  if(!annualBudgetState.deleteSelectedAnnualBudgetLoadable.isLoading) {
    if(annualBudgetState.deleteSelectedAnnualBudgetLoadable.isReceived) {
      isBudgetDeleted = annualBudgetState.deleteSelectedAnnualBudgetLoadable.isReceived;
    }
  }

  return (
    <>
      <PageHeader>Annual Budgets</PageHeader>
      <AppPage>
        <AnnualBudgetsDashboardWithLoading
          loadable={annualBudgetState.getAnnualBudgetListLoadable}
          annualBudgetsList={annualBudgetState.entities}
          userName={username}
          isBudgetDeleted={isBudgetDeleted}
        />
      </AppPage>
    </>
  );
};

export default AnnualBudgetsDashboardContainer;
