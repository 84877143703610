import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Alert, Button, Spinner, Table } from 'reactstrap';
import ApiRequest from '../../Utils/ApiRequest';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { toast } from 'react-toastify';

interface RowData {
    enddate: string
    reportid: string
    run:    boolean
    startdate: string
    tablename: string
    updatelob: string
}

export const KenshooDashboard = () => {
    const [rows,setRows] = useState<RowData[]>([])
    const [refresh, setRefresh] = useState(true)
    const [status, setStatus] = useState({status: "Failed to update"})
    const [disabled,setDisabled] = useState<number[]>([])

    useEffect(()=>{
        if(refresh === true){
            new ApiRequest().withAuthToken(true).get('/kbp/api/row')
            .then(res=>res.json())
            .then(data=>{setRows(data); setRefresh(false)})
            .catch(error=> toast.error("Something wrong with kbp row API, please try again!"))

            //temporary disable /kbp/api/trigger API call
            // new ApiRequest().withAuthToken(true).get('/kbp/api/trigger').then(res => res.json()).then(data=>{
            //     setStatus(data)
            // })
        }
        setRefresh(false)
    },[refresh])
    
    const resetDisabled = (index:any) => {
        setTimeout(()=>{
            if( index !== null){
                let newDisabled = [...disabled]
                newDisabled.filter(data=> data !== index)
                setDisabled(newDisabled)
            }
        },10)
        
    }

    const sendUpdate = (newRows:any, index:any) => {
        let body = newRows
        // setRows(newRows)
        new ApiRequest().withAuthToken(true).withBody(body).put('/kbp/api/row').then(res=> res.text()).then(data=>{if(data === 'OK'){
            setRefresh(true);resetDisabled(index)
        }})
        .catch(error=>toast.error("Something wrong with kbp row API, please try again!"))
    }


    const sendSelectedRows = (selectedRows: any) => {
        let body = selectedRows
        new ApiRequest().withAuthToken(true).withBody(body).patch('/kbp/api/row').then(res=> res.text()).then(data=>{if(data === 'OK'){
            setRefresh(true)
        }})
        .catch(error=>toast.error("Something wrong with kbp row API, please try again!"))
    }

    const selectRadioButton = (index:number) => {
        let newDisabled = [...disabled]
        newDisabled.push(index)
        setDisabled(newDisabled)

        let newRows = [...rows]
        let updateRow = {...newRows[index]}
        updateRow['run'] = !rows[index].run
        sendUpdate(updateRow, index)
    }

    const changeInput = (index:number, e:any, type:string) => {
        let newRows = [...rows]
        let updatedRow:any = {...newRows[index]}
        updatedRow[type] = e.target.value
        sendUpdate(updatedRow, index)
    }

    const toggleSelection = (toggle: boolean) => {
        let newRows = [...rows]
        let newArray:any = []
        newRows.forEach(data=>{
            let newData = {...data}
            newData['run'] = toggle
            newArray.push(newData)
        })
        sendSelectedRows(newArray)
    }

    const addNewRow = (reportId:any) => {
        if(reportId !== undefined && reportId.trim() !== ''){
            let newRows = [...rows]
            let newRow = {
                enddate: '',
                reportid: reportId,
                run: false,
                startdate: '',
                tablename: '',
                updatelob: ''
            }
            newRows.push(newRow)
            sendUpdate(newRows, null)
        }
    }

    const deleteReport = (response:any, row:string) => {
        if(response === true){
            new ApiRequest().withAuthToken(true).withBody(row).delete('/kbp/api/row').then(res=> res.text()).then(data=>{
                if(data === "OK"){
                    setRefresh(true)
                }else {
                    alert("Could not delete this row.")
                }
            }).catch(error=> alert("Could not delete this row."))
        }
        
    }

    const runReport = () => {
        //temporary disable /kbp/api/trigger API call
        alert('Faled to start reports.')
        
        // new ApiRequest().withAuthToken(true).put('/kbp/api/trigger').then(res=>res.text()).then(data=>{
        //     if(data === 'OK'){
        //         alert('Started processes')
        //     }else{
        //         alert('Faled to start reports.')
        //     }
        // }).catch(error=>alert("Failed to start reports"))
    }

    const mapRows = () => {
        return rows.map((data:any, index:any)=>{
            return(
                <tr>
                    <th>{disabled.includes(index) ? <Spinner size="sm" color="secondary" /> : 
                        data.run ? 
                        <FontAwesomeIcon color="green" onClick={()=>{selectRadioButton(index)}} icon={faCheck}/> : 
                        <FontAwesomeIcon color="red" onClick={()=>{selectRadioButton(index)}} icon={faTimes}/>}
                    </th>
                    <th>
                        {data.updatelob}
                        {/* <Input value={data.updatelob} onChange={(e)=>changeInput(index, e, 'updatelob')}/> */}
                    </th>
                    <th>
                        {data.tablename}
                        {/* <Input value={data.tablename}onChange={(e)=>changeInput(index, e, 'tablename')}/>                       */}
                    </th>
                    <th>
                        {data.reportid}
                    </th>
                    <th>
                        {data.tabstartdatelename}
                        {/* <Input value={data.tabstartdatelename}onChange={(e)=>changeInput(index, e, 'startdate')}/>                       */}
                    </th>
                    <th>
                        {data.enddate}
                        {/* <Input value={data.enddate}onChange={(e)=>changeInput(index, e, 'enddate')}/>                       */}
                    </th>
                    <th>
                        <FontAwesomeIcon icon={faTrash} onClick={()=>{
                            let response = window.confirm(`Are you sure you want to delete ${data.reportid}.`);
                            deleteReport(response, data)
                        }}/>
                    </th>
            </tr>          
            )
            
        })
    }

    return (<div style={{padding: '5vw'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        <Button onClick={()=>{toggleSelection(true)}}>
                            All
                        </Button>
                        <Button style={{marginLeft: '1vw'}} onClick={()=>{toggleSelection(false)}} color='secondary'>
                            None
                        </Button>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Button style={{marginBottom: '1rem'}} onClick={()=>{runReport()}}>
                            Run Reports
                        </Button>
                        <Alert style={{marginLeft: '1vw'}} color='primary'>
                            Status: {status.status}
                        </Alert>
                    </div>
                </div>
                <Table responsive hover>
                    <thead>
                        <tr>
                            <th>Run?</th>
                            <th>Update Lob</th>
                            <th>Table Name</th>
                            <th>Report ID</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mapRows()}
                    </tbody>
                </Table>

                <Button onClick={()=>{let reportId = prompt("Please enter report ID","");addNewRow(reportId)}}>
                    Add new row
                </Button>
        </div>
    )
}
