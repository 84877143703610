import { IDagApiConfigDto, IDagApiConfigListDto } from "State/DagApiConfig/Models/DagApiConfigsState";
import ApiService from "./ApiService";

export default class DagApiConfigsService {
  static retrieveDagApiConfigs(): Promise<Array<IDagApiConfigListDto>> {
    return ApiService.get({ relativeUrl: "/dag-api-config/api/dag-api-configs" });
  }
  static retrieveDagApiConfigById(rowId): Promise<Array<IDagApiConfigDto>> {
    return ApiService.get({ relativeUrl: `/dag-api-config/api/dag-api-configs/${rowId}` });
  }
  static updateDagApiConfig(rowId, payload): Promise<any> {
    return ApiService.put({ relativeUrl: `/dag-api-config/api/dag-api-configs/${rowId}`, body: payload });
  }
  static retrieveDagApiConfigDropdown(rowId): Promise<any> {
    return ApiService.get({ relativeUrl: `/dag-api-config/api/dag-api-configs/${rowId}/list` });
  }
}
