import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LobForm from '../Common/LobForm'

import { isEqual, pick, keys, values, filter, union, max } from 'lodash';

import { LobFormInterface } from '../../../Models/LobFormInterface';
import { ChannelFieldInterface } from '../../../Models/ChannelFieldInterface';

import { updateSelectedLob, deleteSelectedLob } from '../../../State/Lob/Actions/LobActions';
import { getUserPermissions } from '../../../State/Authentication/Selectors/AuthenticationSelectors';

import { ConfirmationModal } from '../../../Common';

import { ModalsConsumer } from '../../../Contexts/ModalsContext';
import { toast } from 'react-toastify';

import { RequiredFieldModal } from '../../../Common/RequiredFieldModal';

const EditLob = (props: any) => {

  const dispatch = useDispatch();

  const [lobData, setLobData] = useState<LobFormInterface>(props.selectedLob.lob);
  const [selectedLobId, setSelectedLobId] = useState(0);
  const [budgetSharingFlagInvalid, setBudgetSharingFlagInvalid] = useState(false);
  const [channelsToRemove, setChannelsToRemove] = useState([]);
  const [modal, setModal] = useState(false);
  const [requiredFields, setRequiredFields] = useState([]);
  const [selectedProductChannelList, setSelectedProductChannelList] = useState([]);  
  const userPermissions = useSelector(getUserPermissions);

  useEffect(() => {
    setLobData(props.selectedLob.lob);
    populateChannelData();
  }, [props.selectedLob.lob]);

  const [channelList, setChannelList] = useState<Array<ChannelFieldInterface>>([
  {
    channel: "",
    anchor_cid: "",
    // bing_cid: "",
    // apple_cid: "",
    // google_cid: "",
    campaign: "",
    lob_id: 0
  }]);

  // populate multiple channel fields on edit lob form
  const populateChannelData = () => {
    let allChannelData = props.selectedLob.channel_data;
    let retrievedChannelList: ChannelFieldInterface[] = [];

    for (let channel of allChannelData) {
      let temp: ChannelFieldInterface = {
        channel:        channel.channel,
        anchor_cid:     channel.anchor_cid,
        // bing_cid:       channel.bing_cid,
        // apple_cid:      channel.apple_cid,
        // google_cid:     channel.google_cid,
        campaign:       channel.campaign,
        lob_id:         channel.id
      }
      retrievedChannelList.push(temp);
    }
    setChannelList(retrievedChannelList);
  };

  const buildUpdateLobConfirmationText = () => {
    // const lobAnchorCid = lobData['anchor_cid'];
    const lobProduct = lobData['products'];
    const lobName = lobData['name']

    return `The Product '${lobProduct}' is going to be updated under ${lobName}. Do you want to proceed?`;
  };

  const handleUpdateLob = (event: any, openUpdateConfirmationModal: any, id: any) => {
    event.preventDefault();
    setSelectedLobId(id);

    // Display alert if no values are changed
    const mergedData = mergeLobAndChannel();
    const lobDataToUpdate = getChangedData(props.selectedLob.channel_data, mergedData);
    if(isEqual(mergedData, props.selectedLob.channel_data)) {
      toast.error("Alert!!! Can't submit unchanged form");
    } else {
      // Check required fields values for entered channels
      const requiredFields = checkRequiredFields(lobDataToUpdate);
      if(requiredFields.length > 0) {
        setModal(true);
        setRequiredFields(requiredFields);
      } else {
        openUpdateConfirmationModal();
      }        
    }
  };

  // Create Lob change logs
  const handleGetChangeLog = (newData) => {    
    const changedOldLobKeysMethod = (o1: any, o2: any) => {
      return filter(union(keys(o1), keys(o2)), (key) => {
        return o1[key] !== o2[key];
      });
    };

    const changedNewLobKeysMethod = (o1: any) => {
      return keys(o1)
    };

    let mergedChangeLog = [];
    let oldData = props.selectedLob.channel_data;
    
    // Create changelog array for newly inserted channels data
    const addedRows = newData.filter(row => row.id === 0);
    for(let row of addedRows) {
      const lobChangedKeys = changedNewLobKeysMethod(row);
  
      const lobChangeLogs = lobChangedKeys.map((key) => {
          const newValue = values(pick(row, key));
          return `${key} -> ${newValue}`;
      });
      mergedChangeLog.push(lobChangeLogs.toString());    
    }    

    // Create changelog array for updated channels data
    const updatedRows = newData.filter(row => row.id != 0);
    for(let row of updatedRows) {
      const oldRow = oldData.find(element => element.id === row.id);
      const lobChangedKeys = changedOldLobKeysMethod(row, oldRow);

      const lobChangeLogs = lobChangedKeys.map((key) => {
        const newValue = values(pick(row, key));
        const prevValue = values(pick(oldRow, key));
        return `${key} (${oldRow.channel}) : ${prevValue} -> ${newValue}`;
      });
      mergedChangeLog.push(lobChangeLogs.toString());
    }
    return mergedChangeLog;
  };

  // Create changelog array for deleted channels data
  const handleDeletedChannelsChangeLog = () => {
    let deletedChannelsChangeLog = [];
    let oldData = props.selectedLob.channel_data;
    
    const deletedRows = oldData.filter(row => channelsToRemove.includes(row.id));
    for(let row of deletedRows) {
      let deleteMsg = `Anchor CID (${row.channel}) : ${row.anchor_cid} -> Deleted`;
      deletedChannelsChangeLog.push(deleteMsg); 
    }
    return deletedChannelsChangeLog;
  }

  // Merge channel cids data with the lob form data
  const mergeLobAndChannel = () => {
    let mergedData: LobFormInterface[] = [];
    for(let channel of channelList) {
        let temp: LobFormInterface = {
            channel:            channel.channel,
            name:               lobData.name,
            name_abbr:          lobData.name_abbr,
            products:           lobData.products,            
            partner_google:     '',
            partner_bing:       '',
            anchor_cid:         channel.anchor_cid,
            // google_cid:         channel.google_cid,
            // bing_cid:           channel.bing_cid,
            // apple_cid:          channel.apple_cid,
            id:                 channel.lob_id,
            campaign:           channel.campaign,
            // kenshoo_profile:    lobData.kenshoo_profile,
            // kenshoo_token:      lobData.kenshoo_token,
        }

        if(temp.channel === 'Google') {
          if(lobData.partner_google) {
            temp.partner_google = lobData.partner_google;
          } else {
              temp.partner_google = 'Google';
          }
        }
        if(temp.channel === 'Bing') {  
          if(lobData.partner_bing) {
              temp.partner_bing = lobData.partner_bing;
          } else {
              temp.partner_bing = 'Bing';
          }
        }
        mergedData.push(temp);
    }
    return mergedData;
  };

  // Create final array for the changed data on the edit page to save it in database
  const getChangedData = (oldData, newData) => {
    let changedData = [];
    
    const addedRows = newData.filter(row => row.id === 0);
    if(addedRows) {
      changedData = addedRows;
    }    

    for(let i=0; i < oldData.length; i++) {
      const oldRow = oldData[i];
      const newRow = newData.find(row => row.id === oldData[i].id);

      if(newRow && !isEqual(oldRow, newRow)) {
        changedData.push(newRow);
      }
    }

    return changedData;
  };

  // Create a list if the required fields are empty in each channel row
  const checkRequiredFields = (lobDataToUpdate) => {
    let requiredFields = [];
    for(let index of lobDataToUpdate) {
      if(index.channel === '' && !requiredFields.includes('Channel')) {
        requiredFields.push('Channel');
      } 
      if(index.anchor_cid === '' && !requiredFields.includes('Anchor CID')) {
        requiredFields.push('Anchor CID');
      } 
      if(index.campaign === '' && !requiredFields.includes('Campaign')) {
        requiredFields.push('Campaign');
      } 
    }
    return requiredFields;
  };

  const updateLobAction = () => {    
    const mergedData = mergeLobAndChannel();
    const lobDataToUpdate = getChangedData(props.selectedLob.channel_data, mergedData);
    const changeLog = handleGetChangeLog(lobDataToUpdate);
    const deletedChannelsChangeLog = handleDeletedChannelsChangeLog();

    // Update the channels data in database
    if(lobDataToUpdate.length > 0) {
      const lobPayload = {
        lobData: lobDataToUpdate,
        changeLog: changeLog,
        editedBy: props.userName
      };
      dispatch(updateSelectedLob(selectedLobId.toString(10), lobPayload)); 
    }

    // Delete the channels data from database
    if(channelsToRemove.length > 0) {
      const deletePayload = {
        channelsToRemove: channelsToRemove,
        deletedChannelsChangeLog: deletedChannelsChangeLog,
        deletedBy : props.userName
      };
      dispatch(deleteSelectedLob(selectedLobId, deletePayload));
    }
  };

  return (
    <ModalsConsumer>
      {
        modalsState => {
          return modalsState && (
            <>
              <LobForm
                lobData={lobData}
                auditLogs={props.selectedLob.lob_audit_logs}
                budgetSharingFlagInvalid={budgetSharingFlagInvalid}
                setBudgetSharingFlagInvalid={setBudgetSharingFlagInvalid}
                lobDropdownOptions={props.lobDropdownOptions}
                channelDropdownOptions={props.channelDropdownOptions}
                onSave={handleUpdateLob}
                openUpdateConfirmationModal={modalsState.handleToggleConfirmationModal}
                setLobData={setLobData}
                setChannelList={setChannelList}
                channelList={channelList}
                channelsToRemove={channelsToRemove}
                setChannelsToRemove={setChannelsToRemove}
                selectedProductChannelList={selectedProductChannelList}
                setSelectedProductChannelList={setSelectedProductChannelList}
                // isSubmitDisabled={
                //   (lobData.name && lobData.channel && lobData.products && lobData.campaign && lobData.anchor_cid) === "" || props.isSubmitDisabled || !userPermissions.includes('change_lob')? true : false
                // }
                isSubmitDisabled={
                  (lobData.name && lobData.products) === "" || props.isSubmitDisabled || !userPermissions.includes('change_lob')? true : false
                }
                isDisabled={true}
              />
              <RequiredFieldModal 
                requiredFields={requiredFields}
                modal={modal}
                setModal={setModal}
              />
              <ConfirmationModal
                confirmationHeader="Update LOB"
                confirmationText={buildUpdateLobConfirmationText()}
                executeConfirmationAction={updateLobAction}
                handleToggleModal={modalsState.handleToggleConfirmationModal}
                isModalOpen={modalsState.isConfirmationModalOpen}
              />
            </>
          )
        }
      }
    </ModalsConsumer>
  )
}

export default EditLob;