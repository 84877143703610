import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import GrossBudgetSession from './GrossBudgetSession';
import NetBudgetSession from './NetBudgetSession';

const BudgetBreakdownSession = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState('gross');

  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === 'gross' ? 'active' : ''}
            onClick={() => setActiveTab('gross')}>
            Gross Budget
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === 'net' ? 'active' : ''}
            onClick={() => setActiveTab('net')}>
            Net Budget
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} style={{ paddingTop: '10px' }}>
        <TabPane tabId="gross">
          <GrossBudgetSession />
        </TabPane>
        <TabPane tabId="net">
          <NetBudgetSession />
        </TabPane>
      </TabContent>
    </>
  );
};

export default BudgetBreakdownSession;
