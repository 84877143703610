import React from 'react';
import PropTypes from 'prop-types';

import { AnchorCidTableCellDropdown, EditableTableCell }from '../../../Common';

import { Button, Table, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { getUserPermissions } from 'State/Authentication/Selectors/AuthenticationSelectors';
import { AnchorCidGridDropdown, ChangeLogModal , ChannelDropdown, GridInput} from '../../../Common';
import EditableChannelRow from './EditableChannelRow';

const ChannelSummary = (props: any) => {
    const userPermissions = useSelector(getUserPermissions);
    let channelComponent;

    channelComponent =  
    <>
      {props.channelList.map((channelData: any, index: number) => (
        <EditableChannelRow 
          key={index}
          // index={channelData.lob_id ? channelData.lob_id : index}
          index={index}
          channelOptions={props.channelOptions}
          channelList={props.channelList}
          handleChannelChange={props.handleChannelChange}
          // handleGoogleCidChange={props.handleGoogleCidChange}
          // handleBingCidChange={props.handleBingCidChange}
          // handleAppleCidChange={props.handleAppleCidChange}
          // handleFacebookCidChange={props.handleFacebookCidChange}
          // handlePinterestCidChange={props.handlePinterestCidChange}
          // handleBellCidChange={props.handleBellCidChange}
          // handleTiktokCidChange={props.handleTiktokCidChange}
          handleCampaignChange={props.handleCampaignChange}
          handleRemoveChannelFromList={props.handleRemoveChannelFromList}
          isDisabled={channelData.lob_id ? true : false}
          channelData={channelData}
          handleCidChange={props.handleCidChange}
        />
      ))}
    </>

    return (
      <div style={{'marginBottom': '16px'}}>
        {channelComponent}
        <Button color="link" size="md" disabled={!userPermissions.includes('add_cid')} onClick={props.handleAddChannelToList} style={{'padding': '0.375rem 0'}}>
            Add Channel
        </Button>
      </div>
    );
};

ChannelSummary.propTypes = {
    channelOptions: PropTypes.array,
    channelList: PropTypes.array,
    isDisabled: PropTypes.bool,
    channel: PropTypes.string,
    handleAddChannelToList: PropTypes.func,
    handleChannelChange: PropTypes.func,
    // handleGoogleCidChange: PropTypes.func,
    // handleBingCidChange: PropTypes.func,
    // handleAppleCidChange: PropTypes.func,
    // handleFacebookCidChange: PropTypes.func,
    // handlePinterestCidChange: PropTypes.func,
    // handleBellCidChange: PropTypes.func,
    // handleTiktokCidChange: PropTypes.func,
    handleCampaignChange: PropTypes.func,
    handleRemoveChannelFromList: PropTypes.func,
    handleCidChange: PropTypes.func
};

export default ChannelSummary;