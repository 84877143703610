import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { WEEKS_LEFT_CONFIGURATION } from '../Models/WeeksLeftConfiguration';

interface Props{
  handleWeeksLeftChange: (value: string) => void, 
  selectedEntry: string, 
}

export const WeeksLeftDropdown = (props: any) => {
  const { handleWeeksLeftChange, selectedEntry } = props;

  const [isWeeksLeftDropdownOpen, setisWeeksLeftDropdownOpen] = useState<boolean>(false);

  const handleSelectOption = (event: any) => {
    handleWeeksLeftChange(event.target.value);
  }

  const handleToggleWeeksLeftDropdown = () => {
    setisWeeksLeftDropdownOpen(prevState => !prevState);
  }

  return (
    <Dropdown
      isOpen={isWeeksLeftDropdownOpen}
      toggle={handleToggleWeeksLeftDropdown}
      className="mr-2">
      <DropdownToggle caret color="primary">
        { selectedEntry ? WEEKS_LEFT_CONFIGURATION[selectedEntry].name : 'Weeks Left' }
      </DropdownToggle>
      <DropdownMenu>
        {
          Object.entries(WEEKS_LEFT_CONFIGURATION).map(
            ([key, val]) => (
              <DropdownItem
                key={key}
                value={key}
                onClick={handleSelectOption}>
                {val.name}
              </DropdownItem>
            )
          )
        }
      </DropdownMenu>
    </Dropdown>
  );
};

WeeksLeftDropdown.propTypes = {
  handleWeeksLeftChange: PropTypes.func.isRequired,
  selectedEntry: PropTypes.string.isRequired,
};
