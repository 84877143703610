import React from 'react';
import PropTypes from 'prop-types';

import { Pagination, PaginationItem, PaginationLink} from 'reactstrap';

export const TablePagination = (props: any) => {
  const handleFirstPageButtonClick = () => {
    props.handleChangePage(0);
  };

  const handleBackButtonClick = () => {
    props.handleChangePage(props.page - 1);
  };

  const handleNextButtonClick = () => {
    props.handleChangePage(props.page + 1);
  };

  const handleLastPageButtonClick = () => {
    props.handleChangePage(Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1));
  };

  return (
    <Pagination>
      <PaginationItem disabled={props.page === 0}>
        <PaginationLink first tag="button" onClick={handleFirstPageButtonClick} />
      </PaginationItem>
      <PaginationItem disabled={props.page === 0}>
        <PaginationLink previous tag="button" onClick={handleBackButtonClick} />
      </PaginationItem>
      <PaginationItem disabled={props.page === Math.max(0, Math.ceil(props.count/props.rowsPerPage) - 1)}>
        <PaginationLink next tag="button" onClick={handleNextButtonClick} />
      </PaginationItem>
      <PaginationItem disabled={props.page === Math.max(0, Math.ceil(props.count/props.rowsPerPage) - 1)}>
        <PaginationLink last tag="button" onClick={handleLastPageButtonClick} />
      </PaginationItem>
    </Pagination>
  );
};

TablePagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  handleChangePage: PropTypes.func
};
