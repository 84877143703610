import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';

interface IAuthProvider {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: IAuthProvider) => {
  console.log('Oauth Client ID:', process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID);
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      {children}
    </GoogleOAuthProvider>
  );
};

export default AuthProvider;
