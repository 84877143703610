import { GridDivider } from 'Common';
import React from 'react';
import BudgetEstimatorOnReach from './BudgetEstimatorOnReach';
import MinimumBudgetEstimatorOnCPA from './MinimumBudgetEstimatorOnCPA';

const BudgetEstimatorSession = (): JSX.Element => {
  return (
    <>
      <BudgetEstimatorOnReach />
      <GridDivider />
      <MinimumBudgetEstimatorOnCPA />
    </>
  );
};

export default BudgetEstimatorSession;
