import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';
import history from '../../../Utils/History';
import { keys, cloneDeep } from 'lodash';

import MpaIntakeForm from './MpaIntakeForm';
import MpaSummary from './MpaSummary';
import MpaFormBottom from './MpaFormBottom';
import PDFDocument from './PDFDocument';
import PDFDownloadButton from './PDFDownloadButton';
import PDFStyles from './PDFStyles';
import { ChangeLogModal } from '../../../Common';

import { getMpaFormContextState } from '../../../State/MpaFormContext/Selectors/MpaFormContextSelectors';
import { getFormInfoState } from '../../../State/FormInfo/Selectors/FormInfoSelectors';
import { getAnchorCids } from '../../../State/FormInfo/Actions/FormInfoActions';
import { setSelectedLob } from '../../../State/MpaFormContext/Actions/MpaFormContextActions';
import BudgetCalculator from '../../../Services/BudgetCalculator';
import { getUserPermissions } from 'State/Authentication/Selectors/AuthenticationSelectors';
import { ModalsConsumer } from '../../../Contexts/ModalsContext';
import { differenceInCalendarMonths } from 'date-fns';
import { addMonthsToDate, formatDate, getMonthName } from 'Common/actions';
import moment from 'moment-timezone';

const MpaForm = (props: any)=> {
  const formInfoState = useSelector(getFormInfoState);
  const mpaFormContextState = useSelector(getMpaFormContextState);
  const dispatch = useDispatch();

  const userPermissions = useSelector(getUserPermissions);
  
  const [lobOptions, setLobOptions] = useState<Array<any>>(Object.keys(props.dropdownOptions));
  const [channelOptions, setChannelOptions] = useState<Array<any>>([]);
  const [productOptions, setProductOptions] = useState<Array<any>>([]);
  const [showPdfButton, setShowPdfButton] = useState(false);
  const [summaryBudgetChanges, setSummaryBudgetChanges] = useState(false);
  const [platformRateChangeFacebook, setPlatformRateChangeFacebook] = useState(false);
  const [startDateChange, setStartDateChange] = useState(false);
  const [endDateChange, setEndDateChange] = useState(false);

  const [anchorCidList, setAnchorCidList] = useState<Array<any>>([]);

  // useEffect(() => {  
  //   if (typeof props.anchorCidOptions != "undefined") {
  //     let cidData = Object.keys(props.anchorCidOptions);
  //     if(cidData.length > 0) {
  //       let channelOptions = [];
  //       for(let cid of cidData) {
  //         let cidChannel = props.anchorCidOptions[cid][0].channel;
  //         channelOptions.push(cidChannel);
  //       }

  //       // remove duplicate channels from options array
  //       var distinctChannelOptions = channelOptions.filter(function(item, pos){
  //         return channelOptions.indexOf(item)== pos; 
  //       });
  //       setChannelOptions(distinctChannelOptions);      
  //     }
  //     setAnchorCidList(formInfoState.anchorCids);
  //   }
  // },[props.anchorCidOptions]);

  useEffect(() => {
    // remove channel name from campaign field in Summary section
    let channel_in_campaign = props.mpaIntake.campaign.split("-")[3];    
    if(channel_in_campaign === 'Facebook' || channel_in_campaign === 'SEM') {
      let modified_campaign = props.mpaIntake.campaign.replace(channel_in_campaign + '-','');
      props.mpaIntake.campaign = modified_campaign;
    }

    // handle pdf generation on pageload
    setShowPdfButton(true);
  });

  useEffect(() => {      
    // get anchor_cids and channels list from LOB table to load values in Channel and Anchor CID dropdown
    for(let row of props.cidList) {
      if(row.id === 0) {        
        if (typeof formInfoState.anchorCids != "undefined") {
          let cidData = Object.keys(formInfoState.anchorCids);
          if(cidData.length > 0) {
            let channelOptions = [];
            for(let cid of cidData) {
              let cidChannel = formInfoState.anchorCids[cid][0].channel;
              channelOptions.push(cidChannel);
            }
            // remove duplicate channels from options array
            var distinctChannelOptions = channelOptions.filter(function(item, pos){
              return channelOptions.indexOf(item)== pos; 
            });
            setChannelOptions(distinctChannelOptions);      
          }
          setAnchorCidList(formInfoState.anchorCids);
        }
      }
    }
  },[formInfoState.anchorCids]);

  //dispatch action to get cid dropdown list
  const handleCidDropdownList = (lob: any, channel:any, products:any) => {
    dispatch(getAnchorCids(lob, channel, products));
  }

  // Set value of lob and populate Channel dropdown list
  const handleLobChange = (event: any) => {
    const selectedLineOfBusiness = event.target.value;
    // const channelOptions = Object.keys(props.dropdownOptions[selectedLineOfBusiness][0]);
    // setChannelOptions(channelOptions);

    // handle product change if channel is already selected
    // if(props.mpaIntake.channel){
    //   const productsOptions = props.dropdownOptions[selectedLineOfBusiness][0][props.mpaIntake.channel];
    //   setProductOptions(productsOptions);
    // }     

    // form reset
    if(props.mpaIntake.client_business_unit !== selectedLineOfBusiness){
      setProductOptions([])
      props.setMpaIntake({
        ...props.mpaIntake, 
        client_business_unit: selectedLineOfBusiness,
        channel:"",
        products:"",
        phase_number:0,
        mpa_status:"",
        commission_rate:1.5,
        platform_rate:0,
        platform_fees:0,
        tax_rate:13,
        promotion_credit:0,
        working_funds_total:0,
        commission:0,
        net_total:0,
        tax:0,
        grand_total:0
      });

      props.setCidList([{
        channel:"",
        anchor_cid:'',
        working_funds:0,
        campaign_name:'',
        november:0,
        december: 0,
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,	
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        id:0,
        mpa_id: 0,
        anchor_cid_platform_fee:0
        // legacy_mpa:"",
        // google_cid:'',
        // google_budget:0,
        // bing_cid:'',
        // bing_budget:0,
        // apple_cid:'',
        // apple_budget:0,
        // facebook_cid: "",
        // pinterest_cid: "",
        // bell_cid: "",
        // tiktok_cid: ""       
      }])
      // handleCidDropdownList(selectedLineOfBusiness, "", "");
      dispatch(setSelectedLob(selectedLineOfBusiness));
    }

    // // dispatch an action to get new mpa number if a new lob is selected
    // if(selectedLineOfBusiness !== mpaFormContextState.selectedLob) {
    //   dispatch(setSelectedLob(selectedLineOfBusiness))
    // }
    
  };

  useEffect(() => {
    // handle product change if LOB is already selected
    if(props.mpaIntake.client_business_unit){
      const productsOptions = props.dropdownOptions[props.mpaIntake.client_business_unit];
      setProductOptions(productsOptions);
    } else {
      setProductOptions([]);
    }
  }, [props.mpaIntake.client_business_unit]);

  // set New MPA Number on Add New MPA Form (fromInfoState- newMpaNumber = {'abbr' : value, 'new_mpa_number': value})
  useEffect(() => {
    props.setMpaIntake({ ...props.mpaIntake, mpa_number: formInfoState.newMpaNumber.new_mpa_number, budget_sharing_flag: formInfoState.newMpaNumber.new_mpa_number});
  }, [formInfoState.newMpaNumber])

  // Assemble Campaign name when user selects the lob, channel etc.
  useEffect(() => {
    const campaignName = BudgetCalculator.assembleCampaignName(
      props.mpaIntake.mpa_number,
      // props.mpaIntake.channel,
      props.mpaIntake.products,
      props.mpaIntake.phase_number,
      props.mpaIntake.scheduled_start
    )
    props.setMpaIntake({...props.mpaIntake, campaign: campaignName});
  }, [
    props.mpaIntake.mpa_number,
    // props.mpaIntake.channel,
    props.mpaIntake.products,
    props.mpaIntake.phase_number,
    props.mpaIntake.scheduled_start
  ])

  const handleChannelChange = (event: any, channelIndex: number) => {
    const selectedChannel = event.target.value;

    const newChannelList = [...props.cidList].map((object: any, index: number) => {
      if (index === channelIndex) {
        return {
          ...object,
          channel: selectedChannel,
          anchor_cid:'',
          working_funds:0,
          campaign_name:'',
          november:0,
          december: 0,
          january: 0,
          february: 0,
          march: 0,
          april: 0,
          may: 0,	
          june: 0,
          july: 0,
          august: 0,
          september: 0,
          october: 0,
          id:0,
          anchor_cid_platform_fee:0,
          difference:0
          // legacy_mpa:"",
          // google_cid:'',
          // google_budget:0,
          // bing_cid:'',
          // bing_budget:0,
          // apple_cid:'',
          // apple_budget:0,
          // facebook_cid: "",
          // pinterest_cid: "",
          // bell_cid: "",
          // tiktok_cid: ""
        };
      } else {
        return object;
      }
    });
    props.setCidList(newChannelList);

    // populate selected Channel's Anchor CID only and remove rest when a channel is selected
    let anchorCidOptions = cloneDeep(formInfoState.anchorCids);
    let cidData = Object.keys(anchorCidOptions);
    if(cidData.length > 0) {
      for(let cid of cidData) {
        let cidChannel = anchorCidOptions[cid][0].channel;
        if(selectedChannel != cidChannel) {
          delete anchorCidOptions[cid];
        }
      }
    }
    setAnchorCidList(anchorCidOptions);

    // handleCidDropdownList(props.mpaIntake.client_business_unit, '', props.mpaIntake.products);
  };

  const handleProductsChange = (event: any) => {
    const selectedProduct = event.target.value;

    // form reset
    if(props.mpaIntake.products !== selectedProduct){
      props.setMpaIntake({
        ...props.mpaIntake, 
        products: selectedProduct,
        phase_number:0,
        mpa_status:"",
        commission_rate:1.5,
        platform_fees:0,
        tax_rate:13,
        promotion_credit:0,
        working_funds_total:0,
        commission:0,
        net_total:0,
        tax:0,
        grand_total:0

      });

      props.setCidList([{
        channel:"",
        anchor_cid:'',
        working_funds:0,
        campaign_name:'',
        november:0,
        december: 0,
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,	
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        id:0,
        mpa_id: 0,
        anchor_cid_platform_fee:0,
        difference:0
        // legacy_mpa:"",
        // google_cid:'',
        // google_budget:0,
        // bing_cid:'',
        // bing_budget:0,
        // apple_cid:'',
        // apple_budget:0,
        // facebook_cid: "",
        // pinterest_cid: "",
        // bell_cid: "",
        // tiktok_cid: ""
      }])
    } 

    if(selectedProduct != '') {
      handleCidDropdownList(props.mpaIntake.client_business_unit, '', selectedProduct); 
    }
       
  };

  const handlePhaseNumberChange = (event: any) => {
    props.setMpaIntake({...props.mpaIntake, phase_number: parseInt(event.target.value)});
  };

  const handleScheduledStartChange = (event: any) => {
    setStartDateChange(true);
    if(!props.fromAddMPA)
      props.setStartDateChange(true);
    props.setMpaIntake({...props.mpaIntake, scheduled_start: event.toISOString()});
  };

  const handleScheduledEndChange = (event: any) => {
    setEndDateChange(true);
    if(!props.fromAddMPA)
      props.setEndDateChange(true);
    props.setMpaIntake({...props.mpaIntake, scheduled_end: event.toISOString()});
  };

  const handlePreparedForChange = (event: any) => {
    props.setMpaIntake({...props.mpaIntake, prepared_for: event.target.value});
  };

  const handleMpaStatusChange = (event: any) => {
    props.setMpaIntake({...props.mpaIntake, mpa_status: event.target.value});
  };

  const handleCommissionRateChange = (event: any) => {
    props.setMpaIntake(event.target.value !== "" ?  
      {...props.mpaIntake, commission_rate: parseFloat(event.target.value) }
      : {...props.mpaIntake, commission_rate: 0 }
    );
    
    setSummaryBudgetChanges(true);
  };

  // For Facebook, platform rate should have a default value of 0%
  // Checking whether the value is changed using 'setPlatformRateChangeFacebook' to change the value dynamically
  const handlePlatformRateChange = (event: any) => {
    props.setMpaIntake(event.target.value !== "" ?  
      {...props.mpaIntake, platform_rate: parseFloat(event.target.value) }
      : {...props.mpaIntake, platform_rate: 0 }
    );
    setSummaryBudgetChanges(true);
    setPlatformRateChangeFacebook(true);
  };

  // set sub_total when commision and platform rate changes
  // useEffect(() => {
  //   const commisionRate = props.mpaIntake.commission_rate/100;
  //   const platformRate = props.mpaIntake.platform_rate/100;

  //   props.setMpaIntake({
  //     ...props.mpaIntake,
  //     sub_total: Math.round(props.mpaIntake.net_total / (1 + commisionRate + platformRate) * 100) / 100
  //   });

  // }, [props.mpaIntake.commision_rate, props.platform_rate]);

  // Tax Rate calculations: This effects the Net Total as
  //     Net Total = GrandTotal / (1+taxRate)
  const handleTaxRateChange = (event: any) => {
    props.setMpaIntake(event.target.value !== "" ?  
      {...props.mpaIntake, tax_rate: parseFloat(event.target.value) }
      : {...props.mpaIntake, tax_rate: 0 }
    );
    setSummaryBudgetChanges(true);
  };

  const handlePromotionCreditChange = (event: any) => {
    props.setMpaIntake(event.target.value !== "" ?  
      {...props.mpaIntake, promotion_credit: parseFloat(event.target.value) }
      : {...props.mpaIntake, promotion_credit: 0 }
    );
    // setSummaryBudgetChanges(true);
  };

  // Tax Calculations: Grand Total - Net Total
  // set commision and platform fees when commision, platform rate and sub_total changes
  // useEffect(() => {
  //   const commissionRate = props.mpaIntake.commission_rate/100;
  //   const platformRate = props.mpaIntake.platform_rate/100;
  //   const taxPercentage = props.mpaIntake.tax_rate/100;
  //   const netTotal = Math.round(props.mpaIntake.grand_total * 100 / (1 + taxPercentage)) / 100;
  //   const subTotal = Math.round(netTotal / (1 + commissionRate + platformRate) * 100) / 100;

  //   props.setMpaIntake({
  //     ...props.mpaIntake,
  //     net_total: netTotal,
  //     tax: Math.round((props.mpaIntake.grand_total - netTotal) * 100) / 100,
  //     sub_total: subTotal,
  //     commission: Math.round(subTotal * commissionRate * 100) / 100,
  //     platform_fees: Math.round(subTotal * platformRate * 100) / 100
  //   });
  //   setSummaryBudgetChanges(false);
  // },[summaryBudgetChanges]);

  useEffect(() => {
    const commissionRate = props.mpaIntake.commission_rate/100;
    const taxPercentage = props.mpaIntake.tax_rate/100;
    // const netTotal = Math.round(props.mpaIntake.grand_total * 100 / (1 + taxPercentage)) / 100;
    // const subTotal = Math.round(netTotal / (1 + commissionRate + platformRate) * 100) / 100;

    const working_funds_total = props.mpaIntake.working_funds_total;
    const commission = Math.round(working_funds_total * commissionRate * 100) / 100;
    const platform_fees = Math.round(props.mpaIntake.platform_fees * 100) / 100;
    const net_total = Math.round((working_funds_total + commission + platform_fees) * 100) / 100;
    const tax = Math.round(net_total * taxPercentage * 100) / 100;
    const grand_total = Math.round((net_total + tax) * 100) / 100;

    props.setMpaIntake({
      ...props.mpaIntake,
      commission: commission,
      platform_fees: platform_fees,
      net_total: net_total,
      tax: tax,
      grand_total: grand_total,
    });
    setSummaryBudgetChanges(false);
  },[summaryBudgetChanges]);

  const handleNotesChange = (event: any) => {
    props.setMpaIntake({...props.mpaIntake, notes: event.target.value});
  };

  const handleMPONotesChange = (event:any)=>{
    props.setMpaIntake({...props.mpaIntake,  mpo_notes: event.target.value});
  }


  const handleInitMapChange = (event: any) => {
    props.setMpaIntake({...props.mpaIntake, legacy_mpa: event.target.value});
  };

  const handleRevMpaChange = (event: any) => {
    props.setMpaIntake({...props.mpaIntake, revision_number: event.target.value});
  };


  const MPAPdfFileName = useMemo(() => 
    {
    const currentMonth = moment(props.mpaIntake.scheduled_start).format("MM")
    const hNumber = ["11","12","01","02","03","04"].includes(currentMonth) ?"H1":"H2"
    return `MPA ${props.mpaIntake.legacy_mpa} ${props.mpaIntake.revision_number??"R0"} - FY${(props.mpaIntake.campaign.split("-")[1])?.substring(2)??""} ${hNumber} - ${props.mpaIntake.products} Performance Media`}, 
  [props.mpaIntake])

  useEffect(()=>{        
    const scheduled_start_month = formatDate(props.mpaIntake.scheduled_start);
    const scheduled_end_month = formatDate(props.mpaIntake.scheduled_end);
    const monthsDiff = differenceInCalendarMonths(scheduled_end_month, scheduled_start_month);
    let allMonths = {        
      november:0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      april: 0,
      may: 0,	
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,}


    for(let i = 0; i <= monthsDiff; i++) {
      let nextMonth = addMonthsToDate(scheduled_start_month, i);
      let newDate = (nextMonth.getMonth() + 1) + '/01/' + nextMonth.getFullYear();
      delete allMonths[getMonthName(newDate)];
    }
    props.setCidList(prevList => prevList.map(cid=>({...cid, ...allMonths})))
   
  },[props.mpaIntake.scheduled_end,props.mpaIntake.scheduled_start,differenceInCalendarMonths,formatDate,addMonthsToDate,getMonthName])


  return (
    <>
      <ModalsConsumer>
        {
          modalsState => {
            return modalsState && (
              <>
                <Container fluid={true}>
                  <Row>
                    <Col md={8}>
                      <MpaIntakeForm
                        mpaIntake={props.mpaIntake}
                        lobOptions={lobOptions}
                        channelOptions={channelOptions}
                        productOptions={productOptions}
                        isDisabled={props.isDisabled}
                        handleLobChange={handleLobChange}
                        handleChannelChange={handleChannelChange}
                        handleProductsChange={handleProductsChange}
                        handlePhaseNumberChange={handlePhaseNumberChange}
                        handleScheduledStartChange={handleScheduledStartChange}
                        startDateChange={startDateChange}
                        handleScheduledEndChange={handleScheduledEndChange}
                        endDateChange={endDateChange}
                        handlePreparedForChange={handlePreparedForChange}
                        handleMpaStatusChange={handleMpaStatusChange}
                        handleCommissionRateChange={handleCommissionRateChange}
                        handlePlatformRateChange={handlePlatformRateChange}
                        handleTaxRateChange={handleTaxRateChange}
                        handlePromotionCreditChange={handlePromotionCreditChange}
                        handleNotesChange={handleNotesChange}
                        handleMPONotesChange={handleMPONotesChange}
                        platformRateChangeFacebook={platformRateChangeFacebook}
                        fromAddMPA={props.fromAddMPA}
                      />
                    </Col>
                    <Col md={4}>
                      <MpaSummary
                        mpaSummary={props.mpaIntake}
                        isDisabled
                        newMpaNumber={formInfoState.newMpaNumber}
                        handleInitMapChange={handleInitMapChange}
                        handleRevMpaChange={handleRevMpaChange}
                      />
                    </Col>
                  </Row>
                  <hr/>
                  <Row>
                    <Col xs={12}>
                      <MpaFormBottom
                        fromAddMPA={props.fromAddMPA}
                        cidList={props.cidList}
                        channelOptions={channelOptions}
                        isDisabled={props.isDisabled}
                        setCidList={props.setCidList}
                        cidsToRemove={props.cidsToRemove}
                        setCidsToRemove={props.setCidsToRemove}
                        setMpaIntake={props.setMpaIntake}
                        setSummaryBudgetChanges={setSummaryBudgetChanges}
                        mpaIntake={props.mpaIntake}
                        handleCidDropdownList={handleCidDropdownList}
                        // anchorCidList={formInfoState.anchorCids}
                        anchorCidList={anchorCidList}
                        selectedChannelOption={props.mpaIntake.channel}
                        campaign={props.mpaIntake.campaign}
                        handleChannelChange={handleChannelChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Button
                        color="primary"
                        disabled={props.isFormDisabled}
                        onClick={(event: any) => props.handleMpaFormSubmit(event, props.mpaIntake.id, modalsState.handleToggleConfirmationModal, modalsState.handleToggleMpaWarningModal)
                      }>Save</Button>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col xs={12}>
                      <Button
                        color="warning"
                        size="md"
                        onClick={()=>{ dispatch(getAnchorCids('', '', '')); history.push(`/map/mpa/`,'/map/mpa')}}
                        style={{'marginRight': '16px', 'marginBottom': '16px'}}
                      >
                        Return to MPA List
                      </Button>
                      <ChangeLogModal
                        logsData={props.auditLogs}
                      />

                      <Button
                        color="primary"
                        size="md"
                        onClick={() => {history.push('/map/exclude-cids')}}
                        style={{'marginRight': '16px', 'marginBottom': '16px'}}
                      >
                        Exclude CIDs
                      </Button>

                      {/* <Button
                        color="primary"
                        size="md"
                        disabled={!userPermissions.includes('add_yearbudget')}
                        onClick={()=>{history.push(`/map/annual-budgets/add`,'/map/mpa')}}
                        style={{'marginRight': '16px', 'marginBottom': '16px'}}
                      >
                        Add Annual Budget
                      </Button> */}
                      {showPdfButton && (
                      <PDFDownloadButton
                        document={<PDFDocument data={{'mpaIntake': props.mpaIntake, 'cidList': props.cidList}}/>}
                        fileName={MPAPdfFileName}
                        style={PDFStyles.BlueButton}
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Generate PDF"
                        }
                      </PDFDownloadButton>)}
                    </Col>
                  </Row>
                </Container>
              </>
            )
          }
        }
      </ModalsConsumer>
    </>
  );
};

export default MpaForm;