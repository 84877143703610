import queryString from 'query-string';

import { loadState } from './SessionStorage';

interface ApiRequestOptions {
  mode: any,
  cache: any,
  credentials: any,
  headers: any,
  redirect: any,
  referrer: any,
  body?: any
};

const createApiRequestOptions = () => {
  return {
    mode: 'same-origin',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'error',
    referrer: 'no-referrer',
	};
};

class ApiRequest {
  options: ApiRequestOptions;
  queryParams: string;
  baseUrl: string;

  constructor(options = createApiRequestOptions()) {
    this.options = options;
    this.queryParams = '';
    this.baseUrl = '';
  }

  buildRequestUrl(relativeUrl: string): string {
    return this.queryParams !== '' ?
      this.baseUrl + relativeUrl + '?' + this.queryParams :
      this.baseUrl + relativeUrl;
  }

  setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
    return this;
  }

  withAuthToken(addAuthToken: boolean = false) {
    const token = loadState()['authenticationState']['token'];
    if (addAuthToken) this.options.headers['Authorization'] = token;
    return this;
  }

  withBody(body: any) {
    this.options['body'] = JSON.stringify(body);
    return this;
  }

  withQueryParams(queryParams: any) {
    this.queryParams = Object.keys(queryParams).length === 0 ? '' : queryString.stringify(queryParams);
    return this;
  }

  get(relativeUrl: string): Promise<Response> {
    return fetch(this.buildRequestUrl(relativeUrl), {
      ...this.options,
      method: 'GET'
    });
    // function to call method multiple times after timeout
    // return this.fetchRetry(this.buildRequestUrl(relativeUrl), 1, 5, {
    //   ...this.options,
    //   method: 'GET'
    // });
  }

  post(relativeUrl: string): Promise<Response> {
    return fetch(this.buildRequestUrl(relativeUrl), {
      ...this.options,
      method: 'POST'
    })
  }

  patch(relativeUrl: string): Promise<Response> {
    return fetch(this.buildRequestUrl(relativeUrl), {
      ...this.options,
      method: 'PATCH'
    })
  }

  put(relativeUrl: string): Promise<Response> {
    return fetch(this.buildRequestUrl(relativeUrl), {
      ...this.options,
      method: 'PUT'
    });
  }

  delete(relativeUrl: string): Promise<Response> {
    return fetch(this.buildRequestUrl(relativeUrl), {
      ...this.options,
      method: 'DELETE'
    });
  }

  wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  fetchRetry(relativeUrl, delay, tries, fetchOptions = {}) {
    function onError(err){
        let triesLeft = tries - 1;
        if(!triesLeft){
            throw err;
        }
        return this.wait(delay).then(() => this.fetchRetry(relativeUrl, delay, triesLeft, fetchOptions));
    }
    return fetch(relativeUrl,fetchOptions).catch(onError);
  }
}

export default ApiRequest;
