import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Breadcrumb } from 'reactstrap';

import { BudgetOrderFilterBreadcrumbItem } from './BudgetOrderFilterBreadcrumbItem';
import { WEEKS_LEFT_CONFIGURATION } from '../Models/WeeksLeftConfiguration';
import { BudgetOrdersViewState } from '../Models/BudgetOrdersView';

import { BudgetOrderFilterBreadcrumbHeader } from '../Styled/BudgetOrderFilterBreadcrumbHeader';


interface Props {
  budgetOrdersView: BudgetOrdersViewState,
  history: any,
}

export const BudgetOrderFilterBreadcrumb = (props: Props) => {
  const { budgetOrdersView, history } = props;

  const handleRemoveFilter = (budgetOrderFilter: string) => {
    const budgetOrderViewParams = { ...budgetOrdersView, [budgetOrderFilter]: '' };
    history.push(`/bom/budget-orders?${queryString.stringify(budgetOrderViewParams)}`);
  }

  return (
    <Breadcrumb>
      <BudgetOrderFilterBreadcrumbHeader>Filter(s):</BudgetOrderFilterBreadcrumbHeader>
      {budgetOrdersView.selectedLineOfBusiness &&
      (<BudgetOrderFilterBreadcrumbItem
        budgetOrderFilterKey="selectedLineOfBusiness"
        value={budgetOrdersView.selectedLineOfBusiness}
        handleRemoveFilter={handleRemoveFilter}
      />)}
      {budgetOrdersView.selectedCategory &&
      (<BudgetOrderFilterBreadcrumbItem
        budgetOrderFilterKey="selectedCategory"
        value={budgetOrdersView.selectedCategory}
        handleRemoveFilter={handleRemoveFilter}
      />)}
      {budgetOrdersView.selectedWeeksLeft &&
      (<BudgetOrderFilterBreadcrumbItem
        budgetOrderFilterKey="selectedWeeksLeft"
        value={WEEKS_LEFT_CONFIGURATION[budgetOrdersView.selectedWeeksLeft].name}
        handleRemoveFilter={handleRemoveFilter}
      />)}
      {budgetOrdersView.searchTerm &&
      (<BudgetOrderFilterBreadcrumbItem
        budgetOrderFilterKey="searchTerm"
        value={budgetOrdersView.searchTerm}
        handleRemoveFilter={handleRemoveFilter}
      />)}
    </Breadcrumb>
  );
}

BudgetOrderFilterBreadcrumb.propTypes = {
  budgetOrdersView: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

