import { createDefaultMpaState, MpaState } from '../Models/MpaState';
import {
  updateLoadableOnStart,
  updateLoadableOnSuccess,
  updateLoadableOnError
} from '../../Common/Loadable';

import {
  ADD_NEW_MPA, ADD_NEW_MPA_SUCCESS, ADD_NEW_MPA_ERROR,
  DELETE_SELECTED_MPA, DELETE_SELECTED_MPA_SUCCESS, DELETE_SELECTED_MPA_ERROR,
  GET_EXPORT_TO_BQ_STATUS, GET_EXPORT_TO_BQ_STATUS_SUCCESS, GET_EXPORT_TO_BQ_STATUS_ERROR,
  GET_MPAS, GET_MPAS_SUCCESS, GET_MPAS_ERROR,
  GET_MPAS_CSV, GET_MPAS_CSV_SUCCESS, GET_MPAS_CSV_ERROR,
  GET_SELECTED_MPA, GET_SELECTED_MPA_SUCCESS, GET_SELECTED_MPA_ERROR,
  UPDATE_SELECTED_MPA, UPDATE_SELECTED_MPA_SUCCESS, UPDATE_SELECTED_MPA_ERROR,
} from '../ActionTypes/MpaActionTypes';

export default function (state = createDefaultMpaState(), action: any): MpaState {
  switch (action.type) {

    // Add New MPA Reducers
    case ADD_NEW_MPA:
      return {
        ...state,
        addMpaLoadable: updateLoadableOnStart()
      };

    case ADD_NEW_MPA_SUCCESS:
      return {
        ...state,
        addMpaLoadable: updateLoadableOnSuccess()
      };

    case ADD_NEW_MPA_ERROR:
      return {
        ...state,
        addMpaLoadable: updateLoadableOnError(action.error)
      };

    case DELETE_SELECTED_MPA:
      return {
        ...state,
        deleteSelectedMpaLoadable: updateLoadableOnStart()
      };

    case DELETE_SELECTED_MPA_SUCCESS:
      return {
        ...state,
        deleteSelectedMpaLoadable: updateLoadableOnSuccess()
      };

    case DELETE_SELECTED_MPA_ERROR:
      return {
        ...state,
        deleteSelectedMpaLoadable: updateLoadableOnError(action.error)
      };


    // get formMpa table data for PRM dashboard
    case GET_MPAS:
      return {
        ...state,
        getMpaListLoadable: updateLoadableOnStart()
      };

    case GET_MPAS_SUCCESS:
      return {
        ...state,
        entities: action.result,
        getMpaListLoadable: updateLoadableOnSuccess()
      };

    case GET_MPAS_ERROR:
      return {
        ...state,
        getMpaListLoadable: updateLoadableOnError(action.error)
      };

      // get mpas csv file in mpa dashboard
    case GET_MPAS_CSV:
      return {
        ...state,
        getMpasCsvLoadable: updateLoadableOnStart()
      };

    case GET_MPAS_CSV_SUCCESS:
      return {
        ...state,
        getMpasCsvLoadable: updateLoadableOnSuccess()
      };

    case GET_MPAS_CSV_ERROR:
      return {
        ...state,
        getMpasCsvLoadable: updateLoadableOnError(action.error)
      };

    // set data of selected mpa form formMpa table
    case GET_SELECTED_MPA:
      return {
        ...state,
        getSelectedMpaLoadable: updateLoadableOnStart()
      };

    case GET_SELECTED_MPA_SUCCESS:
      return {
        ...state,
        selectedMpa: action.result,
        getSelectedMpaLoadable: updateLoadableOnSuccess()
      };

    case GET_SELECTED_MPA_ERROR:
      return {
        ...state,
        getSelectedMpaLoadable: updateLoadableOnError(action.error)
      };

    case UPDATE_SELECTED_MPA:
      return {
        ...state,
        updateSelectedMpaLoadable: updateLoadableOnStart()
      };

    case UPDATE_SELECTED_MPA_SUCCESS:
      return {
        ...state,
        updateSelectedMpaLoadable: updateLoadableOnSuccess()
      };

    case UPDATE_SELECTED_MPA_ERROR:
      return {
        ...state,
        updateSelectedMpaLoadable: updateLoadableOnError(action.error)
      };

    case GET_EXPORT_TO_BQ_STATUS:
      return {
        ...state,
        getExportToBqStatusLoadable: updateLoadableOnStart()
      };

    case GET_EXPORT_TO_BQ_STATUS_SUCCESS:
      return {
        ...state,
        exportToBqStatus: "Success",
        getExportToBqStatusLoadable: updateLoadableOnSuccess()
      };

    case GET_EXPORT_TO_BQ_STATUS_ERROR:
      return {
        ...state,
        exportToBqStatus: "Error",
        getExportToBqStatusLoadable: updateLoadableOnError(action.error)
      };

    default:
      return state;
  }
}
