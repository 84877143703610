import { call, put } from 'redux-saga/effects';

import {
    getBudgetPacingSuccess, getBudgetPacingError,
    getBudgetPacingMetricsSuccess, getBudgetPacingMetricsError
} from '../Actions/BudgetPacingActions';

import BudgetPacingService from '../../../Services/BudgetPacingService';

export function* onGetBudgetPacing(action: any) {
    try {
      let response = yield call(BudgetPacingService.retrieveBudgetPacing);
      yield put(getBudgetPacingSuccess(response));
    } catch (err) {
      yield put(getBudgetPacingError(err));
    }
}

export function* onGetBudgetPacingMetrics(action: any) {
  try {
    let response = yield call(BudgetPacingService.retrieveBudgetPacingMetrics);
    yield put(getBudgetPacingMetricsSuccess(response));
  } catch (err) {
    yield put(getBudgetPacingMetricsError(err));
  }
}