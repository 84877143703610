import {
    ADD_CID_TO_EXCLUDE_LIST, ADD_CID_TO_EXCLUDE_LIST_SUCCESS, ADD_CID_TO_EXCLUDE_LIST_ERROR,
    DELETE_SELECTED_EXCLUDE_ID, DELETE_SELECTED_EXCLUDE_ID_SUCCESS, DELETE_SELECTED_EXCLUDE_ID_ERROR,
    GET_EXCLUDE_CIDS, GET_EXCLUDE_CIDS_SUCCESS, GET_EXCLUDE_CIDS_ERROR,
    GET_SELECTED_EXCLUDE_ID, GET_SELECTED_EXCLUDE_ID_SUCCESS, GET_SELECTED_EXCLUDE_ID_ERROR,
    UPDATE_SELECTED_EXCLUDE_ID, UPDATE_SELECTED_EXCLUDE_ID_SUCCESS, UPDATE_SELECTED_EXCLUDE_ID_ERROR
} from '../ActionTypes/ExcludeCidsActionTypes';

export const addCidToExcludeList = (excludedCidData: any) => {
    return {
      type: ADD_CID_TO_EXCLUDE_LIST,
      excludedCidData
    };
};
  
export const addCidToExcludeListSuccess = (result: any) => {
    return {
      type: ADD_CID_TO_EXCLUDE_LIST_SUCCESS,
      result
    };
};
  
export const addCidToExcludeListError = (error: any) => {
    return {
      type: ADD_CID_TO_EXCLUDE_LIST_ERROR,
      error
    };
};

export const deleteSelectedExcludeId = (excludeId: any, payload: any) => {
    return {
      type: DELETE_SELECTED_EXCLUDE_ID,
      excludeId,
      payload
    };
};
  
export const deleteSelectedExcludeIdSuccess = (excludeId: any) => {
    return {
      type: DELETE_SELECTED_EXCLUDE_ID_SUCCESS,
      excludeId
    };
};
  
export const deleteSelectedExcludeIdError = (error: any) => {
    return {
      type: DELETE_SELECTED_EXCLUDE_ID_ERROR,
      error
    };
};

export const getExcludeCids = () => {
    return {
        type: GET_EXCLUDE_CIDS
    };
};

export const getExcludeCidsSuccess = (result: any) => {
    return {
        type: GET_EXCLUDE_CIDS_SUCCESS,
        result
    };
};

export const getExcludeCidsError = (error: any) => {
    return {
        type: GET_EXCLUDE_CIDS_ERROR,
        error
    };
};

export const getSelectedExcludeId = (excludeId: string) => {
    return {
      type: GET_SELECTED_EXCLUDE_ID,
      excludeId
    };
};
  
export const getSelectedExcludeIdSuccess = (result: any) => {
    return {
      type: GET_SELECTED_EXCLUDE_ID_SUCCESS,
      result
    };
};
  
export const getSelectedExcludeIdError = (error: any) => {
    return {
      type: GET_SELECTED_EXCLUDE_ID_ERROR,
      error
    };
};

export const updateSelectedExcludeId = (excludeId: string, excludedCidData: any) => {
    return {
      type: UPDATE_SELECTED_EXCLUDE_ID,
      excludeId,
      excludedCidData
    };
  };
  
  export const updateSelectedExcludeIdSuccess = (result: any) => {
    return {
      type: UPDATE_SELECTED_EXCLUDE_ID_SUCCESS,
      result
    };
  };
  
  export const updateSelectedExcludeIdError = (error: any) => {
    return {
      type: UPDATE_SELECTED_EXCLUDE_ID_ERROR,
      error
    };
  };