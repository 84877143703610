import { createDefaultLoadable, Loadable } from "../../Common/Loadable";

export interface IDagApiConfigDto {
  custom_id: number;
  dag_name?: string;
  date_settings?: string;
  custom_start_date?: string;
  custom_end_date?: string;
  config_new_table?: string;
  include_conversion_actions?: string;
  custom_account_cids?: string;
  custom_cid_1?: string;
  custom_cid_2?: string;
  custom_cid_3?: string;
  custom_cid_4?: string;
  custom_cid_5?: string;
  api_config_table_value_id?: number;
  conv_name?: string;
  conv_acct?: string;
  acct_lob?: string;
}

export interface IDagApiConfigListDto {
  custom_id: number;
  dag_name?: string;
}

export interface IDagApiConfigDropdownDto {
  api_config_table_list_id: number;
  conv_name: string;
  conv_acct: string;
  acct_lob: string;
}
export interface DagApiConfigsState {
  entities: IDagApiConfigListDto[];
  dropdown: IDagApiConfigDropdownDto[];
  dagApiConfig: IDagApiConfigDto;
  getApiConfigListLoadable: Loadable;
  getApiConfigByIdLoadable: Loadable;
  getApiConfigDropdownLoadable: Loadable;
  updateApiConfigLoadable: Loadable;
}

export function createDefaultDagApiConfigsState(): DagApiConfigsState {
  return {
    entities: [],
    dagApiConfig: null,
    dropdown: [],
    getApiConfigListLoadable: createDefaultLoadable(),
    getApiConfigByIdLoadable: createDefaultLoadable(),
    getApiConfigDropdownLoadable: createDefaultLoadable(),
    updateApiConfigLoadable: createDefaultLoadable(),
  };
}
