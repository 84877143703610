import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { Row } from '../Models/ReportTable';
import { CSVLink } from "react-csv";

interface Props {
    rows: Row[],
    selectedFiscal?: string,
    selectedDollar?: string,
    lobProductCid?:Row[],
    channelOptions?: any
}

export const ReportTableBody = (props: Props) => {
    const {rows} = props;
    const [dataCSV, setDataCSV]: any[] = useState([]);
    const [headerCSV, setHeaderCSV]: any[] = useState([]);

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0, 
      minimumFractionDigits: 0, 
    })

    //only for productxlob table view
    function createData(
      lob: string,
      channel: string,
      product: string,
      anchor_cid: string,
      budget_amount: number,
      mpa_amount: number,
      // remaining_to_mpa: number,
      loaded: number,
      remaining_to_load: number,
      spend: number,
      remaining_on_mpa: number,
    ) {
      return {
        lob,
        channel,
        product,
        anchor_cid,
        budget_amount,
        mpa_amount,
        // remaining_to_mpa,
        loaded,
        remaining_to_load,
        spend,
        remaining_on_mpa
      };
    }

    //only for productxlob table view
    const grandTotal = (response:any) =>{
      let lobs = new Set();
      
      for( let r of response){
        lobs.add(r.lob)
      }
    
      let calculatedRow: Row[] =[];
  
      for(let l of Array.from(lobs)){
        let countLOB = 0;
        let lob = l.toString()
        let grand_total = createData('', '','','Grand Total', 0,0,0,0,0,0);
  
        for(let r of response){
          if(r.lob === lob){
            let temp = createData('', '','','',0,0,0,0,0,0);
            // if(countLOB != 0){
            //   temp.lob = ' ';
            // } else {
            //   temp.lob = r.lob;
            // }
            temp.lob = r.lob;
            countLOB++;
            
            temp.channel = r.channel;
            temp.product = r.product;
            temp.anchor_cid = r.anchor_cid;
  
            if(props.selectedDollar === "Gross"){
              temp.budget_amount = Math.round(r.gross_budget_amount);
              temp.mpa_amount = Math.round(r.gross_mpa_amount);
              var gross_remaining_to_mpa = Math.round(r.gross_budget_amount) - Math.round(r.gross_mpa_amount);
              // temp.remaining_to_mpa = Math.round(gross_remaining_to_mpa) === -0 ? 0 : Math.round(gross_remaining_to_mpa);
              if(r.channel === "Facebook"){
                temp.loaded = Math.round(r.gross_mpa_amount);
                temp.remaining_to_load = 0
              } else {
                temp.loaded = Math.round(r.gross_loaded_budget);
                var gross_remaining_to_load = Math.round(r.gross_mpa_amount) - Math.round(r.gross_loaded_budget);
                temp.remaining_to_load = Math.round(gross_remaining_to_load) === -0 ? 0 : Math.round(gross_remaining_to_load);
                // temp.remaining_to_load = Math.round(r.gross_remaining_to_load);
              }
              // temp.remaining_to_load = Math.round(r.gross_remaining_to_load) ===-0 ?0: Math.round(r.gross_remaining_to_load);
              temp.spend = Math.round(r.gross_spend);
              var gross_remaining_on_mpa = Math.round(r.gross_mpa_amount) - Math.round(r.gross_spend);
              temp.remaining_on_mpa = Math.round(gross_remaining_on_mpa) === -0 ? 0 : Math.round(gross_remaining_on_mpa);
              // temp.remaining_on_mpa = Math.round(r.gross_remaining_on_mpa);  
            } else if(props.selectedDollar === "Net"){
              temp.budget_amount = Math.round(r.net_budget_amount);
              temp.mpa_amount = Math.round(r.net_mpa_amount);
              var net_remaining_to_mpa = Math.round(r.net_budget_amount) - Math.round(r.net_mpa_amount);
              // temp.remaining_to_mpa = Math.round(net_remaining_to_mpa) === -0 ? 0 : Math.round(net_remaining_to_mpa);
              if(r.channel === "Facebook"){
                temp.loaded = Math.round(r.net_mpa_amount);
                temp.remaining_to_load = 0
              } else {
                temp.loaded = Math.round(r.net_loaded_budget);
                var net_remaining_to_load = Math.round(r.net_mpa_amount) - Math.round(r.net_loaded_budget);
                temp.remaining_to_load = Math.round(net_remaining_to_load) === -0 ? 0: Math.round(net_remaining_to_load);
                // temp.remaining_to_load = Math.round(r.net_remaining_to_load);
              }
              // temp.loaded = Math.round(r.net_loaded_budget);
              // temp.remaining_to_load = Math.round(r.net_remaining_to_load) ===-0? 0: Math.round(r.net_remaining_to_load);
              temp.spend = Math.round(r.net_spend);
              var net_remaining_on_mpa = Math.round(r.net_mpa_amount) - Math.round(r.net_spend);
              temp.remaining_on_mpa = Math.round(net_remaining_on_mpa) === -0 ? 0 : Math.round(net_remaining_on_mpa);
              // temp.remaining_on_mpa = Math.round(r.net_remaining_on_mpa);  
            }
            
            calculatedRow.push(temp);
            
            grand_total.lob = temp.lob;
            grand_total.budget_amount = grand_total.budget_amount + temp.budget_amount;
            grand_total.mpa_amount = grand_total.mpa_amount + temp.mpa_amount;
            // grand_total.remaining_to_mpa = grand_total.remaining_to_mpa + temp.remaining_to_mpa;
            grand_total.loaded = grand_total.loaded + temp.loaded;
            grand_total.remaining_to_load = grand_total.remaining_to_load + temp.remaining_to_load;
            grand_total.spend = grand_total.spend + temp.spend;
            grand_total.remaining_on_mpa = grand_total.remaining_on_mpa + temp.remaining_on_mpa;
          }
        }
        calculatedRow.push(grand_total);
      }

      let tempRows = calculatedRow.map(r=>{return {...r}});
      for(let channel of props.channelOptions){
        if(!channel.checked){   
            tempRows = tempRows.filter(row => row.channel !== channel.name);
        }
      }      

      for(let l of Array.from(lobs)){  
        let lob = l.toString();
        let grand_total_row: Row = {
          lob: ' ',     
          channel: ' ',
          product: ' ',
          anchor_cid: 'Grand Total', 
          budget_amount: 0,
          mpa_amount: 0,          
          // remaining_to_mpa: 0,
          loaded: 0,
          remaining_to_load: 0,
          spend: 0,
          remaining_on_mpa: 0
        };
        for(let row of tempRows) {
          if(row.lob === lob && row.anchor_cid !== 'Grand Total') {
            grand_total_row.lob = row.lob;
            grand_total_row.budget_amount += row.budget_amount;
            grand_total_row.mpa_amount += row.mpa_amount;
            // grand_total_row.remaining_to_mpa += row.remaining_to_mpa;
            grand_total_row.loaded += row.loaded;
            grand_total_row.remaining_to_load += row.remaining_to_load;
            grand_total_row.spend += row.spend;
            grand_total_row.remaining_on_mpa += row.remaining_on_mpa;
          }       
        }
       
        tempRows = tempRows.map((row) => {
          if(row.lob === lob && row.anchor_cid === 'Grand Total') {
            return grand_total_row;
          }
          return row;
        })
      }

      // Remove duplicate budget amount values for each LOB
      let removeDuplicateValues = tempRows.map(r=>{return {...r}});

      for(let l of Array.from(lobs)){            
        let countLOB = 0;
        let lob = l.toString();
        let prev_amount = 0;
        let amount_difference = 0;
        let currentChannel = '';
        let amountRowsCnt = 0;
        let currentProduct = '';

        for(let r of removeDuplicateValues){             
          if(r.lob === lob && r.anchor_cid !== 'Grand Total'){ 
            if(r.budget_amount !== prev_amount){
              prev_amount = r.budget_amount;
              amountRowsCnt = 0;
            }

            if(amountRowsCnt != 0) {
              amount_difference += prev_amount;
              prev_amount = r.budget_amount;
              r.budget_amount = null;
            }
            amountRowsCnt++;

            if(countLOB != 0 ){              
                r.lob = ' ';
            }
            countLOB++;

            // remove duplicate channels for each row
            if(currentChannel != r.channel) {
              currentChannel = r.channel;
            } else {
              r.channel = ' ';
            } 

            // remove duplicate products for each row
            if(currentProduct != r.product) {
              currentProduct = r.product;
            } else {
              r.product = ' ';
            } 
          } else if(r.lob === lob && r.anchor_cid === 'Grand Total') {
            r.lob = ' ';
            r.budget_amount -= amount_difference;
            // r.remaining_to_mpa = r.budget_amount - r.mpa_amount;
          }
        }
      }
      setDataCSV(removeDuplicateValues);
    }

    //only for productxlob table view
    const handleCSV = (e:any) => {
      setHeaderCSV([
        { label: "LOB", key: "lob" },
        { label: "Channel", key: "channel" },
        { label: "Product", key: "product" },
        { label: "Anchor_cid", key: "anchor_cid" },
        { label: "Budget Amount", key: "budget_amount" },
        { label: "MPA Amount", key: "mpa_amount" },
        // { label: "Remaining To MPA", key: "remaining_to_mpa" },
        { label: "Loaded", key: "loaded" },
        { label: "Remaining To Loaded", key: "remaining_to_load" },
        { label: "Spend", key: "spend" },
        { label: "Remaining On MPA", key: "remaining_on_mpa" },
      ])

      let filterData: Row[];
      
      if(e.target.name !== "" && e.target.nonce !== ""){
        filterData = props.lobProductCid.filter(r=>r.lob === e.target.name && r.product === e.target.innerHTML)
      } else if(e.target.name !== "" && e.target.nonce === ""){
        filterData = props.lobProductCid.filter(r=>r.lob === e.target.name && r.channel === e.target.innerHTML)
      }
      else {
        filterData = props.lobProductCid.filter(r=>r.lob === e.target.innerHTML)
      }
      // if(e.target.name !== "" && e.target.nonce !== ""){
      //   filterData = props.lobProductCid.filter(r=>r.lob === e.target.name && r.product === e.target.innerHTML)
      // } 
      // else {
      //   filterData = props.lobProductCid.filter(r=>r.lob === e.target.innerHTML)
      // }

      grandTotal(filterData);
    }

    return(
      <TableBody>
      {rows.map((row, index) => {
          return (
            <TableRow key={index} style={{ backgroundColor: row.lob==="Grand Total" ||row.channel==="Grand Total" ||row.product==="Grand Total"? "Gainsboro":"white"}}>

              {/* {row.lob?<TableCell>{row.lob}</TableCell>:null} */}
              {row.lob && row.product?
                <TableCell>
                  <CSVLink 
                    data={dataCSV} 
                    headers={headerCSV} 
                    filename={"Budget_Breakdown_"+row.lob+"_"+props.selectedFiscal.slice(3,7)+"_"+props.selectedDollar+".csv"}
                    // asyncOnClick={true} 
                    onClick={(event)=>handleCSV(event)}
                  >
                    {row.lob}
                  </CSVLink>
                </TableCell>:
                row.lob?<TableCell>{row.lob}</TableCell>
                :null
              }
              {row.product && row.product !== "Grand Total"?
              <TableCell>
                <CSVLink 
                    data={dataCSV} 
                    headers={headerCSV} 
                    filename={"Budget_Breakdown_"+row.origLob+"_"+props.selectedFiscal.slice(3,7)+"_"+props.selectedDollar+"_"+row.channel+".csv"}
                    // asyncOnClick={true} 
                    onClick={(event)=>handleCSV(event)}
                    name={row.origLob}
                  >
                    {row.channel}
                </CSVLink>
              </TableCell>:
              row.channel?<TableCell>{row.channel}</TableCell>:
              null}              
              {/* {typeof row.channel !== "undefined" && row.channel !== null && row.channel !== ' ' && row.product && row.product !== "Grand Total"?<TableCell>
                <CSVLink 
                    data={dataCSV} 
                    headers={headerCSV} 
                    filename={"Budget_Breakdown_"+row.origLob+"_"+props.selectedFiscal.slice(3,7)+"_"+props.selectedDollar+"_"+row.channel+".csv"}
                    // asyncOnClick={true} 
                    onClick={(event)=>handleCSV(event)}
                    name={row.origLob}
                  >
                    {row.channel}
                </CSVLink>
              </TableCell>: typeof row.channel !== "undefined" && row.channel !== null && row.channel !== ' ' ? <TableCell>{row.channel}</TableCell> : ''} */}
              {row.product && row.product !== "Grand Total"?
              <TableCell>
                  <CSVLink 
                    data={dataCSV} 
                    headers={headerCSV} 
                    filename={"Budget_Breakdown_"+row.origLob+"_"+props.selectedFiscal.slice(3,7)+"_"+props.selectedDollar+"_"+row.product+".csv"}
                    // asyncOnClick={true} 
                    onClick={(event)=>handleCSV(event)}
                    name={row.origLob}
                    nonce={row.product}
                  >
                    {row.product}
                  </CSVLink>  
              </TableCell>:
              row.product?
              <TableCell>{row.product}</TableCell>
              :null}
              {typeof row.budget_amount !== "undefined" ?<TableCell align="right" style={{fontWeight:row.lob==="Grand Total" ||row.channel==="Grand Total" ||row.product==="Grand Total"?"bold":"normal", color:row.budget_amount<0? "red":"black"}}>{row.budget_amount !== null ? formatter.format(row.budget_amount) : ''}</TableCell> : ''}
              <TableCell align="right" style={{fontWeight:row.lob==="Grand Total" ||row.channel==="Grand Total" ||row.product==="Grand Total"?"bold":"normal", color:row.mpa_amount<0? "red":"black"}}>{formatter.format(row.mpa_amount)}</TableCell>
              {typeof row.remaining_to_mpa !== "undefined" ?<TableCell align="right" style={{fontWeight:row.lob==="Grand Total" ||row.channel==="Grand Total" ||row.product==="Grand Total"?"bold":"normal", color:row.remaining_to_mpa<0? "red":"black"}}>{formatter.format(row.remaining_to_mpa)}</TableCell>
              : ''}
              <TableCell align="right" style={{fontWeight:row.lob==="Grand Total" ||row.channel==="Grand Total" ||row.product==="Grand Total"?"bold":"normal", color:row.loaded<0? "red":"black"}}>{formatter.format(row.loaded)}</TableCell>
              <TableCell align="right" style={{fontWeight:row.lob==="Grand Total" ||row.channel==="Grand Total" ||row.product==="Grand Total"?"bold":"normal", color:row.remaining_to_load<0? "red":"black"}}>{formatter.format(row.remaining_to_load)}</TableCell>
              <TableCell align="right" style={{fontWeight:row.lob==="Grand Total" ||row.channel==="Grand Total" ||row.product==="Grand Total"?"bold":"normal", color:row.spend<0? "red":"black"}}>{formatter.format(row.spend)}</TableCell>
              <TableCell align="right" style={{fontWeight:row.lob==="Grand Total" ||row.channel==="Grand Total" ||row.product==="Grand Total"?"bold":"normal", color:row.remaining_on_mpa<0? "red":"black"}}>{formatter.format(row.remaining_on_mpa)}</TableCell>
            </TableRow>
          );
      })}
      </TableBody>
    )


}