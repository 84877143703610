import React from 'react';

// import { getLobsCsv } from '../../../State/Lob/Actions/LobActions';

import { Button, ButtonToolbar } from 'reactstrap';
import history from '../../../Utils/History'
const LobToolbar = (props: any) => {

  const { showAddLobButton } = props;

  return (
    <ButtonToolbar style={{'padding': '1.5rem 0'}}>
      {showAddLobButton &&
        <Button
          color="primary"
          onClick={()=>{history.push(`/map/lob/add`,'/map/mpa')}}
          className="mr-1"
        >
          Add Line of Business
        </Button>
      }
    </ButtonToolbar>
  );
};

export default LobToolbar;
