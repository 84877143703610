import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';


export const ForecastingTableBody = (props: any) => {
    const {rows, checkedH1, checkedH2} = props;
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0, 
        minimumFractionDigits: 0, 
      })

    return(
      <TableBody>
      {rows.map((row, index) => {
          return (
            <TableRow key={index} style={{ backgroundColor: index%8===0||index%8===1||index%8===2||index%8===3?"#F5F5F5":"white"}}>
         
                <TableCell>{row.mpa}</TableCell>
                <TableCell>{row.channel}</TableCell>
                <TableCell>{row.products}</TableCell>
                {row.mpa_subtotal !== ' ' ?
                    <TableCell align="right" style={{color:row.mpa_subtotal<0? "red":"black"}}>{formatter.format(row.mpa_subtotal)}</TableCell>
                :
                    <TableCell>{row.mpa_subtotal}</TableCell>}
                {/* {row.mpa_total !== ' ' ?
                    <TableCell align="right" style={{color:row.mpa_total<0? "red":"black"}}>{formatter.format(row.mpa_total)}</TableCell>
                :
                    <TableCell>{row.mpa_total}</TableCell>} */}
                {row.remaining_budget !== ' ' ?
                    <TableCell align="right" style={{color:row.remaining_budget<0? "red":"black"}}>{formatter.format(row.remaining_budget)}</TableCell>
                :
                    <TableCell>{row.remaining_budget}</TableCell>}
                {/* <TableCell style={{position:'sticky', left:0, zIndex:600, background: 'white'}}>{row.spends}</TableCell> */}
                <TableCell>{row.spends}</TableCell>
                {checkedH1 && checkedH2?
                    <React.Fragment>
                    {row.spends !== "Variance %"? 
                        <React.Fragment>
                                <TableCell align="right" style={{color:row.m11<0? "red":"black"}}>{formatter.format(row.m11)}</TableCell>
                                <TableCell align="right" style={{color:row.m12<0? "red":"black"}}>{formatter.format(row.m12)}</TableCell>
                                <TableCell align="right" style={{color:row.m1<0? "red":"black"}}>{formatter.format(row.m1)}</TableCell>
                                <TableCell align="right" style={{color:row.m2<0? "red":"black"}}>{formatter.format(row.m2)}</TableCell>
                                <TableCell align="right" style={{color:row.m3<0? "red":"black"}}>{formatter.format(row.m3)}</TableCell>
                                <TableCell align="right" style={{color:row.m4<0? "red":"black"}}>{formatter.format(row.m4)}</TableCell>
                                <TableCell align="right" style={{color:row.m5<0? "red":"black"}}>{formatter.format(row.m5)}</TableCell>
                                <TableCell align="right" style={{color:row.m6<0? "red":"black"}}>{formatter.format(row.m6)}</TableCell>
                                <TableCell align="right" style={{color:row.m7<0? "red":"black"}}>{formatter.format(row.m7)}</TableCell>
                                <TableCell align="right" style={{color:row.m8<0? "red":"black"}}>{formatter.format(row.m8)}</TableCell>
                                <TableCell align="right" style={{color:row.m9<0? "red":"black"}}>{formatter.format(row.m9)}</TableCell>
                                <TableCell align="right" style={{color:row.m10<0? "red":"black"}}>{formatter.format(row.m10)}</TableCell>
                                <TableCell align="right" style={{color:row.total<0? "red":"black"}}>{formatter.format(row.total)}</TableCell>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <TableCell align="right">{row.m11+"%"}</TableCell>
                            <TableCell align="right">{row.m12+"%"}</TableCell>
                            <TableCell align="right">{row.m1+"%"}</TableCell>
                            <TableCell align="right">{row.m2+"%"}</TableCell>
                            <TableCell align="right">{row.m3+"%"}</TableCell>
                            <TableCell align="right">{row.m4+"%"}</TableCell>
                            <TableCell align="right">{row.m5+"%"}</TableCell>
                            <TableCell align="right">{row.m6+"%"}</TableCell>
                            <TableCell align="right">{row.m7+"%"}</TableCell>
                            <TableCell align="right">{row.m8+"%"}</TableCell>
                            <TableCell align="right">{row.m9+"%"}</TableCell>
                            <TableCell align="right">{row.m10+"%"}</TableCell>
                            <TableCell align="right" style={{color:row.total<0? "red":"black"}}>{row.total+"%"}</TableCell>
                        </React.Fragment>}
                </React.Fragment>
                :
                checkedH1 && !checkedH2?
                <React.Fragment>
                    {row.spends !== "Variance %"? 
                        <React.Fragment>
                                <TableCell align="right" style={{color:row.m11<0? "red":"black"}}>{formatter.format(row.m11)}</TableCell>
                                <TableCell align="right" style={{color:row.m12<0? "red":"black"}}>{formatter.format(row.m12)}</TableCell>
                                <TableCell align="right" style={{color:row.m1<0? "red":"black"}}>{formatter.format(row.m1)}</TableCell>
                                <TableCell align="right" style={{color:row.m2<0? "red":"black"}}>{formatter.format(row.m2)}</TableCell>
                                <TableCell align="right" style={{color:row.m3<0? "red":"black"}}>{formatter.format(row.m3)}</TableCell>
                                <TableCell align="right" style={{color:row.m4<0? "red":"black"}}>{formatter.format(row.m4)}</TableCell>
                                <TableCell align="right" style={{color:row.total<0? "red":"black"}}>{formatter.format(row.total)}</TableCell>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <TableCell align="right">{row.m11+"%"}</TableCell>
                            <TableCell align="right">{row.m12+"%"}</TableCell>
                            <TableCell align="right">{row.m1+"%"}</TableCell>
                            <TableCell align="right">{row.m2+"%"}</TableCell>
                            <TableCell align="right">{row.m3+"%"}</TableCell>
                            <TableCell align="right">{row.m4+"%"}</TableCell>
                            <TableCell align="right" style={{color:row.total<0? "red":"black"}}>{row.total+"%"}</TableCell>
                        </React.Fragment>}
                </React.Fragment>
                :
                !checkedH1 && checkedH2?
                <React.Fragment>
                    {row.spends !== "Variance %"? 
                        <React.Fragment>
                                <TableCell align="right" style={{color:row.m5<0? "red":"black"}}>{formatter.format(row.m5)}</TableCell>
                                <TableCell align="right" style={{color:row.m6<0? "red":"black"}}>{formatter.format(row.m6)}</TableCell>
                                <TableCell align="right" style={{color:row.m7<0? "red":"black"}}>{formatter.format(row.m7)}</TableCell>
                                <TableCell align="right" style={{color:row.m8<0? "red":"black"}}>{formatter.format(row.m8)}</TableCell>
                                <TableCell align="right" style={{color:row.m9<0? "red":"black"}}>{formatter.format(row.m9)}</TableCell>
                                <TableCell align="right" style={{color:row.m10<0? "red":"black"}}>{formatter.format(row.m10)}</TableCell>
                                <TableCell align="right" style={{color:row.total<0? "red":"black"}}>{formatter.format(row.total)}</TableCell>
                        </React.Fragment>
                        : 
                        <React.Fragment>
                            <TableCell align="right">{row.m5+"%"}</TableCell>
                            <TableCell align="right">{row.m6+"%"}</TableCell>
                            <TableCell align="right">{row.m7+"%"}</TableCell>
                            <TableCell align="right">{row.m8+"%"}</TableCell>
                            <TableCell align="right">{row.m9+"%"}</TableCell>
                            <TableCell align="right">{row.m10+"%"}</TableCell>
                            <TableCell align="right" style={{color:row.total<0? "red":"black"}}>{row.total+"%"}</TableCell>
                        </React.Fragment>}
                </React.Fragment>
                : null
                }
                               
               
            </TableRow>
          );
      })}
      </TableBody>
    )


}