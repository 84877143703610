import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';

export const SessionTimeoutModal = (props: any) => {
  const { handleToggleModal, isModalOpen } = props;

  const dispatch = useDispatch();
  const [state, setState] = useState({
    secondsRemaining: 60,
    isSessionTimedOut: false,
  });
  let interval: any = null;

  const tick = () =>
    setState((prevState) => {
      if (prevState.isSessionTimedOut) {
        clearInterval(interval);
        handleToggleModal();
        // dispatch(logout());
      }

      return {
        ...prevState,
        secondsRemaining: prevState.secondsRemaining - 1,
        isSessionTimedOut: prevState.secondsRemaining - 1 <= 0,
      };
    });

  const printTimeRemaining = () =>
    state.secondsRemaining === 60
      ? '1 minute'
      : `${state.secondsRemaining} seconds`;

  useEffect(() => {
    interval = setInterval(tick, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [tick]);

  return (
    <>
      <Modal isOpen={isModalOpen || state.isSessionTimedOut} centered>
        <ModalHeader>Session Timeout Warning</ModalHeader>
        <ModalBody>
          <p>
            You will be automatically logged out in {printTimeRemaining()}.
            <br />
            To remain logged in, click 'Cancel' button.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleToggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
