import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AppPage, PageHeader, WithMultipleLoading } from '../../../Common';

import EditLob from './EditLob';

import { getUsername } from '../../../State/Authentication/Selectors/AuthenticationSelectors';
import { getLobState } from '../../../State/Lob/Selectors/LobSelectors';
import { getLobFormContextState } from '../../../State/LobFormContext/Selectors/LobFormContextSelectors';
import { getFormInfoState } from '../../../State/FormInfo/Selectors/FormInfoSelectors';
import { setSelectedLobId } from '../../../State/LobFormContext/Actions/LobFormContextActions';
import { getDropdownOptions, getProductOptions, getChannelOptions } from '../../../State/FormInfo/Actions/FormInfoActions';

const EditLobWithMultipleLoading = WithMultipleLoading(EditLob);

const EditLobContainer = (props: any) => {
    const formInfoState = useSelector(getFormInfoState);
    const editLobState = useSelector(getLobState);
    const lobFormContextState = useSelector(getLobFormContextState);
    const username = useSelector(getUsername);

    // get id param, set up in /lob/edit/:id route (app.tsx) 
    const { params } = props.match;
    const dispatch = useDispatch();

    useEffect(() => {
        if (params.id !== lobFormContextState.selectedLobId) {
          dispatch(setSelectedLobId(params.id));
        }
    
        // if (!formInfoState.getDropdownOptionsLoadable.isReceived) {
        //   dispatch(getDropdownOptions());
        // }

        if (!formInfoState.getProductOptionsLoadable.isReceived) {
          dispatch(getProductOptions());
        }

        if (!formInfoState.getChannelOptionsLoadable.isReceived) {
            dispatch(getChannelOptions());
        }

      }, [
        params.id,
        lobFormContextState.selectedLobId,
        formInfoState.getProductOptionsLoadable.isReceived,
        formInfoState.getChannelOptionsLoadable.isReceived,
        dispatch
      ]);

    return (
        <> 
            <PageHeader>Edit LOB</PageHeader>
            <AppPage>
                <EditLobWithMultipleLoading
                    loadables={[
                        editLobState.getSelectedLobListLoadable,
                        formInfoState.getProductOptionsLoadable
                    ]}
                    isSubmitDisabled={editLobState.updateLobListLoadable.isLoading}
                    lobDropdownOptions={formInfoState.productOptionsWithoutChannel}
                    channelDropdownOptions = {formInfoState.channelOptions}
                    selectedLob={editLobState.selectedLob}
                    isDisabled={false}
                    userName={username}
                />
            </AppPage>
        </>
    );      
};

export default EditLobContainer