import {
    ExcludeCidsFormContextState,
    createDefaultExcludeCidsFormContextState
  } from '../Models/ExcludeCidsFormContextState';
  
  import { SET_SELECTED_EXCLUDE_ID } from '../ActionTypes/ExcludeCidsFormContextTypes';
  
  export default function (
    state = createDefaultExcludeCidsFormContextState(),
    action: any
  ): ExcludeCidsFormContextState {
    switch(action.type) {
      case SET_SELECTED_EXCLUDE_ID:
        return {
           ...state,
           selectedExcludeId: action.excludeId
        };
  
      default:
        return state;
    };
  };
  