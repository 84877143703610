import { createDefaultLoadable, Loadable } from '../../Common/Loadable';

export interface FormInfoState {
  anchorCids: Array<any>,
  dropdownOptions: any,
  newMpaNumber: any,
  productOptionsWithoutChannel: any,
  selectedProductChannelList: Array<any>,
  channelOptions: any,
  getAnchorCidsLoadable: Loadable,
  getDropdownOptionsLoadable: Loadable,
  getNewMpaNumberLoadable: Loadable,
  getProductOptionsLoadable: Loadable,
  getChannelsListLoadable: Loadable,
  getChannelOptionsLoadable: Loadable  
}

export function createDefaultFormInfoState(): FormInfoState {
  return {
    anchorCids: [],
    dropdownOptions: [],
    newMpaNumber: '',
    productOptionsWithoutChannel: [],
    selectedProductChannelList: [],
    channelOptions: [],
    getAnchorCidsLoadable: createDefaultLoadable(),
    getDropdownOptionsLoadable: createDefaultLoadable(),
    getNewMpaNumberLoadable: createDefaultLoadable(),
    getProductOptionsLoadable: createDefaultLoadable(),
    getChannelsListLoadable: createDefaultLoadable(),
    getChannelOptionsLoadable: createDefaultLoadable()
  }
}
