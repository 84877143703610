import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BudgetOrdersInterface } from '../Models/BudgetOrders';

interface Props{
  budgetOrder: BudgetOrdersInterface, 
  handleSelect: (clientId : string, actionIcon : string) => void
}

export const TableActionsCell = (props: Props) => {
  const { budgetOrder, handleSelect } = props;

  return (
    <td>
      <FontAwesomeIcon
        icon="edit"
        size="lg"
        data-row-id={budgetOrder['clientID']}
        style={{
          color: '#212529',
          cursor: 'pointer',
          marginRight: '0.5rem'
        }}
        title="Edit budget orders"
        onClick={ () => { handleSelect(budgetOrder['clientID'], 'edit'); }}
      />
      <FontAwesomeIcon
        icon="plus"
        size="lg"
        data-row-id={budgetOrder['clientID']}
        style={{
          color: '#212529',
          cursor: 'pointer'
        }}
        title="Accept suggested transfer amounts"
        onClick={ () => { handleSelect(budgetOrder['clientID'], 'plus'); }}
      />
    </td>
  )
};

TableActionsCell.defaultProps = {
  handleSelect: () => { }
};

TableActionsCell.propTypes = {
  budgetOrder: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired
};
