import React, { useCallback } from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';

interface ICSVButton {
  filename: string;
  content: string[][];
}

const CSVButton = ({ filename, content }: ICSVButton): JSX.Element => {
  const handleExport = useCallback(() => {
    const csvContent = content
      .map((line) => line.map((col) => col.replaceAll(',', '')).join(','))
      .join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    const now = moment();
    a.setAttribute('href', url);
    a.setAttribute('download', `${filename}_${now.format('MM-DD-YYYY')}.csv`);
    a.click();
  }, [filename, content]);

  return (
    <Button
      color="primary"
      onClick={handleExport}
      size="small"
      style={{ marginLeft: '20px', marginBottom: '10px' }}>
      Export CSV
    </Button>
  );
};

export default CSVButton;
