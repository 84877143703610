import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { pick, keys, values } from 'lodash';

import { ExcludeCidFormInterface } from '../../../Models/ExcludeCidFormInterface';
import { addCidToExcludeList } from '../../../State/ExcludeCids/Actions/ExcludeCidsActions';

import ExcludeCidForm from '../Common/ExcludeCidForm';
import { ConfirmationModal } from '../../../Common';
import { ModalsConsumer } from '../../../Contexts/ModalsContext';
import MpaService from '../../../Services/MpaService';
import { toast } from 'react-toastify';

interface OwnProps {
    // lobDropdownOptions: Array<any>;
    userName: String;
  }

const AddExcludeCid = (props: OwnProps) => {
    const [excludedCidData, setExcludedCidData] = useState<ExcludeCidFormInterface>({
        customerId:  "",
        account_name: ""
    });

    const dispatch = useDispatch();

    const handleAddCidToExclude = async (event: any, openUpdateConfirmationModal: any, customerId: any) => {
        event.preventDefault();
        // remove hyphens from Customer Id before inserting it to cids_to_exclude table
        let regex = new RegExp('-', 'g');
        excludedCidData.customerId = excludedCidData.customerId.replace(regex, '');

        // check if entered customer ID already exists in cids_to_exclude table then display alert
        let excluded_cids_params = {
            anchor_cids_without_dash: excludedCidData.customerId
        }
        const response = await MpaService.retrieveExcludedAnchorIds(excluded_cids_params);            
        if(Object.keys(response['customerId']).length != 0){
            toast.error("The Customer ID already exists in the Exclude CIDs table", {
                autoClose: 10000
            });
        } else {
            openUpdateConfirmationModal();
        }        
    };

    const buildAddCidToExcludeConfirmationText = () => {
        const customerId = "'" + excludedCidData.customerId + "'";
        const accountName = "'" + excludedCidData.account_name + "'";

        return `CID ${customerId} with the account name ${accountName} will be added to the exclude list. Do you want to proceed?`;
    };

    const addCidToExcludeAction = () => {       
        const excludeCidPayload = {
            excludedCidData: excludedCidData,
            editedBy: props.userName
        };        
        dispatch(addCidToExcludeList(excludeCidPayload));               
    };

    return(
        <ModalsConsumer>
            {
                modalsState => {
                    return modalsState && (
                        <>
                        <ExcludeCidForm
                            excludedCidData={excludedCidData}
                            onSave={handleAddCidToExclude}
                            openUpdateConfirmationModal={modalsState.handleToggleConfirmationModal}
                            setExcludedCidData={setExcludedCidData}
                            isSubmitDisabled={
                                (excludedCidData.customerId && excludedCidData.account_name) === "" ? true : false
                            }
                            isDisabled={false}
                        />
                        <ConfirmationModal
                            confirmationHeader="Add CID to Exclude List"
                            confirmationText={buildAddCidToExcludeConfirmationText()}
                            executeConfirmationAction={addCidToExcludeAction}
                            handleToggleModal={modalsState.handleToggleConfirmationModal}
                            isModalOpen={modalsState.isConfirmationModalOpen}
                        />
                        </>
                    )
                }
            }
        </ModalsConsumer>
    )
}

export default AddExcludeCid;