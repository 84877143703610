import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppPage, PageHeader, WithMultipleLoading } from '../../../Common';
import EditExcludeCid from './EditExcludeCid';
import { getUsername } from '../../../State/Authentication/Selectors/AuthenticationSelectors';
import { getExcludeCidsState } from '../../../State/ExcludeCids/Selectors/ExcludeCidsSelectors';
import { getExcludeCidsFormContextState } from '../../../State/ExcludeCidsFormContext/Selectors/ExcludeCidsFormContextSelectors';
import { getFormInfoState } from '../../../State/FormInfo/Selectors/FormInfoSelectors';
import { setSelectedExcludeId } from '../../../State/ExcludeCidsFormContext/Actions/ExcludeCidsFormContextActions';
import { getDropdownOptions } from '../../../State/FormInfo/Actions/FormInfoActions';

const EditExcludecidWithMultipleLoading = WithMultipleLoading(EditExcludeCid);

const EditExcludeCidContainer = (props: any) => {
    const formInfoState = useSelector(getFormInfoState);
    const editExcludeCidsState = useSelector(getExcludeCidsState);
    const excludeCidsFormContextState = useSelector(getExcludeCidsFormContextState);
    const username = useSelector(getUsername);

    // get excludeId param, set up in /map/exclude-cids/edit/:excludeId route (app.tsx) 
    const { params } = props.match;
    const dispatch = useDispatch();

    useEffect(() => {
        if (params.excludeId !== excludeCidsFormContextState.selectedExcludeId) {
          dispatch(setSelectedExcludeId(params.excludeId));
        }
      }, [
        params.excludeId,
        excludeCidsFormContextState.selectedExcludeId,
        dispatch
      ]);

    return (
        <> 
            <PageHeader>Edit Exclude CID</PageHeader>
            <AppPage>
                <EditExcludecidWithMultipleLoading
                    loadables={[
                        editExcludeCidsState.getSelectedExcludeIdListLoadable
                    ]}
                    isSubmitDisabled={editExcludeCidsState.updateExcludeIdListLoadable.isLoading}
                    selectedExcludedCidData={editExcludeCidsState.selectedExcludedCidData}
                    isDisabled={false}
                    userName={username}
                />
            </AppPage>
        </>
    );      
};

export default EditExcludeCidContainer