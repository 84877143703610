import React from 'react';
import styled from 'styled-components';
import { BudgetOrderSearchIndexInterface } from '../Models/BudgetOrderSearchIndex';

const SearchResultsList = styled('ul')`
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0 0 5px 5px;
  margin: -3px -25% 0;
  left: 50%;
  padding: 0;
  position: absolute;
  transition: all 0.2s ease 0.1s;
  width: 50%;
  z-index: 1;
`;

const SearchResultsListItem = styled('li')`
  color: #495057;
  cursor: pointer;
  list-style: none;
  padding: 0.5rem 1rem;
  text-align: left;
  transition: all 0.2s ease 0.1s;

  :hover {
    background-color: #f8f9fa;
  }
`;

interface Props {
  searchResultObjectField: string,
  searchResults: Array<BudgetOrderSearchIndexInterface>,
  onSelect: (event: any) => void,
}

export const SearchResults = (props: Props ) => {
  const { searchResultObjectField, searchResults, onSelect } = props;
  return (
    <SearchResultsList>
      {searchResults.map(
        (result: BudgetOrderSearchIndexInterface, index: any) =>
          <SearchResultsListItem
            key={index}
            onClick={onSelect}>
            {result[searchResultObjectField]}
          </SearchResultsListItem>)}

    </SearchResultsList>
  );
};
