import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { Table } from 'reactstrap';

import { AuxiliaryToolbar } from '../Common/AuxiliaryToolbar';
import { BudgetOrderFilterBreadcrumb } from '../Common/BudgetOrderFilterBreadcrumb';
import { BudgetOrderFilterForm } from '../Common/BudgetOrderFilterForm';
import { BudgetOrderSearchBar } from '../Common/BudgetOrderSearchBar';
import { TableHeaderWithSort } from '../Common/TableHeaderWithSort';
import { TableRow } from '../Common/TableRow';

import { BUDGET_ORDER_ACTION_MAP } from '../Models/BudgetOrderEditingContext';
import { OPPOSITE_SORT_DIRECTION, BudgetOrdersViewState } from '../Models/BudgetOrdersView';
import { BudgetOrdersInterface, BudgetOrdersState } from '../Models/BudgetOrders';
import { BudgetOrderSearchIndexInterface } from '../Models/BudgetOrderSearchIndex';

import { getUserPermissions } from '../../../State/Authentication/Selectors/AuthenticationSelectors';

interface Props{
  budgetOrderCategories: Array<string>,
  budgetOrderSearchIndex: Array<BudgetOrderSearchIndexInterface>,
  budgetOrders: Array<BudgetOrdersInterface>,
  budgetOrdersView: BudgetOrdersViewState,
  history: any,
  linesOfBusiness: Array<string>,
  tableConfig: Array<string>,
  budgetOrdersState: BudgetOrdersState
}

export const BudgetOrdersDashboard = (props : Props) => {
  const {
    budgetOrderCategories,
    budgetOrderSearchIndex,
    budgetOrders,
    budgetOrdersView,
    history,
    linesOfBusiness,
    tableConfig,
    budgetOrdersState
  } = props;

  const areFiltersApplied = budgetOrdersView.selectedCategory !== '' || budgetOrdersView.selectedWeeksLeft !== '' || 
                            budgetOrdersView.selectedLineOfBusiness !== '' || budgetOrdersView.searchTerm !== ''

  const userPermissions = useSelector(getUserPermissions);

  const handleSelectBudgetOrder = (clientId : string, actionIcon : string) => {

    history.push({
      pathname:'/bom/edit-budget-orders',
      state:{clientId,editeMode:BUDGET_ORDER_ACTION_MAP[actionIcon],budgetOrdersState}
    });

  }

  const handleSortBudgetOrders = (sortAttribute : string, sortDirection : string) => {
    const budgetOrderViewParams = {
      ...budgetOrdersView,
      sortAttribute,
      sortDirection: OPPOSITE_SORT_DIRECTION[sortDirection]
    };
    history.push(`/bom/budget-orders?${queryString.stringify(budgetOrderViewParams)}`)
  };

  // set the max-height of table according to the window size
  function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);      
  }, []);

  const { height, width } = windowDimensions;
  const calculatedHeight = height-400+'px'; // remove top header height from total window height

  return (
    <div className="App-page">
      <BudgetOrderSearchBar
        budgetOrdersView={budgetOrdersView}
        budgetOrderSearchIndex={budgetOrderSearchIndex}
        history={history}
      />
      <BudgetOrderFilterForm
        budgetOrderCategories={budgetOrderCategories}
        budgetOrdersView={budgetOrdersView}
        history={history}
        linesOfBusiness={linesOfBusiness}
      />
      {areFiltersApplied &&
        (<BudgetOrderFilterBreadcrumb
          budgetOrdersView={budgetOrdersView}
          history={history}
        />)
      }
      <AuxiliaryToolbar history={history} budgetOrdersState={budgetOrdersState} />
      <div style={{'maxHeight': calculatedHeight, 'overflowY': 'scroll'}}>
        <Table bordered style={{'tableLayout': 'fixed'}}>
          <TableHeaderWithSort
                areActionsDisabled={!userPermissions.includes('change_budget_order')}
                budgetOrdersView={budgetOrdersView}
                handleSortTable={handleSortBudgetOrders}
                tableConfig={tableConfig}
              />
          <tbody>
            {budgetOrders.map((budgetOrder: BudgetOrdersInterface, index: any) => (
              <TableRow
                areActionsDisabled={!userPermissions.includes('change_budget_order')}
                budgetOrder={budgetOrder}
                key={index}
                handleSelectRow={handleSelectBudgetOrder}
                tableConfiguration={tableConfig}
              />
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
};