export interface Loadable {
  error: any,
  isLoading: boolean,
  isReceived: boolean,
};

export function createDefaultLoadable(): Loadable {
  return {
    error: null,
    isLoading: false,
    isReceived: false,
  };
};

export function updateLoadableOnStart(): Loadable {
  return {
    error: null,
    isLoading: true,
    isReceived: false,
  };
};

export function updateLoadableOnSuccess(): Loadable {
  return {
    error: null,
    isLoading: false,
    isReceived: true,
  };
};

export function updateLoadableOnError(error: any): Loadable {
  return {
    error,
    isLoading: false,
    isReceived: false
  };
};
