import React, {useState} from 'react';
import PropTypes from 'prop-types'

import { GridDropdown } from '../../../Common';
import { DropdownItem } from 'reactstrap';

const ProductsDropdown = (props: any) => {
  const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);

  const toggleProductsDropdown = () => setIsProductsDropdownOpen(prevState => !prevState);

  return (
    <GridDropdown
      inputLabel="Products:"
      isDisabled={props.isDisabled}
      isDropdownOpen={isProductsDropdownOpen}
      handleToggle={toggleProductsDropdown}
      placeholder="---------"
      value={props.products}
    >
      {props.productOptions.map((item: string) =>
        <DropdownItem key={item} value={item} onClick={props.handleProductsChange}>
          {item}
        </DropdownItem>
      )}
    </GridDropdown>
  );
};

ProductsDropdown.propTypes = {
  products: PropTypes.any,
  productOptions: PropTypes.array,
  handleProductsChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default ProductsDropdown;
