import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

interface Props{
  linesOfBusiness: Array<string>, 
  handleLineOfBusinessChange: (value: string) => void, 
  selectedLineOfBusiness: string
}

export const LinesOfBusinessDropdown = (props: Props) => {
  const { linesOfBusiness, handleLineOfBusinessChange, selectedLineOfBusiness } = props;

  const [isLobDropdownOpen, setIsLobDropdownOpen] = useState<boolean>(false);

  const handleSelectOption= (event: any) => {
    handleLineOfBusinessChange(event.target.value);
  }

  const handleLobDropdownToggle = () => {
    setIsLobDropdownOpen(prevState => !prevState);
  }

  return (
    <Dropdown
      isOpen={isLobDropdownOpen}
      toggle={handleLobDropdownToggle}
      className="mr-2">
      <DropdownToggle caret color="primary">
        { selectedLineOfBusiness ? selectedLineOfBusiness : 'Line of Business' }
      </DropdownToggle>
      <DropdownMenu>
        { linesOfBusiness.map(
          lob => (
            <DropdownItem
              key={lob}
              value={lob}
              onClick={handleSelectOption}
            >
              {lob}
            </DropdownItem>
          )
        )}
      </DropdownMenu>
    </Dropdown>
  )
};

LinesOfBusinessDropdown.propTypes = {
  handleLineOfBusinessChange: PropTypes.func.isRequired,
  linesOfBusiness: PropTypes.array.isRequired,
  selectedLineOfBusiness: PropTypes.string.isRequired
}
