import React from "react";
import { Alert, Col, Row } from "reactstrap";
import ApiConfigDropdown from "./ApiConfigDropdown";
import { IFormValues } from "./FormValues";

interface IDataTableSettingForm {
  formValues: IFormValues;
  onNewTableSelect: (value: string) => void;
  onICASelect: (value: string) => void;
}

const newTableOptions = {
  new_table: "True",
  exist_table: "False",
};

const ICAOptions = {
  conv_action_true: "True",
  conv_action_false: "False",
};

const DataTableSettingsForm = ({ formValues, onNewTableSelect, onICASelect }: IDataTableSettingForm): JSX.Element => {
  return (
    <Row>
      <Col xs={12}>
        <h3>Data Table Settings</h3>
      </Col>
      <Col xs={12}>
        <Alert color="primary">
          <strong>Notes:</strong>
          <br />
          If set to TRUE a new table will be created overwriting the existing table (if one exists).
          <br />
          If set to FALSE data will append to existing table. New tables will be identified by the CID that was pulled.
          <br />
          If "Include Conversion Actions" is set to TRUE all conversion actions for the account will be pulled along with the core metrics of Spend,
          Impressions, Clicks, Conversions (conversion column only), Conversion Value
        </Alert>
      </Col>
      <ApiConfigDropdown
        inputLabel="New Table"
        itemKeyPrefix="new-table"
        options={newTableOptions}
        onSelect={onNewTableSelect}
        value={formValues.config_new_table}
      />
      <ApiConfigDropdown
        inputLabel="Include Conversion Actions"
        itemKeyPrefix="include-conversion-actions"
        options={ICAOptions}
        onSelect={onICASelect}
        value={formValues.include_conversion_actions}
      />
    </Row>
  );
};

export default DataTableSettingsForm;
