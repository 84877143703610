import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../Utils/History'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ConfirmationModal, DashboardTable, PaginationToolbar, RowsPerPageDropdown, SearchBar, TablePagination }
  from '../../../Common';
import ExcludeCidToolbar from '../../ExcludeCids/Common/ExcludeCidToolbar';

import { ModalsConsumer } from '../../../Contexts/ModalsContext';

import { deleteSelectedExcludeId } from '../../../State/ExcludeCids/Actions/ExcludeCidsActions';
import { getUserPermissions } from '../../../State/Authentication/Selectors/AuthenticationSelectors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);
  
    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if(a[sortConfig.key] === null || b[sortConfig.key] === null) {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === 'asc' ? 1 : -1;
            }
          } else {
            if (a[sortConfig.key].toUpperCase() < b[sortConfig.key].toUpperCase()) {
              return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key].toUpperCase() > b[sortConfig.key].toUpperCase()) {
              return sortConfig.direction === 'asc' ? 1 : -1;
            }
          }
         
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);
  
    const requestSort = (key: string) => {
      let direction = 'asc';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'asc'
      ) {
        direction = 'desc';
      }
      setSortConfig({ key, direction });
    };
  
    return { items: sortedItems, requestSort, sortConfig };
  };

const ExcludeCidsDashboard = (props: any) => {
    const [filteredData, setFilteredData] = useState<Array<any>>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchValue, onChangeSearch] = useState('');
    const [tableData, setTableData] = useState<Array<any>>([]);
    const [selectedExcludeId, setSelectedExcludeId] = useState(0);

    const userPermissions = useSelector(getUserPermissions);

    const dispatch = useDispatch();
    const { items, requestSort, sortConfig } = useSortableData(filteredData);

    const getDirection = (name: string) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    useEffect(() => {
        setTableData([...props.excludeCidsList]);
    }, [props.excludeCidsList]);

    const buildDeleteExcludeCidConfirmationText = () => {
      const selectedExcludedCidData = filteredData.length > 0 ?
        filteredData.find((selectedRow: any) => selectedRow['excludeId'] === selectedExcludeId) :
        tableData.find((selectedRow: any) => selectedRow['excludeId'] === selectedExcludeId);
      let selectedCustomerId = selectedExcludedCidData && selectedExcludedCidData['customerId'];
      selectedCustomerId = "'" + selectedCustomerId + "'";
  
      return `Are you sure you want to delete a record for CID ${selectedCustomerId}?`;
    };
    const deleteExcludeCidAction = () => {
      const payload = {
        deletedBy : props.userName
      };
      dispatch(deleteSelectedExcludeId(selectedExcludeId, payload));
    };
  
    const selectExcludeCidToDelete = (event: any, excludeId: number, openConfirmationModal: any) => {
      setSelectedExcludeId(excludeId);
      openConfirmationModal();
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);

    const handleChangePage = (newPage: number) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event: any,) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleSearchValue = (e: any) => {
      onChangeSearch(e)
    };

    useEffect(() => {
      setPage(0);
      if(tableData.length > 0) {
        setFilteredData(        
          tableData.filter((data: any) => 
            data.customerId.includes(searchValue.toLocaleLowerCase()) ||
            data.account_name.toLowerCase().includes(searchValue.toLocaleLowerCase())
          )
        )
      }      
    }, [searchValue, tableData]);

    return (
        <>
            <ModalsConsumer>
            {
                modalsState => {
                    return modalsState && (
                        <>
                            <SearchBar
                            placeholder={"Search for Customer ID or Account Name"}
                            handleSearchValue={handleSearchValue}
                            />
                            <ExcludeCidToolbar showAddToExcludeListButton={userPermissions.includes('add_mpa')} />
                            {/* <DashboardTable bordered style={{'width': '60%'}}>  */}
                            <DashboardTable bordered> 
                                <thead>
                                    <tr>
                                        <th onClick={() => {setPage(0);requestSort('customerId')}} style={{cursor:"pointer",width:"15%"}}>
                                            <b>Customer ID</b> {getDirection('customerId')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('customerId')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                                        </th>
                                        <th onClick={() => {setPage(0);requestSort('account_name')}} style={{cursor:"pointer",width:"15%"}}>
                                            <b>Account Name</b> {getDirection('account_name')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('account_name')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                                        </th>
                                        <th align="left" style={{width:"5%"}}><b>Edit</b></th>
                                        {userPermissions.includes('delete_mpa') && <th align="left" style={{width:"5%"}}><b>Delete</b></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                {(rowsPerPage > 0
                                    ? items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : items
                                ).map((row: any) => (
                                    <tr key={row.excludeId}>
                                        <td align="left">{row.customerId}</td>
                                        <td align="left">{row.account_name}</td>
                                        <td>
                                            <FontAwesomeIcon 
                                              style={{"cursor": "pointer"}}
                                              onClick={()=>{history.push(`/map/exclude-cids/edit/${row.excludeId}`,'/map/mpa')}}
                                            icon="edit" size="sm" />
                                        </td>
                                        {userPermissions.includes('delete_mpa') &&
                                          <td>
                                            <FontAwesomeIcon
                                              icon="trash"
                                              size="sm"
                                              style={{"color": "#006AC3", "cursor": "pointer"}}
                                              onClick={
                                                event => selectExcludeCidToDelete(event, row['excludeId'], modalsState.handleToggleConfirmationModal)
                                              }
                                            />
                                          </td>
                                        }
                                    </tr>
                                ))}
                                {emptyRows > 0 && (
                                <tr style={{ height: 53 * emptyRows }}>
                                    <td colSpan={9}><span></span></td>
                                </tr>
                                )}
                            </tbody>
                            </DashboardTable>
                            <PaginationToolbar>
                              <RowsPerPageDropdown
                                count={items.length}
                                rowsPerPage={rowsPerPage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                              />
                              <TablePagination
                                count={items.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                handleChangePage={handleChangePage}
                              />
                            </PaginationToolbar>
                            <ConfirmationModal
                              confirmationHeader="Delete Exclude CID"
                              confirmationText={buildDeleteExcludeCidConfirmationText()}
                              executeConfirmationAction={deleteExcludeCidAction}
                              handleToggleModal={modalsState.handleToggleConfirmationModal}
                              isModalOpen={modalsState.isConfirmationModalOpen}
                            />
                        </>
                    )
                }
            }
            </ModalsConsumer>
        </>
    )
}

export default ExcludeCidsDashboard;