import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, FormGroup, FormLabel} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const ChannelSelect = (props: any) => {

    return (
        <>
            <Box sx={{display:'flex',ml:5, mb:2, height:100}}>
                <div style={{display:'flex', flexDirection:"column", borderRadius:1,border: '1px solid black'}}>
                    <FormLabel sx={{color: "white", backgroundColor:"#006AC3", paddingTop:1,paddingBottom: 1, textAlign:"center"}}>Channel</FormLabel>     
                    <FormGroup>
                        <FormControlLabel
                            label="Facebook"
                            control={<Checkbox checked={props.checkedFB} onChange={props.handleFBChange} />}
                            sx={{ml:1,mr:2,mb:1,height:"20%"}}
                        />
                        <FormControlLabel
                            label="SEM"
                            control={<Checkbox checked={props.checkedSEM} onChange={props.handleSEMChange} />}
                            sx={{ml:1,mr:2,mb:0,height:"20%"}}
                        />
                    </FormGroup>
                </div>
            </Box>
        </>
    )
}

export default ChannelSelect;