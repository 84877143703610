import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { pick, keys, values, isEqual, filter, union } from 'lodash';

import { LobFormInterface } from '../../../Models/LobFormInterface';
import { ChannelFieldInterface } from '../../../Models/ChannelFieldInterface';

import { addNewLob } from '../../../State/Lob/Actions/LobActions';

import LobForm from '../Common/LobForm';
import { ConfirmationModal } from '../../../Common';

import { ModalsConsumer } from '../../../Contexts/ModalsContext';
import { updateSelectedLob, deleteSelectedLob } from '../../../State/Lob/Actions/LobActions';

import { RequiredFieldModal } from '../../../Common/RequiredFieldModal';
import { toast } from 'react-toastify';

interface OwnProps {
    lobDropdownOptions: Array<any>;
    channelDropdownOptions: Array<any>;
    userName: String;
  }

const AddLob = (props: OwnProps) => {
    const [lobData, setLobData] = useState<LobFormInterface>({
        channel:            "",
        name:               "",
        name_abbr:          "",
        products:           "",        
        partner_google:     "",
        partner_bing:       "",
        anchor_cid:         "",
        // google_cid:         "",
        // bing_cid:           "",
        // apple_cid:          "",
        campaign:           "",
        // kenshoo_profile:    "",
        // kenshoo_token:      ""
    });

    const [channelList, setChannelList] = useState<Array<ChannelFieldInterface>>([{
        channel: "",
        anchor_cid: "",
        // bing_cid: "",
        // apple_cid: "",
        // google_cid: "",
        campaign: "",
        lob_id: 0
    }]);

    const [selectedProductChannelList, setSelectedProductChannelList] = useState([]);
    const dispatch = useDispatch();
    const [budgetSharingFlagInvalid, setBudgetSharingFlagInvalid] = useState(false);
    const [modal, setModal] = useState(false);
    const [requiredFields, setRequiredFields] = useState([]);
    const [channelsToRemove, setChannelsToRemove] = useState([]);

    const handleAddLob = (event: any, openUpdateConfirmationModal: any, id: any) => {
        event.preventDefault();
        const mergedData = mergeLobAndChannel();

        let lobDataToUpdate = [];

        // Display alert if no values are changed for selected LOB and Product
        const lobOptions = Object.keys(props.lobDropdownOptions);       
        if((lobOptions.includes(lobData.name)) && (props.lobDropdownOptions[lobData.name].includes(lobData.products))) {
            lobDataToUpdate = getChangedData(selectedProductChannelList, mergedData);
            if(isEqual(mergedData, selectedProductChannelList)) {
                toast.error("Alert!!! Can't submit unchanged form");
            } else {
                const requiredFields = checkRequiredFields(lobDataToUpdate);
                if(requiredFields.length > 0) {
                    setModal(true);
                    setRequiredFields(requiredFields);
                } else {
                    openUpdateConfirmationModal();
                } 
            }
        } else {
            // Check required fields value for new LOB or Product
            lobDataToUpdate = mergedData;
            const requiredFields = checkRequiredFields(lobDataToUpdate);
            if(requiredFields.length > 0) {
                setModal(true);
                setRequiredFields(requiredFields);
            } else {
                openUpdateConfirmationModal();
            } 
        } 
    };

    const buildAddLobConfirmationText = () => {
        // const lobAnchorCid = lobData['anchor_cid'];
        const lobProduct = lobData['products'];
        const lobName = lobData['name'];

        const lobOptions = Object.keys(props.lobDropdownOptions);       
        if((lobOptions.includes(lobData.name)) && (props.lobDropdownOptions[lobData.name].includes(lobData.products))) {
            return `The Product '${lobProduct}' is going to be updated under ${lobName}. Do you want to proceed?`;
        } else {
            return `A new Product '${lobProduct}' is going to be created under ${lobName}. Do you want to proceed?`;
        }
    };

    // Create Lob change logs
    const handleGetChangeLog = (newData) => {
        const changedOldLobKeysMethod = (o1: any, o2: any) => {
            return filter(union(keys(o1), keys(o2)), (key) => {
              return o1[key] !== o2[key];
            });
        };
    
        const changedNewLobKeysMethod = (o1: any) => {
            return keys(o1)
        };
    
        let mergedChangeLog = [];        
        
        // Create changelog array for newly inserted channels data
        const addedRows = newData.filter(row => row.id === 0);
        for(let row of addedRows) {
            const lobChangedKeys = changedNewLobKeysMethod(row);
        
            const lobChangeLogs = lobChangedKeys.map((key) => {
                const newValue = values(pick(row, key));
                return `${key} -> ${newValue}`;
            });
            mergedChangeLog.push(lobChangeLogs.toString());    
        }    
    
        // Create changelog array for updated channels data
        const lobOptions = Object.keys(props.lobDropdownOptions);       
        if((lobOptions.includes(lobData.name)) && (props.lobDropdownOptions[lobData.name].includes(lobData.products))) {
            let oldData = selectedProductChannelList;
            const updatedRows = newData.filter(row => row.id != 0);
            for(let row of updatedRows) {
                const oldRow = oldData.find(element => element.id === row.id);
                const lobChangedKeys = changedOldLobKeysMethod(row, oldRow);
            
                const lobChangeLogs = lobChangedKeys.map((key) => {
                    const newValue = values(pick(row, key));
                    const prevValue = values(pick(oldRow, key));
                    return `${key} (${oldRow.channel}) : ${prevValue} -> ${newValue}`;
                });
                mergedChangeLog.push(lobChangeLogs.toString());
            }
        }        
        return mergedChangeLog;
    };

    // Create changelog array for deleted channels data
    const handleDeletedChannelsChangeLog = () => {
        let deletedChannelsChangeLog = [];
        const lobOptions = Object.keys(props.lobDropdownOptions);       
        if((lobOptions.includes(lobData.name)) && (props.lobDropdownOptions[lobData.name].includes(lobData.products))) {
            let oldData = selectedProductChannelList;
        
            // create changelog array for deleted channels data
            const deletedRows = oldData.filter(row => channelsToRemove.includes(row.id));
            for(let row of deletedRows) {
                let deleteMsg = `Anchor CID (${row.channel}) : ${row.anchor_cid} -> Deleted`;
                deletedChannelsChangeLog.push(deleteMsg); 
            }            
        }
        return deletedChannelsChangeLog;
    }

    // Merge channel cids data with the lob form data
    const mergeLobAndChannel = () => {
        let mergedData: LobFormInterface[] = [];
        for(let channel of channelList) {
            let temp: LobFormInterface = {
                channel:            channel.channel,
                name:               lobData.name,
                name_abbr:          lobData.name_abbr,
                products:           lobData.products,                
                partner_google:     '',
                partner_bing:       '',
                anchor_cid:         channel.anchor_cid,
                // google_cid:         channel.google_cid,
                // bing_cid:           channel.bing_cid,
                // apple_cid:          channel.apple_cid,
                campaign:           channel.campaign,
                id:                 channel.lob_id,
                // kenshoo_profile:    lobData.kenshoo_profile,
                // kenshoo_token:      lobData.kenshoo_token,
            } 

            if(temp.channel === 'Google') {
                if(lobData.partner_google) {
                    temp.partner_google = lobData.partner_google;
                } else {
                    temp.partner_google = 'Google';
                }
            }
            if(temp.channel === 'Bing') {   
                if(lobData.partner_bing) {
                    temp.partner_bing = lobData.partner_bing;
                } else {
                    temp.partner_bing = 'Bing';
                }                
            }
            mergedData.push(temp);
        }
        return mergedData;
    };

    // Create final array for the changed data on the edit page to save it in database
    const getChangedData = (oldData, newData) => {
        let changedData = [];
        
        const addedRows = newData.filter(row => row.id === 0);
        if(addedRows) {
            changedData = addedRows;
        }    

        for(let i=0; i < oldData.length; i++) {
            const oldRow = oldData[i];
            const newRow = newData.find(row => row.id === oldData[i].id);

            if(newRow && !isEqual(oldRow, newRow)) {
                changedData.push(newRow);
            }
        }
        return changedData;
    };

    // Create a list if the required fields are empty in each channel row
    const checkRequiredFields = (lobDataToUpdate) => {
        let requiredFields = [];
        for(let index of lobDataToUpdate) {
          if(index.channel === '' && !requiredFields.includes('Channel')) {
            requiredFields.push('Channel');
          } 
          if(index.anchor_cid === '' && !requiredFields.includes('Anchor CID')) {
            requiredFields.push('Anchor CID');
          } 
          if(index.campaign === '' && !requiredFields.includes('Campaign')) {
            requiredFields.push('Campaign');
          } 
        }
        return requiredFields;
    };

    const addLobAction = () => {       
        const mergedData = mergeLobAndChannel();    

        // Update the channels data if existing lob and product is selected
        const lobOptions = Object.keys(props.lobDropdownOptions);
        if((lobOptions.includes(lobData.name)) && (props.lobDropdownOptions[lobData.name].includes(lobData.products))) {
            let lobDataToUpdate = getChangedData(selectedProductChannelList, mergedData);
            const changeLog = handleGetChangeLog(lobDataToUpdate);

            if(lobDataToUpdate.length > 0) {
                const selectedLobId = lobDataToUpdate[0].id;
                const lobPayload = {
                    lobData: lobDataToUpdate,
                    changeLog: changeLog,
                    editedBy: props.userName
                };                   
                dispatch(updateSelectedLob(selectedLobId, lobPayload)); 
            }          

            // Delete the channels data if existing lob and product is selected
            if(channelsToRemove.length > 0) {
                const deletedChannelsChangeLog = handleDeletedChannelsChangeLog();
                const selectedLobId = channelsToRemove[0];
                const deletePayload = {
                  channelsToRemove: channelsToRemove,
                  deletedChannelsChangeLog: deletedChannelsChangeLog,
                  deletedBy : props.userName
                };
                dispatch(deleteSelectedLob(selectedLobId, deletePayload));
            }
        } else {
            // Insert the new LOB or Product
            const changeLog = handleGetChangeLog(mergedData);
            const lobPayload = {
                lobData: mergedData,
                changeLog: changeLog,
                editedBy: props.userName
            };            
            dispatch(addNewLob(lobPayload));
        }

        // if(budgetSharingFlagInvalid || (lobData.channel === "SEM" && lobData.budget_sharing_flag === "")){
        //     console.log('Can\'t take invalid budget sharing flag')
        // }else {        
        // }     
    };

    return(
        <ModalsConsumer>
            {
                modalsState => {
                return modalsState && (
                    <>
                    <LobForm
                        lobData={lobData}
                        lobDropdownOptions={props.lobDropdownOptions}
                        auditLogs={[]}
                        budgetSharingFlagInvalid={budgetSharingFlagInvalid}
                        setBudgetSharingFlagInvalid={setBudgetSharingFlagInvalid}
                        onSave={handleAddLob}
                        openUpdateConfirmationModal={modalsState.handleToggleConfirmationModal}
                        setLobData={setLobData}
                        channelDropdownOptions={props.channelDropdownOptions}
                        setChannelList={setChannelList}
                        channelList={channelList}
                        channelsToRemove={channelsToRemove}
                        setChannelsToRemove={setChannelsToRemove}
                        selectedProductChannelList={selectedProductChannelList}
                        setSelectedProductChannelList={setSelectedProductChannelList}
                        // isSubmitDisabled={
                        //     (lobData.name && lobData.channel && lobData.products && lobData.campaign && lobData.anchor_cid) === "" ? true : false
                        // }
                        isSubmitDisabled={
                            (lobData.name && lobData.products) === "" ? true : false
                        }
                        isDisabled={false}                        
                    />
                    <RequiredFieldModal 
                        requiredFields={requiredFields}
                        modal={modal}
                        setModal={setModal}
                    />
                    <ConfirmationModal
                        confirmationHeader="Add LOB"
                        confirmationText={buildAddLobConfirmationText()}
                        executeConfirmationAction={addLobAction}
                        handleToggleModal={modalsState.handleToggleConfirmationModal}
                        isModalOpen={modalsState.isConfirmationModalOpen}
                    />
                    </>
                )
                }
            }
        </ModalsConsumer>
    )
}

export default AddLob;