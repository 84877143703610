import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { HeadCell } from '../Models/ReportTable';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

interface Props {
    headCells: HeadCell[],
    requestSort?: any,
    getDirection?: any
}

export const ReportTableHead = (props: Props) => {
    const {headCells, requestSort, getDirection} = props;
    return(

        <TableHead>
            <TableRow>
            {headCells.map((headCell,index) => (
                headCell.isNum?
                    <TableCell
                    align="right"
                    key={index}
                    style={headCell.applySort? {fontWeight:"bold", backgroundColor:"#006AC3", color:"White", cursor:"pointer"} : {fontWeight:"bold", backgroundColor:"#006AC3", color:"White"}}
                    onClick={headCell.applySort? () => {requestSort(headCell.id)} : undefined}                    
                    >
                    {headCell.label}
                    {headCell.applySort? getDirection(headCell.id)==='asc'? <KeyboardArrowUpIcon fontSize="small" sx={{ color: 'white' }} /> : getDirection(headCell.id)==='desc'? <KeyboardArrowDownIcon fontSize="small" sx={{ color: 'white' }} /> : <UnfoldMoreIcon fontSize="small" sx={{ color: 'white' }} /> : ''}
                    </TableCell>
                :
                    <TableCell
                    key={index}
                    style={headCell.applySort? {fontWeight:"bold", backgroundColor:"#006AC3", color:"White", cursor:"pointer"} : {fontWeight:"bold", backgroundColor:"#006AC3", color:"White"}}
                    onClick={headCell.applySort? () => {requestSort(headCell.id)} : undefined}   
                    >
                    {headCell.label}
                    {headCell.applySort? getDirection(headCell.id)==='asc'? <KeyboardArrowUpIcon fontSize="small" sx={{ color: 'white' }} /> : getDirection(headCell.id)==='desc'? <KeyboardArrowDownIcon fontSize="small" sx={{ color: 'white' }} /> : <UnfoldMoreIcon fontSize="small" sx={{ color: 'white' }} /> : ''}
                    </TableCell>
            ))}
            </TableRow>
        </TableHead>
    )


}