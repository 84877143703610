import {
  ADD_NEW_MPA, ADD_NEW_MPA_SUCCESS, ADD_NEW_MPA_ERROR,
  DELETE_SELECTED_MPA, DELETE_SELECTED_MPA_SUCCESS, DELETE_SELECTED_MPA_ERROR,
  GET_MPAS, GET_MPAS_SUCCESS, GET_MPAS_ERROR,
  GET_MPAS_CSV, GET_MPAS_CSV_SUCCESS, GET_MPAS_CSV_ERROR,
  GET_EXPORT_TO_BQ_STATUS, GET_EXPORT_TO_BQ_STATUS_SUCCESS, GET_EXPORT_TO_BQ_STATUS_ERROR,
  GET_SELECTED_MPA, GET_SELECTED_MPA_ERROR, GET_SELECTED_MPA_SUCCESS,
  UPDATE_SELECTED_MPA, UPDATE_SELECTED_MPA_ERROR, UPDATE_SELECTED_MPA_SUCCESS,
} from '../ActionTypes/MpaActionTypes';

// ADD New MPA Actions

export const addNewMpa = (payload: any) => {
  return {
    type: ADD_NEW_MPA,
    payload
  }
};

export const addNewMpaSuccess = (result: any) => {
  return {
    type: ADD_NEW_MPA_SUCCESS,
    result
  }
};

export const addNewMpaError = (error: any) => {
  return {
    type: ADD_NEW_MPA_ERROR,
    error
  }
};

export const deleteSelectedMpa = (mpaId: any, payload: any) => {
  return {
    type: DELETE_SELECTED_MPA,
    mpaId,
    payload
  };
};

export const deleteSelectedMpaSuccess = (mpaId: any) => {
  return {
    type: DELETE_SELECTED_MPA_SUCCESS,
    mpaId
  };
};

export const deleteSelectedMpaError = (error: any) => {
  return {
    type: DELETE_SELECTED_MPA_ERROR,
    error
  };
};

// Mpa Dashboard Actions
export const getMpas = () => {
  return {
    type: GET_MPAS
  };
};

export const getMpasSuccess = (result: any) => {
  return {
    type: GET_MPAS_SUCCESS,
    result
  };
};

export const getMpasError = (error: any) => {
  return {
    type: GET_MPAS_ERROR,
    error
  };
};

export const getMpasCsv = () => {
  return {
    type: GET_MPAS_CSV
  };
};

export const getMpasCsvSuccess = () => {
  return {
    type: GET_MPAS_CSV_SUCCESS
  };
};

export const getMpasCsvError = (error: any) => {
  return {
    type: GET_MPAS_CSV_ERROR,
    error
  };
};

export const getSelectedMpa = (mpaId: string) => {
  return {
    type: GET_SELECTED_MPA,
    mpaId
  }
};

export const getSelectedMpaSuccess = (result: any) => {
  return {
    type: GET_SELECTED_MPA_SUCCESS,
    result
  }
};

export const getSelectedMpaError = (error: any) => {
  return {
    type: GET_SELECTED_MPA_ERROR,
    error
  }
};

export const updateSelectedMpa = (rowId: any, payload: any) => {
  return {
    type: UPDATE_SELECTED_MPA,
    rowId,
    payload
  }
};

export const updateSelectedMpaSuccess = (result: any) => {
  return {
    type: UPDATE_SELECTED_MPA_SUCCESS,
    result
  }
};

export const updateSelectedMpaError = (error: any) => {
  return {
    type: UPDATE_SELECTED_MPA_ERROR,
    error
  }
};

export const getExportToBqStatus = () => {
  return {
    type: GET_EXPORT_TO_BQ_STATUS
  }
};

export const getExportToBqStatusSuccess = (result: any) => {
  return {
    type: GET_EXPORT_TO_BQ_STATUS_SUCCESS,
    result
  }
};

export const getExportToBqStatusError = (error: any) => {
  return {
    type: GET_EXPORT_TO_BQ_STATUS_ERROR,
    error
  }
};
