import React, {useState} from 'react';
import PropTypes from 'prop-types'

import { GridDropdown } from '.';
import { DropdownItem } from 'reactstrap';

export const ProductDropdown = (props: any) => {
  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);

  const toggleProductDropdown = () => setIsProductDropdownOpen(prevState => !prevState);

  return (
    <GridDropdown
      gridSize={props.gridSize}
      inputLabel='Product:'
      isDisabled={props.isDisabled}
      isDropdownOpen={isProductDropdownOpen}
      handleToggle={toggleProductDropdown}
      placeholder='---------'
      value={props.products}
    >
      {props.productOptions.map((item: string) =>
        <DropdownItem key={item} value={item} onClick={props.handleProductsChange}>
          {item}
        </DropdownItem>
      )}
    </GridDropdown>
  );
};

ProductDropdown.propTypes = {
  gridSize: PropTypes.number,
  products: PropTypes.any,
  productOptions: PropTypes.array,
  handleProductsChange: PropTypes.func,
  isDisabled: PropTypes.bool
};