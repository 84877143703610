import { createDefaultBudgetPacingState, BudgetPacingState } from '../Models/BudgetPacingState'

import {
  updateLoadableOnStart,
  updateLoadableOnSuccess,
  updateLoadableOnError
} from '../../Common/Loadable';

import {
    GET_BUDGET_PACING, GET_BUDGET_PACING_SUCCESS, GET_BUDGET_PACING_ERROR,
    GET_BUDGET_PACING_METRICS, GET_BUDGET_PACING_METRICS_SUCCESS, GET_BUDGET_PACING_METRICS_ERROR
} from '../ActionTypes/BudgetPacingActionTypes';

export default function (state = createDefaultBudgetPacingState(), action: any): BudgetPacingState {
    switch(action.type) {
        case GET_BUDGET_PACING:
            return {
                ...state,
                getBudgetPacingListLoadable: updateLoadableOnStart()
            };

        case GET_BUDGET_PACING_SUCCESS:
            return {
                ...state,
                budgetPacingEntities: action.result,
                getBudgetPacingListLoadable: updateLoadableOnSuccess()
            };

        case GET_BUDGET_PACING_ERROR:
            return {
                ...state,
                getBudgetPacingListLoadable: updateLoadableOnError(action.error)
            };

        case GET_BUDGET_PACING_METRICS:
            return {
                ...state,
                getBudgetPacingMetricsListLoadable: updateLoadableOnStart()
            };

        case GET_BUDGET_PACING_METRICS_SUCCESS:
            return {
                ...state,
                budgetPacingMetricsEntities: action.result,
                getBudgetPacingMetricsListLoadable: updateLoadableOnSuccess()
            };

        case GET_BUDGET_PACING_METRICS_ERROR:
            return {
                ...state,
                getBudgetPacingMetricsListLoadable: updateLoadableOnError(action.error)
            };

        default:
            return state;
    };
};
