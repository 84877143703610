import { GridInput } from 'Common';
import React, { useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import {
  IMediaCalculatorObj,
  formattedDivision,
  formattedMultiplication,
  formattedSum,
  initialMediaCalculatorObj,
  numberToMediaCalculatorObj,
} from '../formulation';
import CSVButton from '../CSVButton';
import ValidInput from '../ValidInput';

const gridSize = 3;

const GrossBudgetSession = (): JSX.Element => {
  const [MPATotal, setMPATotal] = useState<IMediaCalculatorObj>(
    initialMediaCalculatorObj
  );

  const budget = useMemo(() => {
    const workingMedia = formattedDivision(MPATotal.value, 1.14695);
    const commission = formattedMultiplication(workingMedia.value, 0.015);
    const subTotal = formattedSum(workingMedia.value, commission.value);
    const hst = formattedMultiplication(subTotal.value, 0.13);
    return {
      workingMedia,
      commission,
      subTotal,
      hst,
      csvContest: [
        ['MPA Total', MPATotal.formattedString],
        ['HST', hst.formattedString],
        ['Sub-Total', subTotal.formattedString],
        ['Commission', commission.formattedString],
        ['Working Media', workingMedia.formattedString],
      ],
    };
  }, [MPATotal]);

  return (
    <Row>
      <Col xs={12}>
        <h3>
          Budget Breakdowns - Gross Budget
          <CSVButton
            filename="Gross_Budget_Breakdowns"
            content={budget.csvContest}
          />
        </h3>
      </Col>
      <ValidInput values={MPATotal} onChange={setMPATotal} label="MPA Total:" />
      <Col xs={9} />
      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="working-media"
        inputId="working-media-input"
        inputLabel="Working Media:"
        value={budget.workingMedia.formattedString}
      />
      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="commission"
        inputId="commission-input"
        inputLabel="Commission:"
        value={budget.commission.formattedString}
      />
      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="sub-total"
        inputId="sub-total-input"
        inputLabel="Sub-Total:"
        value={budget.subTotal.formattedString}
      />
      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="hst"
        inputId="hst-input"
        inputLabel="HST:"
        value={budget.hst.formattedString}
      />
    </Row>
  );
};

export default GrossBudgetSession;
