import React from 'react';
import PropTypes from 'prop-types'

import { Col, Dropdown, DropdownMenu, DropdownToggle, FormGroup, Label } from 'reactstrap';

interface GridDropdownProps {
  children: any,
  gridSize?: string,
  inputLabel: string,
  isDisabled: boolean,
  isDropdownOpen: boolean,
  handleToggle: any,
  placeholder?: string,
  value: string,
  elemClassname?: any
}

export const GridDropdown = (props: GridDropdownProps) => {
  return (
    <Col xs={props.gridSize || 3} className={props.elemClassname}>
      <FormGroup>
        { props.inputLabel && <React.Fragment><Label>{props.inputLabel}</Label><Label style={{color:"red"}}>*</Label></React.Fragment>}
        <Dropdown isOpen={props.isDropdownOpen} disabled={props.isDisabled} toggle={props.handleToggle}>
          <DropdownToggle
            caret={!props.isDisabled}
            disabled={props.isDisabled}
            color="muted"
            style={{
              'alignItems': 'center',
              'border': '1px solid #ced4da',
              'display': 'flex',
              'justifyContent': 'space-between',
              'width': '100%',
            }}
          >
            {props.value || props.placeholder}
          </DropdownToggle>
          <DropdownMenu style={{'width': '100%'}}>
            {props.children}
          </DropdownMenu>
        </Dropdown>
      </FormGroup>
    </Col>
  );
};

GridDropdown.propTypes = {
  gridSize: PropTypes.number,
  inputLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  isDropdownOpen: PropTypes.bool,
  handleToggle: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  elemClassname: PropTypes.any
};
