import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row, Label } from 'reactstrap';
import { keys, values } from 'lodash';
import { GridInput} from '../../../Common';
import { getFormInfoState } from '../../../State/FormInfo/Selectors/FormInfoSelectors';
import history from '../../../Utils/History'

const ExcludeCidForm = (props: any) => {
    const formInfoState = useSelector(getFormInfoState);
    const dispatch = useDispatch();

    const handleInputFieldChange = (event: any, type: string) => {
        props.setExcludedCidData({
            ...props.excludedCidData,
            [type]: event.target.value
        });
    };

    return(
        <Container fluid={true}>            
            <Row>
                <GridInput
                    gridSize={2}
                    inputAria="exclude-cid-text"
                    inputId="exclude-cid-input"
                    inputLabel="Customer ID:"
                    required={true}
                    inputType="string"
                    value={props.excludedCidData.customerId}
                    handleChange={(e: any) => handleInputFieldChange(e, "customerId")}
                />
                <GridInput
                    gridSize={2}
                    inputAria="account-name-text"
                    inputId="account-name-input"
                    inputLabel="Account Name:"
                    inputType="string"
                    value={props.excludedCidData.account_name}
                    handleChange={(e: any) => handleInputFieldChange(e, "account_name")}
                />
            </Row>     
            <Row>
                <Col xs={12}>
                    <Label style={{color: "red"}}>*Please enter Customer ID without hyphen (-)</Label>
                </Col>
            </Row>       
            <br/>
            <Row>
                <Col xs={12}>
                    <Button
                        color="primary"
                        size="md"
                        onClick={(event: any) => props.onSave(event, props.openUpdateConfirmationModal, props.excludedCidData.excludeId)}
                        disabled={props.isSubmitDisabled}
                        style={{'marginRight': '16px', 'marginBottom': '16px'}}
                    >
                        Save
                    </Button>
                    <Button
                        color="warning"
                        size="md"
                        onClick={()=>{history.push(`/map/exclude-cids`,'/map/mpa')}}
                        style={{'marginRight': '16px', 'marginBottom': '16px'}}
                    >
                        Return to Exclude CIDs Page
                    </Button>{' '}                
                </Col>
            </Row>
        </Container>
    )
};

export default ExcludeCidForm;