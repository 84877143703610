import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ButtonToolbar, Button, Spinner } from 'reactstrap'
import { getUserPermissions } from 'State/Authentication/Selectors/AuthenticationSelectors'
import ApiRequest from 'Utils/ApiRequest'
import LinesOfBusinessTable from './LineOfBusinessTable'
import WorksheetsDropdown from './WorkSheetsDropdown'
import { toast } from 'react-toastify';

export const StemsDashboard = () => {

    const userPermissions = useSelector(getUserPermissions);
    
    const [worksheetsList, changeWorksheetList] = useState(undefined)
    const [isWorksheetsDropdownOpen, setDropdownOpen] = useState(false)
    const [currentWorksheet, setCurrentWorksheetId] = useState({id: 0, title: 'Select Worksheet'})
    const [linesOfBusiness, setLob] = useState(undefined)
    const [updating,setUpdating] = useState(false)

    useEffect(()=>{
        new ApiRequest().withAuthToken(true).get('/stems/api/worksheets').then(res=>res.json()).then(data=>{
            changeWorksheetList(data.worksheets)
        }).catch(error=>toast.error("Something wrong with Stems worksheets API, please try again!"))
    },[])

    useEffect(()=>{

      // if(currentWorksheet !== undefined){
        new ApiRequest().withAuthToken(true).withQueryParams({worksheetId: currentWorksheet.id}).get('/stems/api/notificationConfiguration').then(res=>res.json()).then(data=>{
          setLob(data)
      }).catch(error=>toast.error("Something wrong with Stems notificationConfiguration API, please try again!"))
      // }
    },[currentWorksheet])

    const onUpdateCell = (index, header, value) => {
          let copyLob = [...linesOfBusiness]
          value = value.toUpperCase()
          if(value === 'TRUE'){
            value = 'FALSE'
          } else {
            value = 'TRUE'
          }
          let newLob = {...copyLob[index]}
          newLob[header] = value
          copyLob[index] = newLob
          setLob(copyLob)
    }

    const updateLineOfBusiness = () => {   
        
        setUpdating(true)  
        new ApiRequest().withBody({worksheetId:currentWorksheet.id, linesOfBusiness}).post('/stems/api/notificationConfiguration')
        .then(res=>res.json()).then(data=>{ setUpdating(false)})
    }
    const dropDownToggle = () => {setDropdownOpen(prevState => !prevState);}
    return (
        <div style={{margin:'1vw'}}>
        {worksheetsList != undefined ? 
          <WorksheetsDropdown
            worksheets={worksheetsList}
            selectedWorksheet={currentWorksheet}
            isDropdownOpen={isWorksheetsDropdownOpen}
            onDropdownToggle={dropDownToggle}
            onSelect={setCurrentWorksheetId}
          />
        : null}
        
        {linesOfBusiness != undefined ? <LinesOfBusinessTable
          linesOfBusiness={linesOfBusiness}
          onUpdateCell={onUpdateCell}
        /> : null }

        <ButtonToolbar className="justify-content-start">
          {updating ?            
          <Spinner color="primary" /> :
          <Button
            color="primary"
            disabled={!userPermissions.includes('change_stems_config')}
            onClick={()=>{updateLineOfBusiness()}}
          >
            Update
          </Button> }

        </ButtonToolbar>
      </div>
    )
}
