import {
  ADD_NEW_ANNUAL_BUDGET, ADD_NEW_ANNUAL_BUDGET_SUCCESS, ADD_NEW_ANNUAL_BUDGET_ERROR,
  DELETE_SELECTED_ANNUAL_BUDGET, DELETE_SELECTED_ANNUAL_BUDGET_SUCCESS, DELETE_SELECTED_ANNUAL_BUDGET_ERROR,
  GET_ANNUAL_BUDGET, GET_ANNUAL_BUDGET_SUCCESS, GET_ANNUAL_BUDGET_ERROR,
  GET_ANNUAL_BUDGETS_CSV, GET_ANNUAL_BUDGETS_CSV_SUCCESS, GET_ANNUAL_BUDGETS_CSV_ERROR,
  GET_SELECTED_ANNUAL_BUDGET, GET_SELECTED_ANNUAL_BUDGET_SUCCESS, GET_SELECTED_ANNUAL_BUDGET_ERROR,
  UPDATE_SELECTED_ANNUAL_BUDGET, UPDATE_SELECTED_ANNUAL_BUDGET_SUCCESS, UPDATE_SELECTED_ANNUAL_BUDGET_ERROR
} from '../ActionTypes/AnnualBudgetActionTypes';

export const addNewAnnualBudget = (annualBudgetData: any) => {
  return {
    type: ADD_NEW_ANNUAL_BUDGET,
    annualBudgetData
  };
};

export const addNewAnnualBudgetSuccess = (result: any) => {
  return {
    type: ADD_NEW_ANNUAL_BUDGET_SUCCESS,
    result
  };
};

export const addNewAnnualBudgetError = (error: any) => {
  return {
    type: ADD_NEW_ANNUAL_BUDGET_ERROR,
    error
  };
};

export const deleteSelectedAnnualBudget = (annualBudgetId: any, payload: any) => {
  return {
    type: DELETE_SELECTED_ANNUAL_BUDGET,
    annualBudgetId,
    payload
  };
};

export const deleteSelectedAnnualBudgetSuccess = (annualBudgetId: any) => {
  return {
    type: DELETE_SELECTED_ANNUAL_BUDGET_SUCCESS,
    annualBudgetId
  };
};

export const deleteSelectedAnnualBudgetError = (error: any) => {
  return {
    type: DELETE_SELECTED_ANNUAL_BUDGET_ERROR,
    error
  };
};

export const getAnnualBudgets = () => {
  return {
    type: GET_ANNUAL_BUDGET
  };
};

export const getAnnualBudgetSuccess = (result: any) => {
  return {
    type: GET_ANNUAL_BUDGET_SUCCESS,
    result
  };
};

export const getAnnualBudgetError = (error: any) => {
  return {
    type: GET_ANNUAL_BUDGET_ERROR,
    error
  };
};

export const getAnnualBudgetsCsv = () => {
  return {
    type: GET_ANNUAL_BUDGETS_CSV
  };
};

export const getAnnualBudgetSuccessCsv = () => {
  return {
    type: GET_ANNUAL_BUDGETS_CSV_SUCCESS
  };
};

export const getAnnualBudgetErrorCsv = (error: any) => {
  return {
    type: GET_ANNUAL_BUDGETS_CSV_ERROR,
    error
  };
};

export const getSelectedAnnualBudget = (annualBudgetId: string) => {
  return {
    type: GET_SELECTED_ANNUAL_BUDGET,
    annualBudgetId
  };
};

export const getSelectedAnnualBudgetSuccess = (result: any) => {
  return {
    type: GET_SELECTED_ANNUAL_BUDGET_SUCCESS,
    result
  };
};

export const getSelectedAnnualBudgetError = (error: any) => {
  return {
    type: GET_SELECTED_ANNUAL_BUDGET_ERROR,
    error
  };
};

export const updateSelectedAnnualBudget = (annualBudgetId: string, annualBudgetData: any) => {
  return {
    type: UPDATE_SELECTED_ANNUAL_BUDGET,
    annualBudgetId,
    annualBudgetData
  };
};

export const updateSelectedAnnualBudgetSuccess = (result: any) => {
  return {
    type: UPDATE_SELECTED_ANNUAL_BUDGET_SUCCESS,
    result
  };
};

export const updateSelectedAnnualBudgetError = (error: any) => {
  return {
    type: UPDATE_SELECTED_ANNUAL_BUDGET_ERROR,
    error
  };
};
