import moment from "moment-timezone";

class BudgetCalculator {
  static getCommissionRate(commissionPercentage: number): number {
    return commissionPercentage / 100;
  }

  static getPlatformRate(platformPercentage: number): number {
    return platformPercentage / 100;
  }

  static getTaxRate(taxPercentage: number): number {
    return taxPercentage / 100;
  }

  static calculateWorkingFunds(netTotal: number, commissionPercentage: number, platformPercentage: number): number{
    const commissionRate = BudgetCalculator.getCommissionRate(commissionPercentage);
    const platformRate = BudgetCalculator.getPlatformRate(platformPercentage);

    return Math.round(netTotal/(1 + commissionRate + platformRate) * 100) / 100;
  }

  static calculateCommission(workingFunds: number, commissionPercentage: number): number {
    const commissionRate = BudgetCalculator.getCommissionRate(commissionPercentage);

    return Math.round(workingFunds * commissionRate * 100) / 100;
  }

  static calculatePlatformFees(workingFunds: number, platformPercentage: number): number {
    const platformRate = BudgetCalculator.getPlatformRate(platformPercentage);

    return Math.round(workingFunds * platformRate * 100) / 100;
  }

  static calculateNetTotal(grandTotal: number, taxPercentage: number, budgetAdjustments: number): number {
    const taxRate = BudgetCalculator.getTaxRate(taxPercentage);
    if(budgetAdjustments != 0) {
      grandTotal = grandTotal - budgetAdjustments;
    }      

    return Math.round(grandTotal * 100 / (1 + taxRate)) / 100;
  }

  static calculateTax(grandTotal: number, netTotal: number, budgetAdjustments: number): number {
    if(budgetAdjustments != 0) {
      grandTotal = grandTotal - budgetAdjustments;
    }

    return Math.round((grandTotal - netTotal) * 100) / 100;
  }

  // static assembleCampaignName(mpaNumber: string, channel: string, products: string,phaseNumber: string, scheduled_start: Date){
  static assembleCampaignName(mpaNumber: string, products: string, phaseNumber: string, scheduled_start: Date){
    let startDate = new Date(scheduled_start);
    let fiscalYear = startDate.getFullYear();
    if(startDate.getMonth()+1 >= 11) {
      fiscalYear = startDate.getFullYear() + 1
    }
    const currentMonth = moment(scheduled_start).format("MM")
    const hNumber = ["11","12","01","02","03","04"].includes(currentMonth)?"H1":"H2"
    const lobAbbr = mpaNumber.split("-").slice(0,1).toString();
    return "".concat('FY-',fiscalYear.toString(),'-',hNumber,"-",lobAbbr,'-',products,'-Phase',phaseNumber)
    // return "".concat('FY-',fiscalYear.toString(),'-',lobAbbr,'-',channel,'-',products,'-Phase',phaseNumber)
  }
}

export default BudgetCalculator;
