import React, {useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

const MpaSigningProcessModal = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => { setModal(!modal)}

    return (
        <div>
            <Button color="info" onClick={toggle}>MPA Signing Process</Button>
            <Modal size="xl" isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}><h3><b>MPA Signing Authority & Process</b></h3></ModalHeader>
                <ModalBody>
                <div>
                    <b>Marketing Managers & Directors:</b> 
                    <br />
                    NOTE: At this level the amounts will vary, therefore needs to be confirmed.
                    <p />
                    <ul>
                    <li>Senior Manager: ~&lt;$50k </li>
                    <li>Directors: ~&lt;$100K</li>
                    <li>Senior Directors: ~&lt;$250K</li>
                    </ul>
                </div>
                <div>
                    <b>VP Level & Above:</b>
                        <p />
                    <ul>
                        <li>
                            Jennifer Howard (Cards, PBA, Mobile, Payments): &lt;$500K
                        </li>
                        <li>
                            Larry Jacobs (HEF, Lending, Auto Finance, US Bank, Newcomers): &lt;$500K
                        </li>
                        <li>
                            Alan Depencier: &lt;$1.5MM
                        </li>
                        <li>
                            Mary DePaoli, David Mun: &lt;$3MM
                        </li>
                        <li>
                            Neil McLaughlin: &gt;$3MM
                        </li>
                    </ul>
                </div>
                <div>
                    <b>Lead Time for Signature from SVP/VP:</b>
                    <p />
                    <ul>
                        <li>
                            Minimum 10 business days. Will take longer if required people have NOT signed off on the MPA
                        </li>
                    </ul>
                </div>
                <div>
                    <b>SVP Approvals:</b>
                    <p />
                    <ul>
                        <li>
                            VP approval by LOB is expected prior to sending to Neil/Mary for signature (they need to know each LOB
                            has approved the requested amount).
                        </li>
                        <li>
                            Email thread documenting approvals at each step in the process is strongly encouraged (e.g.
                            Manager/Director/VP). This will reduce the risk of delays. Images of the MPA in the email is required.
                        </li>
                    </ul>
                </div>
                <div>
                    <b>MPA and Signing Authority:</b>
                        <p />
                    <ul>
                        <li>
                            When MPAs are generated, need to consider signing authorities as well as the required chain of
                            approvals.
                        </li>
                        <li>
                            Even if it is outside of their signing authority they need to sign off first before it moves up the
                            chain.
                        </li>
                        <li>
                            MPAs should not be revised if the reason for the revision is to bring it within someone’s signing
                            authority.
                        </li>
                    </ul>
                </div>
                </ModalBody>
                <ModalFooter>
                <Button color="danger" onClick={toggle}>Close Window</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default MpaSigningProcessModal;