import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import EditAnnualBudget from './EditAnnualBudget';

import { AppPage, PageHeader, WithMultipleLoading } from '../../../Common';

import { getUsername } from '../../../State/Authentication/Selectors/AuthenticationSelectors';
import { getAnnualBudgetState } from '../../../State/AnnualBudget/Selectors/AnnualBudgetSelectors';
import { getAnnualBudgetFormContextState } from '../../../State/AnnualBudgetFormContext/Selectors/AnnualBudgetFormContextSelectors';
import { getFormInfoState } from '../../../State/FormInfo/Selectors/FormInfoSelectors';
import { setSelectedAnnualBudgetId } from '../../../State/AnnualBudgetFormContext/Actions/AnnualBudgetFormContextActions';
import { getDropdownOptions, getProductOptions } from '../../../State/FormInfo/Actions/FormInfoActions';

const EditAnnualBudgetWithMultipleLoading = WithMultipleLoading(EditAnnualBudget);

export const EditAnnualBudgetContainer = (props: any) => {
  const formInfoState = useSelector(getFormInfoState);
  const editAnnualBudgetState = useSelector(getAnnualBudgetState);
  const annualBudgetFormContextState = useSelector(getAnnualBudgetFormContextState);
  const username = useSelector(getUsername);

  const dispatch = useDispatch();

  const { params } = props.match;

  useEffect(() => {
    if (params.id !== annualBudgetFormContextState.selectedAnnualBudgetId) {
      dispatch(setSelectedAnnualBudgetId(params.id));
    }

    // if (!formInfoState.getDropdownOptionsLoadable.isReceived) {
    //   dispatch(getDropdownOptions());
    // }

    if (!formInfoState.getProductOptionsLoadable.isReceived) {
      dispatch(getProductOptions());
    }
  }, [
    params.id,
    annualBudgetFormContextState.selectedAnnualBudgetId,
    formInfoState.getProductOptionsLoadable.isReceived,
    dispatch
  ]);

  return (
    <>
      <PageHeader>Edit Annual Budget</PageHeader>
      <AppPage>
        <EditAnnualBudgetWithMultipleLoading
          loadables={[
            editAnnualBudgetState.getSelectedAnnualBudgetListLoadable,
            formInfoState.getProductOptionsLoadable
          ]}
          isSubmitDisabled={editAnnualBudgetState.updateAnnualBudgetListLoadable.isLoading}
          lobDropdownOptions={formInfoState.productOptionsWithoutChannel}
          selectedAnnualBudget={editAnnualBudgetState.selectedAnnualBudget}
          isDisabled={false}
          userName={username}
        />
      </AppPage>
    </>
  );
};

export default EditAnnualBudgetContainer;
