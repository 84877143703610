import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';

import App from './App';

import * as serviceWorker from './serviceWorker';
import { createDefaultAppState } from './State/Common/AppState';
import configureStore from './State/ConfigureStore';
import history from './Utils/History';
import AuthProvider from 'Auth/AuthProvider';

ReactDOM.render(
  <Provider store={configureStore(createDefaultAppState())}>
    <AuthProvider>
      <Router history={history}>
        <App />
      </Router>
    </AuthProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
