import React from 'react'
import { Box, FormGroup, FormLabel} from '@mui/material';

const VarianceSelect = (props: any) => {
    return (
        <>
            <Box sx={{display:'flex',ml:5, mb:2, height:"120px"}}>
                <div style={{display:'flex', flexDirection:"column",borderRadius:1,border: '1px solid black'}}>
                    <FormLabel sx={{color: "white", backgroundColor:"#006AC3", paddingTop:1,paddingBottom: 1, textAlign:"center"}}>Variance Type</FormLabel>     
                        <FormGroup>
                        {props.varianceOptions.map((item,index) => 
                            item === props.selectedVariance?
                            <FormLabel key={index} sx={{paddingLeft:1,paddingRight:2,mb:0,color:"white",backgroundColor:'#006AC3'}} onClick={(e)=>props.handleVarianceChange(e.target)}>{item}</FormLabel>
                            :
                            <FormLabel key={index} sx={{paddingLeft:1,paddingRight:2,mb:0,color:"black"}} onClick={(e)=>props.handleVarianceChange(e.target)}>{item}</FormLabel>
                        )}
                        </FormGroup>          
                </div>
            </Box>
        </>
    )
}

export default VarianceSelect;