import React from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  budgetOrderFilterKey: string,
  handleRemoveFilter: (budgetOrderFilter: string) => void,
  value: string
}

export const BudgetOrderFilterBreadcrumbItem = (props: Props) => {
  const { budgetOrderFilterKey, handleRemoveFilter, value } = props;

  return (
    <BreadcrumbItem>
      <span style={{marginRight: '8px'}}>{value}</span>
      <FontAwesomeIcon
        icon="times"
        size="lg"
        style={{ cursor: 'pointer'}}
        onClick={() => handleRemoveFilter(budgetOrderFilterKey)}
      />
    </BreadcrumbItem>
  );
};

BudgetOrderFilterBreadcrumbItem.propTypes = {
  budgetOrderFilterKey: PropTypes.string.isRequired,
  handleRemoveFilter: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}
