import { ConfirmationModal, GridDivider } from "Common";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import history from "../../../Utils/History";
import DateSettingsForm from "./DataSettingsForm";
import DataTableSettingsForm from "./DataTableSettingsForm";
import CustomAccountIDSettingsForm from "./CustomAccountIDSettingsForm";
import { IFormValues } from "./FormValues";
import { IDagApiConfigDropdownDto, IDagApiConfigDto } from "State/DagApiConfig/Models/DagApiConfigsState";
import { useDispatch } from "react-redux";
import { updateDagApiConfig } from "State/DagApiConfig/Actions/DagApiConfigsAction";
import ExtraSettingsForm from "./ExtraSettingsForm";
import moment from "moment-timezone";

// Use this function to fix timezone issue
const formatDate = (date?: string, day: number = 1): string =>
  date ? moment(date).add(day, "day").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");

const initialData: IFormValues = {
  date_settings: null,
  custom_start_date: formatDate(),
  custom_end_date: formatDate(),
  config_new_table: null,
  include_conversion_actions: null,
  custom_account_cids: null,
  customAccountIDs: [],
  conv_name: null,
  conv_acct: null,
  acct_lob: null,
};

interface IEditApiConfig {
  dagApiConfigId: number;
  record: IDagApiConfigDto;
  apiConfigDropdown: IDagApiConfigDropdownDto[];
}

const EditApiConfig = ({ dagApiConfigId, record, apiConfigDropdown }: IEditApiConfig) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState<IFormValues>(initialData);
  const [isModelOpen, setIsModelOpen] = useState(false);

  const IsCustomAccountsIDsOnSelect = (value) => {
    setFormValues((oldValues) => ({
      ...oldValues,
      custom_account_cids: value,
      customAccountIDs: value === "cust_acct" ? [""] : [],
    }));
  };

  const CustomDateOnChange = (date, isStartDate) => {
    const newValue = isStartDate ? { custom_start_date: formatDate(date, 1) } : { custom_end_date: formatDate(date, 1) };
    setFormValues((oldValue) => ({
      ...oldValue,
      ...newValue,
    }));
  };

  const CustomAccountIDOnChange = useCallback(
    (value: string, index: number) => {
      let newIdList = [...formValues.customAccountIDs];
      newIdList[index] = value;

      setFormValues((oldValue) => ({
        ...oldValue,
        customAccountIDs: [...newIdList],
      }));
    },
    [formValues.customAccountIDs]
  );

  const AddCustomAccount = useCallback(() => {
    setFormValues((oldValue) => ({
      ...oldValue,
      customAccountIDs: [...formValues.customAccountIDs, ""],
    }));
  }, [formValues.customAccountIDs]);

  const RemoveCustomAccountID = useCallback(
    (index) => {
      let newList = formValues.customAccountIDs;
      newList.splice(index, 1);
      setFormValues((oldValue) => ({
        ...oldValue,
        customAccountIDs: [...newList],
      }));
    },
    [formValues.customAccountIDs]
  );

  const onConvNameSelect = useCallback(
    (value: string, index: number) => {
      setFormValues((oldValue) => ({
        ...oldValue,
        conv_name: value,
        conv_acct: value !== "-" ? apiConfigDropdown[index - 1].conv_acct : oldValue.conv_acct,
        acct_lob: value !== "-" ? apiConfigDropdown[index - 1].acct_lob : oldValue.acct_lob,
      }));
    },
    [apiConfigDropdown]
  );

  const onAfterConfirmation = useCallback(() => {
    const payload: IDagApiConfigDto = {
      ...formValues,
      custom_id: dagApiConfigId,
      custom_start_date: formatDate(formValues.custom_start_date, -1),
      custom_end_date: formatDate(formValues.custom_end_date, -1),
      custom_cid_1: formValues.customAccountIDs[0] ?? null,
      custom_cid_2: formValues.customAccountIDs[1] ?? null,
      custom_cid_3: formValues.customAccountIDs[2] ?? null,
      custom_cid_4: formValues.customAccountIDs[3] ?? null,
      custom_cid_5: formValues.customAccountIDs[4] ?? null,
      conv_name: formValues.conv_name === "-" ? null : formValues.conv_name,
      conv_acct: formValues.conv_acct === "-" ? null : formValues.conv_acct,
      acct_lob: formValues.acct_lob === "-" ? null : formValues.acct_lob,
    };
    dispatch(updateDagApiConfig(dagApiConfigId, payload));
  }, [formValues, dagApiConfigId, dispatch, updateDagApiConfig]);

  useEffect(() => {
    if (!record) return;
    setFormValues((value) => ({
      ...record,
      custom_start_date: formatDate(record.custom_start_date, 1) ?? value.custom_start_date,
      custom_end_date: formatDate(record.custom_end_date, 1) ?? value.custom_end_date,
      conv_name: record.conv_name ?? "-",
      conv_acct: record.conv_acct ?? "-",
      acct_lob: record.acct_lob ?? "-",
      customAccountIDs: [record.custom_cid_1, record.custom_cid_2, record.custom_cid_3, record.custom_cid_4, record.custom_cid_5].filter(
        (value) => !!value
      ),
    }));
  }, [record]);

  return (
    <Container fluid={true}>
      <DateSettingsForm
        CustomDateOnChange={CustomDateOnChange}
        formValues={formValues}
        onRangeSelect={(value) => setFormValues((oldValue) => ({ ...oldValue, date_settings: value }))}
      />
      <GridDivider />
      <DataTableSettingsForm
        formValues={formValues}
        onNewTableSelect={(value) => setFormValues((oldValue) => ({ ...oldValue, config_new_table: value }))}
        onICASelect={(value) => setFormValues((oldValue) => ({ ...oldValue, include_conversion_actions: value }))}
      />

      <GridDivider />
      <CustomAccountIDSettingsForm
        formValues={formValues}
        RemoveCustomAccountID={RemoveCustomAccountID}
        CustomAccountIDOnChange={CustomAccountIDOnChange}
        AddCustomAccount={AddCustomAccount}
        IsCustomAccountsIDsOnSelect={IsCustomAccountsIDsOnSelect}
      />
      {apiConfigDropdown.length > 1 && <GridDivider />}

      {apiConfigDropdown.length > 1 && (
        <ExtraSettingsForm
          formValues={formValues}
          apiConfigDropdown={apiConfigDropdown}
          onConvNameSelect={onConvNameSelect}
          onConvAcctSelect={(value) => setFormValues((oldValue) => ({ ...oldValue, conv_acct: value }))}
          OnAcctLobSelect={(value) => setFormValues((oldValue) => ({ ...oldValue, acct_lob: value }))}
        />
      )}
      <Row>
        <Col xs={12} style={{ marginTop: "20px" }}>
          <Button color="primary" size="md" style={{ marginRight: "16px", marginBottom: "16px" }} onClick={() => setIsModelOpen(true)}>
            Save
          </Button>
        </Col>

        <Col xs={12}>
          <Button color="warning" size="md" onClick={() => history.push("/dag/api-config")} style={{ marginRight: "16px", marginBottom: "16px" }}>
            Return to Dag API Config
          </Button>
        </Col>
      </Row>
      <ConfirmationModal
        confirmationHeader={`Update ${record.dag_name}`}
        confirmationText={`${record.dag_name} is going to be updated. Do you want to proceed?`}
        executeConfirmationAction={onAfterConfirmation}
        handleToggleModal={() => setIsModelOpen((value) => !value)}
        isModalOpen={isModelOpen}
      />
    </Container>
  );
};

export default EditApiConfig;
