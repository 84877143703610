import { call, put } from 'redux-saga/effects';

import history from '../../../Utils/History';

import {
  loginSuccess,
  loginError,
  logoutSuccess,
} from '../Actions/AuthenticationActions';

import AuthenticationService from '../../../Services/AuthenticationService';

export function* onLogin(action: any) {
  try {
    let response = yield call(AuthenticationService.login, action.code);
    console.log('On login response');
    yield put(loginSuccess(response));
    yield history.push(`/map/mpa`, `/map/mpa`);
  } catch (err) {
    console.log('login error');
    console.log(err);
    yield put(loginError(err));
  }
}

export function* OnLogout() {
  try {
    yield call(AuthenticationService.logout);
  } catch (err) {
  } finally {
    yield put(logoutSuccess());
    yield history.push(`/`);
  }
}
