import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddLob from './AddLob';

import { AppPage, PageHeader, WithLoading } from '../../../Common';

import { getUsername, getUserPermissions } from '../../../State/Authentication/Selectors/AuthenticationSelectors';
import { getDropdownOptions, getProductOptions, getChannelOptions } from '../../../State/FormInfo/Actions/FormInfoActions';
import { getFormInfoState } from '../../../State/FormInfo/Selectors/FormInfoSelectors';
import { Redirect } from 'react-router-dom';

const AddLobWithLoading = WithLoading(AddLob);

const AddLobContainer = () => {
    const formInfoState = useSelector(getFormInfoState);
    const dispatch = useDispatch();

    const username = useSelector(getUsername);
    const userPermissions = useSelector(getUserPermissions);

    useEffect(() => {
        if (!formInfoState.getProductOptionsLoadable.isReceived) {
            dispatch(getProductOptions());
        }
    }, [formInfoState.getProductOptionsLoadable.isReceived, dispatch]);

    useEffect(() => {
        if (!formInfoState.getChannelOptionsLoadable.isReceived) {
            dispatch(getChannelOptions());
        }
    }, [formInfoState.getChannelOptionsLoadable.isReceived, dispatch]);

    if(userPermissions.includes('add_lob')){
        return (
            <>
                <PageHeader>Add a Line Of Business</PageHeader>
                <AppPage>
                    <AddLobWithLoading
                        loadable={formInfoState.getProductOptionsLoadable}
                        lobDropdownOptions={formInfoState.productOptionsWithoutChannel}
                        channelDropdownOptions = {formInfoState.channelOptions}
                        userName={username}
                    />
                </AppPage>
            </>
        )
    } else {
        return <Redirect to={{pathname:'/map/lob', state: '/map/mpa'}} />
    }
}

export default AddLobContainer;