import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../Utils/History'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ConfirmationModal, DashboardTable, PaginationToolbar, RowsPerPageDropdown, SearchBar, TablePagination }
  from '../../../Common';
import LobToolbar from '../Common/LobToolbar';

import { ModalsConsumer } from '../../../Contexts/ModalsContext';

import { deleteSelectedLob } from '../../../State/Lob/Actions/LobActions'
import { getUserPermissions } from '../../../State/Authentication/Selectors/AuthenticationSelectors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if(a[sortConfig.key] === null || b[sortConfig.key] === null) {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
        } else {
          if (a[sortConfig.key].toUpperCase() < b[sortConfig.key].toUpperCase()) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key].toUpperCase() > b[sortConfig.key].toUpperCase()) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
        }
       
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: string) => {
    let direction = 'asc';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'asc'
    ) {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const LobDashboard = (props: any) => {
  const [filteredData, setFilteredData] = useState<Array<any>>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, onChangeSearch] = useState('');
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [selectedLobId, setSelectedLobId] = useState(0);

  const userPermissions = useSelector(getUserPermissions);

  const dispatch = useDispatch();
  const { items, requestSort, sortConfig } = useSortableData(filteredData);

  const getDirection = (name: string) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  useEffect(() => {
    setTableData([
      ...props.lobList
    ]); 
  }, [props.lobList]);

  const buildDeleteLobConfirmationText = () => {
    const selectedLob = filteredData.length > 0 ?
      filteredData.find((selectedRow: any) => selectedRow['id'] === selectedLobId) :
      tableData.find((selectedRow: any) => selectedRow['id'] === selectedLobId);
    const selectedLobAnchorCid = selectedLob && selectedLob['anchor_cid'];

    return `Are you sure you want to delete a LOB record for ${selectedLobAnchorCid}?`;
  };

  const deleteLobAction = () => {
    const payload = {
      deletedBy : props.userName
    };
    dispatch(deleteSelectedLob(selectedLobId, payload));
  };

  const selectLobToDelete = (event: any, id: number, openConfirmationModal: any) => {
    setSelectedLobId(id);
    openConfirmationModal();
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any,) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchValue = (e: any) => {
    onChangeSearch(e)
  };

  useEffect(() => {
    setPage(0);
    setFilteredData(
      tableData.filter((data: any) =>
        data.name.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        data.anchor_cid.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        data.products.toLowerCase().includes(searchValue.toLocaleLowerCase())
      )
    )
  }, [searchValue, tableData]);
  
  return (
    <>
      <ModalsConsumer>
        {
          modalsState => {
            return modalsState && (
              <>
                <SearchBar
                  placeholder={"Search for LOB, Product or CID"}
                  handleSearchValue={handleSearchValue}
                />
                <LobToolbar showAddLobButton={userPermissions.includes('add_lob')} />
                <DashboardTable>
                  <thead>
                    <tr>
                      <th onClick={() => {setPage(0);requestSort('anchor_cid')}} style={{cursor:"pointer",width:"15%"}}>
                        <b>CIDs</b> {getDirection('anchor_cid')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('anchor_cid')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>                      
                      <th onClick={() => {setPage(0);requestSort('name')}} style={{cursor:"pointer",width:"10%"}}>
                        <b>LOB</b> {getDirection('name')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('name')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      <th onClick={() => {setPage(0);requestSort('products')}} style={{cursor:"pointer",width:"15%"}}>
                        <b>Products</b> {getDirection('products')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('products')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      {/* <th onClick={() => {setPage(0);requestSort('channel')}} style={{cursor:"pointer",width:"15%"}}>
                        <b>Channel</b> {getDirection('channel')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('channel')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th> */}                      
                      <th onClick={() => {setPage(0);requestSort('campaign')}} style={{cursor:"pointer",width:"15%"}}>
                        <b>Campaign</b> {getDirection('campaign')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('campaign')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      <th onClick={() => {setPage(0);requestSort('name_abbr')}} style={{cursor:"pointer",width:"10%"}}>
                        <b>Abbreviation</b> {getDirection('name_abbr')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('name_abbr')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      {/* <th onClick={() => {setPage(0);requestSort('kenshoo_profile')}} style={{cursor:"pointer",width:"15%"}}>
                        <b>Kenshoo Profile</b> {getDirection('kenshoo_profile')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('kenshoo_profile')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th> */}
                      <th align="left" style={{width:"15%"}}><b>Edit</b></th>
                      {/* {userPermissions.includes('delete_lob') && <th align="left"><b>Delete</b></th>} */}
                    </tr>
                  </thead>
                  <tbody>
                    {(rowsPerPage > 0
                        ? items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : items
                      ).map((row: any) => (
                        <tr key={row.id}>
                          <td align="left">{row.anchor_cid}</td>                          
                          <td align="left">{row.name}</td>
                          <td align="left">{row.products}</td>                          
                          <td align="left">{row.campaign}</td>
                          <td align="left">{row.name_abbr}</td>
                          {/* <td align="left">{row.kenshoo_profile}</td> */}
                          <td>
                              <FontAwesomeIcon style={{cursor:"pointer"}}
                                onClick={()=>{history.push(`/map/lob/edit/${row.id}`,'/map/mpa')}}
                              icon="edit" size="sm" />
                          </td>
                          {/* {userPermissions.includes('delete_lob') &&
                            <td>
                              <FontAwesomeIcon
                                icon="trash"
                                size="sm"
                                style={{"color": "#006AC3", "cursor": "pointer"}}
                                onClick={
                                  event => selectLobToDelete(event, row['id'], modalsState.handleToggleConfirmationModal)
                                }
                              />
                            </td>
                          } */}
                        </tr>
                    ))}
                    {emptyRows > 0 && (
                      <tr style={{ height: 53 * emptyRows }}>
                        <td colSpan={9}><span></span></td>
                      </tr>
                    )}
                  </tbody>
                </DashboardTable>
                <PaginationToolbar>
                  <RowsPerPageDropdown
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TablePagination
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                  />
                </PaginationToolbar>
                <ConfirmationModal
                  confirmationHeader="Delete LOB"
                  confirmationText={buildDeleteLobConfirmationText()}
                  executeConfirmationAction={deleteLobAction}
                  handleToggleModal={modalsState.handleToggleConfirmationModal}
                  isModalOpen={modalsState.isConfirmationModalOpen}
                />
              </>
            )
          }
        }
      </ModalsConsumer>
    </>)
}

export default LobDashboard;