import React from 'react';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import { Badge, Progress } from 'reactstrap';

import { MPA_STATUS_BADGE_COLOR_MAP } from '../Models/MpaStatus';
import { BudgetOrdersInterface } from '../Models/BudgetOrders';
import { BUDGET_ORDER_ATTRIBUTE_MAP, BUDGET_ORDER_ATTRIBUTE_TYPE } from '../Models/BudgetOrderAttributeMap'

import convertSnakeCaseToStartCase from '../../../Utils/convertSnakeCaseToStartCase';


interface Props {
  attribute: string,
  budgetOrder: BudgetOrdersInterface,
}

export const ReadOnlyTableCell = (props: Props) => {
  const { attribute, budgetOrder } = props;

  if (attribute === 'mpaStatus') {
    return (
      <td>
        <h5>
          <Badge color={MPA_STATUS_BADGE_COLOR_MAP[budgetOrder[attribute]]}>
            {convertSnakeCaseToStartCase(budgetOrder[attribute])}
          </Badge>
        </h5>
      </td>
    );
  } else if (attribute === 'progressIndicator') {
    const progressIndicator = budgetOrder['progressIndicator'];

    if (progressIndicator <= 9) {
      return (<td><Progress bar animated color="success" value={progressIndicator} max="10">{progressIndicator}</Progress></td>)
    } else if (progressIndicator > 9 && progressIndicator <=24) {
      return (<td><Progress bar animated color="info" value={progressIndicator} max="30">{progressIndicator}</Progress></td>)
    } else if (progressIndicator > 24 && progressIndicator <=49) {
      return (<td><Progress bar animated color="warning" value={progressIndicator} max="60">{progressIndicator}</Progress></td>)
    } else {
      return (
        <td>
          <Progress bar animated color="danger" value={progressIndicator > 100 ? 100 : progressIndicator} max={100}>
            {progressIndicator}
          </Progress>
        </td>
      )
    }
  } else if (attribute === 'weeksLeft') {
    return (<td><span>{parseFloat(budgetOrder[attribute]).toFixed(2)}</span></td>);
  } else if (BUDGET_ORDER_ATTRIBUTE_MAP[attribute].type === BUDGET_ORDER_ATTRIBUTE_TYPE.currency) {
    return (
      budgetOrder[attribute]
      ? <td>
          <CurrencyFormat
            value={budgetOrder[attribute]}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={true}
          />
        </td>
      : <td><span>{budgetOrder[attribute]}</span></td>
    );
  } else {
    return (<td><span>{budgetOrder[attribute]}</span></td>)
  }
};

ReadOnlyTableCell.propTypes = {
  attribute: PropTypes.string.isRequired,
  budgetOrder: PropTypes.object.isRequired,
};
