import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, FormGroup, FormLabel, Button, Stack} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const LobSelect = (props: any) => {
    
    return (
        <>
            <Box sx={{display:'flex',ml:5, mb:2, height:150}}>
              <div style={{display:'flex', flexDirection:"column", borderRadius:1,border: '1px solid black',}}>
              <FormLabel sx={{color: "white", backgroundColor:"#006AC3", paddingTop:1,paddingBottom: 1, marginBottom:0,textAlign:"center"}}>Line of Business</FormLabel>     
                  <div style={{overflowY:"scroll"}}>
                    <FormGroup>
                        <Stack spacing={2} justifyContent="center" direction="row" style={{position:"sticky", top:0, backgroundColor:"White", zIndex:1}}>    
                            <Button size="small" onClick={props.selectAll}>Select All</Button>
                            <Button size="small" onClick={props.clearAll}>Clear</Button>
                        </Stack>
                        {props.lobOptions.map((lob,index) => 
                            <FormControlLabel
                                key={index}
                                label={lob.name}
                                control={<Checkbox checked={lob.checked} name={lob.name} onChange={props.handleLobChange} />}
                                sx={{mt:0.5,ml:1,mr:2,height:15}}
                            />
                            
                        )}
                        
                        
                    </FormGroup>
                  </div>
              </div>
            </Box>
        </>
    )
}

export default LobSelect;