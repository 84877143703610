export interface MpaFormContextState {
    selectedMpaId: number,
    selectedLob: any,
};

export function createDefaultMpaFormContextState(): MpaFormContextState {
  return {
    selectedMpaId: 0,
    selectedLob: null
  };
};
