import { createDefaultAnnualBudgetState, AnnualBudgetState } from "../AnnualBudget/Models/AnnualBudgetState";
import { createDefaultAnnualBudgetFormContextState, AnnualBudgetFormContextState } from "../AnnualBudgetFormContext/Models/AnnualBudgetFormContextState";
import { createDefaultAuthState, AuthState } from "../Authentication/Models/AuthState";
import { createDefaultFormInfoState, FormInfoState } from "../FormInfo/Models/FormInfoState";
import { createDefaultLobState, LobState } from "../Lob/Models/LobState";
import { createDefaultLobFormContextState, LobFormContextState } from "../LobFormContext/Models/LobFormContextState";
import { createDefaultMpaState, MpaState } from "../Mpa/Models/MpaState";
import { createDefaultMpaFormContextState, MpaFormContextState } from "../MpaFormContext/Models/MpaFormContextState";
import { createDefaultExcludeCidsState, ExcludeCidsState } from "../ExcludeCids/Models/ExcludeCidsState";
import { createDefaultExcludeCidsFormContextState, ExcludeCidsFormContextState } from "../ExcludeCidsFormContext/Models/ExcludeCidsFormContextState";
import { DagApiConfigsState, createDefaultDagApiConfigsState } from "State/DagApiConfig/Models/DagApiConfigsState";
import { BudgetPacingState, createDefaultBudgetPacingState } from "../BudgetPacing/Models/BudgetPacingState";

export interface AppState {
  annualBudgetState: AnnualBudgetState;
  annualBudgetFormContextState: AnnualBudgetFormContextState;
  authenticationState: AuthState;
  formInfoState: FormInfoState;
  lobFormContextState: LobFormContextState;
  lobState: LobState;
  mpaFormContextState: MpaFormContextState;
  mpaState: MpaState;
  excludeCidsFormContextState: ExcludeCidsFormContextState;
  excludeCidsState: ExcludeCidsState;
  dagApiConfigsState: DagApiConfigsState;
  budgetPacingState: BudgetPacingState;
}

export const createDefaultAppState: any = () => {
  return {
    annualBudgetState: createDefaultAnnualBudgetState(),
    annualBudgetFormContextState: createDefaultAnnualBudgetFormContextState(),
    authenticationState: createDefaultAuthState(),
    formInfoState: createDefaultFormInfoState(),
    lobState: createDefaultLobState(),
    lobFormContextState: createDefaultLobFormContextState(),
    mpaFormContextState: createDefaultMpaFormContextState(),
    mpaState: createDefaultMpaState(),
    excludeCidsState: createDefaultExcludeCidsState(),
    excludeCidsFormContextState: createDefaultExcludeCidsFormContextState(),
    dagApiConfigsState: createDefaultDagApiConfigsState(),
    budgetPacingState: createDefaultBudgetPacingState()
  };
};

export const buildInitialAppState: any = (initialState: AppState, preloadedAuthState: AuthState) => {
  return {
    ...initialState,
    authenticationState: preloadedAuthState ?? createDefaultAuthState(),
  };
};
