import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

export const OverviewTableBody = (props: any) => {
    const {rows, checkedH1, checkedH2} = props;
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0, 
        minimumFractionDigits: 0, 
      })

    return(
      <TableBody>
      {rows.map((row, index) => {
          return (
            <TableRow key={index} style={{ backgroundColor: row.background_color}}>         
                <TableCell>{row.lob}</TableCell>
                <TableCell>{row.channel}</TableCell>
                <TableCell>{row.products}</TableCell>
                {row.mpa_total !== ' ' ?
                    <TableCell align="right" style={{color:row.mpa_total<0? "red":"black"}}>{formatter.format(row.mpa_total)}</TableCell>
                :
                    <TableCell>{row.mpa_total}</TableCell>}
                {row.total_actual_spend !== ' ' ?
                    <TableCell align="right" style={{color:row.total_actual_spend<0? "red":"black"}}>{formatter.format(row.total_actual_spend)}</TableCell>
                :
                    <TableCell>{row.total_actual_spend}</TableCell>}
                {row.total_remaining !== ' ' ?
                    <TableCell align="right" style={{color:row.total_remaining<0? "red":"black"}}>{formatter.format(row.total_remaining)}</TableCell>
                :
                    <TableCell>{row.total_remaining}</TableCell>}
                {row.total_forecast_spend !== ' ' ?
                    <TableCell align="right" style={{color:row.total_forecast_spend<0? "red":"black"}}>{formatter.format(row.total_forecast_spend)}</TableCell>
                :
                    <TableCell>{row.total_forecast_spend}</TableCell>}
                {row.remaining_budget !== ' ' ?
                    <TableCell align="right" style={{color:row.remaining_budget<0? "red":"black"}}>{formatter.format(row.remaining_budget)}</TableCell>
                :
                    <TableCell>{row.remaining_budget}</TableCell>}
                {row.total_daily_cost !== ' ' ?
                    <TableCell align="right" style={{color:row.total_daily_cost<0? "red":"black"}}>{formatter.format(row.total_daily_cost)}</TableCell>
                :
                    <TableCell>{row.total_daily_cost}</TableCell>}
                {row.actual_avg_cost !== ' ' ?
                    <TableCell align="right" style={{color:row.actual_avg_cost<0? "red":"black"}}>{formatter.format(row.actual_avg_cost)}</TableCell>
                :
                    <TableCell>{row.actual_avg_cost}</TableCell>}
            </TableRow>
          );
      })}
      </TableBody>
    )
}