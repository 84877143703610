import React from "react";
import PropTypes from "prop-types";

import DatePicker from "react-datepicker";
import { Col, FormGroup, Label } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import "./GridDatepicker.css";

export const GridDatepicker = (props: any) => {
  return (
    <Col xs={props.gridSize}>
      <FormGroup>
        <Label>{props.inputLabel}</Label>
        <DatePicker
          selected={
            props.fromAddMPA || props.startDateChanged || props.endDateChanged
              ? new Date(props.date)
              : new Date(props.date.slice(0, -1))
          }
          onChange={props.handleChange}
          dateFormat={props.dateFormat}
          disabled={props.disabled}
        />
      </FormGroup>
    </Col>
  );
};

GridDatepicker.propTypes = {
  gridSize: PropTypes.number,
  inputId: PropTypes.string,
  inputLabel: PropTypes.string,
  date: PropTypes.any,
  handleChange: PropTypes.func,
  startDateChanged: PropTypes.bool,
  endDateChanged: PropTypes.bool,
  fromAddMPA: PropTypes.bool,
  dateFormat: PropTypes.string,
  disabled:PropTypes.bool
};
