import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { downloadFile } from '../../../Utils/DownloadFile';
import history from '../../../Utils/History';

import {
  addNewLobSuccess, addNewLobError,
  deleteSelectedLobSuccess, deleteSelectedLobError,
  getLobSuccess, getLobError,
  getLobSuccessCsv, getLobErrorCsv,
  getSelectedLobSuccess, getSelectedLobError,
  updateSelectedLobSuccess, updateSelectedLobError
} from '../Actions/LobActions';

import LobService from '../../../Services/LobService';

export function* onAddNewLob(action: any) {
  try {
    let response = yield call(LobService.createLob, action.lobData);
    yield put(addNewLobSuccess(response));
    toast.success('Successfully added new Lob');
  } catch (err) {
    yield put(addNewLobError(err));
    toast.error('Could not add new Lob');
  } finally {
    yield history.push( `/map/lob`, `/map/mpa`);
    // yield call(history.push, `/map/lob`);
  }
}

export function* onDeleteSelectedLob(action: any) {
  try {
    yield call(LobService.deleteSelectedLob, action.lobId, action.payload);
    yield put(deleteSelectedLobSuccess(action.lobId, action.payload));
    // toast.success('Successfully deleted selected Lob');
    toast.success('Successfully deleted channels of the selected Product');
  } catch (err) {
    yield put(deleteSelectedLobError(err));
    toast.error('Could not delete channels of the selected Product');
  } finally {
    yield history.push( `/map/lob`, `/map/mpa`);
  }
}

export function* onGetLobs(action: any) {
  try {
    let response = yield call(LobService.retrieveLobs);
    yield put(getLobSuccess(response));
  } catch (err) {
    yield put(getLobError(err));
  }
}

export function* onGetSelectedLob(action: any) {
  try {
    let response = yield call(LobService.retrieveSelectedLob, action.lobId);
    yield put(getSelectedLobSuccess(response));
  } catch (err) {
    yield put(getSelectedLobError(err));
  }
}

export function* onGetLobsCsv(action: any) {
  try {
    let lobsCsvBlob = yield call(LobService.retrieveLobsCsv);
    let lobsCsvFilename = `lobs_${new Date().getTime()}.csv`;
    downloadFile(lobsCsvBlob, lobsCsvFilename);
    yield put(getLobSuccessCsv());
  } catch (err) {
    toast.error('Could not download Lobs as CSV');
    yield put(getLobErrorCsv(err));
  }
}

export function* onUpdateSelectedLob(action: any) {
  try {
    let response = yield call(
      LobService.updateSelectedLob,
      action.lobId,
      action.lobData
    );
    yield put(updateSelectedLobSuccess(response));
    // toast.success('Successfully updated selected Lob');
    toast.success('Successfully updated the selected Product');
  } catch (err) {
    yield put(updateSelectedLobError(err));
    toast.error('Could not update the selected Product');
  } finally {
    yield history.push( `/map/lob`, `/map/mpa`);

    // yield call(history.push, `/map/lob`);
  }
}