import React from 'react';

import { Button, ButtonToolbar } from 'reactstrap';
import history from '../../../Utils/History'
const ExcludeCidToolbar = (props: any) => {

  const { showAddToExcludeListButton } = props;

  return (
    <ButtonToolbar style={{'padding': '1.5rem 0'}}>
      {showAddToExcludeListButton &&
        <Button
          color="primary"
          onClick={()=>{history.push(`/map/exclude-cids/add`,'/map/mpa')}}
          className="mr-1"
        >
          Add CID To Exclude List
        </Button>
      }
    </ButtonToolbar>
  );
};

export default ExcludeCidToolbar;