import React, { useEffect, useState } from 'react';

import { TableConfiguration } from './TableConfiguration';
import { PageHeader } from '../../../Common/PageHeader';
import { WithLoading } from '../../../Common/WithLoading';

import { createDefaultTableConfigurationState, TableConfigurationState } from '../Models/TableConfiguration';
import TableConfigurationService from '../Services/TableConfigurationService';
import { updateLoadableOnError, updateLoadableOnStart, updateLoadableOnSuccess } from '../../../State/Common/Loadable';

const TableConfigurationWithLoading = WithLoading(TableConfiguration);

export const TableConfigurationContainer = (props: any) => {
  const { history } = props;

  const [tableConfiguration, setTableConfiguration] = useState<TableConfigurationState>(createDefaultTableConfigurationState());

  useEffect(() => {
    async function getTableConfiguration() {
      setTableConfiguration({...tableConfiguration, getTableConfigLoadable:updateLoadableOnStart()});

      try{
        const response = await TableConfigurationService.retrieveTableConfiguration();
        setTableConfiguration({...tableConfiguration, config:response, getTableConfigLoadable:updateLoadableOnSuccess()});
      } catch(ex){
        setTableConfiguration({...tableConfiguration, getTableConfigLoadable:updateLoadableOnError(ex.message)});
      }
    }

    if(!sessionStorage.getItem('tableConfig'))
      getTableConfiguration();
    else {
      setTableConfiguration({...tableConfiguration, config:JSON.parse(sessionStorage.getItem('tableConfig')), getTableConfigLoadable:updateLoadableOnSuccess()})
    }

  }, [])

  return (
    <>
      <PageHeader>Table Configuration</PageHeader>
      <TableConfigurationWithLoading
        history={history}
        isFormLoading={tableConfiguration.getTableConfigLoadable.isLoading}
        loadable={tableConfiguration.getTableConfigLoadable}
        tableConfig={tableConfiguration.config}
      />
    </>
  )
}
