import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BUDGET_ORDER_ATTRIBUTE_MAP } from '../Models/BudgetOrderAttributeMap';
import { BudgetOrdersViewState, SORT_CONFIG_DIRECTION } from '../Models/BudgetOrdersView';
import { SORT_DIRECTION_ICON } from '../Models/BudgetOrdersView';

import { ActionsHeaderCell } from '../Styled/ActionsHeaderCell';
import { HeaderCell } from '../Styled/HeaderCell';

const createHeaderCellsWithSort = (budgetOrdersView: BudgetOrdersViewState, 
                                  handleSortTable: (sortAttribute : string, sortDirection : string) => void) => {
                                
  return function (attribute: string) {

    const attributeDirection = budgetOrdersView.sortAttribute === attribute ?
      budgetOrdersView.sortDirection :
      SORT_CONFIG_DIRECTION.NORMAL;

    return (
      <HeaderCell
        key={attribute}
        style={{ width: BUDGET_ORDER_ATTRIBUTE_MAP[attribute].width }}
        onClick={() => { handleSortTable(attribute, attributeDirection) }}
      >
        <span className='Column-Name'>
          { BUDGET_ORDER_ATTRIBUTE_MAP[attribute].name }
        </span>
        &nbsp;
        <FontAwesomeIcon icon={SORT_DIRECTION_ICON[attributeDirection]} size='lg'/>
      </HeaderCell>
    );
  }
};

interface Props{
  areActionsDisabled: boolean, 
  budgetOrdersView: BudgetOrdersViewState, 
  tableConfig: Array<string>, 
  handleSortTable: (sortAttribute : string, sortDirection : string) => void
}

export const TableHeaderWithSort = (props: Props) => {
  const { areActionsDisabled, budgetOrdersView, tableConfig, handleSortTable } = props;
  return (
    <thead>
      <tr>
        {!areActionsDisabled && <ActionsHeaderCell>Actions</ActionsHeaderCell>}
        {tableConfig.map(createHeaderCellsWithSort(budgetOrdersView, handleSortTable), this)}
      </tr>
    </thead>
  );
};

TableHeaderWithSort.defaultProps = {
  areActionsDisabled: false,
  handleSortTable: () => {}
};

TableHeaderWithSort.propTypes = {
  areActionsDisabled: PropTypes.bool.isRequired,
  budgetOrdersView: PropTypes.object.isRequired,
  tableConfig: PropTypes.array.isRequired,
  handleSortTable: PropTypes.func.isRequired
};
