import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, ButtonToolbar, Table } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import { Container } from 'reactstrap';
import { TableHeader } from '../Common/TableHeader';
import { TableRowWithEditableCells } from '../Common/TableRowWithEditableCells';

import { BUDGET_ORDER_EDITING_MODE } from '../Models/BudgetOrderEditingContext';
import {
  BudgetOrdersInterface,
  BudgetOrdersState,
  buildBudgetOrdersForModification,
  calculateNewBudgetOrderDeadlines,
  getInitialTransferAmountTotal
} from '../Models/BudgetOrders';

import { SubmissionBar } from '../Styled/SubmissionBar';
import { TableFooter } from '../Styled/TableFooter';

interface Props{
  areBudgetOrdersBeingUpdated: boolean,
  budgetOrders: Array<BudgetOrdersInterface>,
  budgetOrderEditingMode: string,
  budgetOrderClientIdToEdit: string,
  history: any,
  tableConfiguration: Array<string>,
  selectedBudgetOrderCategory: string,
  budgetOrdersState: BudgetOrdersState
}

export const EditBudgetOrders = (props: Props) => {
  const {
    areBudgetOrdersBeingUpdated,
    budgetOrders,
    budgetOrderEditingMode,
    budgetOrderClientIdToEdit,
    history,
    tableConfiguration,
    selectedBudgetOrderCategory,
    budgetOrdersState
  } = props;

  
  const [budgetOrdersList, setBudgetOrdersList] = useState<Array<BudgetOrdersInterface>>(budgetOrders.map(buildBudgetOrdersForModification(budgetOrderEditingMode)));
  const [transferAmountTotal, setTransferAmountTotal] = useState<number>(budgetOrderEditingMode !== BUDGET_ORDER_EDITING_MODE.NO_SUGGESTIONS
    ? budgetOrders.reduce(getInitialTransferAmountTotal(budgetOrderEditingMode), 0.00)
    : 0
  );
  
  useEffect(() => {
    setBudgetOrdersList(budgetOrders.map(buildBudgetOrdersForModification(budgetOrderEditingMode)))
    setTransferAmountTotal(budgetOrderEditingMode !== BUDGET_ORDER_EDITING_MODE.NO_SUGGESTIONS
      ? budgetOrders.reduce(getInitialTransferAmountTotal(budgetOrderEditingMode), 0.00): 0)
  },[budgetOrders])

  const handleCalculateTransferAmountTotal = (event: any) => {
    setTransferAmountTotal((prevState: number) => budgetOrdersList.reduce(
      (accumulator: number, budgetOrder: BudgetOrdersInterface) => accumulator + parseFloat(budgetOrder['transferAmount']),
      0
    ));
  }

  const handleCancel = () => {
    history.push('/bom/budget-orders')
  }

  const handleEditBudgetOrder = (event: any) => {
    const budgetOrderBeingEdited = {
      clientID: event.target.name || event.currentTarget.dataset.row,
      transferAmount: event.target.value
    };

    setBudgetOrdersList((prevState: Array<BudgetOrdersInterface>) => {
      return [...prevState].map(budgetOrder => {
        if (budgetOrder['clientID'] === budgetOrderBeingEdited['clientID']) {
          const { newDarkDate, newWeeksLeft } = calculateNewBudgetOrderDeadlines(
            budgetOrder,
            parseFloat(budgetOrderBeingEdited['transferAmount']) || 0
          );

          return {
            ...budgetOrder,
            commitBudget: budgetOrder['totalBudget'] + (parseFloat(budgetOrderBeingEdited['transferAmount']) || 0),
            activeBudgetOrderAmount: budgetOrder['activeBudgetOrderAmount'],
            commitActiveBudgetOrderAmount: budgetOrder['activeBudgetOrderAmount'] + (parseFloat(budgetOrderBeingEdited['transferAmount']) || 0),
	          commitTotalRemaining: budgetOrder['totalRemaining'] + (parseFloat(budgetOrderBeingEdited['transferAmount']) || 0),
            commitDarkDate: newDarkDate,
            commitWeeksLeft: newWeeksLeft,
            transferAmount: budgetOrderBeingEdited['transferAmount']
          }
        }
        return budgetOrder;
      });
    });
  }

  const handleHighlightAll = () => {
    setTimeout(() => { document.execCommand('selectAll', false, null); }, 0);
  }
  
  const handleSubmit = () => {
    history.push({
      pathname:'/bom/review-budget-orders',
      state:{budgetOrdersList,budgetOrdersState,budgetOrderEditingMode,budgetOrderClientIdToEdit}
    });
  }

  const handleValidateTransferAmount = (event: any) => {
    event.persist();
    const keyCode = event.keyCode || event.which;

    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 45 || keyCode === 46) {
      event.returnValue = true;
    } else {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  }

  return (
    <Container fluid={true} style={{'padding': '0 2.5rem'}}>
      <div className="App-page">
        <SubmissionBar>
          <span style={{'fontSize': '1.25rem', 'lineHeight': '1.8'}}>
            Budget Order Category: { selectedBudgetOrderCategory }
          </span>
          <ButtonToolbar>
            <Button className="mr-3" color="primary" disabled={areBudgetOrdersBeingUpdated}>Clear All</Button>
            <ButtonGroup>
              <Button
                color="primary"
                disabled={Math.abs(transferAmountTotal) > 0.001 || areBudgetOrdersBeingUpdated}
                onClick={handleSubmit}
              >
                Proceed
              </Button>
              <Button
                color="secondary"
                disabled={areBudgetOrdersBeingUpdated}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </SubmissionBar>
        <p style={{'color': '#212529', 'fontStyle': 'italic'}}>* Balance must net zero before update</p>
        <div style={{'maxHeight': '480px', 'overflowY': 'scroll'}}>
          <Table bordered style={{'tableLayout': 'fixed'}}>
            <TableHeader tableConfig={tableConfiguration} />
            <tbody>
              {budgetOrdersList.map((budgetOrder: BudgetOrdersInterface, index: any) => {
                return (
                  <TableRowWithEditableCells
                    budgetOrder={budgetOrder}
                    key={index}
                    handleContentEditableBlur={handleCalculateTransferAmountTotal}
                    handleContentEditableChange={handleEditBudgetOrder}
                    handleContentEditableFocus={handleHighlightAll}
                    handleContentEditableKeyPress={handleValidateTransferAmount}
                    tableConfiguration={tableConfiguration}
                  />
                );
              })}
            </tbody>
          </Table>
        </div>
        <TableFooter>
          <span style={{fontSize: '1.25rem'}}>
            Transfer Amount Balance:&nbsp;
            <CurrencyFormat
              value={transferAmountTotal.toFixed(2)}
              displayType="text"
              thousandSeparator={true}
              prefix="$"
              decimalScale={2}
              fixedDecimalScale={true}
              allowNegative={true}
            />
          </span>
        </TableFooter>
      </div>
    </Container>
  )
};