import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const searchIconStyles = {
  color: '#495057',
  cursor: 'pointer',
};

const searchFormStyles = {
  alignItems: 'stretch',
  border: '1px solid #ced4da',
  borderRadius: '5px',
  display: 'flex',
  margin: '0 auto',
  width: '50%'
};

const searchButtonStyles = {
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',
  padding: '0 15px'
};

const searchInputStyles = {
  backgroundClip: 'padding-box',
  backgroundColor: 'transparent',
  border: '1px solid transparent',
  borderRadius: '0.3rem',
  color: '#495057',
  fontSize: '1.25rem',
  height: 'calc(2.875rem + 2px)',
  lineHeight: '1.5',
  outline: 'none',
  overflow: 'visible',
  padding: '0.5rem 1rem',
  width: '100%'
};

interface Props {
  placeholder: string,
  searchTerm: string,
  onSearch: (event: any) => void,
  onSubmit: (event: any) => void,
}

export const SearchForm = (props: Props) => {
  const { placeholder, searchTerm, onSearch, onSubmit } = props
  return (
    <form onSubmit={onSubmit} style={searchFormStyles}>
      <input
        type="text"
        placeholder={placeholder}
        onChange={onSearch}
        style={searchInputStyles}
        value={searchTerm}
      />
      <button type="submit" style={searchButtonStyles}>
        <FontAwesomeIcon
          icon="search"
          size="lg"
          style={searchIconStyles}
        />
      </button>
    </form>
  );
};
