import { GridDropdown } from "Common";
import React, { useCallback, useMemo, useState } from "react";
import { DropdownItem } from "reactstrap";

interface IApiConfigDropdown {
  options: { [key: string]: string };
  value: string;
  onSelect: (value: string) => void;
  itemKeyPrefix: string;
  inputLabel: string;
  gridSize?: number;
}

export default ({
  options,
  value,
  onSelect,
  itemKeyPrefix,
  inputLabel,
  gridSize = 3,
}: IApiConfigDropdown): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = useCallback(() => {
    setIsOpen((oldValue) => !oldValue);
  }, []);

  const dropdownOption = useMemo(
    () =>
      Object.entries(options).map(([key, value]) => ({
        label: value,
        value: key,
      })),
    [options]
  );

  return (
    <GridDropdown
      gridSize={gridSize + ""}
      inputLabel={inputLabel}
      isDisabled={false}
      isDropdownOpen={isOpen}
      handleToggle={toggleDropdown}
      value={options[value]}
    >
      {dropdownOption.map(({ value, label }) => (
        <DropdownItem
          onClick={() => onSelect(value)}
          key={`${itemKeyPrefix}-${value}`}
        >
          {label}
        </DropdownItem>
      ))}
    </GridDropdown>
  );
};
