import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { downloadFile } from '../../../Utils/DownloadFile';
import history from '../../../Utils/History';

import {
  addNewMpaSuccess, addNewMpaError,
  deleteSelectedMpaSuccess, deleteSelectedMpaError,
  getExportToBqStatusSuccess, getExportToBqStatusError,
  getMpasSuccess, getMpasError,
  getMpasCsvSuccess, getMpasCsvError,
  getSelectedMpaSuccess, getSelectedMpaError,
  updateSelectedMpaSuccess, updateSelectedMpaError
} from '../Actions/MpaActions';

import MpaService from '../../../Services/MpaService';

export function* onAddNewMpa(action: any) {
  try {
    let response = yield call(MpaService.addNewMpa, action.payload);
    yield put(addNewMpaSuccess(response));
    toast.success('Successfully created new MPA');
  } catch (err) {
    yield put(addNewMpaError(err));
    toast.error('Could not create new MPA');
  } finally {
    yield history.push( `/map/mpa`, `/map/mpa`)

    // yield call(history.push, `/map/mpa`);
  }
}

export function* onDeleteSelectedMpa(action: any) {
  try {
    let response = yield call(MpaService.deleteSelectedMpa, action.mpaId, action.payload);
    // console.log('DELETE API response');
    // console.log(response);
    yield put(deleteSelectedMpaSuccess(action.mpaId));
    toast.success('Successfully deleted selected MPA');
  } catch (err) {
    yield put(deleteSelectedMpaError(err));
    toast.error('Could not delete selected MPA');
  } finally {
    yield history.push( `/map/mpa`, `/map/mpa`);
  }
}

// fetch All MPAs  to be dsplayed on dashboard
export function* onGetMpas(action: any) {
  try {
    let response = yield call(MpaService.retrieveMpas);
    console.log('Get MPA response');
    console.log(response);
    yield put(getMpasSuccess(response));
  } catch (err) {
    console.log('Get MPA error');
    console.log(err);
    yield put(getMpasError(err));
  }
}

// fetch All MPAs as CSV to be downloaded
export function* onGetMpasCsv(action: any) {
  try {
    let mpaCsvBlob = yield call(MpaService.retrieveMpasCsv);
    let mpaCsvFilename = `mpas_${new Date().getTime()}.csv`;
    downloadFile(mpaCsvBlob, mpaCsvFilename);

    yield put(getMpasCsvSuccess());
  } catch (err) {
    toast.error('Could not download MPAs as CSV');
    yield put(getMpasCsvError(err));
  }
}

export function* onGetSelectedMpa(action: any) {
  try {
    let response = yield call(MpaService.retrieveSelectedMpa, action.mpaId);
    yield put(getSelectedMpaSuccess(response));
  } catch (err) {
    yield put(getSelectedMpaError(err));
  }
}

export function* onUpdateSelectedMpa(action: any) {
  try {
    let response = yield call(MpaService.updateSelectedMpa, action.rowId, action.payload);
    yield put(updateSelectedMpaSuccess(response));
    toast.success('Successfully updated selected MPA');
  } catch (err) {
    yield put(updateSelectedMpaError(err));
    toast.error('Could not update selected MPA');
  } finally {
    yield history.push( `/map/mpa`, `/map/mpa`)

    // yield call(history.push, `/map/mpa`);
  }
}

export function* onGetExportToBqStatus(){
  try {
    let response = yield call(MpaService.retrieveExportToBqStatus);
    yield put(getExportToBqStatusSuccess(response));
  } catch (err) {
    yield put(getExportToBqStatusError(err));
  }
}
