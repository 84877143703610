import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { pick, keys, values } from 'lodash';

import { FieldDataInterface } from '../../../Models/AnnualFormInterface';

import { addNewAnnualBudget } from '../../../State/AnnualBudget/Actions/AnnualBudgetActions';
import { getAnnualBudgetState } from '../../../State/AnnualBudget/Selectors/AnnualBudgetSelectors';

import AnnualBudgetForm from '../Common/AnnualBudgetForm';
import { ConfirmationModal } from '../../../Common';

import { ModalsConsumer } from '../../../Contexts/ModalsContext';

import { formatNumberToCanadianDollars } from '../../../Utils/FormatNumberToCurrency';

interface OwnProps {
  lobDropdownOptions: Array<any>;
  userName: String;
}

const AddAnnualBudget = (props: OwnProps) => {
  const [annualBudgetData, setAnnualBudgetData] = useState<FieldDataInterface>({
    fiscal_year: new Date().getMonth()+1>=11?"F"+(new Date().getFullYear()+1).toString().slice(-2): "F"+ new Date().getFullYear().toString().slice(-2),
    lob: "",
    products: "",
    // anchor_cid: "",
    // channel: "",
    // account_name: "",
    budget: 0,
    budget_amount_adjustments: 0,
    status: "",
    notes: "",    
    commission: 0,
    commission_rate: 1.5,
    platform_fees: 0,
    platform_rate: 0,
    tax: 0,
    tax_rate: 13,
    net_total: 0,
    working_funds: 0    
  });

  const annualBudgetState = useSelector(getAnnualBudgetState);
  const dispatch = useDispatch();

  const buildAddAnnualBudgetConfirmationText = () => {
    // const annualBudgetAnchorCid = annualBudgetData['anchor_cid'];
    const annualBudgetProduct = annualBudgetData['products'];
    const annualBudgetValue = formatNumberToCanadianDollars(annualBudgetData['budget']);

    return `The budget amount for Product '${annualBudgetProduct}' is set to ${annualBudgetValue}. Do you want to proceed?`;
  };

  const handleGetChangeLog = () => {
    //create Annual Budgets change logs
    const changedAnnualBudgetKeysMethod = (o1: any) => {
        return keys(o1)
    };

    const AnnualBudgetChangedKeys = changedAnnualBudgetKeysMethod(annualBudgetData);

    const annualBudgetChangeLogs = AnnualBudgetChangedKeys.map((key) => {
      const newValue = values(pick(annualBudgetData, key));
      return `${key} : ->  ${newValue}`;
    });

    // TODO - Return as string
    return annualBudgetChangeLogs.toString();
  };

  const handleAddAnnualBudget = (event: any, openUpdateConfirmationModal: any) => {
    event.preventDefault();
    openUpdateConfirmationModal();
  };

  const addAnnualBudgetAction = () => {
    // pass the changeLog along with payload + need to add logic to handle unchanged form submission
    const changeLog = handleGetChangeLog();

    // replace the null values with empty string to fix query issues
    for(let key in annualBudgetData) {
      if(key != 'budget_amount_adjustments' && annualBudgetData[key] === null) {
        annualBudgetData[key] = '';
      }
    }

    const annualBudgetInfo = {
      ...annualBudgetData,
      created_by: props.userName,
      edit_by: '',
      edit_date: new Date(),
      created_date: new Date()
    };
    dispatch(addNewAnnualBudget({ annualBudgetInfo, changeLog }));
  };

  return(
    <ModalsConsumer>
      {
        modalsState => {
          return modalsState && (
            <>
              <AnnualBudgetForm
                lobDropdownOptions={props.lobDropdownOptions}
                annualBudgetData={annualBudgetData}
                auditLogs={[]}
                onSave={handleAddAnnualBudget}
                openUpdateConfirmationModal={modalsState.handleToggleConfirmationModal}
                setAnnualBudgetData={setAnnualBudgetData}
                isSubmitDisabled={annualBudgetState.addAnnualBudgetLoadable.isLoading}
                isDisabled={false}
              />
              <ConfirmationModal
                confirmationHeader="Create Annual Budget"
                confirmationText={buildAddAnnualBudgetConfirmationText()}
                executeConfirmationAction={addAnnualBudgetAction}
                handleToggleModal={modalsState.handleToggleConfirmationModal}
                isModalOpen={modalsState.isConfirmationModalOpen}
              />
            </>
          )
        }
      }
    </ModalsConsumer>
  )
};

export default AddAnnualBudget;
