import ApiService from './ApiService';

export default class FormInfoService {
  static retrieveAnchorCids(lob: string, channel: string, product?: string): Promise<Array<any>> {
    return ApiService.get({ relativeUrl: `/form-info/api/anchor-cids`, queryParams: { lob, channel, product } });
  }

  static retrieveDropdownOptions(): Promise<any> {
    return ApiService.get({ relativeUrl: `/form-info/api/dropdown-options` });
  }

  static retrieveNewMpaNumber(lob: string): Promise<Array<any>> {
    return ApiService.get({ relativeUrl: `/form-info/api/new-mpa-number/${lob}` });
  }

  // static retrieveAnnualBudgetValidationCount(year: string, anchor_cid: string): Promise<any> {
  //   return ApiService.get({ relativeUrl: `/form-info/api/annual-budget-validation/${year}/${anchor_cid}` });
  // }

  // Retrieve annual budget for selected LOB, Product and Fiscal year
  static retrieveAnnualBudgetValidationCount(year: string, lob: string, product: string): Promise<any> {
    return ApiService.get({ relativeUrl: `/form-info/api/annual-budget-validation/${year}/${lob}/${product}` });
  }

  static retrieveProductOptions(): Promise<any> {
    return ApiService.get({ relativeUrl: `/form-info/api/product-options` });
  }

  static retrieveSelProductChannelList(product: string): Promise<Array<any>> {
    return ApiService.get({ relativeUrl: `/form-info/api/get-channel-list-for-lob`, queryParams: { product } });
  }

  // Retrieve distinct channels from form_lob table to display them in channel dropdown
  static retrieveAllChannelOptions(): Promise<any> {
    return ApiService.get({ relativeUrl: `/form-info/api/channel-options` });
  }
}
