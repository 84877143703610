import React from 'react';
import { CustomInput } from 'reactstrap';
import PropTypes from 'prop-types';

export const CheckboxInput = (props: any) => {
  const {
    checked,
    disabled,
    handleCheckboxToggle,
    labelText,
    name
  } = props;

  return (
    <CustomInput
      inline={true}
      label={labelText}
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={() => { handleCheckboxToggle(name); }}
      className="Table-checkbox-input"
      id={name}
      name={name}
    />
  );
};

CheckboxInput.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleCheckboxToggle: PropTypes.func.isRequired,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired
};
