export const ADD_NEW_MPA = 'ADD_NEW_MPA';
export const ADD_NEW_MPA_SUCCESS = 'ADD_NEW_MPA_SUCCESS';
export const ADD_NEW_MPA_ERROR = 'ADD_NEW_MPA_ERROR';

export const DELETE_SELECTED_MPA = 'DELETE_SELECTED_MPA';
export const DELETE_SELECTED_MPA_SUCCESS = 'DELETE_SELECTED_MPA_SUCCESS';
export const DELETE_SELECTED_MPA_ERROR = 'DELETE_SELECTED_MPA_ERROR';


export const GET_MPAS = 'GET_MPAS';
export const GET_MPAS_SUCCESS = 'GET_MPAS_SUCCESS';
export const GET_MPAS_ERROR = 'GET_MPAS_ERROR';

export const GET_MPAS_CSV = 'GET_MPAS_CSV';
export const GET_MPAS_CSV_SUCCESS = 'GET_MPAS_CSV_SUCCESS';
export const GET_MPAS_CSV_ERROR = 'GET_MPAS_CSV_ERROR';

export const GET_SELECTED_MPA = 'GET_SELECTED_MPA';
export const GET_SELECTED_MPA_SUCCESS = 'GET_SELECTED_MPA_SUCCESS';
export const GET_SELECTED_MPA_ERROR = 'GET_SELECTED_MPA_ERROR';

export const UPDATE_SELECTED_MPA = 'UPDATE_SELECTED_MPA';
export const UPDATE_SELECTED_MPA_SUCCESS = 'UPDATE_SELECTED_MPA_SUCCESS';
export const UPDATE_SELECTED_MPA_ERROR = 'UPDATE_SELECTED_MPA_ERROR';

export const GET_EXPORT_TO_BQ_STATUS = 'GET_EXPORT_TO_BQ_STATUS';
export const GET_EXPORT_TO_BQ_STATUS_SUCCESS = 'GET_EXPORT_TO_BQ_STATUS_SUCCESS';
export const GET_EXPORT_TO_BQ_STATUS_ERROR = 'GET_EXPORT_TO_BQ_STATUS_ERROR';
