import styled from 'styled-components';

export const TableFooter = styled('div')`
  background-color: #f3f3f3;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0.5rem;
  width: 100%;
  margin-bottom: 10px;
`;
