import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, NavItem, NavLink, NavbarBrand } from 'reactstrap';
import history from '../Utils/History';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import { logout } from 'State/Authentication/Actions/AuthenticationActions';
import {
  getAuthenticationState,
  getUsername,
} from 'State/Authentication/Selectors/AuthenticationSelectors';

const navigationDetails = [
  {
    title: 'MPA Tool',
    route: '/map/mpa',
    minNavInfo: [
      {
        route: '/map/mpa',
        title: 'Manage MPAs',
      },
      {
        route: '/map/exclude-cids',
        title: 'Manage Exclude CIDs',
      },
      {
        route: '/map/annual-budgets',
        title: 'Manage Annual Budgets',
      },
      {
        route: '/map/lob',
        title: 'Manage LOBs',
      },
    ],
  },
  {
    title: 'BOM Tool',
    route: '/bom/budget-orders',
    minNavInfo: [
      {
        route: '/bom/budget-orders',
        title: 'Manage Budget Orders',
      },
      {
        route: '/bom/table-configuration',
        title: 'Manage Columns',
      },
    ],
  },
  {
    title: 'DAG API Config',
    route: '/dag/api-config',
    minNavInfo: [
      {
        route: '/dag/api-config',
        title: 'Manage API Config',
      },
    ],
  },
  {
    title: 'Manage Kenshoo BQ Pipeline',
    route: '/kbp',
    minNavInfo: [
      {
        route: '/kbp',
        title: 'Manage Kenshoo',
      },
    ],
  },
  {
    title: 'Budget Validation',
    route: '/budget-validation',
    minNavInfo: [
      {
        route: '/budget-validation',
        title: 'Budget Validation',
      },
    ],
  },
  {
    title: 'Budget Pacing',
    route: '/budget-pacing/overview',
    minNavInfo: [
      {
        route: '/budget-pacing/overview',
        title: 'Budget Pacing Overview',
      },
      {
        route: '/budget-pacing/metrics',
        title: 'Budget Pacing Metrics',
      },
    ],
  },
  // {
  //   title: 'Spend Forecast (Beta)',
  //   route: '/forecasting-tool/overview',
  //   minNavInfo: [{
  //     route: '/forecasting-tool/overview',
  //     title: 'Spend Forecast Overview'
  //   },{
  //     route: '/forecasting-tool',
  //     title: 'Spend Forecast Breakdown'
  //   }]
  // },
  {
    title: 'Stems Config',
    route: '/stems-config',
    minNavInfo: [
      {
        route: '/stems-config',
        title: 'Stems Config',
      },
    ],
  },
  {
    title: 'Media Calculators',
    route: '/media-calculators',
    minNavInfo: [
      {
        route: '/media-calculators',
        title: 'Media Calculators',
      },
    ],
  },
  {
    title: 'PRM Online Privacy Policy',
    route: '/privacy',
  },
];

const NavbarWithoutRouter = () => {
  const dispatch = useDispatch();
  const username = useSelector(getUsername);
  const authState = useSelector(getAuthenticationState);

  const createDropDowns = () => {
    return navigationDetails.map((data) => {
      if (
        data.route !== '/bom/budget-orders' &&
        (data.route === history.location.state ||
          data.route === history.location.pathname)
      ) {
        return data.minNavInfo.map((navInfo, index) => {
          return (
            <NavItem key={index}>
              <NavLink
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push(navInfo.route, data.route);
                }}>
                {navInfo.title}
              </NavLink>
            </NavItem>
          );
        });
      } else if (data.route === '/bom/budget-orders') {
        if (
          history.location.pathname === data.route ||
          history.location.pathname === '/bom/edit-budget-orders' ||
          history.location.pathname === '/bom/review-budget-orders' ||
          history.location.pathname === '/bom/table-configuration'
        ) {
          return data.minNavInfo.map((navInfo, index) => {
            return (
              <NavItem key={index}>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(navInfo.route, data.route);
                  }}>
                  {navInfo.title}
                </NavLink>
              </NavItem>
            );
          });
        }
      } else if (data.route === '/map/mpa') {
        if (
          history.location.pathname === data.route ||
          history.location.pathname === '/map/exclude-cids' ||
          history.location.pathname === '/map/annual-budgets' ||
          history.location.pathname === '/map/lob' ||
          history.location.pathname === '/map/mpa/add' ||
          history.location.pathname === '/map/lob/add' ||
          history.location.pathname === '/map/exclude-cids/add' ||
          history.location.pathname === '/map/annual-budgets/add'
        ) {
          return data.minNavInfo.map((navInfo, index) => {
            return (
              <NavItem key={index}>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(navInfo.route, data.route);
                  }}>
                  {navInfo.title}
                </NavLink>
              </NavItem>
            );
          });
        }
      }
    });
  };

  const createHeading = () => {
    return navigationDetails.map((data, index) => {
      if (
        data.route !== '/bom/budget-orders' &&
        (data.route === history.location.state ||
          data.route === history.location.pathname)
      ) {
        return (
          <NavbarBrand
            key={index}
            style={{ cursor: 'pointer' }}
            className="mr-3"
            onClick={() => {
              history.push(data.route, data.route);
            }}>
            {data.title}
          </NavbarBrand>
        );
      } else if (data.route === '/bom/budget-orders') {
        if (
          history.location.pathname === data.route ||
          history.location.pathname === '/bom/edit-budget-orders' ||
          history.location.pathname === '/bom/review-budget-orders' ||
          history.location.pathname === '/bom/table-configuration'
        ) {
          return (
            <NavbarBrand
              key={index}
              style={{ cursor: 'pointer' }}
              className="mr-3"
              onClick={() => {
                history.push(data.route, data.route);
              }}>
              {data.title}
            </NavbarBrand>
          );
        }
      } else if (data.route === '/map/mpa') {
        if (
          history.location.pathname === data.route ||
          history.location.pathname === '/map/annual-budgets' ||
          history.location.pathname === '/map/lob' ||
          history.location.pathname === '/map/exclude-cids' ||
          history.location.pathname === '/map/mpa/add' ||
          history.location.pathname === '/map/lob/add' ||
          history.location.pathname === '/map/exclude-cids/add' ||
          history.location.pathname === '/map/annual-budgets/add'
        ) {
          return (
            <NavbarBrand
              key={index}
              style={{ cursor: 'pointer' }}
              className="mr-3"
              onClick={() => {
                history.push(data.route, data.route);
              }}>
              {data.title}
            </NavbarBrand>
          );
        }
      }
    });
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary navbar-fixed-top">
      <div className="navbar-collapse">
        <div className="dropdown mr-3">
          <button className="btn btn-outline-light" data-toggle="dropdown">
            <FontAwesomeIcon icon="bars" className="ml-1 mr-1" />
          </button>

          <ul className="dropdown-menu multi-level">
            {navigationDetails.map((data) => (
              <div key={data.route} className="dropdown-item">
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push(data.route)}>
                    {data.title}
                  </NavLink>
                </NavItem>
              </div>
            ))}
          </ul>
        </div>
        {createHeading()}
        {history.location.pathname !== '/not-found' ? (
          history.location.pathname !== '/privacy' ? (
            <div className="dropdown mr-3">
              <span
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                style={{ color: 'white', cursor: 'pointer' }}>
                Select
              </span>
              <ul className="dropdown-menu" style={{ minWidth: '15rem' }}>
                {createDropDowns()}
              </ul>
            </div>
          ) : null
        ) : null}
        <div className="dropdown ml-auto">
          <span
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            style={{ color: 'white', cursor: 'pointer' }}>
            {username}
          </span>

          <ul className="dropdown-menu multi-level">
            {authState.isAdmin && (
              <li className="dropdown-item">
                <a href="/admin/users">Admin</a>
              </li>
            )}
            {authState.isAdmin && <li className="dropdown-divider"></li>}
            <li>
              <Button
                className="dropdown-item"
                color="link"
                onClick={() => dispatch(logout())}>
                Log out
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export const Navbar = withRouter(NavbarWithoutRouter);
