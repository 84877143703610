import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { ReportTableHead } from '../Common/ReportTableHead';
import { ReportTableBody } from '../Common/ReportTableBody';
import { HeadCell, Row } from '../Models/ReportTable';
import BudgetReportsService from '../Services/BudgetReportsService';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';

export const LobProduct = (props: any) => {

  function createData(
    lob: string,
    origLob: string,
    channel: string,
    origChannel: string,
    product: string,
    budget_amount: number,
    mpa_amount: number,
    // remaining_to_mpa: number,
    loaded: number,
    remaining_to_load: number,
    spend: number,
    remaining_on_mpa: number,
  ) {
    return {
      lob,
      origLob,
      channel,
      origChannel,
      product,
      budget_amount,
      mpa_amount,
      // remaining_to_mpa,
      loaded,
      remaining_to_load,
      spend,
      remaining_on_mpa
    };
  }

  const [lobProduct, setLobProduct] = useState<Row[]>([]);
  const [lobProductCid, setLobProductCid] = useState<Row[]>([]);
  
  const grandTotal = (response:any) =>{
    let lobs = new Set();
    for( let r of response){
      lobs.add(r.lob);
    }
  
    let calculatedRow: Row[] =[];

    for(let l of Array.from(lobs)){
      let lob = l.toString()
      let grand_total = createData('','','','','Grand Total', 0,0,0,0,0,0);

      for(let r of response){        
        if(r.lob === lob){
          let temp = createData('','','','','',0,0,0,0,0,0);  

          temp.lob = r.lob;
          temp.origLob = lob;          
          temp.product = r.product;
          temp.channel = r.channel;
          temp.origChannel = r.channel;

          grand_total.origLob = lob;

          if(props.selectedDollar === "Gross"){
            temp.budget_amount = Math.round(r.gross_budget_amount);
            // if(countBudgetAmount != 0){
            //   temp.budget_amount = null;
            // } else {
            //   temp.budget_amount = Math.round(r.gross_budget_amount);
            // }
            
            temp.mpa_amount = Math.round(r.gross_mpa_amount);
            var gross_remaining_to_mpa = Math.round(r.gross_budget_amount) - Math.round(r.gross_mpa_amount);
            // temp.remaining_to_mpa = Math.round(gross_remaining_to_mpa) === -0 ? 0 : Math.round(gross_remaining_to_mpa);
            if(temp.origChannel === "Facebook"){
              temp.loaded = Math.round(r.gross_mpa_amount);
              temp.remaining_to_load = 0
            } else {
              temp.loaded = Math.round(r.gross_loaded_budget);
              var gross_remaining_to_load = Math.round(r.gross_mpa_amount) - Math.round(r.gross_loaded_budget);
              temp.remaining_to_load = Math.round(gross_remaining_to_load) === -0 ? 0 : Math.round(gross_remaining_to_load);
              // temp.remaining_to_load = Math.round(r.gross_remaining_to_load) ===-0 ?0: Math.round(r.gross_remaining_to_load);
            }
            // temp.loaded = Math.round(r.gross_loaded);
            // temp.remaining_to_load = Math.round(r.gross_remaining_to_load) ===-0 ?0: Math.round(r.gross_remaining_to_load);
            temp.spend = Math.round(r.gross_spend);
            var gross_remaining_on_mpa = Math.round(r.gross_mpa_amount) - Math.round(r.gross_spend);
            temp.remaining_on_mpa = Math.round(gross_remaining_on_mpa) === -0 ? 0 : Math.round(gross_remaining_on_mpa);
            // temp.remaining_on_mpa = Math.round(r.gross_remaining_on_mpa) ===-0? 0: Math.round(r.gross_remaining_on_mpa);
          } else if(props.selectedDollar === "Net"){
            temp.budget_amount = Math.round(r.net_budget_amount);
            // if(countBudgetAmount != 0){
            //   temp.budget_amount = null;
            // } else {
            //   temp.budget_amount = Math.round(r.net_budget_amount);
            // }
            
            temp.mpa_amount = Math.round(r.net_mpa_amount);
            var net_remaining_to_mpa = Math.round(r.net_budget_amount) - Math.round(r.net_mpa_amount);
            // temp.remaining_to_mpa = Math.round(net_remaining_to_mpa) === -0 ? 0 : Math.round(net_remaining_to_mpa);
            if(temp.origChannel === "Facebook"){
              temp.loaded = Math.round(r.net_mpa_amount);
              temp.remaining_to_load = 0
            } else {
              temp.loaded = Math.round(r.net_loaded_budget);
              var net_remaining_to_load = Math.round(r.net_mpa_amount) - Math.round(r.net_loaded_budget);
              temp.remaining_to_load = Math.round(net_remaining_to_load) === -0 ? 0: Math.round(net_remaining_to_load);
              // temp.remaining_to_load = Math.round(r.net_remaining_to_load) ===-0? 0: Math.round(r.net_remaining_to_load);
            }
            // temp.loaded = Math.round(r.net_loaded);
            // temp.remaining_to_load = Math.round(r.net_remaining_to_load) ===-0? 0: Math.round(r.net_remaining_to_load);
            temp.spend = Math.round(r.net_spend);
            var net_remaining_on_mpa = Math.round(r.net_mpa_amount) - Math.round(r.net_spend);
            temp.remaining_on_mpa = Math.round(net_remaining_on_mpa) === -0 ? 0 : Math.round(net_remaining_on_mpa);
            // temp.remaining_on_mpa = Math.round(r.net_remaining_on_mpa) ===-0? 0: Math.round(r.net_remaining_on_mpa);
          }
          
          calculatedRow.push(temp);
          
          grand_total.budget_amount = grand_total.budget_amount + temp.budget_amount;
          grand_total.mpa_amount = grand_total.mpa_amount + temp.mpa_amount;
          // grand_total.remaining_to_mpa = grand_total.remaining_to_mpa + temp.remaining_to_mpa;
          grand_total.loaded = grand_total.loaded + temp.loaded;
          grand_total.remaining_to_load = grand_total.remaining_to_load + temp.remaining_to_load;
          grand_total.spend = grand_total.spend + temp.spend;
          grand_total.remaining_on_mpa = grand_total.remaining_on_mpa + temp.remaining_on_mpa;

          // countBudgetAmount++;
        }
      }
      calculatedRow.push(grand_total);
    }

    let tempRows = calculatedRow.map(r=>{return {...r}});
    for(let channel of props.channelOptions){
      if(!channel.checked){   
          tempRows = tempRows.filter(row => row.channel !== channel.name);
      }
    }

    let emptyRow;

    for(let l of Array.from(lobs)){  
      let lob = l.toString();
      let grand_total_row: Row = {
        lob: ' ',
        origLob: '',     
        channel: ' ',
        origChannel: ' ',
        product: 'Grand Total', 
        budget_amount: 0,
        mpa_amount: 0,          
        // remaining_to_mpa: 0,
        loaded: 0,
        remaining_to_load: 0,
        spend: 0,
        remaining_on_mpa: 0
      };

      emptyRow = cloneDeep(grand_total_row);

      for(let row of tempRows) {
        if(row.origLob === lob && row.product !== 'Grand Total') {
          grand_total_row.origLob = row.lob;
          grand_total_row.budget_amount += row.budget_amount;
          grand_total_row.mpa_amount += row.mpa_amount;
          // grand_total_row.remaining_to_mpa += row.remaining_to_mpa;
          grand_total_row.loaded += row.loaded;
          grand_total_row.remaining_to_load += row.remaining_to_load;
          grand_total_row.spend += row.spend;
          grand_total_row.remaining_on_mpa += row.remaining_on_mpa;
        }       
      }
      tempRows = tempRows.map((row) => {
        if(row.origLob === lob && row.product === 'Grand Total') {
          return grand_total_row;
        }
        return row;
      })
    }

    tempRows = tempRows.filter(row => row.origLob != '');

    let isDataEmpty = true;
    for(let row of tempRows) {
      if(row.product != 'Grand Total') {
        isDataEmpty = false;
      }
    }

    if(isDataEmpty) {
      tempRows = [emptyRow];
    }

    // Remove duplicate budget amount values for each LOB
    let removeDuplicateValues = tempRows.map(r=>{return {...r}});    

    for(let l of Array.from(lobs)){            
      let countLOB = 0;
      let lob = l.toString();
      let prev_amount = 0;
      let amount_difference = 0;
      let currentChannel = '';
      let amountRowsCnt = 0;
      let currentProduct = '';

      for(let r of removeDuplicateValues){             
        if(r.lob === lob){ 
          if(r.budget_amount !== prev_amount){
            prev_amount = r.budget_amount;
            amountRowsCnt = 0;
          }
          
          if(amountRowsCnt != 0) {
            amount_difference += prev_amount;
            prev_amount = r.budget_amount;
            r.budget_amount = null;
          }
          amountRowsCnt++;

          if(countLOB != 0 ){  
              r.lob = ' ';
          }
          countLOB++;

          // remove duplicate channels for each row
          if(currentChannel != r.channel) {
            currentChannel = r.channel;
          } else {
            r.channel = ' ';
          }  
          
          // remove duplicate products for each row
          if(currentProduct != r.product) {
            currentProduct = r.product;
          } else {
            r.product = ' ';
          } 
          
        } else if(r.origLob === lob && r.product === 'Grand Total') {
          r.budget_amount -= amount_difference;
          // r.remaining_to_mpa = r.budget_amount - r.mpa_amount;
        }
      }
    }    

    props.setLobProductisloaded(true);
    setLobProduct(removeDuplicateValues);
    // setLobProduct(calculatedRow);    
  }
  
  //get LOB x Product
  useEffect(() => {
    async function getLobProduct() {
      props.setLobProductisloaded(false);

      try{
        const response = await BudgetReportsService.retrieveLobProduct(props.selectedFiscal);
        // const currentYear = "FY-"+new Date().getFullYear().toString();
        // if(props.selectedFiscal !== currentYear){
        //   for( let r of response){
        //    r.loaded = 0;
        //    r.remaining_to_load = 0;
        //    r.spend = 0;
        //    r.remaining_on_mpa = 0;
        //   }
        // }

        grandTotal(response);
      } catch(ex){
        props.setLobProductisloaded(true);
        if(ex === "SQL Query Failed"){
          toast.error("Couldn't retrieve data in LOBxProduct, please try again");
        } else {
          sessionStorage.removeItem("authenticationState");
          sessionStorage.removeItem("tableConfig");
          window.location.reload(); 
        } 
      } 
    }
    getLobProduct();
  }, [props.selectedFiscal, props.selectedDollar, props.channelOptions]);

  //get LOB x Product x Cid
  useEffect(() => {
    async function getLobProductCid() {
      try{
        const response = await BudgetReportsService.retrieveLobProductCid(props.selectedFiscal);
        setLobProductCid(response)

      } catch(ex){
        
        if(ex === "SQL Query Failed"){
          toast.error("Couldn't retrieve data in LOBxProductxCid, please try again");
        } else {
          sessionStorage.removeItem("authenticationState");
          sessionStorage.removeItem("tableConfig");
          window.location.reload(); 
        } 
      } 
    }
    getLobProductCid();
  }, [props.selectedFiscal, props.selectedDollar, props.channelOptions]);

  let headCells: HeadCell[];
    headCells = [
      {id: 'lob',label: 'LOB', isNum:false, applySort:false},
      {id: 'channel',label: 'Channel', isNum:false, applySort:false},  
      {id: 'product', label: 'Product', isNum:false, applySort:false},    
      {id: 'budget_amount', label: 'Budget Amount', isNum:true, applySort:false},
      {id: 'mpa_amount',label: 'MPA Amount', isNum:true, applySort:false},
      // {id: 'remaining_to_mpa',label: 'Remaining Budget', isNum:true, applySort:false},
      {id: 'loaded',label: 'Loaded', isNum:true, applySort:false},
      {id: 'remaining_to_load',label: 'Remaining To Load', isNum:true, applySort:false},
      {id: 'spend',label: 'Spend', isNum:true, applySort:false},
      {id: 'remaining_on_mpa',label: 'Remaining On MPA', isNum:true, applySort:false},
    ];

  return (
    <>
     {props.lobProductisloaded?
        <Paper sx={{ width: '95%', ml:5,mb:5, border:"1px solid"}}>
          <TableContainer sx={{ maxHeight: 900 }}>
            <h4 style={{textAlign: "left", padding:"5px"}}>BUDGET BREAKDOWN - LOB x PRODUCT</h4>
            <Table stickyHeader size='small' >
              <ReportTableHead headCells={headCells} />
              <ReportTableBody 
                rows={lobProduct}
                selectedFiscal={props.selectedFiscal}
                selectedDollar={props.selectedDollar}
                lobProductCid={lobProductCid}
                channelOptions={props.channelOptions}
              />
            </Table>
          </TableContainer>
        </Paper>
     : null}      
    </>
  )
}