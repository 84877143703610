import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, pick, keys, values, filter, union } from 'lodash';

import { FieldDataInterface } from '../../../Models/AnnualFormInterface';

import { updateSelectedAnnualBudget } from '../../../State/AnnualBudget/Actions/AnnualBudgetActions';
import { getUserPermissions } from '../../../State/Authentication/Selectors/AuthenticationSelectors';

import AnnualBudgetForm from '../Common/AnnualBudgetForm';
import { ConfirmationModal } from '../../../Common';

import { ModalsConsumer } from '../../../Contexts/ModalsContext';

import { formatNumberToCanadianDollars } from '../../../Utils/FormatNumberToCurrency';
import { toast } from 'react-toastify';

const EditAnnualBudget = (props: any) => {

  const dispatch = useDispatch();

  const [annualBudgetData, setAnnualBudgetData] = useState<FieldDataInterface>(props.selectedAnnualBudget);
  const [selectedAnnualBudgetId, setSelectedAnnualBudgetId] = useState(0);

  const userPermissions = useSelector(getUserPermissions);

  useEffect(() => {
    setAnnualBudgetData(props.selectedAnnualBudget.annual_budget);
  }, [props.selectedAnnualBudget]);

  const buildUpdateAnnualBudgetConfirmationText = () => {
    // const annualBudgetAnchorCid = annualBudgetData['anchor_cid'];
    const annualBudgetProduct = annualBudgetData['products'];
    const annualBudgetValue = formatNumberToCanadianDollars(annualBudgetData['budget']);

    return `The budget amount for Product '${annualBudgetProduct}' is set to ${annualBudgetValue}. Do you want to proceed?`;
  };

  const handleGetChangeLog = () => {
    //create Annual Budgets change logs
    const changedMpaKeysMethod = (o1: any, o2: any) => {
      return filter(union(keys(o1), keys(o2)), (key) => {
        return o1[key] !== o2[key];
      });
    };

    const annualBudgetChangedKeys = changedMpaKeysMethod(annualBudgetData, props.selectedAnnualBudget.annual_budget);

    const annualBudgetChangeLogs = annualBudgetChangedKeys.map((key) => {
      const newValue = values(pick(annualBudgetData, key));
      const prevValue = values(pick(props.selectedAnnualBudget.annual_budget, key));
      return `${key} :  ${prevValue} ->  ${newValue}`;
    });

    return annualBudgetChangeLogs.toString();
  };

  const handleUpdateAnnualBudget = (event: any, openUpdateConfirmationModal: any, id: any) => {
    event.preventDefault();
    setSelectedAnnualBudgetId(id);
    openUpdateConfirmationModal();
  };

  const updateAnnualBudgetAction = () => {
    const changeLog = handleGetChangeLog();

    if(isEqual(annualBudgetData, props.selectedAnnualBudget.annual_budget)){
      toast.error("Alert!!! Can't submit unchanged form");
    } else {
      // replace the null values with empty string to fix query issues
      for(let key in annualBudgetData) {
        if(key != 'budget_amount_adjustments' && annualBudgetData[key] === null) {
          annualBudgetData[key] = '';
        }
      }

      const annualBudgetPayload = { 
        annualBudgetInfo: annualBudgetData, 
        changeLog: changeLog,
        editedBy: props.userName 
      };
      dispatch(updateSelectedAnnualBudget(selectedAnnualBudgetId.toString(10), annualBudgetPayload));
    }
  };

  return (
    <ModalsConsumer>
      {
        modalsState => {
          return modalsState && (
            <>
              <AnnualBudgetForm
                annualBudgetData={annualBudgetData}
                auditLogs={props.selectedAnnualBudget.annual_budget_audit_logs}
                lobDropdownOptions={props.lobDropdownOptions}
                onSave={handleUpdateAnnualBudget}
                openUpdateConfirmationModal={modalsState.handleToggleConfirmationModal}
                setAnnualBudgetData={setAnnualBudgetData}
                isSubmitDisabled={props.isSubmitDisabled || !userPermissions.includes('change_yearbudget')}
                isDisabled={true}
                selectedBudgetData={props.selectedAnnualBudget.annual_budget}
              />
              <ConfirmationModal
                confirmationHeader="Update Annual Budget"
                confirmationText={buildUpdateAnnualBudgetConfirmationText()}
                executeConfirmationAction={updateAnnualBudgetAction}
                handleToggleModal={modalsState.handleToggleConfirmationModal}
                isModalOpen={modalsState.isConfirmationModalOpen}
              />
            </>
          )
        }
      }
    </ModalsConsumer>
  );
};

export default EditAnnualBudget;
