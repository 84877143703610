import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AnchorCidGridDropdown, ChannelDropdown, ChangeLogModal, GridInput, GridTextField, LobDropdown } from '../../../Common';
import AnnualBudgetStatusDropdown from './AnnualBudgetStatusDropdown';
import FiscalYearDropdown from './FiscalYearDropdown';
import { Button, Col, Container, Row } from 'reactstrap';
import BudgetCalculator from '../../../Services/BudgetCalculator';
import { formatNumberToCanadianDollars } from '../../../Utils/FormatNumberToCurrency';

import { getFormInfoState } from '../../../State/FormInfo/Selectors/FormInfoSelectors';
import { getAnchorCids } from '../../../State/FormInfo/Actions/FormInfoActions';
import history from '../../../Utils/History'
import { getUserPermissions } from 'State/Authentication/Selectors/AuthenticationSelectors';
import { ProductDropdown } from '../../../Common/ProductDropdown';

const AnnualBudgetForm = (props: any) => {
  const [channelOptions, setChannelOptions] = useState<Array<any>>([]);

  const lobOptions = Object.keys(props.lobDropdownOptions);
  const [productOptions, setProductOptions] = useState<Array<any>>([]);

  const formInfoState = useSelector(getFormInfoState);
  const dispatch = useDispatch();

  const userPermissions = useSelector(getUserPermissions);

  // Values Calculation useEffect
  useEffect(() => {
    const netTotal = BudgetCalculator.calculateNetTotal(
      props.annualBudgetData.budget,
      props.annualBudgetData.tax_rate,
      props.annualBudgetData.budget_amount_adjustments
    );

    const subTotal = BudgetCalculator.calculateWorkingFunds(
      props.annualBudgetData.net_total,
      props.annualBudgetData.commission_rate,
      props.annualBudgetData.platform_rate
    );

    const commission = BudgetCalculator.calculateCommission(
      subTotal,
      props.annualBudgetData.commission_rate
    );

    const platformFees = BudgetCalculator.calculatePlatformFees(
      props.annualBudgetData.working_funds,
      props.annualBudgetData.platform_rate
    );

    const tax = BudgetCalculator.calculateTax(
      props.annualBudgetData.budget,
      props.annualBudgetData.net_total,
      props.annualBudgetData.budget_amount_adjustments
    );

    props.setAnnualBudgetData({
      ...props.annualBudgetData,
      working_funds: subTotal,
      net_total: netTotal,
      commission,
      platform_fees: platformFees,
      tax
    });
  }, [
    props.annualBudgetData.budget,
    props.annualBudgetData.budget_amount_adjustments,
    props.annualBudgetData.working_funds,
    props.annualBudgetData.commission_rate,
    props.annualBudgetData.platform_rate,
    props.annualBudgetData.tax_rate,
    props.annualBudgetData.net_total
  ]);

  useEffect(() => {
    // set product options
    const checkLob = lobOptions.includes(props.annualBudgetData.lob);    

    if(props.annualBudgetData.lob){
      // only set productOptions if an existing lob is selected
      if(checkLob){
        const productsOptions = props.lobDropdownOptions[props.annualBudgetData.lob];
        setProductOptions(productsOptions);
      } else {
        setProductOptions([]);
      }
    }      
  }, [props.annualBudgetData.lob, props.annualBudgetData.products])

  const handleInputFieldChange = (event: any, type: string) => {
    if (type === 'notes') {
      props.setAnnualBudgetData({
        ...props.annualBudgetData,
        notes: event.target.value
      });
    } else {
      props.setAnnualBudgetData(event.target.value !== "" ?  
      {...props.annualBudgetData, [type]: parseFloat(event.target.value) }
      : {...props.annualBudgetData, [type]: 0 });
    }
  };

  /*const handleAnchorCidChange = (event: any) => {
    const selectedAnchorCid = event.target.value;

    // form reset
    if(props.annualBudgetData.anchor_cid !== selectedAnchorCid){
      props.setAnnualBudgetData({
        ...props.annualBudgetData,
        account_name: formInfoState.anchorCids[selectedAnchorCid][0]['campaign'],
        anchor_cid: selectedAnchorCid,
        budget: 0,
        status: "",
        notes: "",
        commission: 0,
        commission_rate: 1.5,
        platform_fees: 0,
        platform_rate: 0,
        tax: 0,
        tax_rate: 13,
        net_total: 0,
        working_funds: 0
      })
    } 
  };*/

  const handleAnnualBudgetStatusChange = (event: any) => {
    const selectedAnnualBudgetStatus = event.target.value;
    props.setAnnualBudgetData({ ...props.annualBudgetData, status: selectedAnnualBudgetStatus });
  };

  /**const handleChannelChange = (event: any) => {
    const selectedChannel = event.target.value;

    // form reset
    if(props.annualBudgetData.channel !== selectedChannel){
      props.setAnnualBudgetData({ 
        ...props.annualBudgetData, 
        channel: selectedChannel,
        anchor_cid: "",
        account_name: "",
        budget: 0,
        status: "",
        notes: "",
        commission: 0,
        commission_rate: 1.5,
        platform_fees: 0,
        platform_rate: 0,
        tax: 0,
        tax_rate: 13,
        net_total: 0,
        working_funds: 0
      });
    }
    dispatch(getAnchorCids(props.annualBudgetData.lob, selectedChannel));
  }; **/

  const handleFiscalYearChange = (event: any) => {
    const selectedFiscalYear = event.target.value;
    props.setAnnualBudgetData({ ...props.annualBudgetData, fiscal_year: selectedFiscalYear });
  };

  const handleLobChange = (event: any) => {
    const selectedLob = event.target.value;
    const channelOptions = Object.keys(props.lobDropdownOptions[selectedLob][0]);
    setChannelOptions(channelOptions);

    // form reset
    if(props.annualBudgetData.lob !== selectedLob){
      props.setAnnualBudgetData({
        ...props.annualBudgetData,
        lob: selectedLob,
        products: "",
        // channel: "",
        // anchor_cid: "",
        // account_name: "",
        budget: 0,
        budget_amount_adjustments: 0,
        status: "",
        notes: "",
        commission: 0,
        commission_rate: 1.5,
        platform_fees: 0,
        platform_rate: 0,
        tax: 0,
        tax_rate: 13,
        net_total: 0,
        working_funds: 0        
      });
      dispatch(getAnchorCids(selectedLob, ""));
    }     
  };

  const handleProductsChange = (event: any) => {
    const selectedProduct = event.target.value;

    // form reset
    if(props.annualBudgetData.products !== selectedProduct){
      props.setAnnualBudgetData({ 
        ...props.annualBudgetData, 
        products: selectedProduct
      });  
    } 
  };

  return(
    <Container fluid={true}>
      <Row>
        <FiscalYearDropdown
          gridSize={3}
          fiscalYear={props.annualBudgetData.fiscal_year}
          handleFiscalYearChange={handleFiscalYearChange}
          isDisabled={props.isDisabled}
        />
        <LobDropdown
          gridSize={3}
          lobOptions={lobOptions}
          lob={props.annualBudgetData.lob}
          handleLobChange={handleLobChange}
          isDisabled={props.isDisabled}
        />
        <ProductDropdown
          gridSize={3}
          productOptions={productOptions}
          products={props.annualBudgetData.products}
          handleProductsChange={handleProductsChange}
          isDisabled={props.selectedBudgetData ? !props.selectedBudgetData.products ? false : true : props.isDisabled}
        />
        <AnnualBudgetStatusDropdown
          gridSize={3}
          annualBudgetStatus={props.annualBudgetData.status}
          handleAnnualBudgetStatusChange={handleAnnualBudgetStatusChange}
          isDisabled={false}
        />
        {/* <ChannelDropdown
          gridSize={4}
          channelOptions={channelOptions}
          channel={props.annualBudgetData.channel}
          handleChannelChange={handleChannelChange}
          isDisabled={props.isDisabled}
        /> */}
      </Row>
      <Row>        
        {/* <AnchorCidGridDropdown
          anchorCidOptions={Object.keys(formInfoState.anchorCids)}
          handleAnchorCidChange={handleAnchorCidChange}
          isDisabled={props.isDisabled}
          inputLabel="Anchor CID:"
          gridSize={4}
          anchorCid={props.annualBudgetData.anchor_cid}
        />
        <GridInput
          gridSize={4}
          inputAria="account-name-text"
          inputId="account-name-input"
          inputLabel="Account Name:"
          inputType="string"
          value={props.annualBudgetData.account_name? props.annualBudgetData.account_name: ""}
          isDisabled={true}
        /> */}        
      </Row>
      <Row>
        <GridInput
          inputAria="budget-text"
          inputId="budget-input"
          inputLabel="Budget ($)"
          inputType="number"
          value={props.annualBudgetData.budget? props.annualBudgetData.budget: ""}
          handleChange={(e: any) => handleInputFieldChange(e, "budget")}
          required={true}
        />
        <GridInput
          inputAria="budget-amount-adjustments-text"
          inputId="budget-amount-adjustments-input"
          inputLabel="Budget Amount Adjustments ($)"
          inputType="number"
          value={props.annualBudgetData.budget_amount_adjustments? props.annualBudgetData.budget_amount_adjustments: ""}
          handleChange={(e: any) => handleInputFieldChange(e, "budget_amount_adjustments")}
        />
        <GridInput
          inputAria="commission-rate-text"
          inputId="commission-rate-input"
          inputLabel="Commission Rate (%)"
          inputType="number"
          value={props.annualBudgetData.commission_rate? props.annualBudgetData.commission_rate: ""}
          handleChange={(e: any) => handleInputFieldChange(e, "commission_rate")}
        />
        <GridInput
          inputAria="platform-rate-text"
          inputId="platform-rate-input"
          inputLabel="Platform Rate (%)"
          inputType="number"
          value={props.annualBudgetData.platform_rate? props.annualBudgetData.platform_rate: ""}
          handleChange={(e: any) => handleInputFieldChange(e, "platform_rate")}
        />
        <GridInput
          inputAria="tax-rate-text"
          inputId="tax-rate-input"
          inputLabel="Tax Rate (%)"
          inputType="number"
          value={props.annualBudgetData.tax_rate? props.annualBudgetData.tax_rate: ""}
          handleChange={(e: any) => handleInputFieldChange(e, "tax_rate")}
        />
      </Row>
      <Row>
        <GridInput
          inputAria="working-funds-text"
          inputId="working-funds-input"
          inputLabel="Working Funds"
          inputType="string"
          value={formatNumberToCanadianDollars(props.annualBudgetData.working_funds)}
          isDisabled={true}
          isReadOnly={true}
        />
        <GridInput
          inputAria="commission-text"
          inputId="commission-input"
          inputLabel="Commission"
          inputType="string"
          value={formatNumberToCanadianDollars(props.annualBudgetData.commission)}
          isDisabled={true}
          isReadOnly={true}
        />
        <GridInput
          inputAria="platform-fees-text"
          inputId="platform-fees-input"
          inputLabel="Platform Fees"
          inputType="string"
          value={formatNumberToCanadianDollars(props.annualBudgetData.platform_fees)}
          isDisabled={true}
          isReadOnly={true}
        />
        <GridInput
          inputAria="net-total-text"
          inputId="net-total-input"
          inputLabel="Net Total"
          inputType="string"
          value={formatNumberToCanadianDollars(props.annualBudgetData.net_total)}
          isDisabled={true}
          isReadOnly={true}
        />
        <GridInput
          inputAria="tax-text"
          inputId="tax-input"
          inputLabel="Tax"
          inputType="string"
          value={formatNumberToCanadianDollars(props.annualBudgetData.tax)}
          isDisabled={true}
          isReadOnly={true}
        />
      </Row>
      <Row>
        <GridTextField
          gridSize={12}
          inputId="notes-input"
          inputLabel="Notes:"
          value={props.annualBudgetData.notes || ''}
          handleChange={(e: any) => handleInputFieldChange(e, "notes")}
        />
      </Row>
      <Row>
        <Col xs={12}>
          <Button
            color="warning"
            size="md"
            onClick={()=>{history.push(`/map/annual-budgets`,'/map/mpa')}}
            style={{'marginRight': '16px', 'marginBottom': '16px'}}
          >
              Return to Annual Budgets
          </Button>{' '}
          <ChangeLogModal
            logsData={props.auditLogs}
          />          
          <Button
            color="primary"
            size="md"
            // onClick={props.onSave}
            onClick={(event: any) => props.onSave(event, props.openUpdateConfirmationModal, props.annualBudgetData.id)}
            // disabled={props.isSubmitDisabled}
            // disabled={
            //   (props.annualBudgetData.fiscal_year && props.annualBudgetData.lob && 
            //     props.annualBudgetData.channel && props.annualBudgetData.anchor_cid && 
            //     props.annualBudgetData.status) === "" ||
            //     props.annualBudgetData.budget === 0 || props.isSubmitDisabled? true : false
            // }
            disabled={
              (props.annualBudgetData.fiscal_year && props.annualBudgetData.lob && props.annualBudgetData.products && props.annualBudgetData.status) === "" ||
                props.annualBudgetData.budget === 0 || !props.annualBudgetData.products || props.isSubmitDisabled? true : false
            }
            style={{'marginRight': '16px', 'marginBottom': '16px'}}
          >
              Save
          </Button>
        </Col>
      </Row>
    </Container>
  )
};

export default AnnualBudgetForm;
