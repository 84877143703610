export const WEEKS_LEFT_CONFIGURATION = {
  '<4': {
    name: 'Up to 4 Weeks Left',
    check: (n: number) => {
      return n <= 4
    },
  },
  '4-8': {
    name: '4 to 8 Weeks Left',
    check: (n: number) => {
      return n > 4 && n <= 8;
    },
  },
  '>8': {
    name: 'More than 8 Weeks Left',
    check: (n: number) => {
      return n > 8;
    },
  },
};
