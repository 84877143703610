import { createDefaultExcludeCidsState, ExcludeCidsState } from '../Models/ExcludeCidsState';

import {
  updateLoadableOnStart,
  updateLoadableOnSuccess,
  updateLoadableOnError
} from '../../Common/Loadable';

import {
    ADD_CID_TO_EXCLUDE_LIST, ADD_CID_TO_EXCLUDE_LIST_SUCCESS, ADD_CID_TO_EXCLUDE_LIST_ERROR,
    DELETE_SELECTED_EXCLUDE_ID, DELETE_SELECTED_EXCLUDE_ID_SUCCESS, DELETE_SELECTED_EXCLUDE_ID_ERROR,
    GET_EXCLUDE_CIDS, GET_EXCLUDE_CIDS_SUCCESS, GET_EXCLUDE_CIDS_ERROR,
    GET_SELECTED_EXCLUDE_ID, GET_SELECTED_EXCLUDE_ID_SUCCESS, GET_SELECTED_EXCLUDE_ID_ERROR,
    UPDATE_SELECTED_EXCLUDE_ID, UPDATE_SELECTED_EXCLUDE_ID_SUCCESS, UPDATE_SELECTED_EXCLUDE_ID_ERROR
} from '../ActionTypes/ExcludeCidsActionTypes';

export default function (state = createDefaultExcludeCidsState(), action: any): ExcludeCidsState {
    switch(action.type) {
        case ADD_CID_TO_EXCLUDE_LIST:
            return {
                ...state,
                addExcludeIdLoadable: updateLoadableOnStart()
            };

        case ADD_CID_TO_EXCLUDE_LIST_SUCCESS:
            return {
                ...state,
                entities: [...state.entities, action.result],
                addExcludeIdLoadable: updateLoadableOnSuccess()
            };

        case ADD_CID_TO_EXCLUDE_LIST_ERROR:
            return {
                ...state,
                addExcludeIdLoadable: updateLoadableOnError(action.error)
            };
        
        case DELETE_SELECTED_EXCLUDE_ID:
            return {
                ...state,
                deleteSelectedExcludeIdLoadable: updateLoadableOnStart()
            };

        case DELETE_SELECTED_EXCLUDE_ID_SUCCESS:
            return {
                ...state,
                entities: state.entities.filter((selectedExcludedCidData) => selectedExcludedCidData['excludeId'] !== action.excludeId),
                deleteSelectedExcludeIdLoadable: updateLoadableOnSuccess()
            };

        case DELETE_SELECTED_EXCLUDE_ID_ERROR:
            return {
                ...state,
                deleteSelectedExcludeIdLoadable: updateLoadableOnError(action.error)
            };

        case GET_EXCLUDE_CIDS:
            return {
                ...state,
                getExcludeCidsListLoadable: updateLoadableOnStart()
            };

        case GET_EXCLUDE_CIDS_SUCCESS:
            return {
                ...state,
                entities: action.result,
                getExcludeCidsListLoadable: updateLoadableOnSuccess()
            };

        case GET_EXCLUDE_CIDS_ERROR:
            return {
                ...state,
                getExcludeCidsListLoadable: updateLoadableOnError(action.error)
            };
        
        case GET_SELECTED_EXCLUDE_ID:
            return {
                ...state,
                getSelectedExcludeIdListLoadable: updateLoadableOnStart()
            };

        case GET_SELECTED_EXCLUDE_ID_SUCCESS:
            return {
                ...state,
                selectedExcludedCidData: action.result,
                getSelectedExcludeIdListLoadable: updateLoadableOnSuccess()
            };

        case GET_SELECTED_EXCLUDE_ID_ERROR:
            return {
                ...state,
                getSelectedExcludeIdListLoadable: updateLoadableOnError(action.error)
            };

        case UPDATE_SELECTED_EXCLUDE_ID:
            return {
                ...state,
                updateExcludeIdListLoadable: updateLoadableOnStart()
            };

        case UPDATE_SELECTED_EXCLUDE_ID_SUCCESS:
            return {
                ...state,
                entities: [...state.entities].map(entity => {
                    if ( entity['excludeId'] === action.result.excludeId) {
                        return action.result;
                    }
                    return entity;
                }),
                selectedExcludedCidData: action.result,
                updateExcludeIdListLoadable: updateLoadableOnSuccess()
            };

        case UPDATE_SELECTED_EXCLUDE_ID_ERROR:
            return {
                ...state,
                updateExcludeIdListLoadable: updateLoadableOnError(action.error)
            };

        default:
            return state;
    };
};