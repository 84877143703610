import ApiService from '../../../Services/ApiService';

export default class BudgetReportsService {
  static retrieveChannelRollup(year:string):Promise<Array<any>> {
    return ApiService.get({ relativeUrl: `/bom/api/reports/channel-rollup/${year}` });
  }

  static retrieveLobRollup(year:string):Promise<Array<any>> {
    return ApiService.get({ relativeUrl: `/bom/api/reports/lob-rollup/${year}` });
  }

  static retrieveLobProduct(year:string):Promise<Array<any>> {
    return ApiService.get({ relativeUrl: `/bom/api/reports/lob-product/${year}` });
  }

  static retrieveLobProductCid(year:string):Promise<Array<any>> {
    return ApiService.get({ relativeUrl: `/bom/api/reports/lob-product-cid/${year}` });
  }
};
