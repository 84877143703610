import React, { useDebugValue, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import { AnchorCidTableCellDropdown, EditableTableCell, ChannelDropdown, GridInput }from '../../../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserPermissions } from 'State/Authentication/Selectors/AuthenticationSelectors';

const EditableChannelRow = (props: any) => {
    let fixedColumns;
    let cidComponent; 

    if(props.channelData.channel != null && props.channelData.channel.trim() != ''){
        // if(props.channelData.channel === 'SEM') {
        //     cidComponent = 
        //     <>
        //         <GridInput
        //             gridSize={1}
        //             inputAria="google-cid-text"
        //             inputId="google-cid-input"
        //             inputLabel="Google CID"
        //             inputType="string"
        //             placeholder="Eg. 123-456-7890"
        //             value={props.channelData.google_cid ? props.channelData.google_cid : ''}
        //             handleChange={(event: any) => props.handleGoogleCidChange(event, props.index)}
        //         />
        //         <GridInput
        //             gridSize={1}
        //             inputAria="bing-cid-text"
        //             inputId="bing-cid-input"
        //             inputLabel="Bing CID"
        //             inputType="string"
        //             placeholder="Eg. X1234567"
        //             value={props.channelData.bing_cid ? props.channelData.bing_cid : ''}
        //             handleChange={(event: any) => props.handleBingCidChange(event, props.index)}
        //         />
        //         <GridInput
        //             gridSize={1}
        //             inputAria="apple-cid-text"
        //             inputId="apple-cid-input"
        //             inputLabel="Apple CID"
        //             inputType="string"
        //             placeholder="Eg. 1234567Amp"
        //             value={props.channelData.apple_cid ? props.channelData.apple_cid : ''}
        //             handleChange={(event: any) => props.handleAppleCidChange(event, props.index)}
        //         />
        //     </>
        // }
        cidComponent =
                <GridInput
                    gridSize={2}
                    inputAria={props.channelData.channel + "-cid-text"}
                    inputId={props.channelData.channel + "-cid-input"}
                    inputLabel={props.channelData.channel + " CID"}
                    inputType="string"
                    // placeholder="Eg. 322744481243232"
                    // value={props.channelData.tiktok_cid ? props.channelData.tiktok_cid : ''}                    
                    // handleChange={(event: any) => props.handleTiktokCidChange(event, props.index)}
                    value={props.channelData.anchor_cid ? props.channelData.anchor_cid : ''}
                    handleChange={(event: any) => props.handleCidChange(event, props.index)}
                />
    }

    // if(props.channelData.channel != null && (props.channelData.channel === 'SEM' || props.channelData.channel === 'Facebook' || props.channelData.channel === 'Pinterest' || props.channelData.channel === 'Bell' || props.channelData.channel === 'TikTok')) {
    if(props.channelData.channel != null && props.channelData.channel.trim() != '') {
        fixedColumns = 
        <>            
            <GridInput
                gridSize={2}
                inputAria="anchor-cid-text"
                inputId="anchor-cid-input"
                inputLabel="Anchor CID"
                inputType="string"
                isDisabled={true}
                value={props.channelData.anchor_cid ? props.channelData.anchor_cid : ''}
                handleChange={(event: any) => props.handleCidChange(event, props.index)}
                required={true}
            />
            <GridInput
                gridSize={2}
                inputAria="campaign-text"
                inputId="campaign-input"
                inputLabel="Campaign"
                inputType="string"
                value={props.channelData.campaign ? props.channelData.campaign : ''}
                handleChange={(event: any) => props.handleCampaignChange(event, props.index)}
                required={true}
            />
            {cidComponent}
        </>
    }

    return(
        <Row key={props.index}>
            <ChannelDropdown
                gridSize={2}
                channelDropdownIndex={props.index}
                channelOptions={props.channelOptions}
                channel={props.channelData.channel}
                handleChannelChange={(event: any) => props.handleChannelChange(event, props.index)}
                isDisabled={props.isDisabled}
                currentForm='LOB'
            />
            {fixedColumns}
            {
                props.channelList.length >= 1
                ? <Col xs={1}> 
                    <div className="form-group"><label></label></div>
                    <div>
                        <FontAwesomeIcon style={{cursor:"pointer"}}
                            icon="trash"
                            size="lg"
                            title="Delete Channel"
                            onClick={(event: any) => props.handleRemoveChannelFromList(event, props.index)}
                        />
                    </div>
                </Col>
                : ''                                          
            } 
        </Row>
    );
};

export default EditableChannelRow;