import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { HeadCell } from '../Models/ForecastingTable';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

interface Props {
    headCells: HeadCell[]
}

export const OverviewTableHead = (props: Props) => {
    const {headCells} = props;

    return(
        <TableHead>
            <TableRow>
            {headCells.map((headCell,index) => (
                headCell.isNum?                    
                    <TableCell
                    align="right"
                    key={index}
                    style={headCell.sortColumnName?{fontWeight:"bold", backgroundColor:"#006AC3", color:"White", cursor:"pointer", width:headCell.columnWidth} : {fontWeight:"bold", backgroundColor:"#006AC3", color:"White"}}
                    // onClick={headCell.sortColumnName? () => {requestSort(headCell.sortColumnName)} : undefined}
                    >
                        {headCell.label}
                        {/* {headCell.sortColumnName? 
                        getDirection(headCell.sortColumnName)==='asc'? <KeyboardArrowUpIcon fontSize="small" sx={{ color: 'white' }} /> : getDirection(headCell.sortColumnName)==='desc'? <KeyboardArrowDownIcon fontSize="small" sx={{ color: 'white' }} /> : <UnfoldMoreIcon fontSize="small" sx={{ color: 'white' }} /> : '' } */}
                    </TableCell>
                :
                    headCell.label === "Spends"?
                    <TableCell
                    key={index}
                    // style={{fontWeight:"bold", backgroundColor:"#006AC3", color:"White",position:'sticky', left:0,  zIndex: 700}}>
                    style={{fontWeight:"bold", backgroundColor:"#006AC3", color:"White"}}>
                        {headCell.label}
                    </TableCell>
                    :
                    <TableCell
                    key={index}
                    style={{fontWeight:"bold", backgroundColor:"#006AC3", color:"White", cursor:"pointer", width:headCell.columnWidth}}
                    // onClick={() => {requestSort(headCell.sortColumnName)}}
                    >
                        {headCell.label}
                        {/* {getDirection(headCell.sortColumnName)==='asc'? <KeyboardArrowUpIcon fontSize="small" sx={{ color: 'white' }} /> : getDirection(headCell.sortColumnName)==='desc'? <KeyboardArrowDownIcon fontSize="small" sx={{ color: 'white' }} /> : <UnfoldMoreIcon fontSize="small" sx={{ color: 'white' }} />}                         */}
                    </TableCell>
            ))}
            </TableRow>
        </TableHead>
    )
}