import React, {useState} from 'react';
import { Button, Modal, ModalHeader, Table, ModalBody, ModalFooter} from 'reactstrap';

export const ChangeLogModal = (props: any) => {
    const titles = ['Id', 'Action', 'Time Stamp', 'Details of Change Log', 'User']
    const [modal, setModal] = useState(false);
    const toggle = () => { setModal(!modal)}

    const mapTitles = () => {
      return titles.map((data, index) => {
      return <th key={index}>{data}</th>
      })
    }

    const mapAuditLogs = () => {
      return props.logsData.map((data: any) => {
      return <tr key={data.id}>
        <td>{data.type_id}</td>
        <td>{data.action}</td>
        <td>{data.action_dt_tm}</td>
        <td>{data.detail.split(',').map((item:any, index:number) => <p key={index}>{item}</p>)}</td>
        <td>{data.user}</td>
        </tr>
      })
    }

    return (
        <>
          <Button color="primary" size="md" style={{'marginRight': '16px', 'marginBottom': '16px'}} onClick={toggle}>Change Log</Button>
          <Modal size="xl" isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Change Log</ModalHeader>
            <ModalBody>
              <Table>
                <thead>
                  <tr>
                    {mapTitles()}
                  </tr>
                </thead>
                <tbody>
                    {mapAuditLogs()}
                </tbody>
              </Table>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggle}>Close</Button>
            </ModalFooter>
          </Modal>
        </>
      );
}
