import { TableConfigurationInterface } from '../Models/TableConfiguration';
import ApiService from '../../../Services/ApiService';

export default class TableConfigurationService {
  static retrieveTableConfiguration():Promise<TableConfigurationInterface> {
    return ApiService.get({ relativeUrl: '/bom/api/table-configuration' });
  }

  static updateTableConfiguration(tableConfig: TableConfigurationInterface): Promise<TableConfigurationInterface> {
    return ApiService.put({ relativeUrl: '/bom/api/table-configuration', body: tableConfig });
  }
};
