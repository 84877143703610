import { createDefaultLoadable, Loadable} from '../../../State/Common/Loadable';

export interface BudgetOrderCategoriesState {
  entities: Array<string>,
  getBudgetOrderCategoriesLoadable: Loadable,
}

export const createDefaultBudgetOrderCategoriesState = (): BudgetOrderCategoriesState => {
  return {
    entities: [],
    getBudgetOrderCategoriesLoadable: createDefaultLoadable()
  }
};
