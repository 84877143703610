import React from 'react';
import PropTypes from 'prop-types';

import { ChannelDropdown, LobDropdown, GridDatepicker, GridInput, GridTextField } from '../../../Common';
import MpaStatusDropdown from './MpaStatusDropdown';
import PhaseNumberDropdown from './PhaseNumberDropdown';
import ProductsDropdown from './ProductsDropdown';

import {Col, Row} from 'reactstrap';

const MpaIntakeForm = (props: any) => {

  let platformRateGRid;
  if( props.mpaIntake.channel === 'Facebook') {
    platformRateGRid = 
    <>
      <GridInput
        gridSize={3}
        inputAria="platform-rate-text"
        inputId="platform-rate-input"
        inputLabel="Platform Rate (%):"
        inputType="number"
        handleChange={props.handlePlatformRateChange}
        // value = '2.5'
        value = { props.mpaIntake.platform_rate }
      />
    </>

  }
  else{
    platformRateGRid = 
    <>
      <GridInput
        gridSize={3}
        inputAria="platform-rate-text"
        inputId="platform-rate-input"
        inputLabel="Platform Rate (%):"
        inputType="number"
        handleChange={props.handlePlatformRateChange}
        value={props.mpaIntake.platform_rate}
      />
    </>

  }

  return (
    <>
      <Row>
        <Col xs={12}><h3>MPA Intake Form</h3></Col>
      </Row>
      <Row>
        <LobDropdown
          lobOptions={props.lobOptions}
          lob={props.mpaIntake.client_business_unit}
          handleLobChange={props.handleLobChange}
          isDisabled={props.isDisabled}
        />
        {/* <ChannelDropdown
          channelOptions={props.channelOptions}
          channel={props.mpaIntake.channel}
          handleChannelChange={props.handleChannelChange}
          isDisabled={props.isDisabled}
        /> */}
        <ProductsDropdown
          productOptions={props.productOptions}
          products={props.mpaIntake.products}
          handleProductsChange={props.handleProductsChange}
          isDisabled={props.isDisabled}
        />
        <PhaseNumberDropdown
          phaseNumber={props.mpaIntake.phase_number}
          handlePhaseNumberChange={props.handlePhaseNumberChange}
          isDisabled={props.isDisabled}
        />
        <MpaStatusDropdown
          status={props.mpaIntake.mpa_status}
          handleMpaStatusChange={props.handleMpaStatusChange}
        />
      </Row>
      <Row>
        <GridDatepicker
          gridSize={3}
          inputId="scheduled-start-date"
          inputLabel="Scheduled Start:"
          date={props.mpaIntake.scheduled_start}
          handleChange={props.handleScheduledStartChange}
          startDateChanged={props.startDateChange}
          fromAddMPA={props.fromAddMPA}
          disabled={!props.fromAddMPA}
        />
        <GridDatepicker
          gridSize={3}
          inputId="scheduled-end-date"
          inputLabel="Scheduled End:"
          date={props.mpaIntake.scheduled_end}
          handleChange={props.handleScheduledEndChange}
          endDateChanged={props.endDateChange}
          fromAddMPA={props.fromAddMPA}
          disabled={!props.fromAddMPA}
        />
        <GridInput
          gridSize={3}
          inputAria="budget-sharing-flag-text"
          inputId="budget-sharing-flag-input"
          inputLabel="Budget Sharing Flag:"
          inputType="string"
          handleChange={props.handleBudgetSharingFlagChange}
          value={props.mpaIntake.budget_sharing_flag}
          isDisabled={true}
        />    
        <GridInput
          gridSize={3}
          inputAria="prepared-for-text"
          inputId="prepared-for-input"
          inputLabel="Prepared For:"
          inputType="string"
          handleChange={props.handlePreparedForChange}
          value={props.mpaIntake.prepared_for}
          required={true}
        />
      </Row>
      <Row>
        <GridInput
          gridSize={3}
          inputAria="commission-rate-text"
          inputId="commission-rate-input"
          inputLabel="Commission Rate (%):"
          inputType="number"
          handleChange={props.handleCommissionRateChange}
          value={props.mpaIntake.commission_rate}
        />

        
        {/* Platform Grid view changes for Facebook and other channel

        */}
        {platformRateGRid}
        
        <GridInput
          gridSize={3}
          inputAria="tax-rate-text"
          inputId="tax-rate-input"
          inputLabel="Tax Rate (%):"
          inputType="number"
          handleChange={props.handleTaxRateChange}
          value={props.mpaIntake.tax_rate}
        />
        <GridInput
          gridSize={3}
          inputAria="promotion-credit-text"
          inputId="promotion-credit-input"
          inputLabel="Promotion Credit:"
          inputType="number"
          handleChange={props.handlePromotionCreditChange}
          value={props.mpaIntake.promotion_credit ? props.mpaIntake.promotion_credit : ''}
        />
      </Row>
      <Row>
        <GridTextField
          gridSize={6}
          handleChange={props.handleNotesChange}
          inputId="notes"
          inputLabel="Notes:"
          value={props.mpaIntake.notes}
        />
        <GridTextField
          gridSize={6}
          handleChange={props.handleMPONotesChange}
          inputId="mpo_notes"
          inputLabel="MPO Notes:"
          value={props.mpaIntake.mpo_notes??""}
        />
      </Row>
    </>
  );
};

MpaIntakeForm.propTypes = {
  channelOptions: PropTypes.array,
  handleLobChange: PropTypes.func,
  handleChannelChange: PropTypes.func,
  handleProductsChange: PropTypes.func,
  handleBudgetSharingFlagChange: PropTypes.func,
  handlePhaseNumberChange: PropTypes.func,
  handleScheduledStartChange: PropTypes.func,
  startDateChange: PropTypes.bool,
  handleScheduledEndChange: PropTypes.func,
  endDateChange: PropTypes.bool,
  handlePreparedForChange: PropTypes.func,
  handleMpaStatusChange: PropTypes.func,
  handleCommissionRateChange: PropTypes.func,
  handlePlatformRateChange: PropTypes.func,
  handlePlatformFeesChange: PropTypes.func,
  handleTaxRateChange: PropTypes.func,
  handlePromotionCreditChange: PropTypes.func,
  handleNotesChange: PropTypes.func,
  handleMPONotesChange:PropTypes.func,
  isDisabled: PropTypes.bool,
  mpaIntake: PropTypes.any,
  lobOptions: PropTypes.array,
  productOptions: PropTypes.array,
  budgetSharingFlagOptions: PropTypes.array,
  platformRateChangeFacebook: PropTypes.bool,
  fromAddMPA: PropTypes.bool
};

export default MpaIntakeForm;
