import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import FormInfoService from 'Services/FormInfoService';
import ChannelSummary from './ChannelSummary';
import { getAnchorCids } from '../../../State/FormInfo/Actions/FormInfoActions';
import { ConfirmationModal } from '../../../Common';
import { ModalsConsumer } from '../../../Contexts/ModalsContext';
import { channelDataToDifference } from './ChannelCalculationHelper';

const MpaFormBottom = (props: any) => {
  const [anchorCidOptions, setAnchorCidOptions] = useState<Array<any>>([]);
  // const [channelOptions, setChannelOptions] = useState([])
  // const [productOptions, setProductOptions] = useState([])
  const [reset, setReset] = useState(false);
  const dispatch = useDispatch();
  const [deleteChannelId, setDeleteChannelId] = useState(0);

  useEffect(() => {
    setAnchorCidOptions(Object.keys(props.anchorCidList));
  }, [props.anchorCidList]);

  useEffect(() => {
    const triggerAnnualBudgetValidation = async () => {
      let originalCidList = [...props.cidList];
      let removeList = [];
      for (let cidList of originalCidList) {
        if (cidList.anchor_cid !== '') {
          try {
            const response = await FormInfoService.retrieveAnnualBudgetValidationCount('F' + props.campaign.slice(5, 7), props.mpaIntake.client_business_unit, props.mpaIntake.products);
            if (response.counts === 0) {
              removeList.push(cidList.id);
              originalCidList = originalCidList.filter((l) => l.anchor_cid !== cidList.anchor_cid);
              // originalCidList = originalCidList.map(l=> l.anchor_cid === cidList.anchor_cid?
              //   {...l,
              //     anchor_cid: '',
              //     total_budget:0,
              //     campaign_name:'',
              //     google_cid:'',
              //     google_budget:0,
              //     bing_cid:'',
              //     bing_budget:0,
              //     apple_cid:'',
              //     apple_budget:0,
              //     id:0,
              //     mpa_id: 0}  :
              //   l
              //   );
              toast.error(
                <div>
                  Selected Anchor CID <span style={{ fontWeight: 'bold' }}>{cidList.anchor_cid}</span> doesn't have an associated Annual Budget, Please add an Annual Budget first!
                </div>,
                {
                  position: 'top-right',
                  autoClose: 10000,
                  hideProgressBar: false,
                  pauseOnHover: true,
                  closeOnClick: true,
                }
              );
            }
          } catch (ex) {
            toast.error("Couldn't retrieve count data, please try again");
          }
        }
      }
      props.setCidList(originalCidList);
      setReset((prevState) => !prevState);
      //only works when in the edit MPA page
      if (props.cidsToRemove !== undefined) {
        props.setCidsToRemove([...props.cidsToRemove, ...removeList]);
      }
    };

    triggerAnnualBudgetValidation();
  }, [props.campaign.slice(5, 7)]);

  const handleAnchorCidChange = async (event: any, cidIndex: number) => {
    const selectedAnchorCid = event.target.value;
    const selectedAnchorCidInfo = props.anchorCidList[selectedAnchorCid].reduce((item: any) => item);

    try {
      // const response = await FormInfoService.retrieveAnnualBudgetValidationCount("F"+props.campaign.slice(5,7),selectedAnchorCid );
      const response = await FormInfoService.retrieveAnnualBudgetValidationCount('F' + props.campaign.slice(5, 7), props.mpaIntake.client_business_unit, props.mpaIntake.products);
      if (response.counts === 0) {
        toast.error(
          <div>
            Selected Anchor CID <span style={{ fontWeight: 'bold' }}>{selectedAnchorCid}</span> doesn't have an associated Annual Budget, Please add an Annual Budget first!
          </div>,
          {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            pauseOnHover: true,
            closeOnClick: true,
          }
        );
      } else {
        const newCidList = [...props.cidList].map((object: any, index: number) => {
          if (index === cidIndex) {
            return {
              ...object,
              anchor_cid: selectedAnchorCid,
              campaign_name: selectedAnchorCidInfo['campaign'],
              // google_cid: selectedAnchorCidInfo['google_cid'],
              // bing_cid: selectedAnchorCidInfo['bing_cid'],
              // apple_cid: selectedAnchorCidInfo['apple_cid'],
              // facebook_cid: selectedAnchorCid,
              // pinterest_cid: selectedAnchorCid,
              // bell_cid: selectedAnchorCid,
              // tiktok_cid: selectedAnchorCid,
            };
          } else {
            return object;
          }
        });
        // setAnchorCidOptions([...Object.keys(props.anchorCidList)].filter(l=> l !== selectedAnchorCid))
        props.setCidList(newCidList);
      }
    } catch (ex) {
      toast.error("Couldn't retrieve count data, please try again");
    }
  };

  const handleTotalBudgetChange = useCallback(
    (event: any, cidIndex: number, dates) => {
      props.setCidList((preList) =>
        [...preList].map((object, index) =>
          index === cidIndex
            ? {
                ...object,
                working_funds: Number(event.target.value),
                difference: channelDataToDifference(props.fromAddMPA, { ...object, working_funds: Number(event.target.value) }, dates),
              }
            : object
        )
      );
      setReset((prevState) => !prevState);
    },
    [props.fromAddMPA, props.setCidList, setReset]
  );

  const handleTotalPlatformFeeChange = (value: number, cidIndex: number) => {
    const newCidList = [...props.cidList].map((object, index) => {
      if (index === cidIndex) {
        setReset((prevState) => !prevState);
        return {
          ...object,
          anchor_cid_platform_fee: +value,
        };
      } else {
        return object;
      }
    });
    props.setCidList(newCidList);
  };

  const handleCampaignNameChange = (event: any, cidIndex: number) => {
    props.setCidList(
      [...props.cidList].map((object, index) => {
        if (index === cidIndex) {
          return {
            ...object,
            campaign_name: event.target.value,
          };
        } else {
          return object;
        }
      })
    );
  };

  useEffect(() => {
    const allCidTotal = props.cidList.reduce((accumulator: number, cidTotal: any) => accumulator + cidTotal.working_funds, 0);

    const allPlatformFees = props.cidList.reduce((total, cid) => total + cid.anchor_cid_platform_fee, 0);

    props.setMpaIntake({ ...props.mpaIntake, working_funds_total: allCidTotal, platform_fees: allPlatformFees });

    props.setSummaryBudgetChanges(true);
  }, [reset]);

  const handleAddCidToList = () => {
    // props.handleCidDropdownList(props.mpaIntake.client_business_unit, '', props.mpaIntake.products);
    dispatch(getAnchorCids(props.mpaIntake.client_business_unit, '', props.mpaIntake.products));
    const newRow = {
      channel: '',
      anchor_cid: '',
      working_funds: 0,
      campaign_name: '',
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      id: 0,
      mpa_id: props.mpaIntake.id,
      anchor_cid_platform_fee: 0,
      difference:0,
      // legacy_mpa:"",
      // google_cid:'',
      // google_budget:0,
      // bing_cid:'',
      // bing_budget:0,
      // apple_cid:'',
      // apple_budget:0,
      // facebook_cid: "",
      // pinterest_cid: "",
      // bell_cid: "",
      // tiktok_cid: ""
    };

    props.setCidList([...props.cidList, newRow]);
  };

  const handleMonthFundsChange = useCallback(
    (month: string, event: any, channelIndex: number, dates) => {
      props.setCidList((preList) =>
        [...preList].map((object, index) =>
          index === channelIndex
            ? {
                ...object,
                [month]: Number(event.target.value),
                difference: channelDataToDifference(props.fromAddMPA, { ...object, [month]: Number(event.target.value) }, dates),
              }
            : object
        )
      );
    },
    [props.fromAddMPA, props.setCidList]
  );

  /*const handleInitMapChange = (event: any, channelIndex: number) => {
    props.setCidList(
      [...props.cidList].map((object, index) => {
        if(index === channelIndex) {
          // setReset(prevState=>!prevState);
          return {
            ...object,
            legacy_mpa: event.target.value
          }
        } else {
          return object;
        }
      })
    );
  };*/

  // Confirmation text when CID/Channel row is deleted for selected MPA
  const buildDeletechannelConfirmationText = () => {
    if (props.cidList[deleteChannelId]) {
      const selectedAnchorCid = props.cidList[deleteChannelId].anchor_cid;
      const selectedChannel = props.cidList[deleteChannelId].channel;

      return `The Anchor CID: ${selectedAnchorCid} of Channel: ${selectedChannel} will be deleted from the CID list. Do you want to proceed?`;
    }
  };

  // Remove CID row from the list
  const deleteChannelAction = () => {
    // to delete a cid row from database. Note: newRow is initialised with id = 0
    if (props.cidList[deleteChannelId].id !== 0 && props.cidsToRemove !== undefined) {
      props.setCidsToRemove([...props.cidsToRemove, props.cidList[deleteChannelId].id]);
    }

    let updatedCidList = [...props.cidList];
    updatedCidList.splice(deleteChannelId, 1);
    props.setCidList(updatedCidList);
    setReset((prevState) => !prevState);
  };

  const handleRemoveCidFromList = (event: any, cidIndex: number, openDeleteChannelConfirmationModal: any) => {
    if (props.cidList[cidIndex].id !== 0 && props.cidsToRemove !== undefined) {
      openDeleteChannelConfirmationModal();
      setDeleteChannelId(cidIndex);
    } else {
      let updatedCidList = [...props.cidList];
      updatedCidList.splice(cidIndex, 1);
      props.setCidList(updatedCidList);
      setReset((prevState) => !prevState);
    }
  };

  return (
    <>
      <ModalsConsumer>
        {(modalsState) => {
          return (
            modalsState && (
              <>
                <ChannelSummary
                  channelOptions={props.channelOptions}
                  anchorCidOptions={anchorCidOptions}
                  channelList={props.cidList}
                  isDisabled={props.isDisabled}
                  handleAddChannelToList={handleAddCidToList}
                  handleChannelChange={props.handleChannelChange}
                  fromAddMPA={props.fromAddMPA}
                  // handleGoogleCidChange={handleGoogleCidChange}
                  // handleGoogleBudgetChange={handleGoogleBudgetChange}
                  // handleBingCidChange={handleBingCidChange}
                  // handleBingBudgetChange={handleBingBudgetChange}
                  // handleAppleCidChange={handleAppleCidChange}
                  // handleAppleBudgetChange={handleAppleBudgetChange}
                  // handleFacebookCidChange={handleFacebookCidChange}
                  // handlePinterestCidChange={handlePinterestCidChange}
                  // handleBellCidChange={handleBellCidChange}
                  // handleTiktokCidChange={handleTiktokCidChange}
                  handleCampaignChange={handleCampaignNameChange}
                  handleRemoveChannelFromList={handleRemoveCidFromList}
                  handleTotalBudgetChange={handleTotalBudgetChange}
                  handleAnchorCidChange={handleAnchorCidChange}
                  // handleInitMapChange={handleInitMapChange}
                  modalsState={modalsState.handleToggleDeleteChannelConfirmationModal}
                  mpaIntake={props.mpaIntake}
                  handleMonthFundsChange={handleMonthFundsChange}
                  handleTotalPlatformFeeChange={handleTotalPlatformFeeChange}
                />
                <ConfirmationModal
                  confirmationHeader="Delete Channel"
                  confirmationText={buildDeletechannelConfirmationText()}
                  executeConfirmationAction={deleteChannelAction}
                  handleToggleModal={modalsState.handleToggleDeleteChannelConfirmationModal}
                  isModalOpen={modalsState.isDeleteChannelConfirmationModalOpen}
                />

                {/* <CidSummary
                  anchorCidOptions={anchorCidOptions}
                  cidList={props.cidList}
                  isDisabled={props.isDisabled}
                  selectedChannelOption={props.selectedChannelOption}
                  handleAddCidToList={handleAddCidToList}
                  handleTotalBudgetChange={handleTotalBudgetChange}
                  handleAnchorCidChange={handleAnchorCidChange}
                  handleCampaignNameChange={handleCampaignNameChange}
                  handleGoogleBudgetChange={handleGoogleBudgetChange}
                  handleBingBudgetChange={handleBingBudgetChange}
                  handleAppleBudgetChange={handleAppleBudgetChange}
                  handleRemoveCidFromList={handleRemoveCidFromList}
                  handleChannelChange={props.handleChannelChange}
                 /> */}
              </>
            )
          );
        }}
      </ModalsConsumer>
    </>
  );
};

export default MpaFormBottom;
