import { put } from 'redux-saga/effects';

import { getSelectedMpa } from '../../Mpa/Actions/MpaActions';
import { getNewMpaNumber } from '../../FormInfo/Actions/FormInfoActions';

export function* onSetSelectedMpaId(action: any) {
  yield put(getSelectedMpa(action.mpaId));
}

export function* onSetSelectedLob(action: any) {
  yield put(getNewMpaNumber(action.lob));
}
