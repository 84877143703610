import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddAnnualBudget from './AddAnnualBudget';

import { AppPage, PageHeader, WithLoading } from '../../../Common';

import { getUsername, getUserPermissions } from '../../../State/Authentication/Selectors/AuthenticationSelectors';
import { getProductOptions } from '../../../State/FormInfo/Actions/FormInfoActions';
import { getFormInfoState } from '../../../State/FormInfo/Selectors/FormInfoSelectors';
import { Redirect } from 'react-router-dom';

const AddAnnualBudgetWithLoading = WithLoading(AddAnnualBudget);

export const AddAnnualBudgetContainer = () => {
  const formInfoState = useSelector(getFormInfoState);
  const dispatch = useDispatch();

  const username = useSelector(getUsername);
  const userPermissions = useSelector(getUserPermissions);
  
  // useEffect(() => {
  //   if (!formInfoState.getDropdownOptionsLoadable.isReceived) {
  //     dispatch(getDropdownOptions());
  //   }
  // }, [formInfoState.getDropdownOptionsLoadable.isReceived, dispatch]);

  useEffect(() => {
    if (!formInfoState.getProductOptionsLoadable.isReceived) {
        dispatch(getProductOptions());
    }
  }, [formInfoState.getProductOptionsLoadable.isReceived, dispatch]);

  if(userPermissions.includes('add_yearbudget')){
    return (
      <>
        <PageHeader>Add Annual Budget</PageHeader>
        <AppPage>
          <AddAnnualBudgetWithLoading
            loadable={formInfoState.getProductOptionsLoadable}
            lobDropdownOptions={formInfoState.productOptionsWithoutChannel}
            userName={username}
          />
        </AppPage>
      </>
    );
  } else {
    return <Redirect to={{pathname:'/map/annual-budgets', state: '/map/mpa'}} />
  }
};

export default AddAnnualBudgetContainer;
