import React from 'react';
import PropTypes from 'prop-types';

import { BUDGET_ORDER_ATTRIBUTE_MAP } from '../Models/BudgetOrderAttributeMap'

import { HeaderCell } from '../Styled/HeaderCell';

interface Props{
  tableConfig: Array<any>,
}

export const TableHeader = (props: Props) => {
  const { tableConfig } = props;

  return (
    <thead>
      <tr>
        {tableConfig.map((attribute: string) => (
          <HeaderCell style={{ width: BUDGET_ORDER_ATTRIBUTE_MAP[attribute].width }} key={attribute}>
            <span className='Column-Name'>
              { BUDGET_ORDER_ATTRIBUTE_MAP[attribute].name }
            </span>
          </HeaderCell>
        ))}
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  tableConfig: PropTypes.array.isRequired
};
