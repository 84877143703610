import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { downloadFile } from '../../../Utils/DownloadFile';
import history from '../../../Utils/History';

import {
  addNewAnnualBudgetSuccess, addNewAnnualBudgetError,
  deleteSelectedAnnualBudgetSuccess, deleteSelectedAnnualBudgetError,
  getAnnualBudgetSuccess, getAnnualBudgetError,
  getAnnualBudgetSuccessCsv, getAnnualBudgetErrorCsv,
  getSelectedAnnualBudgetSuccess, getSelectedAnnualBudgetError,
  updateSelectedAnnualBudgetSuccess, updateSelectedAnnualBudgetError
} from '../Actions/AnnualBudgetActions';

import AnnualBudgetService from '../../../Services/AnnualBudgetService';

export function* onAddNewAnnualBudget(action: any) {
  try {
    let response = yield call(AnnualBudgetService.createAnnualBudget, action.annualBudgetData);
    yield put(addNewAnnualBudgetSuccess(response));
    toast.success('Successfully added new Annual Budget');
  } catch (err) {
    yield put(addNewAnnualBudgetError(err));
    toast.error('Could not add new Annual Budget');
  } finally {
    yield history.push( `/map/annual-budgets`, '/map/mpa')
  }
}

export function* onDeleteSelectedAnnualBudget(action: any) {
  try {
    let response = yield call(AnnualBudgetService.deleteSelectedAnnualBudget, action.annualBudgetId, action.payload);
    yield put(deleteSelectedAnnualBudgetSuccess(action.annualBudgetId));
    toast.success('Successfully deleted selected Annual Budget');
  } catch (err) {
    yield put(deleteSelectedAnnualBudgetError(err));
    toast.error('Could not delete selected Annual Budget');
  }
}

export function* onGetAnnualBudgets(action: any) {
  try {
    let response = yield call(AnnualBudgetService.retrieveAnnualBudgets);
    yield put(getAnnualBudgetSuccess(response));
  } catch (err) {
    yield put(getAnnualBudgetError(err));
  }
}

export function* onGetAnnualBudgetsCsv(action: any) {
  try {
    let annualBudgetsCsvBlob = yield call(AnnualBudgetService.retrieveAnnualBudgetsCsv);
    let annualBudgetsCsvFilename = `annual-budgets_${new Date().getTime()}.csv`;
    downloadFile(annualBudgetsCsvBlob , annualBudgetsCsvFilename);
    yield put(getAnnualBudgetSuccessCsv());
  } catch (err) {
    toast.error('Could not download Annual Budgets as CSV');
    yield put(getAnnualBudgetErrorCsv(err));
  }
}

export function* onGetSelectedAnnualBudget(action: any) {
    try {
      let response = yield call(AnnualBudgetService.retrieveSelectedAnnualBudget, action.annualBudgetId);
      yield put(getSelectedAnnualBudgetSuccess(response));
    } catch (err) {
      yield put(getSelectedAnnualBudgetError(err));
    }
}

export function* onUpdateSelectedAnnualBudget(action: any) {
  try {
    let response = yield call(
      AnnualBudgetService.updateSelectedAnnualBudget,
      action.annualBudgetId,
      action.annualBudgetData
    );
    yield put(updateSelectedAnnualBudgetSuccess(response));
    toast.success('Successfully updated selected Annual Budget');
  } catch (err) {
    yield put(updateSelectedAnnualBudgetError(err));
    toast.error('Could not update selected Annual Budget');
  } finally {
    yield history.push( `/map/annual-budgets`, '/map/mpa')

    // yield call(history.push, `/map/annual-budgets`);
  }
}

