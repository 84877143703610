import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../Utils/History'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import AnnualBudgetsToolbar from '../Common/AnnualBudgetsToolbar';
import { ConfirmationModal, DashboardTable, PaginationToolbar, RowsPerPageDropdown, SearchBar, TablePagination }
  from '../../../Common';

import { ModalsConsumer } from '../../../Contexts/ModalsContext';

import { deleteSelectedAnnualBudget } from '../../../State/AnnualBudget/Actions/AnnualBudgetActions';
import { getAnnualBudgetState } from '../../../State/AnnualBudget/Selectors/AnnualBudgetSelectors';
import { getUserPermissions } from '../../../State/Authentication/Selectors/AuthenticationSelectors';

import { formatNumberToCanadianDollars } from '../../../Utils/FormatNumberToCurrency';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { isNumber } from 'lodash';

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if(isNumber(a[sortConfig.key])){
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
        } else {
          if (a[sortConfig.key].toUpperCase() < b[sortConfig.key].toUpperCase()) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key].toUpperCase() > b[sortConfig.key].toUpperCase()) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
        }
        
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: string) => {
    let direction = 'asc';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'asc'
    ) {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
}; 

const AnnualBudgetDashboard = (props: any) => {
  const [filteredData, setFilteredData] = useState<Array<any>>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, onChangeSearch] = useState('');
  const [selectedAnnualBudgetId, setSelectedAnnualBudgetId] = useState(0);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fiscalFilter, setFiscalFilter] = useState(true);
  // const [selectedFiscal, setSelectedFiscal] = useState('');

  const currentFiscalYear = new Date().getFullYear().toString();
  const nextFiscalYear = (new Date().getFullYear()+1).toString();

  // load current fiscal year's data on page load
  const [selectedFiscal, setSelectedFiscal] = useState<String>(new Date().getMonth()+1 >= 11?
                                                              "F" + nextFiscalYear.slice(-2)
                                                              :"F"+ currentFiscalYear.slice(-2));

  const [fiscalData, setFiscalData] = useState<Array<any>>([]);
  const userPermissions = useSelector(getUserPermissions);
  const annualBudgetState = useSelector(getAnnualBudgetState);
  const dispatch = useDispatch();
  const { items, requestSort, sortConfig } = useSortableData(filteredData);

  const getDirection = (name: string) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  useEffect(() => { 
      setTableData([...props.annualBudgetsList]);
  }, [props.annualBudgetsList]);

  // filter current year's data to display it on page load
  useEffect(() => {
    if(fiscalData.length === 0) {
      setFiscalData((tableData).filter((data:any) => data.fiscal_year.includes(selectedFiscal)));
    } 
  }, [tableData]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);

  const buildDeleteAnnualBudgetConfirmationText = () => {
    const selectedAnnualBudget = filteredData.length > 0 ?
      filteredData.find((annualBudget: any) => annualBudget['id'] === selectedAnnualBudgetId) :
      tableData.find((annualBudget: any) => annualBudget['id'] === selectedAnnualBudgetId);
    // const selectedAnnualBudgetAnchorCid = selectedAnnualBudget && selectedAnnualBudget['anchor_cid'];
    const selectedAnnualBudgetProduct = selectedAnnualBudget && selectedAnnualBudget['products'];

    return `Are you sure you want to delete annual budget for '${selectedAnnualBudgetProduct}'?`;
  };

  const deleteAnnualBudgetAction = () => {
    const payload = {
      deletedBy : props.userName
    };
    dispatch(deleteSelectedAnnualBudget(selectedAnnualBudgetId, payload));    
  };

  // empty current year's data if a row is deleted to filter it according to the selected year in the dropdown
  useEffect(() => {
    if(props.isBudgetDeleted) {
      setFiscalData([]);
    }
  },[props.isBudgetDeleted])

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any,) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchValue = (e: any) => {
    onChangeSearch(e)
  };

  const selectAnnualBudgetToDelete = (event: any, id: number, openConfirmationModal: any) => {
    setSelectedAnnualBudgetId(id);
    openConfirmationModal();
  };

  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    // setFilteredData(
    //   tableData.filter((data: any) =>
    //     data.lob.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
    //     data.anchor_cid.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
    //     data.channel.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
    //     data.fiscal_year.toLowerCase().includes(searchValue.toLocaleLowerCase())
    //   )
    // )

    setPage(0);
    const filterFuncton = (data: any, keyword: string) => {
      setFilteredData(
        data.filter((data: any) =>
          data.lob.toLowerCase().includes(keyword.toLocaleLowerCase()) 
          // || data.products.toLowerCase().includes(keyword.toLocaleLowerCase())
      ))
    };

    const keywords = searchValue.split(" ");

    keywords.forEach((element, index) => {
      // call filter function
      if(index === 0 && !fiscalFilter){
        // first word and no fiscal is selected
        filterFuncton(tableData, element);
      }
      else if(index === 0 && fiscalFilter){
        // first word and a fiscal is selected
        filterFuncton(fiscalData, element);
      }
      else if(index > 0 && fiscalFilter) {
        // not a first word but a fiscal is selected
        filterFuncton(filteredData, element);
      }
      else {
        filterFuncton(filteredData, element);
      }
    });
  }, [searchValue, fiscalData, tableData, selectedFiscal, fiscalFilter]);

  const uniqueFiscal = useMemo(()=>tableData.map(item => item.fiscal_year)
  .filter((value, index, self) => self.indexOf(value) === index)
  .sort().reverse(),[tableData]);

  
  const setDropDownAction = (e:any) => {
    // filter has been selected
    setFiscalFilter(true);
    setSelectedFiscal(e.innerHTML);

    // reset search bar if a filter is selected
    onChangeSearch('')
    setPage(0);

    // update table data on base of filter selection
    setFilteredData((tableData).filter((data:any) => data.fiscal_year.includes(e.innerHTML)));
    setFiscalData((tableData).filter((data:any) => data.fiscal_year.includes(e.innerHTML)));
  }

  const cancelDropDownAction = () => {
    setFiscalFilter(false)
    setPage(0);
    // reset search bar if filter is removed
    onChangeSearch('')
  }

  return (
    <>
      <ModalsConsumer>
        {
          modalsState => {
            return modalsState && (
              <>
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle caret color="primary">
                    {/* {fiscalFilter ? `${selectedFiscal}`: 'Select a Fiscal'} */}
                    {selectedFiscal}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={()=>cancelDropDownAction()}>---</DropdownItem>
                    {uniqueFiscal.map((item,index) => 
                      <DropdownItem key={index} onClick={(e)=>setDropDownAction(e.target)}>{item}</DropdownItem>)
                    }         
                  </DropdownMenu>
                </Dropdown>

                <br/>
                <SearchBar
                  // placeholder={"Search for LOB, Channel or CID"}
                  placeholder={"Search for LOB"}
                  searchInput={searchValue}
                  handleSearchValue={handleSearchValue}
                />
                <AnnualBudgetsToolbar showAddAnnualBudgetButton={userPermissions.includes('add_yearbudget')} />
                <DashboardTable>
                  <thead>
                    <tr>
                      {/* <th align="left" onClick={() => {setPage(0);requestSort('anchor_cid')}} style={{cursor:"pointer",width:"15%"}}>
                        <b>Anchor CID</b> {getDirection('anchor_cid')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('anchor_cid')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      <th align="left" onClick={() => {setPage(0);requestSort('account_name')}} style={{cursor:"pointer",width:"15%"}}>
                        <b>Account Name</b> {getDirection('account_name')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('account_name')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th> */}
                      <th align="left" onClick={() => {setPage(0);requestSort('lob')}} style={{cursor:"pointer",width:"15%"}}>
                        <b>LOB</b> {getDirection('lob')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('lob')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      <th align="left" onClick={() => {setPage(0);requestSort('products')}} style={{cursor:"pointer",width:"15%"}}>
                        <b>Product</b> {getDirection('products')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('products')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      {/* <th align="left" onClick={() => {setPage(0);requestSort('channel')}} style={{cursor:"pointer",width:"15%"}}>
                        <b>Channel</b> {getDirection('channel')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('channel')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th> */}
                      <th onClick={() => {setPage(0);requestSort('budget')}} style={{cursor:"pointer", textAlign:"right",width:"15%"}}>
                        <b>Budget</b> {getDirection('budget')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('budget')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      <th onClick={() => {setPage(0);requestSort('budget_amount_adjustments')}} style={{cursor:"pointer", textAlign:"right",width:"15%"}}>
                        <b>Budget Amount Adjustments</b> {getDirection('budget_amount_adjustments')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('budget_amount_adjustments')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      <th onClick={() => {setPage(0);requestSort('fiscal_year')}} style={{cursor:"pointer", textAlign:"center",width:"15%"}}>
                        <b>Fiscal Year</b> {getDirection('fiscal_year')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('fiscal_year')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      <th align="left" onClick={() => {setPage(0);requestSort('status')}} style={{cursor:"pointer",width:"15%"}}>
                        <b>Status</b> {getDirection('status')==='asc'? <KeyboardArrowUpIcon fontSize="small" color="primary" /> : getDirection('status')==='desc'? <KeyboardArrowDownIcon fontSize="small" color="primary" /> : <UnfoldMoreIcon fontSize="small" color="primary" />}
                      </th>
                      <th align="left"><b>Edit</b></th>
                      {userPermissions.includes('delete_yearbudget') && <th align="left"><b>Delete</b></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {
                    (rowsPerPage > 0
                        ? items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : items                        
                      ).map((row: any) => (
                        <tr key={row.id}>
                          {/* <td align="left">{row.anchor_cid}</td>
                          <td align="left">{row.account_name}</td> */}
                          <td align="left">{row.lob}</td>
                          <td align="left">{row.products}</td>
                          {/* <td align="left">{row.channel}</td> */}
                          <td align="right">{formatNumberToCanadianDollars(row.budget)}</td>
                          <td align="right">{formatNumberToCanadianDollars(row.budget_amount_adjustments)}</td>
                          <td align="center">{row.fiscal_year}</td>
                          <td align="left">{row.status}</td>
                          <td>
                              <FontAwesomeIcon style={{"cursor": "pointer"}} 
                                  onClick={()=>{history.push(`/map/annual-budgets/edit/${row.id}`,'/map/mpa')}}
                              icon="edit" size="sm" />
                          </td>
                          {userPermissions.includes('delete_yearbudget') &&
                            <td>
                              <FontAwesomeIcon
                                icon="trash"
                                size="sm"
                                style={{"color": "#006AC3", "cursor": "pointer"}}
                                onClick={
                                  event => selectAnnualBudgetToDelete(event, row['id'], modalsState.handleToggleConfirmationModal)
                                }
                              />
                            </td>
                          }
                        </tr>
                    ))}
                    {emptyRows > 0 && (
                      <tr style={{ height: 53 * emptyRows }}>
                        <td colSpan={9}><span></span></td>
                      </tr>
                    )}
                  </tbody>
                </DashboardTable>
                <PaginationToolbar>
                  <RowsPerPageDropdown
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TablePagination
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                  />
                </PaginationToolbar>
                <ConfirmationModal
                  confirmationHeader="Delete MPA"
                  confirmationText={buildDeleteAnnualBudgetConfirmationText()}
                  executeConfirmationAction={deleteAnnualBudgetAction}
                  handleToggleModal={modalsState.handleToggleConfirmationModal}
                  isModalOpen={modalsState.isConfirmationModalOpen}
                />
              </>
            )
          }
        }
      </ModalsConsumer>

    </>
  );
};



export default AnnualBudgetDashboard;
