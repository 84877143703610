import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {  Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, ButtonToolbar } from 'reactstrap';
import { Box, FormGroup, FormLabel, Stack} from '@mui/material';
import FormInfoService from '../../../Services/FormInfoService';
import { ConfirmationModal, DashboardTable, PaginationToolbar, RowsPerPageDropdown, SearchBar, TablePagination }
  from '../../../Common';
import { CSVLink } from "react-csv";

const BudgetPacingDashboard = (props: any) => {
    const [budgetResponseData, setBudgetResponseData] = useState<Array<any>>([]);    
    const [lobOptions, setLobOptions] = useState<Array<any>>([{}]);
    const [lobOptionsisloaded, setLobOptionsisloaded] = useState<Boolean>(false);
    const [openLobDropdown, setOpenLobDropdown] = useState(false); 
    const [selectedLob, setSelectedLob] = useState<String>("Accounts");   
    const [allProducts, setAllProducts] = useState<any>({});
    const [allProductsisloaded, setAllProductsisloaded] = useState<Boolean>(false);
    const [selProductOptions, setSelProductOptions] = useState<Array<any>>([]);
    const [selProductOptionsisloaded, setSelProductOptionsisloaded] = useState<Boolean>(false);
    const [openProductDropdown, setOpenProductDropdown] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<String>("Select Product");    
    const fundingTypeOptions = ["Gross", "Net"];
    const [openFundingTypeDropdown, setOpenFundingTypeDropdown] = useState(false);
    const [selectedFundingType, setSelectedFundingType] = useState<String>("Gross");
    const [headCells, setHeadCells] = useState<Array<any>>([]);
    const [bodyCells, setBodyCells] = useState<Array<any>>([]);    
    const [dataCSV, setDataCSV]: any[] = useState([]);
    const [headerCSV, setHeaderCSV]: any[] = useState([]);
    const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);
    const [headCellsForAllProducts, setHeadCellsForAllProducts] = useState<Array<any>>([]);
    const [bodyCellsForAllProducts, setBodyCellsForAllProducts] = useState<Array<any>>([]); 

    const toggleLob = () => setOpenLobDropdown(prevState => !prevState);
    const toggleProduct = () => setOpenProductDropdown(prevState => !prevState);
    const toggleFundingType = () => setOpenFundingTypeDropdown(prevState => !prevState);
    const toggleExportDropdown = () => setExportDropdownOpen(prevState => !prevState);

    useEffect(() => {
        setBudgetResponseData([
          ...props.budgetPacingData
        ]); 
    }, [props.budgetPacingData]);
    
    useEffect(() => {
        //get all LOBs from form_lob table
        async function getLobOptions() {
            try {
                const response = await FormInfoService.retrieveDropdownOptions();
                let tempLob = Object.keys(response);
                setLobOptions(tempLob);
                setLobOptionsisloaded(true);
            } catch(ex){  
                sessionStorage.removeItem("authenticationState");
                sessionStorage.removeItem("tableConfig");
                window.location.reload(); 
            } 
        }
        getLobOptions();

        //get all products from form_lob table
        async function getAllProducts() {
            try {
                const response = await FormInfoService.retrieveProductOptions();
                setAllProducts(response);
                setAllProductsisloaded(true);
            } catch(ex){  
                sessionStorage.removeItem("authenticationState");
                sessionStorage.removeItem("tableConfig");
                window.location.reload(); 
            } 
        }
        getAllProducts();
    }, [budgetResponseData])

    useEffect(() => {
        if(selectedLob){
            let getProductsFromBudgetData = budgetResponseData.filter((obj) => obj.LOB == selectedLob);
            let productsArr = [];

            getProductsFromBudgetData.map((row) => {
                productsArr.push(row.products);
            })
            setSelProductOptions(productsArr);
            setSelProductOptionsisloaded(true);
            if(selProductOptionsisloaded) {
                setSelectedProduct(productsArr[0]);
            }
        } else {
            setSelProductOptions([]);
        }
    }, [selectedLob, allProductsisloaded])

    useEffect(() => {
        if(selProductOptionsisloaded) {
            generateBudgetTable('');
            generateBudgetTable('allProducts');
        }
    }, [selectedProduct, selectedFundingType])

    const handleLobChange = (e:any) => {
        setSelectedLob(e.innerHTML);
    }
    const handleProductChange = (e:any) => {
        setSelectedProduct(e.innerHTML);
    }
    const handleFundingTypeChange = (e:any) => {
        setSelectedFundingType(e.innerHTML);
    }

    const generateBudgetTable = (csvType) => {
        const current_date = new Date();
        let current_month = current_date.getMonth() + 1;
        let current_year = current_date.getFullYear();
        let h1_start_date;
        let h2_start_date;

        // Set time to 00:00:00 to compare only dates
        current_date.setHours(0, 0, 0, 0);

        if(current_month <= 4) {
            h1_start_date = new Date(current_year - 1, 10, 1);
            h1_start_date.setHours(0, 0, 0, 0);
        } else {
            h2_start_date = new Date(current_year, 4, 1);
            h2_start_date.setHours(0, 0, 0, 0);
        }
        let h1_end_date = new Date(current_year, 3, 30);
        let h2_end_date = new Date(current_year, 9, 31);

        h1_end_date.setHours(0, 0, 0, 0);
        h2_end_date.setHours(0, 0, 0, 0);

        let months_header;

        if(current_date >= h1_start_date && current_date <= h1_end_date) {
            months_header = [
                {key:"nov", label: "November"},
                {key:"dec", label: "December"},
                {key:"jan", label: "January"},
                {key:"feb", label: "February"},
                {key:"mar", label: "March"},
                {key:"apr", label: "April"}
            ];
        }

        if(current_date >= h2_start_date && current_date <= h2_end_date) {
            months_header = [
                {key:"may", label: "May"},
                {key:"jun", label: "June"},
                {key:"jul", label: "July"},
                {key:"aug", label: "August"},
                {key:"sep", label: "September"},
                {key:"oct", label: "October"}
            ];
        }
        let rowNames = [
            {key:"manual", label: "Manual"},
            {key:"actual", label: "Actual"},
            {key:"variance", label: "Variance"},
            {key:"budget_amount", label: "Budget Amount"}
        ];
        
        let originHeadCells = [
            {key: 'empty_cell',label: ''},
        ]
        originHeadCells.push(...months_header);
        originHeadCells.push({key:"total", label: "Total"});
        setHeadCells(originHeadCells);

        let allProductsHeadCells = [
            {key: 'empty_cell',label: ''},
            {key: "lob", label: "LOB"},
            {key: "product", label: "Product"}
        ];
        allProductsHeadCells.push(...months_header);
        allProductsHeadCells.push({key:"total", label: "Total"});
        setHeadCellsForAllProducts(allProductsHeadCells);

        let tableBody = [];
        rowNames.map((row_name) => {
            tableBody.push(buildRow(row_name, months_header));
        });
        setBodyCells(tableBody);  

        let rowNamesForAllProducts = [
            {key:"product_row", label: "Product Row"},
            {key:"manual", label: "Manual"},
            {key:"actual", label: "Actual"},
            {key:"variance", label: "Variance"}
        ];
        if(csvType == 'allProducts') {
            let tableBodyForAllProducts = [];
            budgetResponseData.forEach((row_data) => {
                rowNamesForAllProducts.map((row_name) => {
                    tableBodyForAllProducts.push(buildRowForAllProducts(row_name, months_header, row_data, selectedFundingType));
                });
            });
            setBodyCellsForAllProducts(tableBodyForAllProducts);
        }              
    }

    const buildRow = (row_name, months) => {
        let row = [];
        let rowTotal = 0;
        let cellValue = 0;
        const row_data = budgetResponseData.find(row => row.LOB == selectedLob && row.products == selectedProduct);
        row.push(selectedFundingType + " " + row_name.label);
        if(row_name.key == 'budget_amount') {
            cellValue = getCellValue(String(selectedFundingType), null, row_name.key, row_data);
            row.push(formatter.format((cellValue)));
        } else {
            months.map((month) => {
                cellValue = getCellValue(String(selectedFundingType), String(month.label), row_name.key, row_data);
                rowTotal += cellValue;
                row.push(formatter.format((cellValue)));
            });
            row.push(formatter.format((rowTotal)));
        } 
        return row;   
    }

    const getCellValue = (funding_type, month, row_name, row_data) => {
        if(row_name == 'variance') {
            let manualKey = funding_type.toLowerCase() + '_' + month.toLowerCase() + '_' + 'manual';
            let actualKey = funding_type.toLowerCase() + '_' + month.toLowerCase() + '_' + 'actual';
            return row_data[manualKey] - row_data[actualKey];
        }

        let key;
        if(month != null) {
            key = funding_type.toLowerCase() + '_' + month.toLowerCase() + '_' + row_name;
        } else {
            key = funding_type.toLowerCase() + '_' + row_name;
        }
        return row_data[key];
    }

    const calculateColspan = (rowLength, rowIndex) => {
        return rowLength == 2 && rowIndex == 1 ? 7 : 1;
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2, 
        minimumFractionDigits: 2, 
    })

    const handleCSV = () => {
        let headers = [];        
        headCells.map((obj) => {
            headers.push(obj.label);
        })
        setHeaderCSV(headers);
        setDataCSV(bodyCells);
        setTimeout(() => {
            toggleExportDropdown(); // Close the dropdown after export
        }, 100);
    }

    const buildRowForAllProducts = (row_name, months, row_data, selectedFundType) => {
        let row = [];
        let rowTotal = 0;
        let cellValue = 0;
        if(row_name.key == 'product_row') {
            row.push(' ');
            row.push(row_data['LOB']);
            row.push(row_data['products']);
            months.map((_) => {
                row.push(' ');
            });
            row.push(' ');
        } else {
            row.push(selectedFundType + " " + row_name.label);
            row.push(' ');
            row.push(' ');
            months.map((month) => {
                cellValue = getCellValue(String(selectedFundType), String(month.label), row_name.key, row_data);
                rowTotal += cellValue;
                row.push(formatter.format((cellValue)));
            });
            row.push(formatter.format((rowTotal)));
        }
        return row;   
    }

    const handleAllCSV = () => {
        generateBudgetTable('allProducts');
        let headers = [];        
        headCellsForAllProducts.map((obj) => {
            headers.push(obj.label);
        })
        setHeaderCSV(headers);
        setDataCSV(bodyCellsForAllProducts);
        setTimeout(() => {
            toggleExportDropdown(); // Close the dropdown after export
        }, 100);
    }

    return (
        <>
            {lobOptionsisloaded && selProductOptionsisloaded ?
                <Box sx={{ display:'flex', justifyContent:"space-between"}}>
                    <div style={{display:"flex", justifyContent:"flex-start"}}>
                        <Dropdown isOpen={openLobDropdown} toggle={toggleLob} style={{ marginBottom:"2.5rem"}}>
                            <DropdownToggle caret color="primary">
                                {selectedLob}
                            </DropdownToggle>
                            <DropdownMenu>
                                {lobOptions.map((item,index) => 
                                    <DropdownItem key={index} onClick={(e) => handleLobChange(e.target)}>{item}</DropdownItem>)
                                }         
                            </DropdownMenu>
                        </Dropdown>

                        <Dropdown isOpen={openProductDropdown} toggle={toggleProduct} style={{ marginLeft: "20px", marginBottom:"2.5rem"}}>
                            <DropdownToggle caret color="primary">
                                {selectedProduct}
                            </DropdownToggle>
                            <DropdownMenu>
                                {selProductOptions.map((item,index) => 
                                    <DropdownItem key={index} onClick={(e) => handleProductChange(e.target)}>{item}</DropdownItem>)
                                }         
                            </DropdownMenu>
                        </Dropdown>

                        <Dropdown isOpen={openFundingTypeDropdown} toggle={toggleFundingType} style={{ marginLeft: "20px", marginBottom:"2.5rem"}}>
                            <DropdownToggle caret color="primary">
                                {selectedFundingType}
                            </DropdownToggle>
                            <DropdownMenu>
                                {fundingTypeOptions.map((item,index) => 
                                    <DropdownItem key={index} onClick={(e) => handleFundingTypeChange(e.target)}>{item}</DropdownItem>)
                                }         
                            </DropdownMenu>
                        </Dropdown>                        
                    </div>
                    <div style={{display:"flex", justifyContent:"flex-end"}}>
                        <Dropdown isOpen={isExportDropdownOpen} toggle={toggleExportDropdown}>
                            <DropdownToggle caret color="primary">Export</DropdownToggle>
                            <DropdownMenu style={{right: "-5px", left: "auto", top: "0", position: "absolute", transform: "translate3d(-5px, 38px, 0px)", willChange: "transform"}}>
                                <CSVLink 
                                    data={dataCSV} 
                                    headers={headerCSV} 
                                    filename={"Budget Pacing.csv"}
                                    // onClick={(event)=>handleCSV(event)}
                                    onMouseUp={handleCSV}
                                    className="dropdown-item"
                                >
                                Export to CSV
                                </CSVLink>
                                <CSVLink 
                                    data={dataCSV} 
                                    headers={headerCSV} 
                                    filename={"Budget Pacing.csv"}
                                    // onClick={(event)=>handleAllCSV(event)}
                                    onMouseUp={handleAllCSV}
                                    className="dropdown-item"
                                >
                                Export All to CSV
                                </CSVLink>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </Box>
            : null}
            <DashboardTable bordered>
                <thead>
                    <tr style={{backgroundColor: "rgb(0, 106, 195)", color: "#ffffff"}}>
                    {headCells.map((headCell,index) => (
                        <th key={headCell.key} style={index == 0 ? {width:"20%"} : {}}>{headCell.label}</th>
                    ))}
                    </tr>
                </thead>
                <tbody>
                    {
                        bodyCells.map((row,index) => (
                            <tr key={index}>
                                {row.map((cell,index) => (
                                    <td key={index} colSpan={calculateColspan(row.length, index)} 
                                    style={row.length == 2 && index == 0 ? {fontWeight:"bold", backgroundColor: "gainsboro"} 
                                            : row.length == 2 && index == 1 ? {textAlign: "center", fontWeight:"bold", backgroundColor: "gainsboro"}
                                            : index == 7 ? {fontWeight:"bold"}
                                            : {textAlign: "left"}}>{cell}</td>
                                ))}
                            </tr>
                        ))
                    }
                </tbody>
            </DashboardTable>
        </>
    )
}

export default BudgetPacingDashboard;