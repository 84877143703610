import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';

import { keys, values } from 'lodash';

import { DropdownWithInput } from './DropdownWithInput';

import { AnchorCidGridDropdown, ChangeLogModal , ChannelDropdown, GridInput} from '../../../Common';
import { getSelectedLob } from '../../../State/Lob/Actions/LobActions';
import { getFormInfoState } from '../../../State/FormInfo/Selectors/FormInfoSelectors';
import { setSelectedLob } from '../../../State/MpaFormContext/Actions/MpaFormContextActions';
import { getChannelsForSelectedProduct } from '../../../State/FormInfo/Actions/FormInfoActions';
import history from '../../../Utils/History';
import ChannelSummary from './ChannelSummary';
import { cloneDeep } from 'lodash';

import { ChannelFieldInterface } from '../../../Models/ChannelFieldInterface';

const LobForm = (props: any) => {
  const [productOptions, setProductOptions] = useState<Array<any>>([]);
  const [anchorCidChanged, setAnchorCidChanged] = useState<Array<any>>([0, false]);  
  const lobOptions = Object.keys(props.lobDropdownOptions);

  const formInfoState = useSelector(getFormInfoState);
  const dispatch = useDispatch();

  const handleInputFieldChange = (event: any, type: string) => {
    props.setLobData({
        ...props.lobData,
        [type]: event.target.value
      });
  };

  // Anchor_Cid priority for SEM Channel: 
  // 1 google_cid, 
  // 2 bing_cid 
  // 3 apple_cid 
  // useEffect(() => {
  //   // props.setLobData({ ...props.lobData, anchor_cid: prioritizeAnchorCid(props.channelList)}); 
  //   props.setChannelList(prioritizeAnchorCid(props.channelList, anchorCidChanged)); 
  // }, [anchorCidChanged])

  // Fill Anchor CID value according to defined priority for each channel
  // function prioritizeAnchorCid(channelList, anchorCidChanged) {
  //   const newChannelList = channelList.map((channel, index) => {
  //     let anchor_cid = ''
  //     if (index != anchorCidChanged[0]) {
  //       return channel;
  //     } else {
  //       if (channel.channel === 'SEM') {
  //         anchor_cid = channel.google_cid ? channel.google_cid 
  //               : channel.bing_cid ? channel.bing_cid 
  //               : channel.apple_cid ? channel.apple_cid 
  //               : '';
  //       }
  //       return {
  //         ...channel,
  //         anchor_cid, 
  //       }
  //     }
  //   });
  //   return newChannelList;
  // }

  const handleLobChange = (event: any) => {
    const selectedLob = event.target.value;

    // form reset
    if(props.lobData.name !== selectedLob){
      props.setLobData({ 
        ...props.lobData, 
        name: selectedLob,
        channel:            "",
        name_abbr:          "",
        products:           "",        
        partner_google:     "",
        partner_bing:       "",
        anchor_cid:         "",
        // google_cid:         "",
        // bing_cid:           "",
        // apple_cid:          "",
        campaign:           "",
        // kenshoo_profile:    "",
        // kenshoo_token:      "",
      });

      props.setChannelList([{
        channel: "",
        anchor_cid: "",
        // bing_cid: "",
        // apple_cid: "",
        // google_cid: "",
        campaign: "",
        lob_id: 0
      }]);
    } 
    
    // dispatch an action to get lob_abbr if a new lob is selected
    if(selectedLob !== "" && selectedLob !== props.lobData.name) {
      dispatch(setSelectedLob(selectedLob))
    }
  };
  
  // set lob_abbr on Add New Lob Form (fromInfoState- newMpaNumber = {'name_abbr' : value, 'new_mpa_number': value})
  useEffect(() => {
    if(props.lobData.name){
      props.setLobData({...props.lobData, name_abbr: formInfoState.newMpaNumber.name_abbr});
    }
  }, [formInfoState.newMpaNumber])

  const handleChannelChange = (event: any, channelIndex: number) => {
    const selectedChannel = event.target.value;
    const newChannelList = [...props.channelList].map((object: any, index: number) => {
      if (index === channelIndex) {
        return {
          ...object,
          channel: selectedChannel
        };
      } else {
        return object;
      }
    });
    props.setChannelList(newChannelList);

    // form reset
    // if(props.lobData.channel !== selectedChannel){
      /*props.setLobData({
        ...props.lobData, 
        channel: selectedChannel,
        // products:           "",
        // campaign:           "",
        // partner_google:     "",
        // partner_bing:       "",
        // anchor_cid:         "",
        // google_cid:         "",
        // bing_cid:           "",
        // apple_cid:          "",
        // kenshoo_profile:    "",
        // kenshoo_token:      "",
      });*/      
    // }
  };

  // const handleGoogleCidChange = (event: any, channelIndex: number) => {
  //   props.setChannelList(
  //     [...props.channelList].map((object, index) => {
  //       if(index === channelIndex) {
  //         // setReset(true);
  //         return {
  //           ...object,
  //           google_cid: event.target.value,
  //         }
  //       } else {
  //         return object;
  //       }
  //     })
  //   );
  //   setAnchorCidChanged([channelIndex, !anchorCidChanged[1]]);
  // };

  // const handleBingCidChange = (event: any, channelIndex: number) => {
  //   props.setChannelList(
  //     [...props.channelList].map((object, index) => {
  //       if(index === channelIndex) {
  //         // setReset(true);
  //         return {
  //           ...object,
  //           bing_cid: event.target.value,
  //           // anchor_cid: object.google_cid ? object.google_cid : event.target.value ? event.target.value : ''
  //         }
  //       } else {
  //         return object;
  //       }
  //     })
  //   );
  //   setAnchorCidChanged([channelIndex, !anchorCidChanged[1]]);
  // };

  // const handleAppleCidChange = (event: any, channelIndex: number) => {
  //   props.setChannelList(
  //     [...props.channelList].map((object, index) => {
  //       if(index === channelIndex) {
  //         // setReset(true);
  //         return {
  //           ...object,
  //           apple_cid: event.target.value
  //         }
  //       } else {
  //         return object;
  //       }
  //     })
  //   );
  //   setAnchorCidChanged([channelIndex, !anchorCidChanged[1]]);
  // };

  const handleCidChange = (event: any, channelIndex: number) => {    
    props.setChannelList(
      [...props.channelList].map((object, index) => {
        if(index === channelIndex) {
          // setReset(true);
          return {
            ...object,
            anchor_cid: event.target.value
          }
        } else {
          return object;
        }
      })
    );
  };

  const handleCampaignChange = (event: any, channelIndex: number) => {
    props.setChannelList(
      [...props.channelList].map((object, index) => {
        if(index === channelIndex) {
          // setReset(true);
          return {
            ...object,
            campaign: event.target.value
          }
        } else {
          return object;
        }
      })
    );
  };

  useEffect(() => {
    // set product options
    const checkLob = lobOptions.includes(props.lobData.name);    

    if(props.lobData.name){
      // only set productOptions if an existing lob is selected
      if(checkLob){
        const productsOptions = props.lobDropdownOptions[props.lobData.name];
        setProductOptions(productsOptions);
      } else {
        setProductOptions([]);
      }
    }      
  }, [props.lobData.name, props.lobData.channel, props.lobData.products])

  // check if a budget_sharing_flag is valid/unique across Line of Businesses(lobData.name)
  // useEffect(() => {
  //   const allNames = keys(props.lobDropdownOptions)
  //   // get all lobs except the one selected
  //   const filteredNames = allNames.filter(name => !props.lobData.name.includes(name))
  //   const getFlags = filteredNames.map((item: any) => values(props.lobDropdownOptions[item][0]['SEM'][0]).flat());
  //   const checkBudgetSharingFlagNotNull = props.lobData.budget_sharing_flag !== "";
  //   const checkBudgetSharingFlag = getFlags.flat().includes(props.lobData.budget_sharing_flag);

  //   if(checkBudgetSharingFlagNotNull && checkBudgetSharingFlag){
  //     props.setBudgetSharingFlagInvalid(true);
  //   }else{
  //     props.setBudgetSharingFlagInvalid(false);
  //   }
  // }, [props.lobData.budget_sharing_flag])

  const handleProductsChange = (event: any) => {
    const selectedProduct = event.target.value;

    // form reset
    if(props.lobData.products !== selectedProduct){
      props.setLobData({ 
        ...props.lobData, 
        products: selectedProduct,        
        partner_google:     "",
        partner_bing:       "",
        anchor_cid:         "",
        // google_cid:         "",
        // bing_cid:           "",
        // apple_cid:          "",
        campaign:           "",
        // kenshoo_profile:    "",
        // kenshoo_token:      "",
      });  
    } 

    props.setChannelList([{
      channel:       "",
      anchor_cid:    "",
      // bing_cid:      "",
      // apple_cid:     "",
      // google_cid:    "",
      campaign:      "",
      lob_id:        0
    }]);

    if(lobOptions.includes(props.lobData.name)) {
      if(selectedProduct != '' && props.lobDropdownOptions[props.lobData.name].includes(selectedProduct)) {
        dispatch(getChannelsForSelectedProduct(selectedProduct));      
      }
    }
  };

  useEffect(() => {    
    if(props.lobData.name){
      // set multiple channels data to save it in database on add lob form
      props.setSelectedProductChannelList(formInfoState.selectedProductChannelList['channel_data']);

      // populate multiple channel fields on add lob form for selected Product
      let allChannelData = formInfoState.selectedProductChannelList['channel_data'];
      if(allChannelData) {
        let retrievedChannelList: ChannelFieldInterface[] = [];
        for (let channel of allChannelData) {
          let temp: ChannelFieldInterface = {
            channel:        channel.channel,
            anchor_cid:     channel.anchor_cid,
            // bing_cid:       channel.bing_cid,
            // apple_cid:      channel.apple_cid,
            // google_cid:     channel.google_cid,
            campaign:       channel.campaign,
            lob_id:         channel.id
          }
          retrievedChannelList.push(temp);
        }
        props.setChannelList(retrievedChannelList);
      }
    }
  },[formInfoState.selectedProductChannelList])

  const handleAddChannelToList = () => {
    // props.handleCidDropdownList(props.mpaIntake.client_business_unit, props.mpaIntake.channel, props.mpaIntake.products);

    const newRow = {
      channel:       "",
      anchor_cid:    "",
      // bing_cid:      "",
      // apple_cid:     "",
      // google_cid:    "",
      campaign:      "",
      lob_id:        0
    };

    props.setChannelList([...props.channelList, newRow]);
  };

  const handleRemoveChannelFromList = (event: any, channelIndex: number) => {    
    if(props.channelList[channelIndex].lob_id !== 0 && props.channelsToRemove !== undefined) {
      props.setChannelsToRemove([...props.channelsToRemove, props.channelList[channelIndex].lob_id]);
    }

    let updatedChannelList = [...props.channelList];
    updatedChannelList.splice(channelIndex, 1);
    props.setChannelList(updatedChannelList);
  };
  
  return(
    <Container fluid={true}>
      <Row>
        <DropdownWithInput
          gridSize={3}
          inputAria="lob-abb-text"
          inputId="lob-abb-input"
          inputLabel='LOB:'
          inputType='name'
          dropdownOptions={lobOptions}
          value={props.lobData.name}
          handleChange={handleLobChange}
          isDisabled={props.isDisabled}
        />             
        <DropdownWithInput
          gridSize={2}
          inputAria="products-text"
          inputId="products-input"
          inputLabel='Products:'
          inputType='products'
          dropdownOptions={productOptions}
          value={props.lobData.products}
          handleChange={handleProductsChange}
          isDisabled={props.isDisabled}
        />
        <GridInput
          gridSize={2}
          inputAria="lob-abb-text"
          inputId="lob-abb-input"
          inputLabel="Abbreviation:"
          inputType="string"
          value={props.lobData.name_abbr? props.lobData.name_abbr: ""}
          handleChange={(e: any) => handleInputFieldChange(e, "name_abbr")}
        />  
      </Row>
      <Row>
                
        {/* <AnchorCidGridDropdown
          anchorCidOptions={Object.keys(formInfoState.anchorCids)}
          isDisabled={true}
          inputLabel="Anchor CID:"
          gridSize={2}
          anchorCid={props.lobData.anchor_cid}
        />
        <GridInput
          gridSize={2}
          inputAria="campaign-text"
          inputId="campaign-input"
          inputLabel="Campaign:"
          inputType="string"
          value={props.lobData.campaign}
          handleChange={(e: any) => handleInputFieldChange(e, "campaign")}
          required={true}
        /> */}
      </Row>
      <ChannelSummary
        // channelOptions={["SEM", "Facebook", "Pinterest", "Bell", "TikTok"]}
        channelOptions={props.channelDropdownOptions}
        channelList={props.channelList}
        isDisabled={props.isDisabled}
        channel={props.lobData.channel}
        handleAddChannelToList={handleAddChannelToList}
        handleChannelChange={handleChannelChange}
        // handleGoogleCidChange={handleGoogleCidChange}
        // handleBingCidChange={handleBingCidChange}
        // handleAppleCidChange={handleAppleCidChange}
        // handleFacebookCidChange={handleFacebookCidChange}
        // handlePinterestCidChange={handlePinterestCidChange}
        // handleBellCidChange={handleBellCidChange}
        // handleTiktokCidChange={handleTiktokCidChange}
        handleCampaignChange={handleCampaignChange}
        handleRemoveChannelFromList={handleRemoveChannelFromList}
        handleCidChange={handleCidChange}
      />
      {/* <Row>
        <GridInput
          gridSize={3}
          inputAria="kenshoo-profile-text"
          inputId="kenshoo-profile-input"
          inputLabel="Kenshoo Profile"
          inputType="string"
          value={props.lobData.kenshoo_profile? props.lobData.kenshoo_profile: ""}
          handleChange={(e: any) => handleInputFieldChange(e, "kenshoo_profile")}
        />
        <GridInput
          gridSize={6}
          inputAria="kenshoo-token-text"
          inputId="kenshoo-token-input"
          inputLabel="Kenshoo Token"
          inputType="string"
          value={props.lobData.kenshoo_token? props.lobData.kenshoo_token: ""}
          handleChange={(e: any) => handleInputFieldChange(e, "kenshoo_token")}
        />
      </Row> */}
      
      <Row>
        <Col xs={12}>
          <Button
            color="warning"
            size="md"
            onClick={()=>{history.push(`/map/lob`,'/map/mpa')}}
            style={{'marginRight': '16px', 'marginBottom': '16px'}}
          >
              Return to LOB Form
          </Button>{' '}
          <ChangeLogModal
            logsData={props.auditLogs}
          />
          {/* <Button
            color="primary"
            size="md"
            onClick={()=>{history.push(`/map/lob/add`,'/map/mpa')}}
            disabled
            style={{'marginRight': '16px', 'marginBottom': '16px'}}
          >
              Add LOB
          </Button> */}
          <Button
            color="primary"
            size="md"
            onClick={(event: any) => props.onSave(event, props.openUpdateConfirmationModal, props.lobData.id)}
            disabled={props.isSubmitDisabled}
            style={{'marginRight': '16px', 'marginBottom': '16px'}}
          >
              Save
          </Button>
        </Col>
      </Row>
    </Container>
  )
};

export default LobForm;
