import React from 'react';
import { Col, Container, Row, NavLink } from 'reactstrap';
import LoginButton from '../../Auth/LoginButton';
import history from '../../Utils/History';

const LoginContainer = () => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} style={{ paddingLeft: '0', paddingRight: '0' }}>
          <div className="hero-image">
            <Col xs={8} className="signin-btn">
              <LoginButton />
            </Col>
            <div className="privacy-link-div">
              <NavLink
                className="privacy-link"
                onClick={() => history.push('/privacy')}>
                Privacy Policy
              </NavLink>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginContainer;
