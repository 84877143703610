import React from "react";
import PropTypes from "prop-types";

import { Col, FormGroup, Input, Label, FormFeedback } from "reactstrap";

export const GridInput = (props: any) => {
  return (
    <Col xs={props.gridSize} style={props.elemStyle}>
      <FormGroup>
        {props.required ? (
          <React.Fragment>
            <Label>{props.inputLabel}</Label>
            <Label style={{ color: "red" }}>*</Label>
            {props.labelExtra}
          </React.Fragment>
        ) : (
          <>
            <Label>{props.inputLabel}</Label>
            {props.labelExtra}
          </>
        )}
        <Input
          id={props.inputId}
          invalid={props.invalid}
          aria-describedby={props.inputAria}
          type={props.inputType}
          disabled={props.isDisabled}
          onChange={!props.isReadOnly ? props.handleChange : () => {}}
          readOnly={props.isReadOnly}
          placeholder={props.placeholder}
          value={props.value}
          onKeyUp={props.onKeyUp}
        />
        {props.invalid ? (
          <FormFeedback>Oh noes! that name is already taken</FormFeedback>
        ) : (
          ""
        )}
      </FormGroup>
    </Col>
  );
};

GridInput.propTypes = {
  gridSize: PropTypes.number,
  invalid: PropTypes.bool,
  handleChange: PropTypes.func,
  inputAria: PropTypes.string,
  inputId: PropTypes.string,
  inputLabel: PropTypes.string,
  inputType: PropTypes.oneOf(["number", "string"]),
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  value: PropTypes.any,
  required: PropTypes.bool,
  elemStyle: PropTypes.any,
  labelExtra: PropTypes.element,
  onKeyUp: PropTypes.func
};
