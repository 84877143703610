import React, {useEffect} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const MandatoryFieldMpaModal = (props) => {
  const toggle = () => props.setModal(!props.modal);

  const fieldRequiredMpa = {
                            "LOB": props.mpaIntake.client_business_unit,
                            // "Channel": props.mpaIntake.channel,
                            "Products": props.mpaIntake.products,
                            "Phase Number": props.mpaIntake.phase_number,
                            "MPA Status": props.mpaIntake.mpa_status,
                            "Prepared For": props.mpaIntake.prepared_for,
                            "INI MPA#": props.mpaIntake.legacy_mpa 
                            }
  let checkerFlagMpa = false;
  let checkerFlagCid = true;
  var loopMpaData = 'The following fields are required:\n'
  let counterVal = 1
  let counterMPAVal = 0
  let checkerFlagMonthsFunds = true;

  for(let reqItems in fieldRequiredMpa){
    if(fieldRequiredMpa[reqItems] === ""){
      loopMpaData += counterVal + ". " + reqItems +'\n'
      counterVal++;
    }
    else{
      counterMPAVal++;
    }
  }

  if(Object.keys(fieldRequiredMpa).length === counterMPAVal){
    checkerFlagMpa = true
  }else{
    checkerFlagMpa = false
  }

  // check for empty fields in each CID row
  let requiredFields = [];
  for (let cidIndex = 0; cidIndex < props.cidList.length; cidIndex++) {
    let cidElement = props.cidList[cidIndex];
    
    if(cidElement.channel === '' && !requiredFields.includes('Channel')) {
      requiredFields.push('Channel');
    } 
    if(cidElement.anchor_cid === '' && !requiredFields.includes('Anchor CID')) {
      requiredFields.push('Anchor CID');
    } 
    // if((cidElement.working_funds === '' || cidElement.working_funds === 0) && !requiredFields.includes('Working Funds')) {
    //   requiredFields.push('Working Funds');
    // }
    if(cidElement.campaign_name === '' && !requiredFields.includes('Campaign')) {
      requiredFields.push('Campaign');
    }
  }
  
  if(requiredFields.length > 0) {
    for (let index in requiredFields) {
      loopMpaData += counterVal + ". " + requiredFields[index] +'\n'
      counterVal++;
    }
    checkerFlagCid = false;
  } else {
    checkerFlagCid = true;
  }

  let monthsFundsTotal = [];

  if(props.fromAddMPA) {
    for (let cidIndex = 0; cidIndex < props.cidList.length; cidIndex++) {
      let cidElement = props.cidList[cidIndex];
      let monthsTotal = cidElement.november + cidElement.december + cidElement.january + cidElement.february + cidElement.march + cidElement.april + cidElement.may + cidElement.june + cidElement.july + cidElement.august + cidElement.september + cidElement.october;
      if(monthsTotal.toFixed(1) != (cidElement.working_funds).toFixed(1) && !monthsFundsTotal.includes(cidElement.anchor_cid)) {
        monthsFundsTotal.push({'channel': cidElement.channel + ", CID: " + cidElement.anchor_cid, 'months_total': monthsTotal.toFixed(1), 'working_funds': cidElement.working_funds.toFixed(1)});
      }
    }
  }

  if(monthsFundsTotal.length > 0) {
    if(checkerFlagMpa === false || checkerFlagCid === false) {
    } else {
      var loopMpaData = "";
    }    
    for (let index in monthsFundsTotal) {      
      loopMpaData += counterVal + ". The sum of all months fields for channel: " + monthsFundsTotal[index].channel +' is $' + monthsFundsTotal[index].months_total +' which is not matching the Working Funds amount $' + monthsFundsTotal[index].working_funds +'\n'
      counterVal++;
    }
    checkerFlagMonthsFunds = false;
  } else {
    checkerFlagMonthsFunds = true;
  }

  if(checkerFlagMpa === true && checkerFlagCid === true && checkerFlagMonthsFunds === true){
    props.setMandatoryModal(true);
  } else {
    props.setMandatoryModal(false);
  }

  return (
    <div>
      <Modal isOpen={props.modal} toggle={toggle} size="xl" style={{marginTop: "5%"}}>
        <ModalHeader toggle={toggle}>Form Error</ModalHeader>
        <ModalBody >
          <span style={{whiteSpace:'pre-line'}}> {loopMpaData} </span>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}