import { GridInput } from 'Common';
import React, { useMemo, useState } from 'react';

import {
  IMediaCalculatorObj,
  formattedMultiplication,
  formattedNumber,
  initialMediaCalculatorObj,
} from '../formulation';
import { Col, Row } from 'reactstrap';
import CSVButton from '../CSVButton';
import ValidInput from '../ValidInput';

const gridSize = 3;

const BudgetEstimatorOnReach = (): JSX.Element => {
  const [reach, setReach] = useState<IMediaCalculatorObj>(
    initialMediaCalculatorObj
  );
  const [avgCPM, setAvgCPM] = useState<IMediaCalculatorObj>(
    initialMediaCalculatorObj
  );
  const [estReactPercent, setEstReachPercent] = useState<IMediaCalculatorObj>(
    initialMediaCalculatorObj
  );
  const [avgFrequencyForCampaignPeriod, setAvgFrequencyForCampaignPeriod] =
    useState<IMediaCalculatorObj>(initialMediaCalculatorObj);

  const formatPercent = (value: number | null): IMediaCalculatorObj => {
    return { value: value, formattedString: formattedNumber(value) + '%' };
  };

  const estimated = useMemo(() => {
    const budget = formattedMultiplication(
      reach.value,
      avgCPM.value,
      estReactPercent.value / 100,
      avgFrequencyForCampaignPeriod.value,
      0.001
    );

    const gross = formattedMultiplication(budget.value, 1.1752);

    return {
      budget,
      gross,
      csvContent: [
        ['Reach', reach.formattedString],
        ['Avg. CPM', avgCPM.formattedString],
        ['Est. Reach%', estReactPercent.formattedString],
        [
          'Avg. Frequency for Campaign Period',
          avgFrequencyForCampaignPeriod.formattedString,
        ],
        ['Estimated Budget for Campaign Period', budget.formattedString],
        ['Estimated Gross', gross.formattedString],
      ],
    };
  }, [reach, avgCPM, estReactPercent, avgFrequencyForCampaignPeriod]);
  return (
    <Row>
      <Col xs={12}>
        <h3>
          Budget Estimator On Reach
          <CSVButton
            filename="Budget_Estimator_On_Reach"
            content={estimated.csvContent}
          />
        </h3>
      </Col>
      <ValidInput
        values={reach}
        onChange={setReach}
        label="Reach:"
        isWholeNumber
        isCurrency={false}
      />
      <ValidInput values={avgCPM} onChange={setAvgCPM} label="Avg. CPM:" />
      <ValidInput
        values={estReactPercent}
        onChange={setEstReachPercent}
        label="Est. Reach %:"
        isCurrency={false}
        otherFormat={formatPercent}
      />

      <ValidInput
        values={avgFrequencyForCampaignPeriod}
        onChange={setAvgFrequencyForCampaignPeriod}
        label="Avg, Frequency for Campaign Period:"
        isCurrency={false}
      />

      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="est-budget-for-campaign-period"
        inputId="est-budget-for-campaign-period-input"
        inputLabel="Estimated Budget for Campaign Period:"
        value={estimated.budget.formattedString}
      />
      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="est-gross"
        inputId="est-gross-input"
        inputLabel="Estimated Gross:"
        value={estimated.gross.formattedString}
      />
    </Row>
  );
};

export default BudgetEstimatorOnReach;
