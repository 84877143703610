import React from 'react';
import PropTypes from 'prop-types';

import { BUDGET_ORDER_ATTRIBUTE_MAP } from '../Models/BudgetOrderAttributeMap'

import { EditableTableCell } from './EditableTableCell';
import { ReadOnlyTableCell } from './ReadOnlyTableCell';
import { BudgetOrdersInterface } from '../Models/BudgetOrders';

const createTableRowElement = (
  budgetOrder: BudgetOrdersInterface,
  handleContentEditableBlur: (event: any) => void,
  handleContentEditableChange: (event: any) => void,
  handleContentEditableFocus: () => void,
  handleContentEditableKeyPress: (event: any) => void,
) => {
  return function (attribute: string) {
    if (BUDGET_ORDER_ATTRIBUTE_MAP[attribute]['isEditable']) {
      return (
        <EditableTableCell
          attribute={attribute}
          budgetOrder={budgetOrder}
          key={attribute}
          handleContentEditableChange={handleContentEditableChange}
          handleContentEditableBlur={handleContentEditableBlur}
          handleContentEditableFocus={handleContentEditableFocus}
          handleContentEditableKeyPress={handleContentEditableKeyPress}
        />
      );
    } else {
      return (
        <ReadOnlyTableCell
          attribute={attribute}
          budgetOrder={budgetOrder}
          key={attribute}
        />
      );
    }
  }
};

interface Props {
  budgetOrder: BudgetOrdersInterface,
  handleContentEditableBlur: (event: any) => void,
  handleContentEditableChange: (event: any) => void,
  handleContentEditableFocus: () => void,
  handleContentEditableKeyPress: (event: any) => void,
  tableConfiguration: Array<string>,
}

export const TableRowWithEditableCells = (props: Props) => {
  const {
    budgetOrder,
    handleContentEditableBlur,
    handleContentEditableChange,
    handleContentEditableFocus,
    handleContentEditableKeyPress,
    tableConfiguration,
  } = props;

  return (
    <tr key={budgetOrder['clientID']}>
      {tableConfiguration.map(
        createTableRowElement(
          budgetOrder,
          handleContentEditableBlur,
          handleContentEditableChange,
          handleContentEditableFocus,
          handleContentEditableKeyPress,
        ),
        this
      )}
    </tr>
  );
};

TableRowWithEditableCells.defaultProps = {
  handleContentEditableBlur: () => {},
  handleContentEditableChange: () => {},
  handleContentEditableFocus: () => {},
  handleContentEditableKeyPress: () => {},
};

TableRowWithEditableCells.propTypes = {
  budgetOrder: PropTypes.object.isRequired,
  handleContentEditableBlur: PropTypes.func.isRequired,
  handleContentEditableChange: PropTypes.func.isRequired,
  handleContentEditableFocus: PropTypes.func.isRequired,
  handleContentEditableKeyPress: PropTypes.func.isRequired,
  tableConfiguration: PropTypes.array.isRequired,
};
