import { createDefaultLoadable, Loadable } from '../../../State/Common/Loadable';
import {BUDGET_ORDER_EDITING_MODE} from './BudgetOrderEditingContext';

export interface BudgetOrdersInterface {

  lineOfBusiness?: string,
  accountName?: string,
  category?: string,
  engine?: string,
  clientID?: string,
  firstDayOfLastWeek?: string,
  firstDayOfLastMonth?: string,
  weeksLeft?: string,
  suggestedTransferAmount?: number,
  newMpaBudgetRequired?: number,
  totalSpend?: number,
  budgetOrderId?: string,
  MPA_budget_all?: number,
  MPA_budget_category?: number,
  MPA_category?: string,
  mpaStatus?: string,
  pacingStatus?: string,
  expectedSpent?: number,
  forecastGap?: number,
  forecastGapPercent?: number,
  forecastSpend?: number,
  progress?: number,
  progressIndicator?: number,
  budgetRatio?: number,
  budgetSpentPercent?: number,
  darkDate?: string,
  projectedBudgetPercent?: number,
  spendLastMonth?: number,
  spendLastWeek?: number,
  timeLapsedPercent?: number,
  totalBudget?: number,
  totalRemaining?: number,
}

export interface BudgetOrdersState {
  entities: Array<BudgetOrdersInterface>,
  entitiesColumns: Array<string>,
  getBudgetOrdersLoadable: Loadable,
  getBudgetOrdersCsvLoadable: Loadable,
  updateBudgetOrdersLoadable: Loadable,
  clearBudgetOrdersLoadable: Loadable,
  publishBudgetOrdersLoadable: Loadable,
}

export const createDefaultBudgetOrdersState = (): BudgetOrdersState => {
  return {
    entities: [],
    entitiesColumns: [],
    getBudgetOrdersLoadable: createDefaultLoadable(),
    getBudgetOrdersCsvLoadable: createDefaultLoadable(),
    updateBudgetOrdersLoadable: createDefaultLoadable(),
    clearBudgetOrdersLoadable: createDefaultLoadable(),
    publishBudgetOrdersLoadable: createDefaultLoadable()
  }
};

export const buildBudgetOrders = (data: Array<BudgetOrdersInterface>) => {
  return data.map((d: BudgetOrdersInterface) => buildBudgetOrder(d));
};

export const buildBudgetOrder = (data: BudgetOrdersInterface) => {
  if(data['engine']){
    return Object.assign(data,
      {
        engine: data['engine'].replace('_', ' ')
      });
  }
  return data;
  
};

export const calculateNewBudgetOrderDeadlines = (budgetOrder: BudgetOrdersInterface, newTransferAmount: number) => {
  if (budgetOrder['spendLastWeek'] === 0) {
    return {newDarkDate: 'n/a', newWeeksLeft: 'n/a'};
  }

  const today = new Date();
  const newWeeksLeft = (budgetOrder['totalRemaining'] + newTransferAmount)/budgetOrder['spendLastWeek'];
  let newDarkDate = new Date();
  newDarkDate.setDate(today.getDate() + (newWeeksLeft * 7));

  return {
    newDarkDate: `${newDarkDate.getFullYear()}-${newDarkDate.getMonth() + 1}-${newDarkDate.getDate()}`,
    newWeeksLeft: newWeeksLeft.toFixed(2)
  }
};

export const getCorrectTransferAmount = (budgetOrder: BudgetOrdersInterface, budgetOrderEditingMode: string) => {
  switch (budgetOrderEditingMode) {
    case BUDGET_ORDER_EDITING_MODE.NO_SUGGESTIONS:
      return 0;
    case BUDGET_ORDER_EDITING_MODE.WITH_SUGGESTIONS:
      return budgetOrder['suggestedTransferAmount'];
    case BUDGET_ORDER_EDITING_MODE.REBALANCING:
      return budgetOrder['transferAmount'];
    default:
      return 0;
  }
};

export const getInitialTransferAmountTotal = (budgetOrderEditingMode: string) => {
  return function (accumulator: number, budgetOrder: BudgetOrdersInterface) {
    return accumulator + getCorrectTransferAmount(budgetOrder, budgetOrderEditingMode);
  };
};

export const buildBudgetOrdersForModification = (budgetOrderEditingMode: string) => {
  return function (budgetOrder: BudgetOrdersInterface) {
    const { newDarkDate, newWeeksLeft }: any = budgetOrderEditingMode === BUDGET_ORDER_EDITING_MODE.WITH_SUGGESTIONS
      ?	 calculateNewBudgetOrderDeadlines(budgetOrder, budgetOrder['suggestedTransferAmount'])
      : {};

    return {
      ...budgetOrder,
      commitBudget: budgetOrderEditingMode === BUDGET_ORDER_EDITING_MODE.WITH_SUGGESTIONS
        ? budgetOrder['totalBudget'] + getCorrectTransferAmount(budgetOrder, budgetOrderEditingMode)
        : budgetOrder['commitBudget'],
      commitDarkDate: newDarkDate
        ? newDarkDate
        : budgetOrder['commitDarkDate'],
      commitWeeksLeft: newWeeksLeft
        ? newWeeksLeft
        : budgetOrder['commitWeeksLeft'],
      transferAmount: getCorrectTransferAmount(budgetOrder, budgetOrderEditingMode)
    }
  };
};

export const getEditableColumns = (columns: Array<string>) => {
  const attributesForEditing = ['totalBudget', 'totalRemaining', 'commitBudget', 'weeksLeft',
    'commitWeeksLeft', 'darkDate', 'commitDarkDate', 'suggestedTransferAmount', 'transferAmount'
  ];

  const remainingColumns = [...columns].filter(column => !attributesForEditing.includes(column));

  return [...attributesForEditing, ...remainingColumns];
};

