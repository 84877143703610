import {
  GET_DAG_API_CONFIGS,
  GET_DAG_API_CONFIGS_ERROR,
  GET_DAG_API_CONFIGS_SUCCESS,
  GET_DAG_API_CONFIG_BY_ID,
  GET_DAG_API_CONFIG_BY_ID_SUCCESS,
  GET_DAG_API_CONFIG_DROPDOWN,
  GET_DAG_API_CONFIG_DROPDOWN_ERROR,
  GET_DAG_API_CONFIG_DROPDOWN_SUCCESS,
  UPDATE_DAG_API_CONFIG,
  UPDATE_DAG_API_CONFIG_ERROR,
  UPDATE_DAG_API_CONFIG_SUCCESS,
} from "../ActionTypes/DagApiConfigsActionsTypes";
import { IDagApiConfigDto } from "../Models/DagApiConfigsState";

export const getDagApiConfigs = () => {
  return {
    type: GET_DAG_API_CONFIGS,
  };
};

export const getDagApiConfigSuccess = (result: any) => {
  return {
    type: GET_DAG_API_CONFIGS_SUCCESS,
    result,
  };
};

export const getDagApiConfigError = (error: any) => {
  return {
    type: GET_DAG_API_CONFIGS_ERROR,
    error,
  };
};

export const getDagApiConfigById = (dagApiConfigId: any) => {
  return {
    type: GET_DAG_API_CONFIG_BY_ID,
    dagApiConfigId,
  };
};

export const getDagApiConfigByIdSuccess = (result: any) => {
  return {
    type: GET_DAG_API_CONFIG_BY_ID_SUCCESS,
    result,
  };
};

export const getDagApiConfigByIdError = (error: any) => {
  return {
    type: GET_DAG_API_CONFIGS_ERROR,
    error,
  };
};

export const updateDagApiConfig = (dagApiConfigId: any, payload: IDagApiConfigDto) => {
  return {
    type: UPDATE_DAG_API_CONFIG,
    dagApiConfigId,
    payload,
  };
};

export const updateDagApiConfigSuccess = (result: any) => {
  return {
    type: UPDATE_DAG_API_CONFIG_SUCCESS,
    result,
  };
};

export const updateDagApiConfigError = (error: any) => {
  return {
    type: UPDATE_DAG_API_CONFIG_ERROR,
    error,
  };
};
export const getDagApiConfigDropdown = (dagApiConfigId: any) => {
  return {
    type: GET_DAG_API_CONFIG_DROPDOWN,
    dagApiConfigId,
  };
};

export const getDagApiConfigDropdownSuccess = (result: any) => {
  return {
    type: GET_DAG_API_CONFIG_DROPDOWN_SUCCESS,
    result,
  };
};

export const getDagApiConfigDropdownError = (error: any) => {
  return {
    type: GET_DAG_API_CONFIG_DROPDOWN_ERROR,
    error,
  };
};
