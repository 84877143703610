import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const WorksheetsDropdown = ({
  worksheets,
  isDropdownOpen,
  onDropdownToggle,
  onSelect,
  selectedWorksheet
}) => {
  return (
    <Dropdown
      isOpen={isDropdownOpen}
      toggle={onDropdownToggle}
      className="mb-3"
    >
      <DropdownToggle caret color="primary">
        {selectedWorksheet !== undefined ? selectedWorksheet['title'] : 'Select Worksheet'}
      </DropdownToggle>
      <DropdownMenu>
        {worksheets.map((worksheet, index) => (
          <DropdownItem
            key={worksheet['id']}
            value={worksheet['id']}
            onClick={()=>onSelect({id:worksheet['id'], title: worksheet['title']})}
          >
            {worksheet['title']}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default WorksheetsDropdown;
