import React, { useEffect, useState } from 'react';
import {  Alert, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Box } from '@mui/material';
import EngineSelect from './EngineSelect';
import MonthSelect from './MonthSelect';
import QuarterSelect from './QuarterSelect';

const BudgetPacingMetrics = (props: any) => {
    const [lobOptions, setLobOptions] = useState<Array<any>>([{}]);
    const [lobOptionsisloaded, setLobOptionsisloaded] = useState<Boolean>(false);    
    const [selectedLob, setSelectedLob] = useState<String>();
    const [openLobDropdown, setOpenLobDropdown] = useState(false);
    const [productOptions, setProductOptions] = useState<Array<any>>([{}]);
    const [productOptionsisloaded, setProductOptionsisloaded] = useState<Boolean>(false); 
    const [selectedProduct, setSelectedProduct] = useState<String>("Select Product");
    const [openProductDropdown, setOpenProductDropdown] = useState(false);
    const fundingTypeOptions = ["Gross", "Net"];
    const [selectedFundingType, setSelectedFundingType] = useState<String>("Gross");
    const [openFundingTypeDropdown, setOpenFundingTypeDropdown] = useState(false);
    const [checkedGoogle, setCheckedGoogle] = useState(true);
    const [checkedBing, setCheckedBing] = useState(true);
    const [checkedFacebook, setCheckedFacebook] = useState(true);
    const [monthsList, setMonthsList] = useState<Array<any>>([]);
    const [checkedH1, setCheckedH1] = useState([11,12,1,2,3,4].includes(new Date().getMonth()+1)?true:false);
    const [checkedH2, setCheckedH2] = useState([5,6,7,8,9,10].includes(new Date().getMonth()+1)?true:false);
    const [headerCells, setHeaderCells] = useState<Array<any>>([]);
    const [bodyCells, setBodyCells] = useState<Array<any>>([]);
    const [monthCells, setMonthCells] = useState<Array<any>>([]);

    const toggleLob = () => setOpenLobDropdown(prevState => !prevState);
    const toggleProduct = () => setOpenProductDropdown(prevState => !prevState);
    const toggleFundingType = () => setOpenFundingTypeDropdown(prevState => !prevState);

    // get all lobs from the response data
    useEffect(() => {
        function getLobOptions() {
            let lobArr = [];
            let lobSet = new Set();

            props.budgetMetricsData.map((row) => {
                lobSet.add(row.lob);
            })
            lobArr = Array.from(lobSet);
            setLobOptions(lobArr);
            setLobOptionsisloaded(true);       
        }
        getLobOptions();

        function getMonthsList() {
            const months = [
                "November", 
                "December",
                "January", 
                "February", 
                "March", 
                "April", 
                "May", 
                "June", 
                "July", 
                "August", 
                "September", 
                "October"
            ];
            let monthsList = [];
            const current_month = new Date().toLocaleString('default', {month: 'long'});
            let checked = false;
            months.map((month) => {
                if(month === current_month){
                    checked = true;
                } else {
                    checked = false;
                }
                monthsList.push({checked:checked, name:month});
            })
            setMonthsList(monthsList);
        }
        getMonthsList();
    }, [props.budgetMetricsData])

    // set the first lob as selected once the lob dropdown is loaded
    useEffect(() => {
        if(lobOptionsisloaded) {
            setSelectedLob(lobOptions[0]);
        }  
    }, [lobOptionsisloaded])

    // get all products from the response data based on selected lob
    useEffect(() => {
        if(selectedLob){           
            let getProductsFromMetricsData = props.budgetMetricsData.filter((obj) => obj.lob == selectedLob);
            let productsArr = [];
            let productsSet = new Set();

            getProductsFromMetricsData.map((row) => {
                productsSet.add(row.products);
            })
            productsArr = Array.from(productsSet);
            setProductOptions(productsArr);
            setProductOptionsisloaded(true);
        } else {
            setProductOptions([]);
        }
    }, [selectedLob])

    // set the first product as selected once the product dropdown is loaded for the selected lob
    useEffect(() => {
        if(productOptionsisloaded) {
            setSelectedProduct(productOptions[0]);
        }
    }, [productOptionsisloaded])

    useEffect(() => {
        if(productOptionsisloaded) {
            setHeaderCells(buildHeader());
            setBodyCells(buildTable());

            setMonthCells(buildMonthTable());
        }
    }, [selectedProduct, selectedFundingType, checkedGoogle, checkedBing, checkedFacebook, monthsList, checkedH1, checkedH2])

    const handleLobChange = (e:any) => {
        setSelectedLob(e.innerHTML);
        setProductOptionsisloaded(false);
    }
    const handleProductChange = (e:any) => {
        setSelectedProduct(e.innerHTML);
    }
    const handleFundingTypeChange = (e:any) => {
        setSelectedFundingType(e.innerHTML);
    }
    const handleGoogleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(checkedBing || checkedFacebook) {
            setCheckedGoogle(event.target.checked);
        }
    };
    const handleBingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(checkedGoogle || checkedFacebook) {
            setCheckedBing(event.target.checked);
        }
    };
    const handleFacebookChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(checkedGoogle || checkedBing) {
            setCheckedFacebook(event.target.checked);
        }
    };
    const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempMonthList = monthsList.map(month=>{return {...month}});
        tempMonthList.map((month) => {
            if(month['name'] === event.target.name){
                month['checked'] = event.target.checked
            }
        })
        setMonthsList(tempMonthList);
    }
    const handleH1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(checkedH2) {
            setCheckedH1(event.target.checked);
        }        
    };
    const handleH2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(checkedH1) {
            setCheckedH2(event.target.checked);
        }
    };

    const getSelectedMonths = () => {
        let selectedMonths = [];
        for(let month of monthsList){
            if(month.checked) {
                selectedMonths.push(getMonth('', month.name));
            }
        }
        return selectedMonths;
    }    
    const getMonth = (monthNum, monthName) => {
        const months = [
            "January", 
            "February", 
            "March", 
            "April", 
            "May", 
            "June", 
            "July", 
            "August", 
            "September", 
            "October",
            "November", 
            "December"
        ];
        if(monthName == '') {
            return months[monthNum - 1];
        } else {
            return months.indexOf(monthName) + 1;
        }
        
    }
    const daysInThisMonth = () => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
    }
    const calculateCumulativeExpectedSpend = (mpaAmount, startDate, endDate) => {
        const numberOfDaysBetweenScheduledDates = getNumberOfDaysBetweenDates(startDate, endDate);
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1);
        const numberOfDaysUntilYesterday = getNumberOfDaysBetweenDates(startDate, currentDate);
        return mpaAmount/numberOfDaysBetweenScheduledDates * numberOfDaysUntilYesterday;
    }
    const calculateExpectedSpend = (mpaAmount, selectedMonth) => {
        const currentMonth = new Date().getMonth()+1;
        const fiscalMonths = [11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        
        if(fiscalMonths.indexOf(selectedMonth) < fiscalMonths.indexOf(currentMonth)) {
            return 0;
        } else if(selectedMonth == currentMonth) {
            return mpaAmount/daysInThisMonth() * new Date().getDate();
        } else {
            return mpaAmount;
        }
    }
    const calculateDelta = (mpaAmount, actualSpend) => {
        return mpaAmount - actualSpend;
    }

    const calculateDeltaPercent = (mpaAmount, actualSpend) => {
        if(mpaAmount === 0) {
            return '-';
        }
        return ((mpaAmount - actualSpend) * 100 / mpaAmount).toFixed(2) + '%';
    }

    function getNumberOfDaysBetweenDates(startDate, endDate) {
        startDate = new Date(startDate);
        endDate = new Date(endDate);
        // Convert both dates to milliseconds
        const startMs = startDate.getTime();
        const endMs = endDate.getTime();
      
        // Calculate the difference in milliseconds
        const differenceMs = endMs - startMs;
      
        // Convert milliseconds to days
        const daysDifference = Math.floor(differenceMs / (1000 * 60 * 60 * 24));      
        return daysDifference;
    }

    function isMonthBetweenDates(startDate, endDate, month) {
        const months = [];
        let currentDate = new Date(startDate);
        endDate = new Date(endDate);
      
        while (currentDate <= endDate) {
          const month = currentDate.getMonth() + 1; // Month is zero-indexed, so adding 1
          months.push(month);
          currentDate.setMonth(currentDate.getMonth() + 1); // Move to the next month
        }
        return months.includes(month);
    }

    const buildRow = (data, fundingType) => {
        // const selectedMonths = getSelectedMonths();
        // const monthlyData = data.filter(row => selectedMonths.includes(row.month));

        let row = [
            data[0].lob,
            data[0].products,
            data[0].engine
        ];
        const cumulativeColumns = buildCumulativeColumns(data, fundingType);
        row.push(...cumulativeColumns);

        // for(var i = 0; i < monthlyData.length; i++) {
        //     const monthColumns = buildMonthColumns(monthlyData[i], fundingType);
        //     row.push(...monthColumns);
        // }

        for(var i = 3; i < row.length; i++) {
            if (i === 8) {
                continue;
            }
            row[i] = formatter.format(row[i]);
        }
        return row;
    }

    const buildMonthRows = (data, fundingType) => {
        const selectedMonths = getSelectedMonths();
        const monthlyData = data.filter(row => selectedMonths.includes(row.month));

        let row = [];
        
        for(var i = 0; i < monthlyData.length; i++) {
            const monthColumns = buildMonthColumns(monthlyData[i], fundingType);
            row.push(...monthColumns);
        }

        for(var i = 0; i < row.length; i++) {
            // if (i === 8) {
            //     continue;
            // }
            row[i] = formatter.format(row[i]);
        }
        return row;
    }

    const buildCumulativeColumns = (data, fundingType) => {
        let mpaTotal = 0;
        let invoiceTotal = 0;
        let expectedSpend = 0;
        let actualSpend = 0;
        let delta = 0;
        let deltaPercent;

        for(var i = 0; i < data.length; i++) {
            if (isMonthBetweenDates(data[i].scheduled_start_date, data[i].scheduled_end_date, data[i].month)) {
                mpaTotal += fundingType == 'Gross' ? data[i].gross_manual : data[i].net_manual;
                invoiceTotal += fundingType == 'Gross' ? data[i].gross_actual : data[i].net_actual;
                actualSpend += fundingType == 'Gross' ? data[i].gross_totalSpend : data[i].net_totalSpend;
            }
        }

        expectedSpend = calculateCumulativeExpectedSpend(mpaTotal, data[0].scheduled_start_date, data[0].scheduled_end_date);
        delta = calculateDelta(expectedSpend, actualSpend);
        deltaPercent = calculateDeltaPercent(expectedSpend, actualSpend);

        let columns = [
            mpaTotal,
            invoiceTotal,
            expectedSpend,
            actualSpend,
            delta,
            deltaPercent
        ];
        return columns;
    }

    const buildMonthColumns = (monthlyData, fundingType) => {
        let columns = [
            fundingType == 'Gross' ? monthlyData.gross_manual : monthlyData.net_manual,
            fundingType == 'Gross' ? monthlyData.gross_actual : monthlyData.net_actual,            
            fundingType == 'Gross' ? calculateExpectedSpend(monthlyData.gross_manual, monthlyData.month) : calculateExpectedSpend(monthlyData.net_manual, monthlyData.month),
            fundingType == 'Gross' ? monthlyData.gross_totalSpend : monthlyData.net_totalSpend,
            fundingType == 'Gross' ? calculateDelta(monthlyData.gross_manual, monthlyData.gross_totalSpend) : calculateDelta(monthlyData.net_manual, monthlyData.net_totalSpend),
            // fundingType == 'Gross' ? calculateDeltaPercent(data.gross_manual, data.gross_totalSpend) : calculateDeltaPercent(data.net_manual, data.net_totalSpend)
        ]        
        return columns;
    }

    const sortDataByFiscalMonths = (data) => {
        const fiscalMonths = [11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        data.sort((a,b) => fiscalMonths.indexOf(a.month) - fiscalMonths.indexOf(b.month));
    }

    const buildHeader = () => {
        const selectedMonths = getSelectedMonths();
        let headers = new Set();
        let data = props.budgetMetricsData.filter(row => row.lob == selectedLob 
            && row.products == selectedProduct
            && selectedMonths.includes(row.month)
        );
        
        sortDataByFiscalMonths(data);

        data.map((item) => {
            headers.add(getMonth(item.month, ''));
        });
        return Array.from(headers);
    }
    
    const buildTable = () => {
        let tableBody = [];
        
        let data = props.budgetMetricsData.filter(row => row.lob == selectedLob 
            && row.products == selectedProduct 
            // && ((checkedH1 && checkedH2) || (!checkedH1 && !checkedH2) ? true :
            //     checkedH1 ? [11, 12, 1, 2, 3, 4].includes(row.month) : false
            //     || checkedH2 ? [5, 6, 7, 8, 9, 10].includes(row.month) : false)
        );

        sortDataByFiscalMonths(data);
        
        if(checkedGoogle) {
            const engineData = data.filter(row => row.engine == 'Google_Adwords' ? row.engine == 'Google_Adwords' : '');
            if(engineData.length > 0) {
                tableBody.push(buildRow(engineData, selectedFundingType));
            }
        }
        if(checkedBing) {
            const engineData = data.filter(row => row.engine == 'Bing' ? row.engine == 'Bing' : '');
            if(engineData.length > 0) {
                tableBody.push(buildRow(engineData, selectedFundingType));
            }            
        }
        if(checkedFacebook) {
            const engineData = data.filter(row => row.engine == 'Facebook' ? row.engine == 'Facebook' : '');
            if(engineData.length > 0) {
                tableBody.push(buildRow(engineData, selectedFundingType));
            }            
        }
        return tableBody;
    }

    const buildMonthTable = () => {
        let tableBody = [];
        
        let data = props.budgetMetricsData.filter(row => row.lob == selectedLob 
            && row.products == selectedProduct 
            // && ((checkedH1 && checkedH2) || (!checkedH1 && !checkedH2) ? true :
            //     checkedH1 ? [11, 12, 1, 2, 3, 4].includes(row.month) : false
            //     || checkedH2 ? [5, 6, 7, 8, 9, 10].includes(row.month) : false)
        );
        sortDataByFiscalMonths(data);
        
        if(checkedGoogle) {
            const engineData = data.filter(row => row.engine == 'Google_Adwords' ? row.engine == 'Google_Adwords' : '');
            if(engineData.length > 0) {
                tableBody.push(buildMonthRows(engineData, selectedFundingType));
            }
        }
        if(checkedBing) {
            const engineData = data.filter(row => row.engine == 'Bing' ? row.engine == 'Bing' : '');
            if(engineData.length > 0) {
                tableBody.push(buildMonthRows(engineData, selectedFundingType));
            }            
        }
        if(checkedFacebook) {
            const engineData = data.filter(row => row.engine == 'Facebook' ? row.engine == 'Facebook' : '');
            if(engineData.length > 0) {
                tableBody.push(buildMonthRows(engineData, selectedFundingType));
            }            
        }
        return tableBody;
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2, 
        minimumFractionDigits: 2, 
    })

    console.log('RESULT', props.budgetMetricsData);

    return (
        <>
            {lobOptionsisloaded && productOptionsisloaded ?
                <Box sx={{ display:'flex', justifyContent:"space-between"}}>
                    <div style={{display:"flex", justifyContent:"flex-start"}}>
                        <Dropdown isOpen={openLobDropdown} toggle={toggleLob} style={{ marginBottom:"2.5rem"}}>
                            <DropdownToggle caret color="primary">
                                {selectedLob}
                            </DropdownToggle>
                            <DropdownMenu>
                                {lobOptions.map((item,index) => 
                                    <DropdownItem key={index} onClick={(e) => handleLobChange(e.target)}>{item}</DropdownItem>)
                                }         
                            </DropdownMenu>
                        </Dropdown>

                        <Dropdown isOpen={openProductDropdown} toggle={toggleProduct} style={{ marginLeft: "20px", marginBottom:"2.5rem"}}>
                            <DropdownToggle caret color="primary">
                                {selectedProduct}
                            </DropdownToggle>
                            <DropdownMenu>
                                {productOptions.map((item,index) => 
                                    <DropdownItem key={index} onClick={(e) => handleProductChange(e.target)}>{item}</DropdownItem>)
                                }         
                            </DropdownMenu>
                        </Dropdown>

                        <Dropdown isOpen={openFundingTypeDropdown} toggle={toggleFundingType} style={{ marginLeft: "20px", marginBottom:"2.5rem"}}>
                            <DropdownToggle caret color="primary">
                                {selectedFundingType}
                            </DropdownToggle>
                            <DropdownMenu>
                                {fundingTypeOptions.map((item,index) => 
                                    <DropdownItem key={index} onClick={(e) => handleFundingTypeChange(e.target)}>{item}</DropdownItem>)
                                }         
                            </DropdownMenu>
                        </Dropdown>
                        <EngineSelect
                            checkedGoogle={checkedGoogle}
                            checkedBing={checkedBing}
                            checkedFacebook={checkedFacebook}
                            handleGoogleChange={handleGoogleChange}
                            handleBingChange={handleBingChange}
                            handleFacebookChange={handleFacebookChange}
                        />
                        <MonthSelect
                            monthsList={monthsList}
                            handleMonthChange={handleMonthChange}
                        />
                        <Alert color="primary" style={{ padding: "0.75rem 1.25rem", marginLeft: "20px", marginBottom:"2.5rem", height: "fit-content"}}>
                            Note: Page layout is affected for Caribbean LOB due to pending product creation.
                        </Alert>
                        {/* <QuarterSelect
                            checkedH1={checkedH1}
                            checkedH2={checkedH2}
                            handleH1Change={handleH1Change}
                            handleH2Change={handleH2Change}
                        /> */}
                    </div>
                </Box>
            : null}
            <div className="table-container" style={{display: "flex", overflowX: "auto", marginBottom: "2rem"}}>
                <div className="fixed-columns" style={{overflow: "hidden", flexShrink: 0}}>
                    <table className='table table-bordered'>
                        <thead>
                            <tr style={{backgroundColor: "rgb(0, 106, 195)", color: "#ffffff", textAlign: "center"}}>
                                <th colSpan={3}>Source Details</th>
                                <th colSpan={6}>Cumulative Pacing Data</th>
                            </tr>
                            <tr>
                                <th style={{height: "74px"}}>LOB</th>
                                <th>Product</th>
                                <th>Engine</th>
                                <th>MPA Total</th>
                                <th>Invoice Total</th>
                                <th>Expected Total Spend</th>
                                <th>Actual Spend</th>
                                <th>Delta</th>
                                <th>% Difference</th>
                            </tr>
                        </thead>
                        <tbody>                           
                            {
                                bodyCells.map((row,index) => (
                                    <tr key={index}>
                                        {row.map((cell,index) => (
                                            <td key={index}>{cell}</td>
                                        ))}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="scrollable-columns" style={{overflow: "hidden", overflowX: "auto"}}>
                    <table className='table table-bordered' style={{marginBottom: "0"}}>
                        <thead>
                            <tr style={{backgroundColor: "rgb(0, 106, 195)", color: "#ffffff", textAlign: "center"}}>
                                {headerCells.map((headCell, index) => (
                                    <th key={index} colSpan={5}>{headCell}</th>
                                ))}
                            </tr>
                            <tr>
                                {headerCells.map((headCell, index) => (
                                    <>
                                        <th key={index+'_mpa'} style={{height: "74px"}}>MPA Amount</th>
                                        <th key={index+'_invoice'}>Invoice Amount</th>
                                        <th key={index+'_expected'}>Expected Spend</th>
                                        <th key={index+'_actual'}>Actual Spend</th>
                                        <th key={index+'_delta'}>Delta</th>
                                    </>                            
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                monthCells.map((row,index) => (
                                    <tr key={index}>
                                        {row.map((cell,index) => (
                                            <td key={index}>{cell}</td>
                                        ))}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <DashboardTable bordered style={{display: "block", overflowX: "auto"}}>
                <thead>
                    <tr style={{backgroundColor: "rgb(0, 106, 195)", color: "#ffffff", textAlign: "center"}}>
                        <th colSpan={3}>Source Details</th>
                        <th colSpan={6}>Cumulative Pacing Data</th>
                        {headerCells.map((headCell, index) => (
                            <th key={index} colSpan={5}>{headCell}</th>
                        ))}
                    </tr>
                    <tr style={{backgroundColor: "rgb(0, 106, 195)", color: "#ffffff"}}>
                        <th>LOB</th>
                        <th>Product</th>
                        <th>Engine</th>
                        <th>MPA Total</th>
                        <th>Invoice Total</th>
                        <th>Expected Daily Spend</th>
                        <th>Actual Spend</th>
                        <th>Delta</th>
                        <th>% Difference</th>
                        {headerCells.map((headCell, index) => (
                            <>
                                <th key={index+'_mpa'}>MPA Amount</th>
                                <th key={index+'_invoice'}>Invoice Amount</th>
                                <th key={index+'_expected'}>Expected Spend</th>
                                <th key={index+'_actual'}>Actual Spend</th>
                                <th key={index+'_delta'}>Delta</th>
                            </>                            
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {
                        bodyCells.map((row,index) => (
                            <tr key={index}>
                                {row.map((cell,index) => (
                                    <td key={index}>{cell}</td>
                                ))}
                            </tr>
                        ))
                    }
                </tbody>
            </DashboardTable> */}
        </>
    )
}

export default BudgetPacingMetrics;