import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';

import { BudgetOrdersDashboard } from './BudgetOrdersDashboard';

import { WithMultipleLoading } from '../../../Common/WithMultipleLoading';
import { PageHeader } from '../../../Common/PageHeader';

import { updateLoadableOnError, updateLoadableOnStart, updateLoadableOnSuccess } from '../../../State/Common/Loadable';

import { BudgetOrdersViewState, createDefaultBudgetOrdersViewState } from 'Components/Bom/Models/BudgetOrdersView';
import { BudgetOrderSearchIndexState, buildSearchIndex, createDefaultBudgetOrderSearchIndexState } from '../Models/BudgetOrderSearchIndex';
import { createDefaultLinesOfBusinessState, LinesOfBusinessState } from '../Models/LinesOfBusiness';
import { BudgetOrderCategoriesState, createDefaultBudgetOrderCategoriesState } from '../Models/BudgetOrderCategories';
import { BudgetOrdersState, buildBudgetOrders, createDefaultBudgetOrdersState } from '../Models/BudgetOrders';

import LineOfBusinessService from '../Services/LineOfBusinessService';
import BudgetOrdersService from '../Services/BudgetOrdersService';
import BudgetOrderCategoriesService from '../Services/BudgetOrderCategoriesService';

import { Box, FormGroup, FormLabel} from '@mui/material';


const BudgetOrdersDashboardWithMultipleLoadables = WithMultipleLoading(BudgetOrdersDashboard);

export const BudgetOrdersDashboardContainer = (props : any) => {
  const { history, location } = props;

  const [linesOfBusiness, setLineOfBusinees] = useState<LinesOfBusinessState>(createDefaultLinesOfBusinessState());
  const [budgetOrderCategories, setBudgetOrderCategories] = useState<BudgetOrderCategoriesState>(createDefaultBudgetOrderCategoriesState());
  const [budgetOrderSearchIndex, setBudgetOrderSearchIndex] = useState<BudgetOrderSearchIndexState>(createDefaultBudgetOrderSearchIndexState());
  const [budgetOrders, setBudgetOrders] = useState<BudgetOrdersState>(createDefaultBudgetOrdersState());
  const [budgetOrdersView, setBudgetOrdersView] = useState<BudgetOrdersViewState>(createDefaultBudgetOrdersViewState());

  const prevLocationRef: any = useRef();

  const getBudgetOrdersViewFromQueryString = (urlQueryString : string) : any=> {
    return urlQueryString ?
      queryString.parse(urlQueryString) :
      budgetOrdersView;
  }

  //get Line of Business
  useEffect(() => {
    
    async function getLineOfBusiness() {
      setLineOfBusinees({...linesOfBusiness, getLinesOfBusinessLoadable:updateLoadableOnStart()});

      try{
        const response = await LineOfBusinessService.retrieveLinesOfBusiness();
        setLineOfBusinees({...linesOfBusiness, entities:response, getLinesOfBusinessLoadable:updateLoadableOnSuccess()});
      } catch(ex){
        setLineOfBusinees({...linesOfBusiness, getLinesOfBusinessLoadable:updateLoadableOnError(ex.message)});
      } 
    }
    getLineOfBusiness();
  }, []);

  //get Category
  useEffect(() => {
    
    async function getBudgetOrderCategories() {
        setBudgetOrderCategories({...budgetOrderCategories, getBudgetOrderCategoriesLoadable:updateLoadableOnStart()});
        try{
            const response = await BudgetOrderCategoriesService.retrieveBudgetOrderCategories();
            setBudgetOrderCategories({...budgetOrderCategories, entities:response, getBudgetOrderCategoriesLoadable:updateLoadableOnSuccess()});
        } catch(ex){
            setBudgetOrderCategories({...budgetOrderCategories, getBudgetOrderCategoriesLoadable:updateLoadableOnError(ex.message)});
        }
    }
    getBudgetOrderCategories();
  }, []);

  //get Search index
  useEffect(() => {

    async function getBudgetOrderSearchIndex() {
        setBudgetOrderSearchIndex({...budgetOrderSearchIndex, getSearchIndexLoadable:updateLoadableOnStart()});
        try{
            const response = await BudgetOrdersService.retrieveBudgetOrderSearchIndex();
            setBudgetOrderSearchIndex({...budgetOrderSearchIndex, searchIndex: buildSearchIndex(response['rowData']), getSearchIndexLoadable:updateLoadableOnSuccess()});
        } catch(ex){
            setBudgetOrderSearchIndex({...budgetOrderSearchIndex, getSearchIndexLoadable:updateLoadableOnError(ex.message)});
        }
        
    }
    getBudgetOrderSearchIndex();
    
  }, []);

  //get Budget orders
  useEffect(() => {
    async function getBudgetOrder() {
        setBudgetOrders({...budgetOrders, getBudgetOrdersLoadable:updateLoadableOnStart()});
        try{
            const {searchTerm,selectedCategory,selectedLineOfBusiness,selectedWeeksLeft,sortAttribute,sortDirection} = getBudgetOrdersViewFromQueryString(location.search)
            setBudgetOrdersView({...budgetOrdersView,searchTerm,selectedCategory,selectedLineOfBusiness,selectedWeeksLeft,sortAttribute,sortDirection});
            
            const response = await BudgetOrdersService.retrieveBudgetOrders(getBudgetOrdersViewFromQueryString(location.search));
            setBudgetOrders({...budgetOrders, entities: buildBudgetOrders(response['rowData']), entitiesColumns: response['headers'], getBudgetOrdersLoadable:updateLoadableOnSuccess()});
        } catch(ex){
            setBudgetOrders({...budgetOrders, getBudgetOrdersLoadable:updateLoadableOnError(ex.message)});
        }
    }
    
    if (!prevLocationRef.current) {
      // Component did mount
      if (!budgetOrders.getBudgetOrdersLoadable.isReceived) {
        getBudgetOrder();
      }

      if (budgetOrders.getBudgetOrdersLoadable.isReceived && location.search === '') {
        getBudgetOrder();
      }
      prevLocationRef.current = location;
    } else {
      // Component did update
      const prevLocation = prevLocationRef.current;
      if (prevLocation.search !== location.search) {
        getBudgetOrder();
      }
    }
  }, [location.search]);

  const loadables = [
    budgetOrderCategories.getBudgetOrderCategoriesLoadable,
    budgetOrderSearchIndex.getSearchIndexLoadable,
    budgetOrders.getBudgetOrdersLoadable,
    linesOfBusiness.getLinesOfBusinessLoadable,
  ];

  return (
    <>
      <PageHeader>Budget Orders</PageHeader>
      <Box sx={{display:'flex', alignItems:'center',ml:5, mb:2, marginRight:"50px"}}>
        <BudgetOrdersDashboardWithMultipleLoadables
          budgetOrderCategories={budgetOrderCategories.entities}
          budgetOrders={budgetOrders.entities}
          budgetOrdersView={budgetOrdersView}
          budgetOrderSearchIndex={budgetOrderSearchIndex.searchIndex}
          history={history}
          linesOfBusiness={linesOfBusiness.entities}
          loadables={loadables}
          tableConfig={budgetOrders.entitiesColumns}
          budgetOrdersState={budgetOrders}
        />
      </Box>
    </>
  );
};
