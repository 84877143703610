import React from 'react';

import { Loader } from '.';

export const WithLoading = (Component: any) => {
  return function withLoadingComponent({ loadable, ...props }: any) {
    if (loadable.isReceived) {
      return (<Component {...props} />);
    }

    if (!!loadable.error) {
      sessionStorage.removeItem("authenticationState");
      sessionStorage.removeItem("tableConfig");
      window.location.reload(); 
    }

    return (<Loader />);
  }
};
