import React, { useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Fuse from 'fuse.js';
import OutsideClickHandler from 'react-outside-click-handler';

import { SearchForm } from './SearchForm';
import { SearchResults } from './SearchResults';

import { BudgetOrdersViewState, MAX_SEARCH_RESULTS } from '../Models/BudgetOrdersView';
import { BudgetOrderSearchIndexInterface } from '../Models/BudgetOrderSearchIndex';


interface Props {
  budgetOrderSearchIndex: Array<BudgetOrderSearchIndexInterface>,
  budgetOrdersView: BudgetOrdersViewState,
  history: any,
}

export const BudgetOrderSearchBar = (props: Props) => {
  const { budgetOrderSearchIndex, budgetOrdersView, history } = props;

  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Array<BudgetOrderSearchIndexInterface>>([]);
  const [searchTerm, setSearchTerm] = useState<string>(budgetOrdersView.searchTerm);

  const fuse = new Fuse(budgetOrderSearchIndex, {
    shouldSort: true,
    threshold: 0.15,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ["clientId", "accountName"]
  });

  const handleOutsideClick = () => {
    setShowSearchResults(prevState => !prevState);
  }

  const handleSearchForBudgetOrder = (event: any) => {
    const results = fuse.search(event.target.value).slice(0, MAX_SEARCH_RESULTS);
    setSearchTerm(event.target.value);
    setSearchResults(results);
    setShowSearchResults(results.length > 0);
  }

  const handleSelectSearchResult = (event: any) => {
    setShowSearchResults(false);
    const budgetOrderViewParams = { ...budgetOrdersView, searchTerm: event.target.innerHTML };
    history.push(`/bom/budget-orders?${queryString.stringify(budgetOrderViewParams)}`);
  }

  const handleSubmitSearch = (event: any) => {
    event.preventDefault();
    const budgetOrderViewParams = { ...budgetOrdersView, searchTerm };
    history.push(`/bom/budget-orders?${queryString.stringify(budgetOrderViewParams)}`);
  }

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div className="search-bar" style={{position: 'relative'}}>
        <SearchForm
          placeholder="Search by Account Name or ClientID"
          searchTerm={searchTerm}
          onSearch={handleSearchForBudgetOrder}
          onSubmit={handleSubmitSearch}
        />
        {showSearchResults &&
        <SearchResults
          searchResultObjectField={isNaN(parseInt(searchTerm)) ? 'accountName' : 'clientId'}
          searchResults={searchResults}
          onSelect={handleSelectSearchResult}
        />}
      </div>
    </OutsideClickHandler>
  )
}

BudgetOrderSearchBar.propTypes = {
  budgetOrderSearchIndex: PropTypes.array.isRequired,
  budgetOrdersView: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}
