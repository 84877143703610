import { createDefaultLoadable, Loadable } from '../../Common/Loadable';

export interface MpaState {
  entities: Array<any>,
  exportToBqStatus: any,
  selectedMpa: any,
  addMpaLoadable: Loadable,
  deleteSelectedMpaLoadable: Loadable,
  getExportToBqStatusLoadable: Loadable,
  getSelectedMpaLoadable: Loadable,
  getMpaListLoadable: Loadable,
  getMpasCsvLoadable: Loadable,
  updateSelectedMpaLoadable: Loadable,
}

export function createDefaultMpaState(): MpaState {
  return {
    entities: [],
    exportToBqStatus: '',
    selectedMpa: null,
    addMpaLoadable: createDefaultLoadable(),
    deleteSelectedMpaLoadable: createDefaultLoadable(),
    getExportToBqStatusLoadable: createDefaultLoadable(),
    getSelectedMpaLoadable: createDefaultLoadable(),
    getMpaListLoadable: createDefaultLoadable(),
    getMpasCsvLoadable: createDefaultLoadable(),
    updateSelectedMpaLoadable: createDefaultLoadable(),
  };
};
