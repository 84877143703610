import { createDefaultLobState, LobState } from '../Models/LobState';

import {
  updateLoadableOnStart,
  updateLoadableOnSuccess,
  updateLoadableOnError
} from '../../Common/Loadable';

import {
  ADD_NEW_LOB, ADD_NEW_LOB_SUCCESS, ADD_NEW_LOB_ERROR,
  DELETE_SELECTED_LOB, DELETE_SELECTED_LOB_SUCCESS, DELETE_SELECTED_LOB_ERROR,
  GET_LOB, GET_LOB_SUCCESS, GET_LOB_ERROR,
  GET_LOBS_CSV, GET_LOBS_CSV_SUCCESS, GET_LOBS_CSV_ERROR,
  GET_SELECTED_LOB, GET_SELECTED_LOB_SUCCESS, GET_SELECTED_LOB_ERROR,
  UPDATE_SELECTED_LOB, UPDATE_SELECTED_LOB_SUCCESS, UPDATE_SELECTED_LOB_ERROR
} from '../ActionTypes/LobActionTypes';

export default function (state = createDefaultLobState(), action: any): LobState {
  switch(action.type) {
    case ADD_NEW_LOB:
      return {
        ...state,
        addLobLoadable: updateLoadableOnStart()
      };

    case ADD_NEW_LOB_SUCCESS:
      // Display updated data on manage-lobs page after new LOB or Product insert
      let resultRows = [];
      for (let index in action.result) {
        resultRows.push(action.result[index].lob);
      }

      return {
        ...state,        
        entities: [...state.entities, ...resultRows],
        addLobLoadable: updateLoadableOnSuccess()
      };

    case ADD_NEW_LOB_ERROR:
      return {
        ...state,
        addLobLoadable: updateLoadableOnError(action.error)
      };

    case DELETE_SELECTED_LOB:
      return {
        ...state,
        deleteSelectedLobLoadable: updateLoadableOnStart()
      };

    case DELETE_SELECTED_LOB_SUCCESS:     
      // Display updated data on manage-lobs page after delete
      let entitiesRow = [...state.entities].filter(row => !action.payload.channelsToRemove.some(el => el === row.id));

      return {
        ...state,
        // entities: state.entities.filter((lob) => lob['id'] !== action.lobId),
        entities: entitiesRow,
        deleteSelectedLobLoadable: updateLoadableOnSuccess()
      };

    case DELETE_SELECTED_LOB_ERROR:
      return {
        ...state,
        deleteSelectedLobLoadable: updateLoadableOnError(action.error)
      };

    case GET_LOB:
      return {
        ...state,
        getLobListLoadable: updateLoadableOnStart()
      };

    case GET_LOB_SUCCESS:
      return {
        ...state,
        entities: action.result,
        getLobListLoadable: updateLoadableOnSuccess()
      };

    case GET_LOB_ERROR:
      return {
        ...state,
        getLobListLoadable: updateLoadableOnError(action.error)
      };

    case GET_LOBS_CSV:
      return {
        ...state,
        getLobsCsvLoadable: updateLoadableOnStart()
      };

    case GET_LOBS_CSV_SUCCESS:
      return {
        ...state,
        getLobsCsvLoadable: updateLoadableOnSuccess()
      };

    case GET_LOBS_CSV_ERROR:
      return {
        ...state,
        getLobsCsvLoadable: updateLoadableOnError(action.error)
      };

    case GET_SELECTED_LOB:
      return {
        ...state,
        getSelectedLobListLoadable: updateLoadableOnStart()
      };

    case GET_SELECTED_LOB_SUCCESS:
      return {
        ...state,
        selectedLob: action.result,
        getSelectedLobListLoadable: updateLoadableOnSuccess()
      };

    case GET_SELECTED_LOB_ERROR:
      return {
        ...state,
        getSelectedLobListLoadable: updateLoadableOnError(action.error)
      };

    case UPDATE_SELECTED_LOB:
      return {
        ...state,
        updateLobListLoadable: updateLoadableOnStart()
      };

    case UPDATE_SELECTED_LOB_SUCCESS:    
      // Display updated data on manage-lobs page after insert/update/delete
      let entities = [...state.entities].map(entity => {
        const row = action.result.channel_data.find(row => row.id === entity['id'])
        if (row) {
          return row;
        }
        return entity;
      });

      entities.push(...action.result.channel_data.filter(row => !entities.some(el => el.id === row.id)));
      entities.sort((prev, next) => prev.name.localeCompare(next.name) || prev.products.localeCompare(next.products));
      
      return {
        ...state,        
        entities,
        selectedLob: action.result,
        updateLobListLoadable: updateLoadableOnSuccess()
      };

    case UPDATE_SELECTED_LOB_ERROR:
      return {
        ...state,
        updateLobListLoadable: updateLoadableOnError(action.error)
      };

    default:
      return state;
  };
};
