import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, FormGroup } from 'reactstrap';

export const ConfirmationPageMpaModal = (props) => {
  const [checkedRules, setCheckedRules] = useState([]);

  const confirmFieldRules = useMemo(
    () =>
      [
        props.mpaIntake.client_business_unit !== 'Caribbean' && {
          value: 'rule1',
          label:
            "CIDs listed in this MPA is/are not associated with any other MPA. \
        <ul class='confirmSubRules'> \
        <li>Multiple CIDs may exist in a single MPA.</li> \
        <li>There can't be more than one MPA per CID.</li> \
        </ul>",
        },
        props.mpaIntake.client_business_unit !== 'Caribbean' && {
          value: 'rule2',
          label:
            "Make sure the start and end date of a similar MPA is not overlapping. \
        <ul class='confirmSubRules'> \
      <li>There is no other active MPA with same combination of CID, LOB, Channel and Product</li> \
      </ul>",
        },
        { value: 'rule3', label: 'Listed CIDs can share budget with each other.' },
        { value: 'rule4', label: 'Once received, please be sure to update this MPA with INI MPA#.' },
      ].filter((item) => item),
    [props.mpaIntake]
  );

  const [checkErrorMsg, setCheckErrorMsg] = useState('');

  const handleCheckboxChange = useCallback(
    (value) => {
      const index = checkedRules.indexOf(value);
      if (index < 0) {
        setCheckedRules([...checkedRules, value]);
      } else {
        let newArray = [...checkedRules];
        newArray.splice(index, 1);
        setCheckedRules([...newArray]);
      }
    },
    [checkedRules, confirmFieldRules, setCheckedRules]
  );

  const confirmButtonHandle = useCallback(() => {
    if (checkedRules.length === confirmFieldRules.length) {
      props.setAllCheckerModal(true);
    } else {
      setCheckErrorMsg('Please select all the rules');
    }
  }, [setCheckErrorMsg, confirmFieldRules, props.setAllCheckerModal, checkedRules]);

  const confirmationToggle = () => props.setConfirmationModal((prev) => !prev);

  return (
    <div>
      <Modal isOpen={props.confirmationModal} toggle={confirmationToggle} backdrop="static" className="modal-lg">
        <ModalHeader toggle={confirmationToggle}>Confirm Business Rules</ModalHeader>
        <ModalBody>
          <span style={{ color: 'red', fontSize: '1.2rem' }}>{checkErrorMsg}</span>
          {confirmFieldRules.map((item, index) => (
            <FormGroup key={index} check style={{ lineHeight: '1.75' }}>
              <Label check>
                <Input type="checkbox" checked={checkedRules.includes(item.value)} className="rule-1" onChange={() => handleCheckboxChange(item.value)} />{' '}
                <div dangerouslySetInnerHTML={{ __html: item.label }} />
              </Label>
            </FormGroup>
          ))}
          <br />
          <p style={{ color: '#6c757d' }}>
            {' '}
            Note: Make sure CIDs listed in this MPA belongs to same product. If selecting multiple CIDs consider using <strong>{props.mpaIntake.client_business_unit}</strong> - All flag.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={confirmButtonHandle}>
            Confirm
          </Button>
          <Button color="secondary" onClick={confirmationToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
