import { GridInput } from 'Common';
import React, { useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import {
  IMediaCalculatorObj,
  formattedMultiplication,
  initialMediaCalculatorObj,
  numberToMediaCalculatorObj,
} from '../formulation';
import CSVButton from '../CSVButton';
import ValidInput from '../ValidInput';

const gridSize = 3;

const MinimumBudgetEstimatorOnCPA = (): JSX.Element => {
  const [estCPA, setEstCPA] = useState<IMediaCalculatorObj>(
    initialMediaCalculatorObj
  );
  const [numberOfAdSets, setNumberOfAdSets] = useState<IMediaCalculatorObj>(
    initialMediaCalculatorObj
  );
  const [numberOfConversion, setNumberOfConversion] =
    useState<IMediaCalculatorObj>(initialMediaCalculatorObj);
  const [numberOfWeeks, setNumberOfWeeks] = useState<IMediaCalculatorObj>(
    initialMediaCalculatorObj
  );

  const estimated = useMemo(() => {
    const budget = formattedMultiplication(
      estCPA.value,
      numberOfAdSets.value,
      numberOfConversion.value,
      numberOfWeeks.value,
      1.1
    );
    const gross = formattedMultiplication(budget.value, 1.1752);
    return {
      budget,
      gross,
      csvContent: [
        ['Est. CPA', estCPA.formattedString],
        ['No. of Ad Sets', numberOfAdSets.formattedString],
        ['No. of Conversions/Week/Ad Set', numberOfConversion.formattedString],
        ['No. of Weeks', numberOfWeeks.formattedString],
        ['Estimated Budget for Campaign Period', budget.formattedString],
        ['Estimated Gross', gross.formattedString],
      ],
    };
  }, [estCPA, numberOfAdSets, numberOfConversion, numberOfWeeks]);
  return (
    <Row>
      <Col xs={12}>
        <h3>
          Minimum Budget Estimator On CPA
          <CSVButton
            filename="Budget_Estimator_On_Reach"
            content={estimated.csvContent}
          />
        </h3>
      </Col>
      <ValidInput values={estCPA} onChange={setEstCPA} label="Est. CPA:" />
      <ValidInput
        values={numberOfAdSets}
        onChange={setNumberOfAdSets}
        label="No. of Ad Sets:"
        isWholeNumber
        isCurrency={false}
      />

      <ValidInput
        values={numberOfConversion}
        onChange={setNumberOfConversion}
        label="No. of Conversions/Week/Ad Set:"
        isWholeNumber
        isCurrency={false}
      />
      <ValidInput
        values={numberOfWeeks}
        onChange={setNumberOfWeeks}
        label="No. of Weeks:"
        isWholeNumber
        isCurrency={false}
      />

      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="est-budget-for-campaign-period"
        inputId="est-budget-for-campaign-period-input"
        inputLabel="Estimated Budget for Campaign Period:"
        value={estimated.budget.formattedString}
      />
      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="est-gross"
        inputId="est-gross-input"
        inputLabel="Estimated Gross:"
        value={estimated.gross.formattedString}
      />
    </Row>
  );
};

export default MinimumBudgetEstimatorOnCPA;
