import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddExcludeCid from './AddExcludeCid';
import { AppPage, PageHeader, WithLoading } from '../../../Common';
import { getUsername, getUserPermissions } from '../../../State/Authentication/Selectors/AuthenticationSelectors';
import { getDropdownOptions } from '../../../State/FormInfo/Actions/FormInfoActions';
import { getFormInfoState } from '../../../State/FormInfo/Selectors/FormInfoSelectors';
import { Redirect } from 'react-router-dom';

const AddExcludeCidWithLoading = WithLoading(AddExcludeCid);

const AddExcludeCidContainer = () => {
    const formInfoState = useSelector(getFormInfoState);
    const dispatch = useDispatch();

    const username = useSelector(getUsername);
    const userPermissions = useSelector(getUserPermissions);
    
    // useEffect(() => {
    //     if (!formInfoState.getDropdownOptionsLoadable.isReceived) {
    //       dispatch(getDropdownOptions());
    //     }
    //   }, [formInfoState.getDropdownOptionsLoadable.isReceived, dispatch]);

    if(userPermissions.includes('add_mpa')){
        return (
            <>
                <PageHeader>Add CID to Exclude List</PageHeader>
                <AppPage>
                    <AddExcludeCid userName={username} />
                    {/* <AddExcludeCidWithLoading
                        // loadable={formInfoState.getDropdownOptionsLoadable}
                        // lobDropdownOptions={formInfoState.dropdownOptions}
                        userName={username}
                    /> */}
                </AppPage>
            </>
        )
    } else {
        return <Redirect to={{pathname:'/map/exclude-cids', state: '/map/mpa'}} />
    }
}

export default AddExcludeCidContainer;