import ApiService from './ApiService';

export default class MpaService {
  static addNewMpa(payload: any): Promise<Array<any>> {
    return ApiService.post({ relativeUrl: `/mpa/api/mpas`, body: payload });
  }

  static deleteSelectedMpa(mpaId: string, payload: string): Promise<Array<any>> {
    return ApiService.delete({ relativeUrl: `/mpa/api/mpas/${mpaId}`, body: payload });
  }

  static retrieveMpas(): Promise<Array<any>> {
    return ApiService.get({ relativeUrl: '/mpa/api/mpas' });
  }

  static retrieveSelectedMpa(mpaId: string): Promise<Array<any>> {
    return ApiService.get({ relativeUrl: `/mpa/api/mpas/${mpaId}` });
  }

  static updateSelectedMpa(rowId: string, payload: any): Promise<Array<any>> {
    return ApiService.put({ relativeUrl: `/mpa/api/mpas/${rowId}`, body: payload });
  }

  static retrieveMpasCsv(): Promise<any> {
    return ApiService.get({ relativeUrl: `/mpa/api/mpas-file` });
  }

  static retrieveExportToBqStatus(): Promise<Array<any>> {
    return ApiService.get({ relativeUrl: '/mpa/api/export-to-bq-status' });
  }
  
  static retrieveAnchorIdProductCount(params: any): Promise<any> {
    return ApiService.get({ relativeUrl: '/mpa/api/anchorid_product_count', queryParams:params});
  }

  static retrieveExcludedAnchorIds(params: any): Promise<any> {
    return ApiService.get({ relativeUrl: '/mpa/api/retrieve_excluded_anchorids', queryParams:params});
  }
}
