import React, { useState } from 'react';
import { Button, ButtonGroup, Form } from 'reactstrap';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import { BudgetOrderCategoriesDropdown } from './BudgetOrderCategoriesDropdown';
import { WeeksLeftDropdown } from './WeeksLeftDropdown';
import { LinesOfBusinessDropdown } from './LinesOfBusinessDropdown';
import { BudgetOrdersViewState } from '../Models/BudgetOrdersView';

interface Props{
  budgetOrderCategories: Array<string>, 
  budgetOrdersView: BudgetOrdersViewState, 
  history: any, 
  linesOfBusiness: Array<string>
}

export const BudgetOrderFilterForm = (props: Props) => {
  const { budgetOrderCategories, budgetOrdersView, history, linesOfBusiness } = props;

  const [selectedBudgetOrderCategory, setSelectedBudgetOrderCategory] = useState<string>(budgetOrdersView.selectedCategory);
  const [selectedLineOfBusiness, setSelectedLineOfBusiness] = useState<string>(budgetOrdersView.selectedLineOfBusiness);
  const [selectedWeeksLeft, setSelectedWeeksLeft] = useState<string>(budgetOrdersView.selectedWeeksLeft);

  const handleApplyFilters = () => {
    const budgetOrderViewParams = {
      ...budgetOrdersView,
      selectedCategory: selectedBudgetOrderCategory,
      selectedLineOfBusiness: selectedLineOfBusiness,
      selectedWeeksLeft: selectedWeeksLeft
    };
    history.push(`/bom/budget-orders?${queryString.stringify(budgetOrderViewParams)}`);
  }

  const handleBudgetOrderCategoryChange = (value: string) => {
    setSelectedBudgetOrderCategory(value);
  }

  const handleClearFilters = () => {
    const budgetOrderViewParams = {
      ...budgetOrdersView,
      selectedCategory: '',
      selectedLineOfBusiness: '',
      selectedWeeksLeft: ''
    };
    history.push(`/bom/budget-orders?${queryString.stringify(budgetOrderViewParams)}`);
  }

  const handleLineOfBusinessChange = (value: string) => {
    setSelectedLineOfBusiness(value);
  }

  const handleWeeksLeftChange = (value: string) => {
    setSelectedWeeksLeft(value);
  }

  return (
    <Form className='form-row ml-0 mr-0' style={{ 'padding': '1rem 0', 'textAlign': 'left' }}>
      <LinesOfBusinessDropdown
        selectedLineOfBusiness={selectedLineOfBusiness}
        linesOfBusiness={linesOfBusiness}
        handleLineOfBusinessChange={handleLineOfBusinessChange}
      />
      <BudgetOrderCategoriesDropdown
        budgetOrderCategories={budgetOrderCategories}
        handleBudgetOrderCategoryChange={handleBudgetOrderCategoryChange}
        selectedBudgetOrderCategory={selectedBudgetOrderCategory}
      />
      <WeeksLeftDropdown
        selectedEntry={selectedWeeksLeft}
        handleWeeksLeftChange={handleWeeksLeftChange}
      />
      <ButtonGroup>
        <Button color="primary" onClick={handleApplyFilters}>Apply</Button>
        <Button color="secondary" onClick={handleClearFilters}>Clear</Button>
      </ButtonGroup>
    </Form>
  );
}

BudgetOrderFilterForm.propTypes = {
  budgetOrderCategories: PropTypes.array.isRequired,
  budgetOrdersView: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  linesOfBusiness: PropTypes.array.isRequired
}
