import ApiService from '../../../Services/ApiService';

export default class BudgetOrdersService {
  static retrieveBudgetOrders(budgetOrdersView = {}): Promise<Array<any>> {
    return ApiService.get({ relativeUrl: '/bom/api/budget-orders', queryParams: budgetOrdersView });
  }

  static retrieveBudgetOrdersCsv(): Promise<any> {
    return ApiService.get({ relativeUrl: '/bom/api/budget-orders-file' });
  }

  static retrieveBudgetOrderSearchIndex(): Promise<Array<any>> {
    return ApiService.get({ relativeUrl: '/bom/api/budget-orders' });
  }

  static updateBudgetOrders(budgetOrders: any): Promise<any> {
    return ApiService.put({ relativeUrl: '/bom/api/budget-orders', body: budgetOrders });
  }

  static clearBudgetOrders(budgetOrdersToDelete: any): Promise<any> {
    return ApiService.delete({ relativeUrl: '/bom/api/budget-orders', body: budgetOrdersToDelete });
  }
};


