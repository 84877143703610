import React from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getUserPermissions } from 'State/Authentication/Selectors/AuthenticationSelectors';
import EditableChannelRow from '../Common/EditableChannelRow';



interface IChannelSummary {
  channelOptions: any[];
  anchorCidOptions:any[];
  channelList:any[];
  isDisabled:boolean;
  handleAddChannelToList:any;
  handleChannelChange:any;
  handleCampaignChange:any;
  handleRemoveChannelFromList:any;
  handleTotalBudgetChange:any;
  handleAnchorCidChange:any;
  modalsState:any;
  mpaIntake:any;
  handleMonthFundsChange:any;
  handleTotalPlatformFeeChange:any;
  fromAddMPA:boolean;

}

const ChannelSummary = (props: IChannelSummary):JSX.Element  => {
    const userPermissions = useSelector(getUserPermissions);

    return (
      <div style={{'marginBottom': '16px'}}>
        {props.channelList.map((channelData: any, index: number) => (
          <EditableChannelRow 
            key={index}
            index={index}
            channelOptions={props.channelOptions}
            anchorCidOptions={props.anchorCidOptions}
            channelList={props.channelList}
            handleChannelChange={props.handleChannelChange}
            handleAnchorCidChange={props.handleAnchorCidChange}          
            handleTotalBudgetChange={props.handleTotalBudgetChange}
            fromAddMPA={props.fromAddMPA}
            handleCampaignChange={props.handleCampaignChange}
            // handleInitMapChange={props.handleInitMapChange}
            handleRemoveChannelFromList={props.handleRemoveChannelFromList}
            isDisabled={channelData.id ? true : false}
            channelData={channelData}
            modalsState={props.modalsState}
            mpaIntake={props.mpaIntake}
            handleMonthFundsChange={props.handleMonthFundsChange}
            handleTotalPlatformFeeChange={props.handleTotalPlatformFeeChange}
          />
        ))}
        <Button color="link" size="md" disabled={!userPermissions.includes('add_cid')} onClick={props.handleAddChannelToList} style={{'padding': '0.375rem 0'}}>
            Add CID
        </Button>
      </div>
    );
};


export default ChannelSummary;