import { createDefaultAuthState, AuthState } from '../Models/AuthState';

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_SUCCESS,
} from '../ActionTypes/AuthenticationActionTypes';

export default function (
  state = createDefaultAuthState(),
  action: any
): AuthState {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isAdmin: action.result['user_info']['is_superuser'] === 1,
        isAuthenticated: true,
        token: action.result['token'],
        userInfo: action.result['user_info'],
        userPermissions: action.result['user_permissions'],
      };

    case LOGIN_ERROR:
      return {
        ...createDefaultAuthState(),
      };

    case LOGOUT_SUCCESS:
      return {
        ...createDefaultAuthState(),
      };
    default:
      return state;
  }
}
