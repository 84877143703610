import { GridInput } from '../../Common';
import React, { useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import {
  IMediaCalculatorObj,
  formattedDifference,
  formattedDivision,
  initialMediaCalculatorObj,
} from './formulation';
import CSVButton from './CSVButton';
import ValidInput from './ValidInput';

const gridSize = 3;

const MarginalCostSession = (): JSX.Element => {
  const [currentSpend, setCurrentSpend] = useState<IMediaCalculatorObj>(
    initialMediaCalculatorObj
  );
  const [previousSpend, setPreviousSpend] = useState<IMediaCalculatorObj>(
    initialMediaCalculatorObj
  );
  const [currentConversions, setCurrentConversions] =
    useState<IMediaCalculatorObj>(initialMediaCalculatorObj);
  const [previousConversions, setPreviousConversions] =
    useState<IMediaCalculatorObj>(initialMediaCalculatorObj);

  const spendDifference = useMemo(
    () => formattedDifference(currentSpend.value, previousSpend.value),
    [currentSpend, previousSpend]
  );

  const conversionDifference = useMemo(
    () =>
      formattedDifference(
        currentConversions.value,
        previousConversions.value,
        true,
        false
      ),
    [currentConversions, previousConversions]
  );

  const currentCPA = useMemo(
    () => formattedDivision(currentSpend.value, currentConversions.value),
    [currentSpend, currentConversions]
  );

  const previousCPA = useMemo(
    () => formattedDivision(previousSpend.value, previousConversions.value),
    [previousSpend, previousConversions]
  );

  const marginalCPA = useMemo(
    () => formattedDivision(spendDifference.value, conversionDifference.value),
    [conversionDifference, spendDifference]
  );

  const csvContent: string[][] = useMemo(() => {
    return [
      ['Type', 'Current', 'Previous', 'Difference'],
      [
        'Spend',
        currentSpend.formattedString,
        previousSpend.formattedString,
        spendDifference.formattedString,
      ],
      [
        'Conversion',
        currentConversions.formattedString,
        previousConversions.formattedString,
        conversionDifference.formattedString,
      ],
      ['CPA', currentCPA.formattedString, previousCPA.formattedString],
      ['Marginal CPA', , , marginalCPA.formattedString],
    ];
  }, [
    currentSpend,
    previousSpend,
    currentConversions,
    previousConversions,
    spendDifference,
    conversionDifference,
    currentCPA,
    previousCPA,
    marginalCPA,
  ]);

  return (
    <Row>
      <Col xs={12}>
        <h3>
          Marginal Cost
          <CSVButton filename="Marginal_Cost" content={csvContent} />
        </h3>
      </Col>
      <ValidInput
        values={currentSpend}
        onChange={setCurrentSpend}
        label="Current Spend:"
      />
      <ValidInput
        values={previousSpend}
        onChange={setPreviousSpend}
        label="Previous Spend:"
      />

      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="=spend-difference"
        inputId="spend-difference-input"
        inputLabel="Spend Difference:"
        value={spendDifference.formattedString}
      />
      <Col xs={3} />

      <ValidInput
        values={currentConversions}
        onChange={setCurrentConversions}
        label="Current Conversion:"
        isWholeNumber
        isCurrency={false}
      />
      <ValidInput
        values={previousConversions}
        onChange={setPreviousConversions}
        label="Previous Conversion:"
        isWholeNumber
        isCurrency={false}
      />

      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="=conversion-difference"
        inputId="conversion-difference-input"
        inputLabel="Conversion Difference:"
        value={conversionDifference.formattedString}
      />
      <Col xs={3} />
      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="=current-cpa"
        inputId="current-cpa-input"
        inputLabel="Current CPA:"
        value={currentCPA.formattedString}
      />
      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="=previous-cpa"
        inputId="previous-cpa-input"
        inputLabel="Previous CPA:"
        value={previousCPA.formattedString}
      />
      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="=marginal-cpa"
        inputId="marginal-cpa-input"
        inputLabel="Marginal CPA:"
        value={marginalCPA.formattedString}
      />
    </Row>
  );
};

export default MarginalCostSession;
