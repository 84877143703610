import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { PageHeader } from '../../../Common';
import { SearchBar } from 'Common/SearchBar';
import ChannelSelect from './ChannelSelect';
import LobSelect from './LobSelect';
import YearSelect from './YearSelect';
import DollarSelect from './DollarSelect';
import QuarterSelect from './QuarterSelect';
import InformationPopup from '../Common/InformationPopup';
import { Box, FormGroup, FormLabel} from '@mui/material';
import SpendForecastService from '../Services/SpendForecastService';
import { ForecastOverview } from './ForecastOverview';

export const ForecastOverviewContainer = (props: any) => {    
    const [fiscalList, setFiscalList] = useState<Array<String>>([]);
    const [fiscalYearisloaded, setFiscalYearisloaded] = useState<Boolean>(false);
    const [selectedFiscal, setSelectedFiscal] = useState<String>(new Date().getMonth()+1>=11?
                                                              "FY-" + (new Date().getFullYear()+1).toString()
                                                              :"FY-"+ new Date().getFullYear().toString());
    const [lobOptions, setLobOptions] = useState<Array<String>>([]);
    const [lobOptionsisloaded, setLobOptionsisloaded] = useState<Boolean>(false);
    const [checkedFB, setCheckedFB] = useState(true);
    const [checkedSEM, setCheckedSEM] = useState(true);    
    const [checkedH1, setCheckedH1] = useState([11,12,1,2,3,4].includes(new Date().getMonth()+1)?true:false);
    const [checkedH2, setCheckedH2] = useState([5,6,7,8,9,10].includes(new Date().getMonth()+1)?true:false);
    const dollarOptions = ["Gross", "Net"];
    const [selectedDollar, setSelectedDollar] = useState<string>("Gross");
    const [searchValue, onChangeSearch] = useState('');
    const [overviewDataisloaded, setOverviewDataisloaded] = useState<Boolean>(false);
    const [overviewData, setOverviewData] = useState<Array<any>>([]);

    //get fiscal years
    useEffect(() => {
        let baseYear = 2022;
        let currentYear = new Date().getFullYear();
        let intervalYears = currentYear - 2022;
        let fiscalList = ["FY-" + baseYear];

        for(let i = 1; i <= intervalYears; i++){
            fiscalList.unshift("FY-"+ (baseYear + i).toString())
        }

        setFiscalList(fiscalList);
        setFiscalYearisloaded(true);
    },[])

    const handleYearChange = (e:any) => {
        setSelectedFiscal(e.innerHTML);    
    }

    //get LOBs
    useEffect(() => {
        async function getLobOptions() {
          try{
            const response = await SpendForecastService.retrieveForecastLOB(selectedFiscal.slice(3,7));
            let tempLob = [];
            response.map((lob)=>tempLob.push({checked:true, name:lob.lob}))
          
            tempLob.sort((a,b)=> a.name > b.name? 1: -1)
            setLobOptions(tempLob);
            setLobOptionsisloaded(true);
          } catch(ex){  
            sessionStorage.removeItem("authenticationState");
            sessionStorage.removeItem("tableConfig");
            window.location.reload(); 
          } 
        }
        setLobOptionsisloaded(false);
        getLobOptions();
    }, []);

    const selectAll = () =>{
        let tempLobOptions = lobOptions.map(lob=>{return {...lob}});
        tempLobOptions.map((lob) => {
          lob['checked'] = true
        })
        setLobOptions(tempLobOptions)    
    }

    const clearAll = () =>{
        let tempLobOptions = lobOptions.map(lob=>{return {...lob}});
        tempLobOptions.map((lob) => {
            lob['checked'] = false
        })
        setLobOptions(tempLobOptions)
    }

    const handleLobChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempLobOptions = lobOptions.map(lob=>{return {...lob}});
        tempLobOptions.map((lob) => {
          if(lob['name'] === event.target.name){
            lob['checked'] = event.target.checked
          }
        })
        setLobOptions(tempLobOptions)
    };

    useEffect(() => {
        async function getForecastOverviewData() {
          try{
            const response = await SpendForecastService.retrieveForecastOverviewData(selectedFiscal.slice(3,7));
            // let tempRows = response.map(r=>{return {...r}})
            // tempRows.map(d=> d.total=0);
    
            setOverviewData(response);
            setOverviewDataisloaded(true);
            
          } catch(ex){  
            sessionStorage.removeItem("authenticationState");
            sessionStorage.removeItem("tableConfig");
            window.location.reload(); 
          } 
        }
        setOverviewDataisloaded(false);
        getForecastOverviewData()
    }, [selectedFiscal]);

    const handleFBChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(checkedSEM)
            setCheckedFB(event.target.checked);
    };
    
    const handleSEMChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(checkedFB)
            setCheckedSEM(event.target.checked);
    };

    const handleH1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(checkedH2)
            setCheckedH1(event.target.checked);
    };
    
    const handleH2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(checkedH1)
            setCheckedH2(event.target.checked);
    };

    const handleDollarChange = (e:any) => {
        setSelectedDollar(e.innerHTML);
    }

    const handleSearchValue = (e: any) => {
        onChangeSearch(e)
    };

    return (
        <>
            <PageHeader>Spend Forecast Overview</PageHeader>
            
            {fiscalYearisloaded && lobOptionsisloaded && overviewDataisloaded?
            <React.Fragment>
              <div style={{padding: "0px 50px 20px 40px"}}>
                <SearchBar
                  placeholder={"Search for LOB, Channel or Product"}
                  handleSearchValue={handleSearchValue}
                />
              </div>
              <Box sx={{ display:'flex', justifyContent:"space-between", marginRight:"50px"}}>
                <div style={{display:'flex'}}>
                    <ChannelSelect
                        checkedFB={checkedFB}
                        checkedSEM={checkedSEM}
                        handleFBChange={handleFBChange}
                        handleSEMChange={handleSEMChange}
                    />
                    <LobSelect
                        lobOptions={lobOptions}
                        handleLobChange={handleLobChange}   
                        selectAll={selectAll}
                        clearAll={clearAll}      
                    />
                    <YearSelect
                        fiscalList={fiscalList}
                        selectedFiscal={selectedFiscal}
                        handleYearChange={handleYearChange}
                    />
                    <QuarterSelect
                        checkedH1={checkedH1}
                        checkedH2={checkedH2}
                        handleH1Change={handleH1Change}
                        handleH2Change={handleH2Change}
                    />
                    <DollarSelect
                        dollarOptions={dollarOptions}
                        selectedDollar={selectedDollar}
                        handleDollarChange={handleDollarChange}
                    />
                    <InformationPopup 
                        currentPage = {"forecast_overview"}
                        selectedFiscal = {selectedFiscal.slice(-4)}
                    />
                </div>
            </Box>
            <ForecastOverview
                selectedFiscal ={selectedFiscal.slice(-4)}
                overviewData = {overviewData}
                checkedH1={checkedH1}
                checkedH2={checkedH2}
                checkedSEM={checkedSEM}
                checkedFB={checkedFB}
                selectedDollar={selectedDollar}
                lobOptions={lobOptions}
                searchValue={searchValue}
            />
            </React.Fragment>
            : <div style={{textAlign:"center"}}><CircularProgress /></div>}
        </>
    )
}