import { createDefaultLoadable, Loadable } from '../../../State/Common/Loadable';

export interface LinesOfBusinessState {
  entities: Array<string>,
  getLinesOfBusinessLoadable: Loadable,
}

export const createDefaultLinesOfBusinessState = (): LinesOfBusinessState => {
  return {
    entities: [],
    getLinesOfBusinessLoadable: createDefaultLoadable()
  };
};
