export const GET_DAG_API_CONFIGS = "GET_DAG_API_CONFIG";
export const GET_DAG_API_CONFIGS_SUCCESS = "GET_DAG_API_CONFIG_SUCCESS";
export const GET_DAG_API_CONFIGS_ERROR = "GET_DAG_API_CONFIG_ERROR";

export const GET_DAG_API_CONFIG_BY_ID = "GET_DAG_API_CONFIG_BY_ID";
export const GET_DAG_API_CONFIG_BY_ID_SUCCESS = "GET_DAG_API_CONFIG_BY_ID_SUCCESS";
export const GET_DAG_API_CONFIG_BY_ID_ERROR = "GET_DAG_API_CONFIG_BY_ID_ERROR";

export const UPDATE_DAG_API_CONFIG = "UPDATE_DAG_API_CONFIG";
export const UPDATE_DAG_API_CONFIG_SUCCESS = "UPDATE_DAG_API_CONFIG_SUCCESS";
export const UPDATE_DAG_API_CONFIG_ERROR = "UPDATE_DAG_API_CONFIG_ERROR";

export const GET_DAG_API_CONFIG_DROPDOWN = "GET_DAG_API_CONFIG_DROPDOWN";
export const GET_DAG_API_CONFIG_DROPDOWN_SUCCESS = "GET_DAG_API_CONFIG_DROPDOWN_SUCCESS";
export const GET_DAG_API_CONFIG_DROPDOWN_ERROR = "GET_DAG_API_CONFIG_DROPDOWN_ERROR";
