import React, {useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const InformationPopup = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => { setModal(!modal)}

    return (
        <div>
            <HelpOutlineIcon onClick={toggle} color='primary' sx={{ marginLeft: "20px", marginBottom:"16px", cursor:"pointer"}}/>
            <Modal size="xl" isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}><h3><b>Known Limitations</b></h3></ModalHeader>
                <ModalBody>
                <div>                    
                    <ul>
                    <li>Loaded Amount: Loaded amount unavailable for Apple Search Ads. For Facebook, the loaded amount is same as the MPA Amount.</li>
                    <li>Spend Amount: Spend amount unavailable for Apple Search Ads. For Facebook's spend amount, the amount is the sum of all the amount from November to the current month</li>
                    <li>MPA Amount: MPA amount for SEM includes amount for Apple Search Ads</li>
                    <li>Tax for US Accounts is 0</li>
                    </ul>
                </div>
                </ModalBody>
                <ModalFooter>
                <Button color="danger" onClick={toggle}>Close Window</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default InformationPopup;