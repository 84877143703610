import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Button, ButtonToolbar } from 'reactstrap';

import MpaSigningProcessModal from './MpaSigningProcessModal'

import { getMpasCsv } from '../../../State/Mpa/Actions/MpaActions';
import history from '../../../Utils/History'

const MpaToolbar = (props: any) => {
  const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);
  const dispatch = useDispatch();

  const toggleExportDropdown = () => setExportDropdownOpen(prevState => !prevState);

  const exportMpaData = () => { dispatch(getMpasCsv()); }

  return (
    <ButtonToolbar style={{'padding': '1.5rem 0'}}>
      {props.showAddMpaButton &&
        <Button color="primary"             
        onClick={()=>{history.push('/map/mpa/add','/map/mpa')}}
        className="mr-1">Add New MPA</Button>
      }
      <Button color="primary" onClick={exportMpaData} className="mr-1">Export all MPA data to CSV</Button>
      {/* <Dropdown isOpen={isExportDropdownOpen} toggle={toggleExportDropdown} className="mr-1">
        <DropdownToggle caret color="primary">Export</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={exportMpaData}>All MPA data to CSV</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={props.handleExportDataToBQ} disabled>All MPA data to BigQuery</DropdownItem>
        </DropdownMenu>
      </Dropdown> */}
      <MpaSigningProcessModal />
    </ButtonToolbar>
  );
};

export default MpaToolbar;
