import React, { useCallback, useState } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import {
  IMediaCalculatorObj,
  initialMediaCalculatorObj,
  numberToMediaCalculatorObj,
} from './formulation';

interface IValidInput {
  values: IMediaCalculatorObj;
  onChange: (values: IMediaCalculatorObj) => void;
  label: string;
  isWholeNumber?: boolean;
  gridSize?: number;
  otherFormat?: (value: number) => IMediaCalculatorObj;
  isCurrency?: boolean;
}

const typeOfMediaObj = {
  number: 'value',
  text: 'formattedString',
};

const ValidInput = ({
  values = initialMediaCalculatorObj,
  onChange,
  label,
  isWholeNumber = false,
  isCurrency = true,
  gridSize = 3,
  otherFormat,
}: IValidInput): JSX.Element => {
  const [inputType, setInputType] = useState<'number' | 'text'>('text');

  const handleChange = useCallback(
    (event) => {
      const value = event.target.value
        ? +(+event.target.value)?.toFixed(isWholeNumber ? 0 : 2)
        : null;

      onChange(
        !otherFormat
          ? numberToMediaCalculatorObj(value, isWholeNumber, isCurrency)
          : otherFormat(value)
      );
    },
    [isWholeNumber, onChange, isWholeNumber, otherFormat, isCurrency]
  );

  return (
    <Col xs={gridSize}>
      <FormGroup>
        <Label>{label}</Label>
        <Label style={{ color: 'red' }}>*</Label>
        <Input
          onBlur={() => setInputType('text')}
          onFocus={() => setInputType('number')}
          type={inputType}
          onChange={handleChange}
          value={values[typeOfMediaObj[inputType]]}
        />
      </FormGroup>
    </Col>
  );
};

export default ValidInput;
