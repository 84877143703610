import { createDefaultLoadable, Loadable } from '../../../State/Common/Loadable';

export interface PipelineState {
  status: string,
  startDate: string,
  isRunnable: boolean,
  lastActionTime: number,
  getPipelineStatusLoadable: Loadable,
  updatePipelineStatusLoadable: Loadable,
}

export const createDefaultPipelineState = (): PipelineState => {
  return {
    status: '',
    startDate: '',
    isRunnable: false,
    lastActionTime: +new Date() - 24*3600*1000,
    getPipelineStatusLoadable: createDefaultLoadable(),
    updatePipelineStatusLoadable: createDefaultLoadable()
  }
};

export const PERIOD = 10000;
