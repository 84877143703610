import React, { useMemo } from "react";
import { Alert, Col, Row } from "reactstrap";
import ApiConfigDropdown from "./ApiConfigDropdown";
import { GridDatepicker } from "Common";
import { IFormValues } from "./FormValues";

const RangeOptions = {
  yest: "Yesterday",
  last3: "Last 3 Days",
  last7: "Last 7 Days",
  last14: "Last 14 Days",
  last30: "Last 30 Days",
  cust_d: "Custom Range",
};
interface IDateSettingsForm {
  CustomDateOnChange: (date: string, isStartDate: boolean) => void;
  formValues: IFormValues;
  onRangeSelect: (value: string) => void;
}

const DateSettingsForm = ({ CustomDateOnChange, formValues, onRangeSelect }: IDateSettingsForm): JSX.Element => {
  const isShowCustomRange = useMemo(() => formValues.date_settings === "cust_d", [formValues.date_settings]);

  return (
    <Row>
      <Col xs={12}>
        <h3>Date Settings</h3>

        {isShowCustomRange && (
          <Alert color="primary">
            <strong>Notes:</strong>
            <br />
            Custom Range should not be longer than 180 days.
          </Alert>
        )}
      </Col>
      <ApiConfigDropdown
        inputLabel="Scheduled Range"
        itemKeyPrefix="scheduled-range"
        options={RangeOptions}
        onSelect={onRangeSelect}
        value={formValues.date_settings}
      />
      {isShowCustomRange && (
        <GridDatepicker
          gridSize={3}
          inputId="scheduled-start-date"
          inputLabel="Start:"
          date={formValues.custom_start_date}
          handleChange={(event) => CustomDateOnChange(event.toISOString(), true)}
          startDateChanged
          dateFormat="yyyy-MM-dd"
        />
      )}
      {isShowCustomRange && (
        <GridDatepicker
          gridSize={3}
          inputId="scheduled-end-date"
          inputLabel="End:"
          date={formValues.custom_end_date}
          handleChange={(event) => CustomDateOnChange(event.toISOString(), false)}
          startDateChanged
          dateFormat="yyyy-MM-dd"
        />
      )}
    </Row>
  );
};

export default DateSettingsForm;
