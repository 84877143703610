import React  from 'react'
import PropTypes from 'prop-types';
import { Input, FormGroup, Form } from 'reactstrap'

export const SearchBar = (props: any) => {
  
  return (
    <Form>
      <FormGroup style={{"margin": "0"}}>
        <Input
          label="Search"
          placeholder={props.placeholder}
          margin="normal"
          variant="outlined"
          value={props.searchInput}
          onChange={(e)=>props.handleSearchValue(e.target.value)}
        />
      </FormGroup>
    </Form>
  )
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  searchInput: PropTypes.string,
  handleSearchValue: PropTypes.func
};