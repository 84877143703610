
import { createDefaultLoadable, Loadable } from '../../../State/Common/Loadable';

export interface BudgetOrderSearchIndexInterface{
  accountName: string,
  clientId: string
}

export interface BudgetOrderSearchIndexState {
  searchIndex: Array<BudgetOrderSearchIndexInterface>,
  getSearchIndexLoadable: Loadable,
}

export const createDefaultBudgetOrderSearchIndexState = (): BudgetOrderSearchIndexState => {
  return {
    searchIndex: [],
    getSearchIndexLoadable: createDefaultLoadable()
  };
};

export const buildSearchIndex = (data: any) => {
  return data.map((d: any) => {
    return { accountName: d['accountName'], clientId: d['clientID'] };
  })
};
