import { GridInput } from 'Common';
import React, { useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import {
  IMediaCalculatorObj,
  formattedMultiplication,
  formattedSum,
  initialMediaCalculatorObj,
  numberToMediaCalculatorObj,
} from '../formulation';
import CSVButton from '../CSVButton';
import ValidInput from '../ValidInput';

const gridSize = 3;

const NetBudgetSession = (): JSX.Element => {
  const [workingMedia, setWorkingMedia] = useState<IMediaCalculatorObj>(
    initialMediaCalculatorObj
  );

  const budget = useMemo(() => {
    const commission = formattedMultiplication(workingMedia.value, 0.015);
    const subTotal = formattedSum(workingMedia.value, commission.value);
    const hst = formattedMultiplication(subTotal.value, 0.13);
    const MPATotal = formattedSum(subTotal.value, hst.value);

    return {
      MPATotal,
      commission,
      subTotal,
      hst,
      csvContent: [
        ['MPA Total', MPATotal.formattedString],
        ['HST', hst.formattedString],
        ['Sub-Total', subTotal.formattedString],
        ['Commission', commission.formattedString],
        ['Working Media', workingMedia.formattedString],
      ],
    };
  }, [workingMedia]);

  return (
    <Row>
      <Col xs={12}>
        <h3>
          Budget Breakdowns - Net Budget
          <CSVButton
            filename="Net_Budget_Breakdowns"
            content={budget.csvContent}
          />
        </h3>
      </Col>
      <ValidInput
        values={workingMedia}
        onChange={setWorkingMedia}
        label="Working Media:"
      />

      <Col xs={9} />
      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="commission"
        inputId="commission-input"
        inputLabel="Commission:"
        value={budget.commission.formattedString}
      />
      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="sub-total"
        inputId="sub-total-input"
        inputLabel="Sub-Total:"
        value={budget.subTotal.formattedString}
      />
      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="hst"
        inputId="hst-input"
        inputLabel="HST:"
        value={budget.hst.formattedString}
      />
      <GridInput
        isDisabled
        gridSize={gridSize}
        inputAria="mpa-total"
        inputId="mpa-total-input"
        inputLabel="MPA Total:"
        value={budget.MPATotal.formattedString}
      />
    </Row>
  );
};

export default NetBudgetSession;
