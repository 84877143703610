import { createDefaultLoadable, Loadable } from '../../../State/Common/Loadable';

export interface PublishLogsState {
  getPublishLogsLoadable: Loadable,
}

export const createDefaultPublishLogsState = (): PublishLogsState => {
  return {
    getPublishLogsLoadable: createDefaultLoadable()
  }
};
