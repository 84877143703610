import ApiService from './ApiService';

export default class AnnualBudgetService {
  static createAnnualBudget(annualBudgetData: any): Promise<any> {
    return ApiService.post({ relativeUrl: `/annual-budgets/api/annual-budgets`, body: annualBudgetData });
  };

  static deleteSelectedAnnualBudget(annualBudgetId: string, payload: string): Promise<any> {
    return ApiService.delete({ relativeUrl: `/annual-budgets/api/annual-budgets/${annualBudgetId}`, body: payload });
  }

  static retrieveAnnualBudgets(): Promise<Array<any>> {
    return ApiService.get({ relativeUrl: '/annual-budgets/api/annual-budgets' });
  };

  static retrieveAnnualBudgetsCsv(): Promise<any> {
    return ApiService.get({ relativeUrl: '/annual-budgets/api/annual-budgets-file' });
  };

  static retrieveSelectedAnnualBudget(annualBudgetId: string): Promise<Array<any>> {
    return ApiService.get({ relativeUrl: `/annual-budgets/api/annual-budgets/${annualBudgetId}` });
  };

  static updateSelectedAnnualBudget(annualBudgetId: string, annualBudgetData: any): Promise<any> {
    return ApiService.put({
      relativeUrl: `/annual-budgets/api/annual-budgets/${annualBudgetId}`,
      body: annualBudgetData
    });
  };
};
