import ApiService from '../../../Services/ApiService';

export default class SpendForecastService {
  static retrieveForecastData(year:string):Promise<Array<any>> {
    return ApiService.get({ relativeUrl: `/forecast/api/forecast/${year}` });
  }

  static retrieveForecastLOB(year:string):Promise<Array<any>> {
    return ApiService.get({ relativeUrl: `/forecast/api/lob/${year}` });
  }

  static retrieveForecastOverviewData(year:string):Promise<Array<any>> {
    return ApiService.get({ relativeUrl: `/forecast/api/overview/${year}` });
  }

};
