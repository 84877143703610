export const GET_ANCHOR_CIDS = 'GET_ANCHOR_CIDS';
export const GET_ANCHOR_CIDS_SUCCESS = 'GET_ANCHOR_CIDS_SUCCESS';
export const GET_ANCHOR_CIDS_ERROR = 'GET_ANCHOR_CIDS_ERROR';

export const GET_DROPDOWN_OPTIONS = 'GET_DROPDOWN_OPTIONS';
export const GET_DROPDOWN_OPTIONS_SUCCESS = 'GET_DROPDOWN_OPTIONS_SUCCESS';
export const GET_DROPDOWN_OPTIONS_ERROR = 'GET_DROPDOWN_OPTIONS_ERROR';

export const GET_NEW_MPA_NUMBER = 'GET_NEW_MPA_NUMBER';
export const GET_NEW_MPA_NUMBER_SUCCESS = 'GET_NEW_MPA_NUMBER_SUCCESS';
export const GET_NEW_MPA_NUMBER_ERROR = 'GET_NEW_MPA_NUMBER_ERROR';

export const GET_PRODUCT_OPTIONS = 'GET_PRODUCT_OPTIONS';
export const GET_PRODUCT_OPTIONS_SUCCESS = 'GET_PRODUCT_OPTIONS_SUCCESS';
export const GET_PRODUCT_OPTIONS_ERROR = 'GET_PRODUCT_OPTIONS_ERROR';

export const GET_CHANNELS_FOR_SELECTED_PRODUCT = 'GET_CHANNELS_FOR_SELECTED_PRODUCT';
export const GET_CHANNELS_FOR_SELECTED_PRODUCT_SUCCESS = 'GET_CHANNELS_FOR_SELECTED_PRODUCT_SUCCESS';
export const GET_CHANNELS_FOR_SELECTED_PRODUCT_ERROR = 'GET_CHANNELS_FOR_SELECTED_PRODUCT_ERROR';

export const GET_CHANNEL_OPTIONS = 'GET_CHANNEL_OPTIONS';
export const GET_CHANNEL_OPTIONS_SUCCESS = 'GET_CHANNEL_OPTIONS_SUCCESS';
export const GET_CHANNEL_OPTIONS_ERROR = 'GET_CHANNEL_OPTIONS_ERROR';
