import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EditMpa from './EditMpa';
import { AppPage, PageHeader, WithMultipleLoading } from '../../../Common';

import { getUsername } from '../../../State/Authentication/Selectors/AuthenticationSelectors';

import { getUserPermissions } from '../../../State/Authentication/Selectors/AuthenticationSelectors';
import { getMpaState } from '../../../State/Mpa/Selectors/MpaSelectors';
import { getFormInfoState } from '../../../State/FormInfo/Selectors/FormInfoSelectors';
import { getProductOptions } from '../../../State/FormInfo/Actions/FormInfoActions';
import { setSelectedMpaId } from '../../../State/MpaFormContext/Actions/MpaFormContextActions';

const EditMpaWithMultipleLoading = WithMultipleLoading(EditMpa);

const EditMpaContainer = (props: any) => {
  // -- implementing redux

  const username = useSelector(getUsername);

  // editMpaState carries all the editForm data
  const editMpaState = useSelector(getMpaState);
  const formInfoState= useSelector(getFormInfoState);

  // mpaFormContextState holds the selected rowID/mpaId
  const userPermissions = useSelector(getUserPermissions);

  const dispatch = useDispatch();

  const {params} = props.match;

  //send a dispatch if user selects a new row
  useEffect(() => {
    if (params.id) {
      dispatch(setSelectedMpaId(params.id));
    }
  }, [
    params.id,
    dispatch,
    setSelectedMpaId
  ]);


  // send a dispatch to get cid and product options
  useEffect(() => {
    if (!formInfoState.getProductOptionsLoadable.isReceived) {
        dispatch(getProductOptions());
    }
  }, [formInfoState.getProductOptionsLoadable.isReceived, dispatch]);

  return (
    <>
      <PageHeader>Edit MPA</PageHeader>
      <AppPage>
        <EditMpaWithMultipleLoading
          loadables={[
            editMpaState.getSelectedMpaLoadable,
            formInfoState.getProductOptionsLoadable
            // formInfoState.getDropdownOptionsLoadable
          ]}
          isFormDisabled={editMpaState.updateSelectedMpaLoadable.isLoading || !userPermissions.includes('change_mpa')}
          dropdownOptions={formInfoState.productOptionsWithoutChannel}
          mpaIntakeList={editMpaState.selectedMpa}
          userName={username}
        />
      </AppPage>
    </>
  );
};

export default EditMpaContainer;
